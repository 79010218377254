import { OrthographicCamera, PerspectiveCamera, Scene, Sphere } from 'three';

import {
  Annotation3dLines,
  Annotation3dPoints,
  Annotation3dPolygons,
  AnyFunction,
  ColorsThreeD,
  EventBusNames,
  HexColor,
  IAnnotationBase,
  IAnnotations3dGeometry,
} from '@agerpoint/types';
import { eventBus } from '@agerpoint/utilities';

import { AnnotationsStore } from './annotations-3d/annotations-store';

export class AnnotationBase implements IAnnotationBase {
  static annoStore: AnnotationsStore = new AnnotationsStore();

  scene: Scene;
  perspectiveCamera: PerspectiveCamera;
  canvas: HTMLCanvasElement | undefined;
  isPotree: boolean;
  orthographicCamera: OrthographicCamera;
  color: ColorsThreeD | HexColor = ColorsThreeD.Cyan;
  listeners: AnyFunction[] = [];

  selectedObject: {
    id: string;
    type: Annotation3dPoints | Annotation3dLines | Annotation3dPolygons;
  } | null = null;

  constructor(
    scene: Scene,
    perspectiveCamera: PerspectiveCamera,
    isPotree: boolean,
    canvas?: HTMLCanvasElement
  ) {
    this.scene = scene;
    if (canvas) {
      this.canvas = canvas;
    }
    this.perspectiveCamera = perspectiveCamera;
    this.isPotree = isPotree;

    const frustumSize = 1000;
    const aspect = window.innerWidth / window.innerHeight;
    this.orthographicCamera = new OrthographicCamera(
      (frustumSize * aspect) / -2,
      (frustumSize * aspect) / 2,
      frustumSize / 2,
      frustumSize / -2,
      1,
      2000
    );
    this.orthographicCamera.position.set(0, 0, 100);
  }

  destroy() {}

  public notifyListeners() {
    const annotationGeom = AnnotationBase.annoStore.getAnnotationGeometry();
    (annotationGeom as IAnnotations3dGeometry).selected = {
      id: this.selectedObject?.id || '',
      type: this.selectedObject?.type || Annotation3dPoints.AnnotationPoint,
    };
    eventBus.dispatch(EventBusNames.AnnotationGeometryUpdate, {
      detail: annotationGeom,
    });
  }
}

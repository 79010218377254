import { PerspectiveCamera, Scene, Vector3 } from 'three';

import { EventBusNames, IPoint2d, MarkerObjOptions } from '@agerpoint/types';

import { AnnotationBase } from '../annotations.base';
import { IconMarker } from './icon-marker';

export class Point2d extends AnnotationBase implements IPoint2d {
  eventName: EventBusNames;
  pointId: string;
  position: Vector3;
  options: MarkerObjOptions;
  public marker: IconMarker;

  constructor(
    eventName: EventBusNames,
    pointId: string,
    position: Vector3,
    options: MarkerObjOptions,
    scene: Scene,
    perspectiveCamera: PerspectiveCamera,
    canvas: HTMLCanvasElement
  ) {
    super(scene, perspectiveCamera, false, canvas);
    if (!canvas || !this.canvas) {
      throw new Error('Canvas is required');
    }
    this.eventName = eventName;
    this.pointId = pointId;
    this.position = position;
    this.options = options;
    this.perspectiveCamera = perspectiveCamera;
    this.marker = new IconMarker(
      this.eventName,
      this.pointId,
      this.options,
      this.perspectiveCamera,
      this.canvas
    );
    this.marker.setPosition(this.position);
  }

  dispose() {
    this.marker.dispose();
  }

  render() {
    this.marker.updatePosition();
  }
}

import { ReactNode, createContext } from 'react';
import { VariableSizeList } from 'react-window';

export interface ICloudDatatable<T> {
  id: string;
  data: T[];
  columns: ICloudDatatableColumn<T>[];
  loading?: boolean;
  overlay?: () => React.ReactNode;
  extraRow?: () => React.ReactNode;
  pagination?: ICloudDatatablePagination;
  rowHeight: (data?: T) => number;
  minWidth?: number | string;
  sort?: ICloudDatatableColumnSorting;
  setSort?: (options: ICloudDatatableColumnSorting) => void;
  cellOnClick?: RowCallbackGenerator<T>;
  style?: ICloudDatatableStyle<T>;
  // datatableRef?: React.MutableRefObject<VariableSizeList<
  //   CloudDatatableItemData<T>
  // > | null>;
}

export interface ICloudDatatableStyle<T> {
  minWidth?: string;
  wrapper?: string;
  headers?: string;
  header?: string;
  row?: (data: T | undefined, index: number) => string;
}

export interface ICloudDatatablePagination {
  loadNextPage?: () => void;
  threshold?: number;
}

interface ICloudDatatableColumnBase<T> {
  onClickId?: string;
  header: ReactNode;
  cell: (row: T, index: number, isHovered: boolean) => ReactNode;
  flex?: number;
  fixed?: number;
  style?: ICloudDatatableColumnStyle;
  visible?: boolean;
}

interface ICloudDatatableSortableColumn<T>
  extends ICloudDatatableColumnBase<T> {
  sortKey: string;
}

interface ICloudDatatableClickableColumn<T>
  extends ICloudDatatableColumnBase<T> {
  onClick: () => void;
}

export type ICloudDatatableColumn<T> =
  | ICloudDatatableSortableColumn<T>
  | ICloudDatatableClickableColumn<T>
  | ICloudDatatableColumnBase<T>;

export function isClickableColumn<T>(
  column: ICloudDatatableColumn<T>
): column is ICloudDatatableClickableColumn<T> {
  return 'onClick' in column;
}

export function isSortableColumn<T>(
  column: ICloudDatatableColumn<T>
): column is ICloudDatatableSortableColumn<T> {
  return 'sortKey' in column;
}

export interface ICloudDatatableColumnSorting {
  key: string | null | undefined;
  order: 'asc' | 'desc';
}

export interface ICloudDatatableColumnStyle {
  header?: string;
  cell?: string;
  overflowStyle?: CloudDatatableColumnOverflowStyle;
}

export type CloudDatatableColumnOverflowStyle =
  | 'truncate'
  | 'overflow-hidden'
  | 'line-clamp-2 break-all'
  | 'line-clamp-3 break-all'
  | 'line-clamp-2 break-words'
  | 'line-clamp-3 break-words';

export interface CloudDatatableItemData<T> {
  data: T[];
  columns: ICloudDatatableColumn<T>[];
  cellOnClick?: RowCallbackGenerator<T>;
  extraRow?: React.ReactNode;
  loading?: boolean;
}

export type RowCallback<T> = (
  row: T,
  event: React.MouseEvent<HTMLDivElement, MouseEvent>
) => void;
export type RowCallbackGenerator<T> = (
  columnName: string
) => RowCallback<T> | void;

export const StyleContext = createContext<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ICloudDatatableStyle<any> | undefined
>(undefined);
export const RefContext = createContext<
  | {
      setWrapperRef: (ref: HTMLDivElement) => void;
      setHeadersRef: (ref: HTMLDivElement) => void;
      setBodyRef: (ref: HTMLDivElement) => void;
      setDatatableRef: (ref: VariableSizeList) => void;
      wrapperRef: HTMLDivElement | null;
      headersRef: HTMLDivElement | null;
      bodyRef: HTMLDivElement | null;
      datatableRef: VariableSizeList | null;
    }
  | undefined
>(undefined);

import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { UseGetReturn } from 'restful-react';
import { Vector3 } from 'three';

import { CaptureImage, useGetCaptureImagesByCaptureId } from '@agerpoint/api';
import { Annotation3dPoints, EventBusNames, IViewer } from '@agerpoint/types';
import { eventBus, useGlobalStore } from '@agerpoint/utilities';

import { PotreeControlsFloatComponent } from '../../potree-viewer';

interface Qaqc3dViewerProps {
  hoistViewer: (
    viewer: React.MutableRefObject<IViewer | undefined> | undefined
  ) => void;
}

export const Qaqc3dViewer = ({ hoistViewer }: Qaqc3dViewerProps) => {
  const params = useParams();
  const navigate = useNavigate();

  const captureId: number = Number.parseInt(params?.captureId ?? '');
  const initialEptId: number = Number.parseInt(params?.eptId ?? '');
  const extId: number = Number.parseInt(params?.extId ?? '');

  const { data: captureImages } = useGetCaptureImagesByCaptureId({
    captureId,
  }) as unknown as UseGetReturn<CaptureImage[], void, void, unknown>;

  const [eptId] = useState<number>(initialEptId);

  const { twoDimensionDrawingIsActive } = useGlobalStore();

  const [viewer] = useState<React.MutableRefObject<IViewer | undefined>>();
  const [viewerReady, setViewerReady] = useState(false);

  const handlePointCloudLoaded = () => {
    setViewerReady(true);
  };

  useEffect(() => {
    setViewerReady(false);
  }, [eptId]);

  useEffect(() => {
    const eventId = eventBus.on(
      EventBusNames.PointCloudLoaded,
      handlePointCloudLoaded,
      true
    );
    return () => {
      eventBus.remove(
        EventBusNames.PointCloudLoaded,
        handlePointCloudLoaded,
        eventId
      );
    };
  }, []);

  useEffect(() => {
    if (viewerReady && captureImages) {
      captureImages.forEach((image) => {
        const { id, x, y, z } = image;
        if (!id || !x || !y || !z) return;
        viewer?.current?.add3dPointMarker(
          id.toString(),
          new Vector3(x, y, z),
          Annotation3dPoints.CaptureImageLocation,
          true
        );
      });
    }
  }, [viewerReady, captureImages, viewer]);

  useEffect(() => {
    hoistViewer(viewer);
  }, [viewer]);

  useEffect(() => {
    if (!eptId) return;
    if (params?.eptId !== eptId.toString()) {
      navigate(`/ops/qaqc/${captureId}/${eptId}/${extId}`);
    }
  }, [eptId]);

  useEffect(() => {
    if (!viewer?.current) return;
    viewer?.current.clearAllMarkers();
    viewer?.current.clearAllTrunkLines();
  }, [extId, viewer]);

  return (
    <div className="relative w-full h-full flex flex-row text-white">
      {twoDimensionDrawingIsActive && (
        <div
          className="z-100 absolute top-0 left-0 pointer-events-auto"
          id="konva-root"
          style={{
            width: '100%',
            height: '100%',
          }}
        ></div>
      )}
      <PotreeControlsFloatComponent
        viewer={viewer}
        captureId={captureId}
        size="default"
      />
    </div>
  );
};

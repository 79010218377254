import { useCapturesViewerContext } from 'libs/feature/src/captures-viewer';
import { useThreeDAnnotationsPluginQueries } from 'libs/feature/src/three-d-wrapper/plugins/annotations-plugin/three-d-annotations-plugin-queries';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Raycaster, Vector2 } from 'three';

import { APIClient } from '@agerpoint/api';
import { CloudButton } from '@agerpoint/cloud/components';
import { AnnotationsController } from '@agerpoint/three-d-viewer';
import {
  Annotation3dLines,
  Annotation3dPoints,
  Annotation3dPolygons,
  IGs3dViewerController,
  IPotreeViewerController,
} from '@agerpoint/types';
import { AnnotationGroupName } from '@agerpoint/utilities';

import './shared-toolbar.scss';

export const SharedThreeDAnnotationToolbar = ({
  viewerController,
  isPotree = false,
}: {
  viewerController: IGs3dViewerController | IPotreeViewerController;
  isPotree?: boolean;
}) => {
  const { selectedCaptureJob, setAnnotationCaptureObjects } =
    useCapturesViewerContext();

  const { captureObjectsBySelectedCaptureJobQuery } =
    useThreeDAnnotationsPluginQueries();
  const [point3dInProgress, setPoint3dInProgress] = useState(false);
  const [line3dInProgress, setLine3dInProgress] = useState(false);
  const [polygon3dInProgress, setPolygon3dInProgress] = useState(false);
  const [count3dInProgress, setCount3dInProgress] = useState(false);

  const annoCtrl = useRef<AnnotationsController | null>(null);

  const setupCaptureObjects = useCallback(
    (cObjects: APIClient.CaptureObject[]) => {
      const filteredObjects = cObjects.filter((c) => {
        if (c.captureJobId !== selectedCaptureJob?.id) return false;
        // check custom attributes for attributeName _customType
        const customType = c?.captureObjectCustomAttributes?.find(
          (attr) => attr.attributeName === '_customType'
        );
        if (!customType || !customType?.attributeValue) return false;
        return [
          Annotation3dPoints.AnnotationPoint,
          Annotation3dLines.AnnotationLine,
          Annotation3dPolygons.AnnotationPolygon,
          Annotation3dPoints.MultiPointMarker,
        ].includes(
          customType.attributeValue as
            | Annotation3dLines
            | Annotation3dPoints
            | Annotation3dPolygons
        );
      });
      annoCtrl?.current?.seedCaptureObjects(filteredObjects);
      setAnnotationCaptureObjects?.(filteredObjects);
    },
    []
  );

  useEffect(() => {
    if (
      !viewerController?.info.viewerReady ||
      !viewerController?.info.sceneLoaded
    )
      return;
    const captureObjects =
      (captureObjectsBySelectedCaptureJobQuery?.data as APIClient.CaptureObject[]) ??
      [];

    if (captureObjects.length > 0) {
      setupCaptureObjects(captureObjects);
    }
  }, [
    captureObjectsBySelectedCaptureJobQuery?.data,
    viewerController?.info?.sceneLoaded,
  ]);

  useEffect(() => {
    if (!viewerController?.info?.viewerReady) {
      return;
    }
    if (!viewerController?.threeViewer?.camera) return;
    if (!viewerController?.threeViewer?.scene) return;
    if (!viewerController?.threeViewer?.targetEl) return;
    annoCtrl.current = new AnnotationsController(
      viewerController?.threeViewer?.camera,
      viewerController.threeViewer?.scene,
      viewerController.threeViewer?.targetEl,
      viewerController.mousePosition,
      isPotree
    );
    return () => {
      annoCtrl.current?.destroy();
    };
  }, [viewerController?.info?.viewerReady]);

  useEffect(() => {
    if (
      !viewerController?.info?.viewerReady ||
      !viewerController?.threeViewer?.targetEl ||
      !viewerController?.threeViewer?.camera
    ) {
      return;
    }
    const raycaster = new Raycaster();
    raycaster.params.Sprite = { threshold: 0.1 }; // Adjust threshold as needed
    raycaster.camera = viewerController?.threeViewer?.camera;

    const mouse = new Vector2();
    const onMouseDown = (event: MouseEvent) => {
      if (
        !viewerController.threeViewer?.targetEl ||
        !viewerController?.threeViewer?.camera
      )
        return;
      event.preventDefault();
      event.stopPropagation();
      // Correct for elements offset
      const rect =
        viewerController.threeViewer?.targetEl.getBoundingClientRect();
      mouse.x = ((event.clientX - rect.left) / rect.width) * 2 - 1;
      mouse.y = -((event.clientY - rect.top) / rect.height) * 2 + 1;
      raycaster.setFromCamera(mouse, viewerController?.threeViewer?.camera);
      const group =
        viewerController?.threeViewer?.scene.getObjectByName(
          AnnotationGroupName
        );
      if (!group) return;
      const intersects = raycaster.intersectObjects(group?.children, true);
      if (intersects.length > 0) {
        const selectedObject = intersects[0].object as any;
        annoCtrl.current?.objectClicked(selectedObject);
      }
      annoCtrl.current?.sceneWasClicked();
    };

    const mouseUp = (event: MouseEvent) => {
      annoCtrl.current?.mouseClick();
    };

    viewerController.threeViewer?.targetEl.addEventListener(
      'mouseup',
      mouseUp,
      false
    );

    viewerController.threeViewer?.targetEl.addEventListener(
      'mousedown',
      onMouseDown,
      false
    );

    return () => {
      if (!viewerController.threeViewer?.targetEl) return;
      viewerController.threeViewer?.targetEl.removeEventListener(
        'mousedown',
        onMouseDown
      );
      viewerController.threeViewer?.targetEl.removeEventListener(
        'mouseup',
        mouseUp
      );
    };
  }, [
    viewerController.info?.viewerReady,
    viewerController.threeViewer?.targetEl,
  ]);

  // const finishDrawing = useCallback(() => {
  //   if (line3dInProgress) {
  //     setLine3dInProgress(false);
  //     annoCtrl?.current?.finishCreating3dLine();
  //   }
  //   if (polygon3dInProgress) {
  //     setPolygon3dInProgress(false);
  //     annoCtrl?.current?.finishCreating3dPolygon();
  //   }
  //   if (count3dInProgress) {
  //     setCount3dInProgress(false);
  //     annoCtrl?.current?.finishCreating3dMultiPoint();
  //   }
  // }, [line3dInProgress, polygon3dInProgress, count3dInProgress]);

  const cancelDrawing = useCallback(() => {
    if (point3dInProgress) {
      setPoint3dInProgress(false);
      annoCtrl?.current?.cancelCreating3dPoint();
    }
    if (line3dInProgress) {
      setLine3dInProgress(false);
      annoCtrl?.current?.cancelCreating3dLine();
    }
    if (polygon3dInProgress) {
      setPolygon3dInProgress(false);
      annoCtrl?.current?.cancelCreating3dPolygon();
    }
    if (count3dInProgress) {
      setCount3dInProgress(false);
      annoCtrl?.current?.cancelCreating3dMultiPoint();
    }
  }, [
    line3dInProgress,
    polygon3dInProgress,
    count3dInProgress,
    point3dInProgress,
  ]);

  const canDrawRequestedType = useCallback(() => {
    if (!annoCtrl?.current) return false;
    const pntInProgress = annoCtrl?.current?.creating3dPoint ?? false;
    if (pntInProgress) return false;
    if (line3dInProgress || polygon3dInProgress || count3dInProgress)
      return false;
    return true;
  }, []);

  return (
    <>
      <div className="absolute z-30 bottom-14 left-1/2 -translate-x-1/2 flex flex-row bg-white shadow-lg rounded-lg p-1 gap-1">
        <CloudButton.Icon
          id="point-3d-marker"
          leadingIcon="location-dot"
          onClick={(e) => {
            e.stopPropagation();
            const canDraw = canDrawRequestedType();

            if (!canDraw) {
              // annoCtrl?.current?.finishCreating3dPoint(true);
              return;
            }

            if (viewerController?.info?.viewerReady) {
              cancelDrawing();
              setPoint3dInProgress(true);
              annoCtrl?.current?.startCreating3dPoint();
            }
          }}
          toggled={point3dInProgress}
        />
        <CloudButton.Icon
          id="multi-point-3d-marker"
          leadingIcon="ball-pile"
          onClick={(e) => {
            e.stopPropagation();
            const canDraw = canDrawRequestedType();

            if (!canDraw) {
              // annoCtrl?.current?.finishCreating3dPoint(true);
              return;
            }

            if (viewerController?.info?.viewerReady) {
              cancelDrawing();
              setCount3dInProgress(true);
              annoCtrl?.current?.startCreating3dMultiPoint();
            }
          }}
          toggled={count3dInProgress}
        />
        <CloudButton.Icon
          id="line-3d-marker"
          leadingIcon="ruler"
          onClick={(e) => {
            e.stopPropagation();
            const canDraw = canDrawRequestedType();

            if (!canDraw) {
              // annoCtrl?.current?.finishCreating3dPoint(true);
              return;
            }

            if (viewerController?.info?.viewerReady && annoCtrl?.current) {
              cancelDrawing();
              setLine3dInProgress(true);
              annoCtrl?.current.startCreating3dLine();
            }
          }}
          toggled={line3dInProgress}
        />
        <CloudButton.Icon
          id="polygon-3d-marker"
          leadingIcon="draw-polygon"
          onClick={(e) => {
            e.stopPropagation();
            const canDraw = canDrawRequestedType();

            if (!canDraw) {
              // annoCtrl?.current?.finishCreating3dPoint(true);
              return;
            }

            if (viewerController?.info?.viewerReady && annoCtrl?.current) {
              cancelDrawing();
              setPolygon3dInProgress(true);
              annoCtrl?.current.startCreating3dPolygon();
            }
          }}
          toggled={polygon3dInProgress}
        />
      </div>
      {point3dInProgress && (
        <div className="pointer-events-none absolute bottom-28 left-1/2 -translate-x-1/2 py-3 px-6 text-white bg-black bg-opacity-40 rounded-lg">
          Click to place a point marker
        </div>
      )}
      {count3dInProgress && (
        <div className="pointer-events-none absolute bottom-28 left-1/2 -translate-x-1/2 py-3 px-6 text-white bg-black bg-opacity-40 rounded-lg">
          Click an object to start counting
        </div>
      )}
      {line3dInProgress && (
        <div className="pointer-events-none absolute bottom-28 left-1/2 -translate-x-1/2 py-3 px-6 text-white bg-black bg-opacity-40 rounded-lg">
          Click the first point to start the measurement
        </div>
      )}
      {polygon3dInProgress && (
        <div className="pointer-events-none absolute bottom-28 left-1/2 -translate-x-1/2 py-3 px-6 text-white bg-black bg-opacity-40 rounded-lg">
          Click to start setting the plane
        </div>
      )}
    </>
  );
};

/**
 * Generated by orval v6.28.2 🍺
 * Do not edit manually.
 * GeminiAPI
 * OpenAPI spec version: v1
 */
import { useQuery } from '@tanstack/react-query';
import type {
  QueryFunction,
  QueryKey,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import type { GetMapParams } from '.././models';
import { AxiosInstance } from '../../axios-instance';
import type { ErrorType } from '../../axios-instance';

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

export const getMap = (
  params?: GetMapParams,
  options?: SecondParameter<typeof AxiosInstance>,
  signal?: AbortSignal,
) => {
  return AxiosInstance<void>(
    { url: `/api/maps/wms`, method: 'GET', params, signal },
    options,
  );
};

export const getGetMapQueryKey = (params?: GetMapParams) => {
  return [`/api/maps/wms`, ...(params ? [params] : [])] as const;
};

export const getGetMapQueryOptions = <
  TData = Awaited<ReturnType<typeof getMap>>,
  TError = ErrorType<void>,
>(
  params?: GetMapParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getMap>>, TError, TData>
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetMapQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getMap>>> = ({
    signal,
  }) => getMap(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getMap>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetMapQueryResult = NonNullable<Awaited<ReturnType<typeof getMap>>>;
export type GetMapQueryError = ErrorType<void>;

export const useGetMap = <
  TData = Awaited<ReturnType<typeof getMap>>,
  TError = ErrorType<void>,
>(
  params?: GetMapParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getMap>>, TError, TData>
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetMapQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

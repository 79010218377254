import { faDisplay } from '@fortawesome/pro-duotone-svg-icons';
import {
  faAngle,
  faArrowsToCircle,
  faArrowsUpDownLeftRight,
  faCameraViewfinder,
  faCircleDashed,
  faCircleDot,
  faEllipsis,
  faEye,
  faEyeSlash,
  faFloppyDisk,
  faRulerHorizontal,
  faRulerVertical,
  faSquareDashed,
  faTrash,
} from '@fortawesome/pro-light-svg-icons';
import '@fortawesome/pro-regular-svg-icons';
import { faEye as faEyeSolid } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { APIClient } from '@agerpoint/api';
import { Viewer } from '@agerpoint/three-d-viewer';
import {
  BackgroundOptionsValues,
  EffectNames,
  IViewer,
  LdFlags,
  MeasurementOptionsValues,
  MixpanelNames,
} from '@agerpoint/types';
import {
  APIUtils,
  createFilename,
  hasPermission,
  useGlobalStore,
} from '@agerpoint/utilities';

import './potree-controls-float.scss';

interface PotreeControlsFloatComponentProps {
  viewer: React.MutableRefObject<IViewer | undefined> | undefined;
  captureId: number;
  size: 'small' | 'default';
}

export const PotreeControlsFloatComponent = ({
  viewer,
  captureId,
  size = 'default',
}: PotreeControlsFloatComponentProps) => {
  const blackStyle = {
    '--fa-primary-opacity': 1,
  } as any;
  const greyStyle = {
    '--fa-primary-opacity': 0.7,
  } as any;
  const whiteStyle = {
    '--fa-primary-opacity': 0.2,
  } as any;
  const { eptId } = useParams<{ eptId: string; id: string }>();
  const {
    actions: { sendEvent },
    permissions,
    subscribe,
  } = useGlobalStore();

  const captureQuery = APIClient.useGetCaptureById(Number(captureId), {
    query: {
      queryKey: [APIUtils.QueryKey.captures, { captureId: Number(captureId) }],
      enabled: Number.isSafeInteger(Number(captureId)),
    },
  });

  const [selectedMeasurementOption, setMeasurement] =
    useState<MeasurementOptionsValues>();
  const [showHide, setShowHide] = useState(true);
  const [showMoreOptions, setShowMoreOptions] = useState(false);
  const [selectedBackgroundOption, setBackground] = useState(
    BackgroundOptionsValues.Gradient
  );
  const [iconClasses, setIconClasses] = useState('');
  const [hasCropToolPermission, setHasCropToolPermission] = useState(false);
  const [captureJob, setCaptureJob] = useState<APIClient.CaptureJob>();
  const [viewerReady, setViewerReady] = useState(false);

  const updateCaptureJob = APIClient.putCaptureJobById;

  useEffect(() => {
    const cropToolPermission = hasPermission(
      LdFlags.CropTool3DViewer,
      permissions
    );
    setHasCropToolPermission(cropToolPermission);
  }, [permissions]);

  const measurementOnChange = (toolType: MeasurementOptionsValues) => {
    if (!viewer?.current) return;
    sendEvent(MixpanelNames.MetricViewerToolSelected, {
      tool: 'Tool',
      type: toolType,
    });

    viewer?.current.setTool(toolType);
    setMeasurement(toolType);
  };
  const showHideOnChange = () => {
    if (!viewer?.current) return;

    sendEvent(MixpanelNames.MetricViewerToolSelected, {
      tool: 'Label Toggle',
      type: '',
    });
    setShowHide(!showHide);
    viewer.current.showHideAllMeasurements(!showHide);
  };
  const removeAllMeasurements = () => {
    if (!viewer?.current) return;

    sendEvent(MixpanelNames.MetricViewerToolSelected, {
      tool: 'Remove All',
      type: '',
    });
    viewer.current.removeAllMeasurements();
    setMeasurement(undefined);
  };

  const resetView = () => {
    if (!viewer?.current) return;

    sendEvent(MixpanelNames.MetricViewerToolSelected, {
      tool: 'Reset',
      type: '',
    });
    viewer.current.resetView();
  };

  const selectBackgroundOnChange = (
    backgroundType: BackgroundOptionsValues
  ) => {
    if (!viewer?.current) return;

    sendEvent(MixpanelNames.MetricViewerToolSelected, {
      tool: 'Background',
      type: backgroundType,
    });
    viewer.current.setBackground(backgroundType);
    setBackground(backgroundType);
  };

  const takeScreenshot = () => {
    const potreeRenderArea = document.getElementById('potree_render_area');
    const canvas = potreeRenderArea?.getElementsByTagName('canvas')[0];
    if (!canvas) return;
    const a = document.createElement('a');
    a.href = canvas
      .toDataURL('image/png')
      .replace('image/png', 'image/octet-stream');
    a.download = createFilename('ap-cloud', 'png');
    a.click();
    a.remove();
  };

  const handlePointCloudLoaded = () => {
    setViewerReady(true);
  };

  useEffect(() => {
    subscribe(EffectNames.POTREE_POINT_CLOUD_LOADED, handlePointCloudLoaded);
  }, []);

  useEffect(() => {
    if (!viewerReady || !viewer?.current) return;
    if (!captureQuery.data?.completedJobs?.length) {
      setCaptureJob(undefined);
      return;
    }
    const currentCaptureJob = captureQuery.data?.completedJobs.find(
      (job) => job.eptPointcloudId === Number(eptId)
    ) as APIClient.CaptureJob;
    setCaptureJob(currentCaptureJob);
    if (currentCaptureJob?.cameraSettings && viewer?.current) {
      const cameraSettings = JSON.parse(currentCaptureJob.cameraSettings);
      setTimeout(() => {
        // hack, need to wait for the viewer to be ready
        viewer.current?.setCameraSettings(cameraSettings);
      }, 10);
    }
  }, [viewerReady, viewer?.current]);

  useEffect(() => {
    const style = `${
      size === 'small' ? 'fa-sm pb-1' : 'fa-lg pt-1 pb-1'
    }  block m-auto cursor-pointer`;
    setIconClasses(style);
  }, [size]);

  const saveCameraView = useCallback(async () => {
    if (!viewer?.current) return;
    if (!captureId) return;
    if (!eptId) return;
    if (!captureJob) return;

    sendEvent(MixpanelNames.MetricViewerToolSelected, {
      tool: 'Save View',
      type: '',
    });
    const cameraSettings = viewer.current.getCameraSettings();
    const cameraStateJSON = JSON.stringify(cameraSettings);
    const updatedCaptureJob = {
      ...captureJob,
      cameraSettings: cameraStateJSON,
    };
    await updateCaptureJob(
      captureId.toString(),
      Number(captureJob?.id),
      updatedCaptureJob
    );
    // refetchCapture
    setCaptureJob(updatedCaptureJob);
  }, [viewer, captureId, eptId, captureJob]);

  const resetSavedView = useCallback(() => {
    if (!viewer?.current) return;
    if (!captureJob?.cameraSettings) return;

    sendEvent(MixpanelNames.MetricViewerToolSelected, {
      tool: 'Reset View',
      type: '',
    });
    const cameraSettings = JSON.parse(captureJob.cameraSettings);
    if (cameraSettings) {
      viewer.current.setCameraSettings(cameraSettings);
    }
  }, [viewer, captureJob]);

  return !viewer?.current || !captureId ? null : (
    <div className="absolute top-0 bottom-0 right-0 z-30 m-auto m-0 flex text-black pointer-events-none">
      <div className="flex flex-col justify-center w-8 mr-4">
        <div className="bg-white overflow-visible text-center rounded pt-2 pb-2 pointer-events-auto relative">
          <FontAwesomeIcon
            onClick={() => measurementOnChange(MeasurementOptionsValues.Angle)}
            icon={faAngle}
            className={iconClasses}
            title="Angle Measurement"
          />
          <FontAwesomeIcon
            onClick={() => measurementOnChange(MeasurementOptionsValues.Area)}
            icon={faSquareDashed}
            className={iconClasses}
            title="Area Measurement"
          />
          <FontAwesomeIcon
            onClick={() => measurementOnChange(MeasurementOptionsValues.Circle)}
            icon={faCircleDashed}
            className={iconClasses}
            title="Radius Measurement"
          />
          <FontAwesomeIcon
            onClick={() =>
              measurementOnChange(MeasurementOptionsValues.Distance)
            }
            icon={faRulerHorizontal}
            className={iconClasses}
            title="Distance Measurement"
          />
          <FontAwesomeIcon
            onClick={() => measurementOnChange(MeasurementOptionsValues.Height)}
            icon={faRulerVertical}
            className={iconClasses}
            title="Height Measurement"
          />
          <FontAwesomeIcon
            onClick={() => measurementOnChange(MeasurementOptionsValues.Point)}
            icon={faCircleDot}
            className={iconClasses}
            title="Point Location"
          />
          <FontAwesomeIcon
            onClick={showHideOnChange}
            icon={showHide ? faEyeSlash : faEye}
            className={iconClasses}
            title="Hide/Show Labels"
          />
          <FontAwesomeIcon
            onClick={removeAllMeasurements}
            icon={faTrash}
            className={iconClasses}
            title="Delete All Measurements"
          />
          <FontAwesomeIcon
            onClick={() => {
              setShowMoreOptions(!showMoreOptions);
            }}
            icon={faEllipsis}
            className={iconClasses}
            title={showMoreOptions ? 'Show Less' : 'Show More'}
          />
          {showMoreOptions && (
            <>
              <div
                className="icon-container cursor-pointer"
                title={'Save View'}
                onClick={saveCameraView}
              >
                <FontAwesomeIcon
                  icon={faFloppyDisk}
                  title={'Save View'}
                  className={iconClasses}
                />
                <span className="badge" title={'Save View'}>
                  <FontAwesomeIcon
                    icon={faEyeSolid}
                    title={'Save View'}
                    className="fa-xs absolute bottom-0"
                  />
                </span>
              </div>
              <FontAwesomeIcon
                icon={faArrowsToCircle}
                className={iconClasses}
                title="Saved View"
                onClick={resetSavedView}
              />
              <FontAwesomeIcon
                icon={faCameraViewfinder}
                title="Take Screenshot"
                className={iconClasses}
                onClick={takeScreenshot}
              />
              <FontAwesomeIcon
                onClick={() => {
                  resetView();
                }}
                icon={faArrowsUpDownLeftRight}
                className={iconClasses}
                title="Reset View"
              />
              <FontAwesomeIcon
                icon={faDisplay}
                className={iconClasses}
                style={blackStyle}
                onClick={() =>
                  selectBackgroundOnChange(BackgroundOptionsValues.Black)
                }
                title="Black Background"
              />
              <FontAwesomeIcon
                icon={faDisplay}
                className={iconClasses}
                style={greyStyle}
                onClick={() =>
                  selectBackgroundOnChange(BackgroundOptionsValues.Gradient)
                }
                title="Grey Background"
              />
              <FontAwesomeIcon
                icon={faDisplay}
                className={iconClasses}
                style={whiteStyle}
                onClick={() =>
                  selectBackgroundOnChange(BackgroundOptionsValues.White)
                }
                title="White Background"
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

import {
  DoubleSide,
  Mesh,
  MeshBasicMaterial,
  PerspectiveCamera,
  RingGeometry,
  Scene,
  Vector3,
} from 'three';

import { APIClient } from '@agerpoint/api';
import {
  Annotation3dLines,
  Annotation3dPoints,
  Annotation3dPolygons,
  ColorsThreeD,
  GeomType,
  HexColor,
  ICustomLine,
  ICustomMesh,
  Line,
  Point,
  Polygon,
  SpecialCaptureObject,
} from '@agerpoint/types';

import { Annotations3d } from './annotations-3d/annotations-3d';

export class AnnotationsController {
  scene: Scene;
  camera: PerspectiveCamera | undefined;

  public annotation3d: Annotations3d | undefined;
  private eventBusLookup: { [key: string]: any } = {};
  private _creating3dPoint: boolean = false;
  private _creating3dMultiPoint: boolean = false;
  private _creating3dLine: boolean = false;
  private _creating3dPolygon: boolean = false;
  private mousePosition: Vector3 | undefined;
  private new3dPointId: string | undefined = undefined;
  private new3dMultiPointId: string | undefined = undefined;
  private new3dLineId: string | undefined = undefined;
  private new3dPolygonId: string | undefined = undefined;
  private pendingLine: Vector3[] | undefined;
  private pendingPolygon: Vector3[] | undefined;
  private pendingMultiPoint: Vector3[] | undefined;
  private mouseCrosshair: Mesh | undefined;
  private mousePositionRef: React.MutableRefObject<Vector3 | undefined>;
  private animationLoopRequestId: number | undefined;

  constructor(
    camera: PerspectiveCamera,
    scene: Scene,
    targetEl: HTMLElement,
    mouseRef: React.MutableRefObject<Vector3 | undefined>,
    isPotree: boolean = false
  ) {
    this.camera = camera;
    this.scene = scene;
    this.camera.far = 1000;
    this.camera.near = 0.0001;
    this.annotation3d = new Annotations3d(scene, camera, isPotree);
    this.mousePositionRef = mouseRef;
    this.mousePosition = new Vector3();
    this.mouseCrosshair = this.createCrosshair();
    this.scene.add(this.mouseCrosshair);
    this.startAnimationLoop();
  }

  get creating3dPoint() {
    return this._creating3dPoint;
  }

  get activeTool() {
    if (this._creating3dPoint) return GeomType.Point;
    if (this._creating3dLine) return GeomType.LineString;
    if (this._creating3dPolygon) return GeomType.Polygon;
    return '';
  }

  objectClicked(object: ICustomLine | ICustomMesh) {
    if (!object) return;
    if (
      this._creating3dLine ||
      this._creating3dPolygon ||
      this._creating3dPoint ||
      this._creating3dMultiPoint
    )
      return;

    if (object.customType === Annotation3dPoints.AnnotationPoint) {
      this.annotation3d?.highlight3dPointById(object.uniqueId);
    } else if (object.customType === Annotation3dLines.AnnotationLine) {
      this.annotation3d?.highlight3dLineById(object.uniqueId);
    } else if (object.customType === Annotation3dPolygons.AnnotationPolygon) {
      this.annotation3d?.highlight3dPolygonById(object.uniqueId);
    } else if (object.customType === Annotation3dPoints.MultiPointMarker) {
      this.annotation3d?.highlight3dMultiPointById(object.uniqueId);
    }
  }

  mouseClick() {
    if (!this.mousePosition) return;
    if (this._creating3dPoint) {
      this._creating3dPoint = false;
      const pnt = this.annotation3d?.get3dPointById(this.new3dPointId || '');
      if (pnt) {
        pnt.updateVisibility(true);
        pnt.updatePosition(this.mousePosition);
        pnt.updateType(Annotation3dPoints.AnnotationPoint);
      }
      return;
    }

    if (this._creating3dLine && this.pendingLine) {
      const pnt = new Vector3().copy(this.mousePosition);
      if (pnt) {
        this.pendingLine?.push(pnt);
        this.annotation3d?.update3dLinePosition(this.new3dLineId || '', [
          ...this.pendingLine,
        ]);
      }

      return;
    }

    if (this._creating3dPolygon && this.pendingPolygon) {
      const pnt = new Vector3().copy(this.mousePosition);
      if (pnt) {
        this.pendingPolygon?.push(pnt);
        this.annotation3d?.update3dPolygonPosition(this.new3dPolygonId || '', [
          ...this.pendingPolygon,
        ]);
      }
      return;
    }

    if (this._creating3dMultiPoint && this.new3dMultiPointId) {
      const pnt = new Vector3().copy(this.mousePosition);
      if (pnt) {
        this.pendingMultiPoint?.push(pnt);
        if (!this.pendingMultiPoint) return;
        this.annotation3d?.update3dMultiPointPosition(this.new3dMultiPointId, [
          ...this.pendingMultiPoint,
        ]);
      }

      return;
    }
  }

  sceneWasClicked() {
    if (this._creating3dPoint) {
      this.finishCreating3dPoint(false);
    }
  }

  setMousePosition(pos: Vector3 | undefined) {
    if (!pos) {
      this.mousePosition = undefined;
    } else {
      this.mousePosition = pos;
    }

    if (!pos && this.mouseCrosshair) {
      this.mouseCrosshair.visible = false;
      return;
    }

    this.updateCrosshair();
  }

  private getColor(obj: SpecialCaptureObject) {
    return obj?.captureObjectCustomAttributes?.find(
      (attr) => attr.attributeName === 'color'
    )?.attributeValue as HexColor;
  }

  seedCaptureObjects(captureObjects: APIClient.CaptureObject[]) {
    this.annotation3d?.destroyGeometry();

    if (!captureObjects?.length) {
      return;
    }

    captureObjects.forEach((co) => {
      const obj: SpecialCaptureObject = co as SpecialCaptureObject;
      if (obj?.geom2D?.type === 'Point') {
        const position = obj?.geom2D?.coordinates as Point;
        const color = this.getColor(obj) ?? ColorsThreeD.Cyan;
        this.annotation3d?.add3dSprite(
          obj.id?.toString() || '',
          new Vector3(position[0], position[1], position[2]),
          Annotation3dPoints.AnnotationPoint,
          color
        );
      } else if (obj?.geom2D?.type === 'LineString') {
        const positions = obj?.geom2D?.coordinates as Line;
        const color = this.getColor(obj) ?? ColorsThreeD.Magenta;
        this.annotation3d?.add3dLine(
          obj.id?.toString() || '',
          positions.map((p: number[]) => new Vector3(p[0], p[1], p[2])),
          Annotation3dLines.AnnotationLine,
          color
        );
      } else if (obj?.geom2D?.type === 'Polygon') {
        const positions = obj?.geom2D?.coordinates as Polygon;
        const color = this.getColor(obj) ?? ColorsThreeD.Green;
        this.annotation3d?.add3dPolygon(
          obj.id?.toString() || '',
          positions[0].map((p: number[]) => new Vector3(p[0], p[1], p[2])),
          Annotation3dPolygons.AnnotationPolygon,
          color
        );
      } else if (obj?.geom2D?.type === 'MultiPoint') {
        const positions = obj?.geom2D?.coordinates as Point[];
        const color = this.getColor(obj) ?? ColorsThreeD.Orange;
        const multiPoint = this.annotation3d?.add3dMultiPoint(
          obj.id?.toString() || '',
          positions.map((p: number[]) => new Vector3(p[0], p[1], p[2])),
          Annotation3dPoints.MultiPointMarker,
          color
        );
        multiPoint?.updatePositions(
          positions.map((p: number[]) => new Vector3(p[0], p[1], p[2]))
        );
      }
    });
  }

  startCreating3dPoint() {
    this._creating3dLine = false;
    this._creating3dPolygon = false;
    this._creating3dPoint = true;
    this.new3dPointId = this.annotation3d?.getNew3dPointId();
    this.annotation3d?.add3dSprite(
      this.new3dPointId || '',
      this.mousePosition,
      Annotation3dPoints.PendingLocationMarker,
      undefined
    );
  }

  finishCreating3dPoint(cancelled: boolean) {
    this._creating3dPoint = false;
    if (cancelled) {
      this.annotation3d?.remove3dSpriteById(this.new3dPointId || '');
    }
    this.annotation3d?.savePoint(this.new3dPointId || '');
    this.new3dPointId = undefined;
  }

  cancelCreating3dPoint() {
    this._creating3dPoint = false;
    this.annotation3d?.remove3dSpriteById(this.new3dPointId || '');
    this.new3dPointId = undefined;
  }

  startCreating3dMultiPoint() {
    this._creating3dPoint = false;
    this._creating3dLine = false;
    this._creating3dPolygon = false;
    this._creating3dMultiPoint = true;
    this.pendingMultiPoint = [];
    this.new3dMultiPointId = this.annotation3d?.getNew3dMultiPointId();

    this.annotation3d?.add3dMultiPoint(
      this.new3dMultiPointId || '',
      this.pendingMultiPoint,
      Annotation3dPoints.PendingMultiPointMarker,
      ColorsThreeD.Orange
    );
  }

  finishCreating3dMultiPoint() {
    if (this.pendingMultiPoint?.length) {
      this._creating3dMultiPoint = false;
      this.annotation3d?.finishCreating3dMultiPoint(
        this.new3dMultiPointId || ''
      );
      this.annotation3d?.update3dMultiPointPosition(
        this.new3dMultiPointId || '',
        [...(this.pendingMultiPoint || [])]
      );
      this.annotation3d?.saveMultiPoint(this.new3dMultiPointId || '');
      this.pendingMultiPoint = undefined;
      this.new3dMultiPointId = undefined;
    }
  }

  cancelCreating3dMultiPoint() {
    this._creating3dMultiPoint = false;
    this.annotation3d?.remove3dMultiPointById(this.new3dMultiPointId || '');
    this.pendingMultiPoint = undefined;
    this.new3dMultiPointId = undefined;
  }

  startCreating3dLine() {
    this._creating3dPoint = false;
    this._creating3dPolygon = false;
    this._creating3dLine = true;
    this.new3dLineId = this.annotation3d?.getNew3dLineId();
    this.pendingLine = [];
    this.annotation3d?.add3dLine(
      this.new3dLineId || '',
      this.pendingLine,
      Annotation3dLines.PendingLine,
      ColorsThreeD.Magenta
    );
  }

  finishCreating3dLine() {
    if (this.pendingLine?.length) {
      this._creating3dLine = false;
      this.annotation3d?.finishCreating3dLine(this.new3dLineId || '');
      this.annotation3d?.update3dLinePosition(this.new3dLineId || '', [
        ...this.pendingLine,
      ]);
      this.annotation3d?.saveLine(this.new3dLineId || '');
      this.pendingLine = undefined;
      this.new3dLineId = undefined;
    }
  }

  cancelCreating3dLine() {
    this._creating3dLine = false;
    this.annotation3d?.remove3dLineById(this.new3dLineId || '');
    this.pendingLine = undefined;
    this.new3dLineId = undefined;
  }

  startCreating3dPolygon() {
    this._creating3dLine = false;
    this._creating3dPoint = false;
    this._creating3dPolygon = true;
    this.new3dPolygonId = this.annotation3d?.getNew3dPolygonId();
    this.pendingPolygon = [];
    this.annotation3d?.add3dPolygon(
      this.new3dPolygonId || '',
      this.pendingPolygon,
      Annotation3dPolygons.PendingPolygon,
      ColorsThreeD.Blue
    );
  }

  finishCreating3dPolygon() {
    if (this.pendingPolygon?.length) {
      this._creating3dPolygon = false;
      this.annotation3d?.finishCreating3dPolygon(this.new3dPolygonId || '');
      this.annotation3d?.update3dPolygonPosition(this.new3dPolygonId || '', [
        ...this.pendingPolygon,
        this.pendingPolygon[0],
      ]);
      this.annotation3d?.savePolygon(this.new3dPolygonId || '');
      this.pendingPolygon = undefined;
      this.new3dPolygonId = undefined;
    }
  }

  cancelCreating3dPolygon() {
    this._creating3dPolygon = false;
    this.annotation3d?.remove3dPolygonById(this.new3dPolygonId || '');
    this.pendingPolygon = undefined;
    this.new3dPolygonId = undefined;
  }

  render() {
    this.setMousePosition(this.mousePositionRef.current);
    if (this._creating3dPoint) {
      this.updatePendingPnt();
    }
    if (this._creating3dLine) {
      this.updatePendingLine();
    }
    if (this._creating3dPolygon) {
      this.updatePendingPolygon();
    }
    if (this._creating3dMultiPoint) {
      this.updatePendingMultiPoint();
    }
    this.updatePointFace();
  }

  startAnimationLoop = () => {
    this.animationLoopRequestId = requestAnimationFrame(
      this.startAnimationLoop
    );
    this.render();
  };

  destroy() {
    cancelAnimationFrame(this.animationLoopRequestId || 0);
    this.annotation3d?.destroyGeometry();
    if (this.mouseCrosshair && this.scene) {
      this.scene.remove(this.mouseCrosshair);
    }
  }

  private updatePendingPnt() {
    if (this.mousePosition) {
      const pnt = this.annotation3d?.get3dPointById(this.new3dPointId || '');
      if (pnt) {
        pnt.updateVisibility(true);
        pnt.updatePosition(this.mousePosition);
      }
    }
  }

  private updatePendingMultiPoint() {
    if (this.mousePosition) {
      this.annotation3d?.update3dMultiPointPosition(
        this.new3dMultiPointId || '',
        [...(this.pendingMultiPoint || []), this.mousePosition]
      );
    }
  }

  private updatePendingLine() {
    if (this.mousePosition && this.pendingLine?.length) {
      this.annotation3d?.update3dLinePosition(this.new3dLineId || '', [
        ...this.pendingLine,
        this.mousePosition,
      ]);
    }
  }

  private updatePendingPolygon() {
    if (this.mousePosition && this.pendingPolygon?.length) {
      this.annotation3d?.update3dPolygonPosition(this.new3dPolygonId || '', [
        ...this.pendingPolygon,
        this.mousePosition,
        this.pendingPolygon[0],
      ]);
    }
  }

  private updatePointFace() {
    if (!this.camera || !this.mousePosition) {
      return;
    }
    this.annotation3d?.updateAll3dPointLookAt();
  }

  private updateCrosshair() {
    if (!this.camera || !this.mouseCrosshair || !this.mousePosition) {
      if (this.mouseCrosshair) {
        this.mouseCrosshair.visible = false;
      }
      return;
    }

    this.mouseCrosshair.position.copy(this.mousePosition);
    this.mouseCrosshair.lookAt(this.camera?.position);
    this.mouseCrosshair.visible = true;
  }

  private createCrosshair() {
    // Ring Crosshair
    const ringGeometry = new RingGeometry(0.05, 0.07, 32);
    const ringMaterial = new MeshBasicMaterial({
      color: 0xffffff,
      side: DoubleSide,
    });
    const ring = new Mesh(ringGeometry, ringMaterial);
    if (this.mousePosition) {
      ring.position.copy(this.mousePosition);
    }
    ring.visible = false;
    return ring;
  }
}

import {
  AxesHelper,
  OrthographicCamera,
  PerspectiveCamera,
  Scene,
  Vector3,
  WebGLRenderer,
} from 'three';

export abstract class BaseThreeDViewer {
  abstract scene: Scene | undefined;
  abstract camera: PerspectiveCamera | undefined;

  protected isGaussian: boolean;
  public targetEl: HTMLElement;
  protected overlayCamera?: OrthographicCamera;
  protected overlayScene?: Scene;
  protected axisWidgetRenderer: WebGLRenderer | undefined;
  protected axesHelper?: AxesHelper;
  protected pointCloudCenter?: Vector3;

  constructor(isGaussian: boolean, targetEl: HTMLElement) {
    this.targetEl = targetEl;
    this.isGaussian = isGaussian;
  }

  abstract findCenter(): Vector3;

  addAxesHelper = () => {
    if (!this.targetEl) return;

    this.pointCloudCenter = this.findCenter();

    this.overlayCamera = new OrthographicCamera(
      -100, // left
      100, // right
      100, // top
      -100, // bottom
      0.001,
      1000
    );
    this.overlayCamera.position.set(150, 150, 150);
    this.overlayCamera.lookAt(new Vector3(0, 0, 0));

    if (this.isGaussian) {
      this.overlayCamera.up.set(0, -1, 0);
    } else {
      this.overlayCamera.up.set(0, 0, 1);
    }
    console.info(
      'Axis Orientation:',
      'x',
      this.overlayCamera.up.x,
      'y',
      this.overlayCamera.up.y,
      'z',
      this.overlayCamera.up.z
    );

    this.axisWidgetRenderer = new WebGLRenderer({
      alpha: true,
      antialias: true,
    });
    this.axisWidgetRenderer.domElement.style.pointerEvents = 'none';
    this.axisWidgetRenderer.domElement.style.position = 'absolute';
    this.axisWidgetRenderer.domElement.style.bottom = '0';
    this.axisWidgetRenderer.domElement.style.right = '0';
    this.axisWidgetRenderer.setSize(200, 200);
    this.axisWidgetRenderer.autoClear = false;
    this.axisWidgetRenderer.domElement.style.background = 'transparent';
    this.targetEl.appendChild(this.axisWidgetRenderer.domElement);

    this.overlayScene = new Scene();

    this.axesHelper = new AxesHelper(100);
    this.axesHelper.position.set(0, 0, 0);

    this.overlayScene.add(this.axesHelper);
  };

  removeAxesHelper = () => {
    if (this.axisWidgetRenderer) {
      this.axisWidgetRenderer.domElement.remove();
      this.overlayCamera = undefined;
      this.overlayScene = undefined;
      this.axisWidgetRenderer = undefined;
    }
  };

  updateOverlayScene = () => {
    // overlay scene (debug axes helper)
    if (
      this.axisWidgetRenderer &&
      this.overlayScene &&
      this.overlayCamera &&
      this.axesHelper &&
      this.pointCloudCenter &&
      this.camera
    ) {
      const mainCamera = this.camera;

      const mainCameraPos = new Vector3();
      mainCamera.getWorldPosition(mainCameraPos);

      const relativePos = mainCameraPos.sub(this.pointCloudCenter);

      const scaledRelativePos = relativePos.normalize().multiplyScalar(500);

      this.overlayCamera.position.copy(scaledRelativePos);

      this.overlayCamera.lookAt(0, 0, 0);

      this.axisWidgetRenderer.render(this.overlayScene, this.overlayCamera);
    }
  };
}

import { Extent as StateExtent } from 'ol/extent';

import {
  Extent as ApiExtent,
  Layer as ApiLayer,
  Layer,
  LayerGroup,
} from '@agerpoint/api';
import {
  Gradient,
  Group,
  LabelType,
  LayerType,
  LayerTypeName,
  LibraryLayer,
  PointCloudStyle,
  VectorStyle,
} from '@agerpoint/types';
import { Layer as StateLayer } from '@agerpoint/types';

import { environment } from '../environments';

export function toApiLayer(layer: StateLayer): ApiLayer {
  const apiLayer: ApiLayer = {
    ...layer,
    extent:
      'extent' in layer ? { minX: 0, maxX: 0, minY: 0, maxY: 0 } : undefined,
    style: 'style' in layer ? JSON.stringify(layer.style) : undefined,
  };

  return apiLayer;
}

export function toLibraryLayers(library: ApiLayer[]): Array<LibraryLayer> {
  return library.map(
    (layer) =>
      ({
        id: `${layer.entityId}-${layer.layerTypeId}`,
        entityId: required(layer.entityId),
        layerTypeId: required(layer.layerTypeId),
        name: layer.name,
        type: layer?.layerType?.layerFormatType?.name as LayerType,
      } as LibraryLayer)
  );
}

// TODO: test this
export function toStateLayer(layer: ApiLayer): StateLayer {
  const layerTypeName = layer.layerType?.name;

  switch (layerTypeName) {
    case LayerTypeName.Farms:
    case LayerTypeName.Blocks:
    case LayerTypeName.Collections:
      return {
        ...layer,
        id: layer.id || -1, // TODO: why can id be undefined?
        name: layer?.name || '',
        type: 'WmsVector',
        typeId: layer.layerTypeId || -1,
        wmsUrl: `${environment.api_server_url}/api/maps/wms`,
        layers: required(layer.layerType).mapLayer || '',
        params: `Id:${layer.entityId}`,
        style: createVectorLayerStyle(layer),
        visible: layer.visible !== undefined ? layer.visible : true,
        dimensions: 2,
        extent: toStateExtent(layer.extent),
        zIndex: layer.zIndex || 0,
        entityId: layer.entityId || 0,
        layerTypeName: layer.layerType?.name || '',
      };
    case LayerTypeName.EptPointClouds:
      return {
        ...layer,
        id: layer.id || -1, // TODO: why can id be undefined?
        entityId: layer.entityId || -1,
        name: layer?.name || '',
        type: 'PointCloud',
        typeId: layer.layerTypeId || -1,
        tilesetUrl: `${environment.api_server_url}/api/EptPointcloud/${layer.entityId}/ept-tileset/tileset.json`,
        visible: layer.visible !== undefined ? layer.visible : true,
        dimensions: 3,
        style: createPointCloudStyle(layer),
        zIndex: layer.zIndex || 0,
        offset: 0,
      };
    case LayerTypeName.ImageMosaics:
      return {
        // TODO: this is a quite ugly way to make sure we don't pull in style string
        ...{ ...layer, style: undefined },
        id: layer.id || -1, // TODO: why can id be undefined?
        entityId: layer.entityId || -1,
        name: layer?.name || '',
        type: 'WmsRaster',
        typeId: layer.layerTypeId || -1,
        wmsUrl: `${environment.api_server_url}/api/maps/wms`,
        layers: required(layer.layerType).mapLayer || '',
        params: `Id:${layer.entityId}`,
        visible: layer.visible !== undefined ? layer.visible : true,
        dimensions: 2,
        extent: toStateExtent(layer.extent),
        zIndex: layer.zIndex || 0,
      };
    case LayerTypeName.File:
      return {
        ...{ ...layer, style: undefined },
        id: layer.id || -1, // TODO: why can id be undefined?
        entityId: layer.entityId || -1,
        name: layer?.name || '',
        type: 'File',
        typeId: layer.layerTypeId || -1,
        fileName: '',
        description: '',
        dimensions: 0,
        visible: layer.visible !== undefined ? layer.visible : true,
        zIndex: layer.zIndex || 0,
        fileDate: '',
        reportName: '',
      };
    case LayerTypeName.GeometryCollection:
      return {
        ...layer,
        id: layer.id || -1, // TODO: why can id be undefined?
        name: layer?.name || '',
        type: 'WmsVector',
        typeId: layer.layerTypeId || -1,
        wmsUrl: `${environment.api_server_url}/api/maps/wms`,
        layers: required(layer.layerType).mapLayer || '',
        params: `Id:${layer.entityId}`,
        style: createVectorLayerStyle(layer),
        visible: layer.visible !== undefined ? layer.visible : true,
        dimensions: 2,
        extent: toStateExtent(layer.extent),
        zIndex: layer.zIndex || 0,
        entityId: layer.entityId || 0,
        layerTypeName: layer.layerType?.name || '',
      };
    case LayerTypeName.Capture:
      return {
        ...layer,
        id: layer.id || -1,
        name: layer?.name || '',
        type: 'WmsVector',
        typeId: layer.layerTypeId || -1,
        wmsUrl: `${environment.api_server_url}/api/maps/wms`,
        layers: required(layer.layerType).mapLayer || '',
        params: `Id:${layer.entityId}`,
        style: createVectorLayerStyle(layer, LayerTypeName.Capture),
        visible: layer.visible !== undefined ? layer.visible : true,
        dimensions: 2,
        extent: toStateExtent(layer.extent),
        zIndex: layer.zIndex || 0,
        entityId: layer.entityId || 0,
        layerTypeName: layer.layerType?.name || '',
      };
    default:
      throw new Error(`Unhandled layer of type "${layerTypeName}".`);
  }
}

export function toStateGroup(group: LayerGroup): Group {
  return {
    ...group,
    // We currently need these since the API def says
    // id, zIndex and visible might be undefined, but
    // they can't really AFAIK.
    id: group.id || 0,
    zIndex: group.zIndex || 100,
    visible: group.visible !== undefined ? group.visible : true,
    // If LayerGroup is the true entity then its icon
    // datatype is not what is expected from US #9760.
    // Until LayerGroup has same icon type as Group,
    // color and note will not be persistent.
    icon: {
      name: group.icon || 'Leaf',
      color: group?.color || '#2E9C73',
      notes: '',
    },
    layers: [],
    extent: group.extent ? toStateExtent(group.extent) : undefined,
  };
}

export function toApiGroup(group: Group): LayerGroup {
  return {
    ...group,
    // See `toStateGroup()` comment for icon on why this
    // is only setting name
    icon: group.icon.name,
    color: group.icon.color,
    extent: group.extent
      ? {
          minX: group.extent[0],
          minY: group.extent[1],
          maxX: group.extent[2],
          maxY: group.extent[3],
        }
      : undefined,
  };
}

function toStateExtent(extent?: ApiExtent): StateExtent {
  return [
    extent?.minX || 0,
    extent?.minY || 0,
    extent?.maxX || 0,
    extent?.maxY || 0,
  ];
}

const DEFAULT_PALETTE = ['#2E9C73', '#339BC0', '#176754', '#2F5175', '#E8718D'];
const DEFAULT_PALETTE_CAPTURE = ['#339BC0'];

function createVectorLayerStyle(
  layer: ApiLayer | undefined,
  type?: LayerTypeName | undefined
): VectorStyle {
  let parsedStyle: any = {};
  try {
    parsedStyle = JSON.parse(layer?.style || '');
  } catch (e) {
    // This is fine 🔥
  }

  const paletteIndex = layer?.id ? layer.id % DEFAULT_PALETTE.length : 0;
  // For backwards compatibility, we need to handle that labelStyle was previously
  // just the LabelType and attribute was stored in fillColor.
  const label = parsedStyle.label?.type
    ? parsedStyle.label
    : parsedStyle.label === LabelType.Attribute &&
      (parsedStyle.fillColor?.type === 'graduated' ||
        parsedStyle.fillColor?.type === 'categorized')
    ? {
        type: LabelType.Attribute,
        attribute: parsedStyle.fillColor.attribute,
      }
    : { type: LabelType.None };

  const defaultStokeColor =
    type !== LayerTypeName.Capture ? DEFAULT_PALETTE[paletteIndex] : '#000000';

  const defaultFillColor =
    type !== LayerTypeName.Capture
      ? DEFAULT_PALETTE[paletteIndex]
      : DEFAULT_PALETTE_CAPTURE[0];

  const defaultFillOpacity = type !== LayerTypeName.Capture ? 0.3 : 1;

  return {
    strokeColor: defaultStokeColor,
    strokeWidth: 1,
    fillColor: defaultFillColor,
    fillOpacity: defaultFillOpacity,
    strokeOpacity: 1,
    strokePattern: 'Solid',
    ...parsedStyle,
    label,
  };
}

function createPointCloudStyle(layer: Layer | undefined): PointCloudStyle {
  let parsedStyle: any = {};
  try {
    parsedStyle = JSON.parse(layer?.style || '');
  } catch (e) {
    // This is fine 🔥
  }

  // Handle legacy styles before we added "type"
  // and only had limited gradient support.
  if (parsedStyle.gradient === 'YlGn') {
    parsedStyle.gradient = Gradient.Greens;
  }
  if (!parsedStyle.type && !parsedStyle.attribute) {
    parsedStyle.type = 'rgb';
  }

  return {
    type: 'graduated',
    gradient: Gradient.Greens,
    contrast: 1,
    ...parsedStyle,
  };
}

function required<T>(x?: T): T {
  if (x !== undefined) {
    return x;
  } else {
    throw new Error(`Encountered undefined on required field.`);
  }
}

export class UncompressedSplatArray {
  splatCount: number;
  scale_0: number[];
  scale_1: number[];
  scale_2: number[];
  rot_0: number[];
  rot_1: number[];
  rot_2: number[];
  rot_3: number[];
  x: number[];
  y: number[];
  z: number[];
  f_dc_0: number[];
  f_dc_1: number[];
  f_dc_2: number[];
  opacity: number[];

  constructor() {
    this.splatCount = 0;
    this.scale_0 = [];
    this.scale_1 = [];
    this.scale_2 = [];
    this.rot_0 = [];
    this.rot_1 = [];
    this.rot_2 = [];
    this.rot_3 = [];
    this.x = [];
    this.y = [];
    this.z = [];
    this.f_dc_0 = [];
    this.f_dc_1 = [];
    this.f_dc_2 = [];
    this.opacity = [];
  }

  addSplat(
    x: number,
    y: number,
    z: number,
    scale0: number,
    scale1: number,
    scale2: number,
    rot0: number,
    rot1: number,
    rot2: number,
    rot3: number,
    r: number,
    g: number,
    b: number,
    opacity: number
  ) {
    this.x.push(x);
    this.y.push(y);
    this.z.push(z);
    this.scale_0.push(scale0);
    this.scale_1.push(scale1);
    this.scale_2.push(scale2);
    this.rot_0.push(rot0);
    this.rot_1.push(rot1);
    this.rot_2.push(rot2);
    this.rot_3.push(rot3);
    this.f_dc_0.push(r);
    this.f_dc_1.push(g);
    this.f_dc_2.push(b);
    this.opacity.push(opacity);
    this.splatCount++;
  }
}

import { User } from 'oidc-react';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { APIClient } from '@agerpoint/api';
import { LDFlagSet, LdFlags, UserClaims } from '@agerpoint/types';
import {
  AgerStore,
  isMobileTablet,
  useAgerStore,
  useGlobalStore,
} from '@agerpoint/utilities';

interface IAgerStoreSetup {
  children: JSX.Element;
}

export const AgerStoreSetup = ({ children }: IAgerStoreSetup) => {
  const [featureFlags, setFeatureFlags] = useState<LDFlagSet>({});
  const hasFeatureFlag = useCallback(
    (flag: LdFlags) => {
      if (!featureFlags) return false;
      return featureFlags[flag] === true;
    },
    [featureFlags]
  );
  const [user, setUser] = useState<{
    auth?: User;
    userInfo?: APIClient.User;
  }>({});

  const isAdmin = useMemo(() => {
    if (!user.userInfo?.cloudClaims) return false;
    return user.userInfo?.cloudClaims.includes(UserClaims.AgerAdmin);
  }, [user.userInfo]);

  const isMobile = useMemo(() => isMobileTablet(), []);

  const [navSideBarOpen, setNavSideBarOpen] = useState(false);
  const [detailsSideBarOpen, setDetailsSideBarOpen] = useState(false);

  return (
    <AgerStore.Provider
      value={{
        launchDarkly: {
          featureFlags,
          setFeatureFlags,
          hasFeatureFlag,
        },
        user: {
          userInfo: user.userInfo,
          auth: user.auth,
          setUser: setUser,
          isAdmin: isAdmin,
          hasClaim: (claimToCheck: string) => {
            if (!user.userInfo?.cloudClaims) return false;
            return user.userInfo?.cloudClaims.includes(claimToCheck);
          },
        },
        sidebars: {
          navSideBarOpen,
          setNavSideBarOpen,
          detailsSideBarOpen,
          setDetailsSideBarOpen,
        },
        isMobile,
      }}
    >
      <StoreAligning>{children}</StoreAligning>
    </AgerStore.Provider>
  );
};

interface IStoreAligning {
  children: JSX.Element;
}

// This is a temporary wrapper component that aligns the old
// global store with ager store for legacy components.
const StoreAligning = ({ children }: IStoreAligning) => {
  const {
    actions: { setPermissions, setUser },
    sidebar: {
      actions: { setIsOpen: setSidebarOpen },
    },
  } = useGlobalStore();

  const {
    launchDarkly: { featureFlags },
    user: { userInfo },
  } = useAgerStore();

  useEffect(() => {
    setSidebarOpen(false);
  }, [setSidebarOpen]);

  useEffect(() => {
    setPermissions(featureFlags);
  }, [featureFlags, setPermissions]);

  useEffect(() => {
    setUser(userInfo ?? null);
  }, [setUser, userInfo]);

  return children;
};

import { PerspectiveCamera, Scene, Vector3 } from 'three';

import { EventBusNames, MarkerObjOptions } from '@agerpoint/types';

import { AnnotationBase } from '../annotations.base';
import { Point2d } from './point-2d';

export class Annotations2d extends AnnotationBase {
  private points2d: { [key: string]: Point2d } = {};

  constructor(
    scene: Scene,
    perspectiveCamera: PerspectiveCamera,
    canvas: HTMLCanvasElement
  ) {
    super(scene, perspectiveCamera, false, canvas);
  }

  public add2dPoint(
    eventName: EventBusNames,
    pointId: string,
    position: Vector3,
    options: MarkerObjOptions
  ) {
    if (!this.points2d[pointId] && this.canvas) {
      const pnt = new Point2d(
        eventName,
        pointId,
        position,
        options,
        this.scene,
        this.perspectiveCamera,
        this.canvas
      );
      this.points2d[pointId] = pnt;
    }
  }
  public get2dPoints() {
    return this.points2d;
  }
  public clear2dPoints() {
    const pointKeys = Object.keys(this.points2d);
    pointKeys.forEach((point) => {
      this.points2d[point].dispose();
    });
    this.points2d = {};
  }
  public clear2dPointById(id: string) {
    this.points2d[id]?.dispose();
    delete this.points2d[id];
  }

  render() {
    const pointKeys = Object.keys(this.points2d);
    pointKeys.forEach((point) => {
      this.points2d[point].render();
    });
  }

  destroy() {
    this.clear2dPoints();
  }
}

import {
  faCheck,
  faChevronLeft,
  faCirclePlus,
} from '@fortawesome/pro-regular-svg-icons';
import { faCircleExclamation, faPause } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { createEmpty, extend } from 'ol/extent';
import Point from 'ol/geom/Point';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Upload, useGetCustomer } from '@agerpoint/api';
import {
  AppBar,
  OpenLayerMap,
  useBackgroundTaskManager,
} from '@agerpoint/feature';
import {
  BackgroundTaskGroupResult,
  LdFlags,
  OpenLayerMapController,
  OpenLayerMapProps,
  OpenMapLayer,
  UserClaims,
} from '@agerpoint/types';
import { AppBarProps, UploadFileWithExif } from '@agerpoint/types';
import {
  Sort,
  environment,
  hasClaims,
  hasPermission,
  useGlobalStore,
} from '@agerpoint/utilities';

import { NewUploadTab } from './subcomponents/new-upload-tab';
import { OngoingUploadTab } from './subcomponents/ongoing-upload-tab';

interface NewUploadPageProps {
  appBar: AppBarProps;
}

export enum NewUploadType {
  images = 'images',
  laz = 'laz',
  cog = 'cog',
  extractions = 'extractions',
}

export const NewUploadPage = ({ appBar }: NewUploadPageProps) => {
  const [uploadTaskGroupId, setUploadTaskGroupId] = useState<string>();
  const [mapController, setMapController] = useState<OpenLayerMapController>();
  const [mapProps, setMapProps] = useState<OpenLayerMapProps<any>>({
    id: 'new-upload-map',
    controller: setMapController,
    bingKey: environment.bing_api_key,
    mapLayers: {
      used: [OpenMapLayer.Hybrid, OpenMapLayer.Aerial, OpenMapLayer.RoadMap],
      initial: OpenMapLayer.Hybrid,
    },
  });

  const { user, permissions } = useGlobalStore();
  const [newUploadType, setNewUploadType] = useState(NewUploadType.images);

  const { taskGroups } = useBackgroundTaskManager();
  const uploadTaskGroups = useMemo(() => {
    return taskGroups.filter(
      (g) =>
        g.tags.includes('upload') &&
        g.groupResult !== BackgroundTaskGroupResult.cancel
    );
  }, [taskGroups]);

  const uploadTaskGroup = useMemo(() => {
    if (uploadTaskGroupId === undefined) {
      return undefined;
    }

    const group = uploadTaskGroups.find((g) => g.groupId === uploadTaskGroupId);
    if (group) {
      return { ...group };
    }

    setUploadTaskGroupId(undefined);
    return undefined;
  }, [uploadTaskGroupId, uploadTaskGroups]);

  const { data: organizations } = useGetCustomer({});

  const sortedOrganizations = useMemo(
    () => Sort.organizations(organizations ?? undefined),
    [organizations]
  );

  useEffect(() => {
    if (!mapController || !uploadTaskGroup) {
      return;
    }

    if (uploadTaskGroup.tags.includes(NewUploadType.images)) {
      const extent = createEmpty();
      for (const task of uploadTaskGroup.tasks) {
        const file: UploadFileWithExif = task.taskCustomPayload;
        const point = new Point([
          file.exif.gps.longitude,
          file.exif.gps.latitude,
        ]).transform('EPSG:4326', 'EPSG:3857');
        extend(extent, point.getExtent());
      }

      mapController.zoomMapToExtent?.(extent);
    } else {
      mapController?.refreshView?.();
    }
  }, [mapController, uploadTaskGroupId]);

  const buildUploadTypeButton = useCallback(
    (type: NewUploadType, title: string) => {
      return (
        <div
          className={`flex-grow rounded p-1 border text-xs text-center ${
            newUploadType === type
              ? 'border-green bg-green text-white'
              : 'border-gray-200 bg-gray-100 cursor-pointer hover:bg-gray-200'
          }`}
          onClick={() => {
            setNewUploadType(type);
          }}
        >
          {title}
        </div>
      );
    },
    [newUploadType]
  );

  const showMap = useMemo(() => {
    if (uploadTaskGroup) {
      return uploadTaskGroup.tags.includes(NewUploadType.images);
    }

    return newUploadType === NewUploadType.images;
  }, [uploadTaskGroup, newUploadType]);

  const navigate = useNavigate();

  return (
    <>
      <AppBar {...appBar} />
      <div className="w-full h-full px-4 pb-4">
        <div className="max-w-7xl mx-auto w-full h-full flex flex-col">
          <div className="w-full flex flex-row gap-2 items-center pt-1">
            <div
              className={`flex flex-row text-gray-500 text-center flex-grow
            border-b-2 border-gray-200 text-sm font-medium whitespace-nowrap`}
              style={{ overflowX: 'auto', overflowY: 'hidden' }}
            >
              <NewUploadPageTabButton
                onClick={() => {
                  navigate('/uploads');
                }}
                active={false}
              >
                <FontAwesomeIcon icon={faChevronLeft} /> Back
              </NewUploadPageTabButton>
              <NewUploadPageTabButton
                active={uploadTaskGroupId === undefined}
                onClick={() => {
                  setUploadTaskGroupId(undefined);
                }}
              >
                New Upload <FontAwesomeIcon icon={faCirclePlus} />
              </NewUploadPageTabButton>

              {uploadTaskGroups.map((g, i) => {
                const groupCustomPayload: Upload = g.groupCustomPayload;

                const hasRunningTasks = g.tasks.some((t) => t.isBeingProcessed);

                let suffix;

                if (g.groupResult === BackgroundTaskGroupResult.success) {
                  suffix = <FontAwesomeIcon icon={faCheck} />;
                } else if (g.groupResult === BackgroundTaskGroupResult.error) {
                  suffix = <FontAwesomeIcon icon={faCircleExclamation} />;
                } else if (g.isPaused && !hasRunningTasks) {
                  suffix = <FontAwesomeIcon icon={faPause} />;
                } else {
                  let groupProgress = g.tasks
                    .map((t) => t.progress ?? (t.result ? 100 : 0))
                    .reduce((p, c) => {
                      return c + p;
                    }, 0);
                  if (g.tasks.length !== 0) {
                    groupProgress /= g.tasks.length;
                  }
                  suffix = `(${groupProgress.toFixed(1)}%)`;
                }

                return (
                  <NewUploadPageTabButton
                    key={i}
                    active={g.groupId === uploadTaskGroupId}
                    onClick={() => {
                      setUploadTaskGroupId(g.groupId);
                    }}
                  >
                    <div className="flex flex-row gap-1">
                      <span className="truncate">
                        {groupCustomPayload.name}
                      </span>
                      <span>{suffix}</span>
                    </div>
                  </NewUploadPageTabButton>
                );
              })}
            </div>
            {uploadTaskGroup === undefined &&
              hasClaims(
                [UserClaims.AgerAdmin],
                user?.cloudClaims as UserClaims[]
              ) && (
                <div className="flex flex-row p-1 gap-1 bg-white border-gray-500 border rounded">
                  {buildUploadTypeButton(NewUploadType.images, 'Images')}
                  {buildUploadTypeButton(NewUploadType.laz, 'LAZ')}
                  {buildUploadTypeButton(NewUploadType.cog, 'COG')}
                  {hasPermission(
                    LdFlags.ExtractionsUploadButton,
                    permissions
                  ) &&
                    buildUploadTypeButton(
                      NewUploadType.extractions,
                      'Extractions'
                    )}
                </div>
              )}
          </div>
          <div className="w-full flex flex-row flex-grow justify-center">
            <div className="w-1/4 flex flex-col" style={{ minWidth: '250px' }}>
              <div
                className={`flex flex-col h-full ${
                  uploadTaskGroup === undefined ? '' : 'hidden'
                }`}
              >
                <NewUploadTab
                  organizations={sortedOrganizations ?? null}
                  goToTaskGroup={setUploadTaskGroupId}
                  mapController={mapController}
                  setMapProps={setMapProps}
                  type={newUploadType}
                  isVisible={uploadTaskGroup === undefined}
                />
              </div>
              {uploadTaskGroup && (
                <OngoingUploadTab
                  organizations={sortedOrganizations ?? null}
                  uploadGroup={uploadTaskGroup}
                  mapController={mapController}
                  setMapProps={setMapProps}
                />
              )}
            </div>
            {showMap && (
              <div className="w-3/4 pt-2 pl-2">
                <div className="h-full rounded overflow-hidden ring ring-white">
                  <OpenLayerMap {...mapProps} />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

interface NewUploadPageTabButtonProps {
  active: boolean;
  onClick: () => void;
  children: React.ReactNode;
}

const NewUploadPageTabButton = ({
  active,
  onClick,
  children,
}: NewUploadPageTabButtonProps) => {
  return (
    <div
      className={`p-2 ${active ? 'text-green font-bold' : 'cursor-pointer'}`}
      style={{ maxWidth: '250px' }}
      onClick={onClick}
    >
      <span>{children}</span>
    </div>
  );
};

import { faCamera } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { debounce } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Vector2 } from 'three';

import { APIClient } from '@agerpoint/api';
import { CloudButton, CloudInput } from '@agerpoint/cloud/components';
import { Input } from '@agerpoint/component';
import {
  CaptureJobTypes,
  EffectNames,
  IGs3dViewerController,
  IGsCloudToolState,
} from '@agerpoint/types';
import { APIUtils, useActionListener } from '@agerpoint/utilities';

import { useCapturesViewerContext } from '../captures-viewer';

interface IGS3DCloudTools {
  viewerController?: IGs3dViewerController;
}

export const Gs3DCloudTools = ({ viewerController }: IGS3DCloudTools) => {
  const { gsCloudToolState, setGsCloudToolState, annotations3dGeometry } =
    useCapturesViewerContext();
  const [gsModelLoaded, setGsModelLoaded] = useState(false);

  const annotations = useMemo(() => {
    return [
      ...(annotations3dGeometry?.points ?? []),
      ...(annotations3dGeometry?.lines ?? []),
      ...(annotations3dGeometry?.polygons ?? []),
      ...(annotations3dGeometry?.multiPoints ?? []),
    ];
  }, [annotations3dGeometry]);

  useEffect(() => {
    setGsModelLoaded(false);
  }, [viewerController?.info?.captureJobMetadata]);

  const gsModelLoadedFn = useCallback(() => {
    setGsModelLoaded(true);
  }, []);

  useActionListener(EffectNames.GS_3D_POINT_CLOUD_LOADED, gsModelLoadedFn);

  const { captureId } = useParams();

  // Hack: For now, we're trying to get Low Res Camera Poses for Gaussian Splat Visualization
  const captureQuery = APIClient.useGetCaptureById(Number(captureId), {
    query: {
      enabled: Number.isSafeInteger(Number(captureId)),
      queryKey: [APIUtils.QueryKey.captures, { captureId: Number(captureId) }],
    },
  });

  const lowResModel = useMemo(
    () =>
      captureQuery.data?.completedJobs?.find(
        (j) => j.captureJobTypeId === CaptureJobTypes['Low Resolution']
      ),
    [captureQuery.data]
  );

  const captureJobImagesQuery = APIClient.useGetCaptureImagesByCaptureJobId(
    Number(lowResModel?.id),
    {
      query: {
        enabled: Number.isSafeInteger(Number(lowResModel?.id ?? undefined)),
        queryKey: [
          APIUtils.QueryKey.captureJobs,
          {
            captureJobId: Number(lowResModel?.id),
          },
          APIUtils.QueryKey.captureImages,
        ],
        select: (data) =>
          data?.filter((image) => image.x && image.y && image.z && image.id),
      },
    }
  );

  const hasImages = useMemo(
    () => (captureJobImagesQuery.data?.length ?? 0) > 0,
    [captureJobImagesQuery.data]
  );

  useEffect(() => {
    if (!viewerController?.info?.viewerReady || !captureQuery.data) {
      return;
    }

    viewerController?.setCaptureMetadata(captureQuery.data);
  }, [captureQuery.data]);

  useEffect(() => {
    if (
      !viewerController?.info?.viewerReady ||
      !captureJobImagesQuery.data ||
      !viewerController?.info?.captureMetadata
    ) {
      return;
    }
    viewerController.loadCameraPositions(captureJobImagesQuery.data);
  }, [
    captureJobImagesQuery.data,
    viewerController?.info?.viewerReady,
    viewerController?.info?.captureMetadata,
  ]);

  useEffect(() => {
    if (!gsModelLoaded || !viewerController?.info?.viewerReady) {
      return;
    }

    viewerController?.removeCameraPositions();
    viewerController?.setCameraPositionsVisible?.(false);
    if (captureJobImagesQuery.data?.length) {
      viewerController?.loadCameraPositions?.(captureJobImagesQuery.data);
    }
  }, [
    gsModelLoaded,
    viewerController?.info?.viewerReady,
    captureJobImagesQuery.data,
  ]);

  const updateToolState = useCallback(
    (key: keyof IGsCloudToolState, value: boolean | Vector2) => {
      if (!viewerController?.info?.viewerReady) return;
      const newState = {
        ...gsCloudToolState,
        [key]: value,
      };
      setGsCloudToolState?.(newState);
    },
    [setGsCloudToolState, gsCloudToolState, viewerController]
  );

  const handleSpaceBarPress = useCallback(() => {
    if (!viewerController?.info?.viewerReady) {
      console.info('Viewer not ready, space bar toggle not allowed.');
      return;
    }
    updateToolState('showSplats', !gsCloudToolState?.showSplats);
  }, [viewerController?.info.viewerReady, gsCloudToolState]);

  const debouncedMouseMove = useCallback(
    debounce((e: MouseEvent) => {
      updateToolState('mousePosition', new Vector2(e.clientX, e.clientY));
    }, 100),
    []
  );

  useEffect(() => {
    if (!viewerController?.info?.element) {
      return;
    }

    const element = viewerController.info.element;

    const keyPressHandler = (event: KeyboardEvent) => {
      const activeElement = document.activeElement;
      if (
        activeElement &&
        (activeElement.tagName === 'INPUT' ||
          activeElement.tagName === 'TEXTAREA' ||
          activeElement.tagName === 'SELECT' ||
          activeElement.tagName === 'BUTTON' ||
          activeElement.hasAttribute('tabindex'))
      ) {
        return;
      }
      if (event.code === 'Space') {
        handleSpaceBarPress();
      }
    };

    const enableKeyDownListener = () => {
      document.addEventListener('keydown', keyPressHandler);
    };

    const disableKeyDownListener = () => {
      document.removeEventListener('keydown', keyPressHandler);
    };

    const isMouseInside = () => {
      const rect = element.getBoundingClientRect();
      const mouseX = gsCloudToolState?.mousePosition?.x || 0;
      const mouseY = gsCloudToolState?.mousePosition?.y || 0;
      // Check if the mouse coordinates are within the bounding rect of the element
      if (
        mouseX >= rect.left &&
        mouseX <= rect.right &&
        mouseY >= rect.top &&
        mouseY <= rect.bottom
      ) {
        // Check if the target element is the topmost element at the mouse position
        const topElement = document.elementFromPoint(mouseX, mouseY);
        return topElement === element || element.contains(topElement);
      }

      return false;
    };

    if (isMouseInside()) {
      document.addEventListener('keydown', keyPressHandler);
    }

    element.addEventListener('mouseenter', enableKeyDownListener);
    element.addEventListener('mouseleave', disableKeyDownListener);
    document.addEventListener('mousemove', debouncedMouseMove);

    return () => {
      element.removeEventListener('mouseenter', enableKeyDownListener);
      element.removeEventListener('mouseleave', disableKeyDownListener);
      document.removeEventListener('keydown', keyPressHandler);
      document.removeEventListener('mousemove', debouncedMouseMove);
    };
  }, [viewerController?.info?.element, gsCloudToolState]);

  return (
    <div
      className="absolute top-4 left-4 flex flex-row gap-2"
      style={{
        zIndex: 2,
      }}
    >
      <div
        className={`bg-white shadow-lg rounded-lg flex flex-row p-1 items-center`}
      >
        <CloudInput.Toggle
          id="camera-positions-toggle"
          disabled={!hasImages || !gsModelLoaded}
          value={
            (viewerController?.info.cameraPositionsVisible ?? false) &&
            hasImages
          }
          setValue={(value) => {
            if (!hasImages || !gsModelLoaded) {
              return;
            }

            viewerController?.setCameraPositionsVisible?.(
              !viewerController.info.cameraPositionsVisible
            );
          }}
          leadingIcon="camera"
          highlighted={
            (viewerController?.info.cameraPositionsVisible ?? false) &&
            hasImages
          }
        />
      </div>
      {annotations.length > 0 && (
        <div
          className={
            'flex flex-row cursor-pointer bg-white rounded-lg px-1 gap-1 items-center'
          }
        >
          <CloudInput.Toggle
            id="annotations-toggle"
            disabled={true}
            value={true}
            setValue={() => {
              //
            }}
            leadingIcon="location-dot"
          />
        </div>
      )}
      <div
        className={'flex flex-row bg-white rounded-lg px-1 gap-1 items-center'}
      >
        <CloudButton.Icon
          id="model-material-toggle-gaussian-splats"
          toggled={gsCloudToolState?.showSplats}
          onClick={() => {
            updateToolState('showSplats', true);
          }}
          // disabled={!gsModelLoaded}
          leadingIcon="cubes"
          label="Gaussian"
        />
        <CloudButton.Icon
          id="model-material-toggle-gaussian-points"
          toggled={!gsCloudToolState?.showSplats}
          onClick={() => {
            updateToolState('showSplats', false);
          }}
          // disabled={!gsModelLoaded}
          leadingIcon="chart-scatter-3d"
          label="Point Cloud"
        />
      </div>
    </div>
  );
};

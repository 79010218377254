import { Extent } from 'ol/extent';
import { GetDataError } from 'restful-react';

import { Layer as ProjectLayer, Project as ProjectType } from '@agerpoint/api';

import { Layer } from './map';
import { ProjectNext } from './projects';

export type SetVisibleFn = (id: number, visible: boolean) => void;

export type Group = {
  id: number;
  name: string;
  icon: GroupIcon;
  zIndex: number;
  extent?: Extent;
  visible: boolean;
  layers: Layer[];
};

export interface GroupExt {
  layers: Array<Layer>;
  zIndex: number;
  visible: boolean;
}

export interface Tag {
  id: number;
  tagName: string;
}

export interface GroupLayersProps {
  group: Group;
  mode: MapMode;
  selectedLayerId?: number;
  setVisible: SetVisibleFn;
  setStyle: (layerId: number, layer: Layer) => void;
  setSelectedLayerId: (layerId: number | undefined, count?: boolean) => void;
  bingKey: string;
  currentExpansionId?: string;
  toggleExpansion?: toggleExpansionFn;
  setMultiSelectedLayers?: (addedSelection: number[]) => void;
  multiSelectGroupId?: number | null;
  setMultiSelectGroupId?: (groupId: number | null) => void;
  clearMultiSelection?: () => void;
  setMultiSelectLayerTypeId?: (layerTypeId: number | null) => void;
  multiSelectedItems?: number[];
  layerTypeId: number | null;
}

export interface GroupIcon {
  name: string;

  /**
   * Hexadecimal string representation
   */
  color: string;
  notes?: string;
}

export type Expansion =
  | GroupExpansion
  | LayerExpansion
  | LibraryExpansion
  | NoExpansion;

type BaseExpansion = {
  type: string;
};

export interface GroupExpansion extends BaseExpansion {
  type: 'group';
  id: number;
  key: string;
}

export interface LayerExpansion extends BaseExpansion {
  type: 'layer';
  key: string;
}

export interface LibraryExpansion extends BaseExpansion {
  type: 'library';
  key: string;
}

export interface NoExpansion extends BaseExpansion {
  type: 'none';
}

export enum Gradient {
  RdYlGn = 'RdYlGn',
  YlGnBu = 'YlGnBu',
  Magma = 'Magma',
  Viridis = 'Viridis',
  Greens = 'Greens',
  Custom = 'Custom,',
}

export interface ProjectActions {
  actions: {
    setProject: (project: ProjectInput) => void;
    setLoading: (loading: boolean) => void;
    setError: (error: GetDataError<unknown> | null) => void;
    setDesiredExtent: (desiredExtent?: Extent) => void;
    groups: GroupActions;
    layers: LayerActions;
  };
}

export interface GroupActions {
  setVisible: SetVisibleFn;
  setName: (groupId: number, name: string) => void;
  setIcon: (groupId: number, icon: GroupIcon) => void;
  setZIndex: (groupId: number, zIndex: number) => void;
  add: () => void;
  delete: (groupId: number) => void;
  saveNew: (group?: Group) => void;
  setLayers: (groupId: number, layers: Array<ProjectLayer>) => void;
}

export interface LayerActions {
  setVisible: SetVisibleFn;
  saveNew: (groupId: number, layer: ProjectLayer, index: number) => void;
  addTemporary: (groupId: number, index: number) => void;
  removeTemporary: (groupId: number) => void;
  setZIndex: (groupId: number, layerId: number, index: number) => void;
  setName: (layerId: number, name: string) => void;
  setStyle: (layerId: number, layer: Layer) => void;
  delete: (layerId: number) => void;
  moveToGroup: (
    layerId: number,
    destinationGroupId: number,
    destinationIndex: number
  ) => void;
}

interface InternalState {
  loading: boolean;
  error: GetDataError<unknown> | null;
  fusionLink: string | null;
  desiredExtent?: Extent;
}

export interface ExpansionInfo {
  id: number | null;
  type?: string;
}

// TODO: should be part of Project, but isn't yet
export interface Tags {
  tags: Array<Tag>;
}

// TODO: this type will be unnecessary if/when the API gets correctly typed
export type ProjectInput = ProjectType & Tags & Groups;

// TODO: remove Tags and LayersExt here once the project def actually have them
export type ProjectState = ProjectType &
  Tags &
  Groups &
  InternalState &
  ProjectActions;

interface Groups {
  groups: Array<Group>;
}

export interface ProjectUrlParams {
  id?: string | undefined;
}

export type MapMode = '2d' | '3d';
export type ToolMode = 'select' | 'attributes' | undefined;
export type onChangeModeFn = (mode: MapMode) => void;

export interface LibraryLayer {
  id: string;
  entityId: number;
  layerTypeId: number;
  name?: string;
  type?: LayerType;
}

export type LayerType = 'Vector' | 'Raster' | '3D Tile' | 'File' | undefined;

export type toggleExpansionFn = (expansion: Expansion) => void;

type EptAttributeStat = {
  count: number;
  value: number;
};

export type EptAttribute = {
  name: string;
  maximum: number;
  minimum: number;
  mean: number;
  stddev: number;
  counts?: EptAttributeStat[];
};

export type TilesetMetadata = {
  asset: {
    ept: {
      schema: EptAttribute[];
    };
    options: {
      dimensions: string[];
    };
  };
};

export type PanelsState = {
  expansionInfo: Expansion;
  currentExpansionId: string | undefined;
  isDraggingFromLibrary: boolean;
  isSidebarOpen: boolean;
  mapMode: MapMode;
  toolMode: ToolMode;
  selectedLayerId?: number;
  selectedFeatureIds: number[];
  layerIdUpdateCount: number;
  selectedOlUid?: string;
  setMapMode: (mapMode: MapMode) => void;
  setToolMode: (toolMode: ToolMode) => void;
  toggleExpansion: toggleExpansionFn;
  toggleSidebar: () => void;
  setSelectedLayerId: (layerId: number | undefined, count?: boolean) => void;
  setSelectedFeatureIds: (featureIds: number[]) => void;
  setIsDraggingFromLibrary: (isDragging: boolean) => void;
  setSelectedOlUid: (olUid: string | undefined) => void;
};

export interface Attributes {
  graduated: string[];
  categorized: string[];
}

export interface ProjectModel extends Partial<ProjectNext> {
  name: string;
  public: boolean;
  groups?: Array<string>;
}

import {
  faCircleNotch,
  faPlus,
  faTrash,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { APIModels } from '@agerpoint/api';
import { Button, Input } from '@agerpoint/component';
import { Datatable, dataTableAgerStyle } from '@agerpoint/feature';
import { LdFlags } from '@agerpoint/types';
import {
  APIUtils,
  useFormValidation,
  useIsViteApp,
  useLookupTable,
} from '@agerpoint/utilities';
import { hasPermission, useGlobalStore } from '@agerpoint/utilities';

import {
  PageErrorState,
  PageLoadingState,
} from '../../../subcomponents/page-states';
import { useAdminAnalyticsQueries } from './admin-analytics-queries';

export const AdminAnalyticsDetailsOrganizationsMgmt = () => {
  const { analyticId } = useParams();
  const formValidation = useFormValidation();
  const navigate = useNavigate();
  const location = useLocation();
  const params = location.state?.params ?? '';

  const isViteApp = useIsViteApp();

  const { permissions } = useGlobalStore();

  const canEditAnalytics = useMemo(
    () => hasPermission(LdFlags.AnalyticsEditOrganizationDetails, permissions),
    [permissions]
  );

  const {
    analyticQuery,
    organizationsQuery,
    analyticOrganizations,
    organizationAnalyticPostMutation,
    organizationAnalyticRemovePutMutation,
  } = useAdminAnalyticsQueries();

  const availableOrganizations = useMemo(() => {
    if (!analyticOrganizations.data) {
      return [];
    }
    return organizationsQuery.data?.filter(
      (a) => !analyticOrganizations.data?.find((oa) => oa.customerId === a.id)
    );
  }, [organizationsQuery.data, analyticOrganizations.data]);

  const sortedOrganizations = useMemo(
    () => APIUtils.Sort.organizations(availableOrganizations ?? []),
    [availableOrganizations]
  );

  const organizationsLookupTable = useLookupTable(
    organizationsQuery.data,
    'id'
  );

  const [selectedOrganization, setSelectedOrganization] =
    useState<APIModels.Customer>();
  const [cost, setCost] = useState<number | undefined>(analyticQuery.data?.dc);

  useEffect(() => {
    setCost(analyticQuery.data?.dc);
  }, [analyticQuery.data]);

  const addOrganization = useCallback(async () => {
    if (organizationAnalyticPostMutation.isPending) {
      return;
    }

    if (await formValidation.hasErrors()) {
      return;
    }

    organizationAnalyticPostMutation.mutate(
      {
        data: {
          analyticId: Number(analyticId),
          customerId: selectedOrganization?.id as number,
          analyticCreditCost: cost,
        },
      },
      {
        onSuccess: () => {
          setSelectedOrganization(undefined);
          setCost(analyticQuery.data?.dc);
        },
      }
    );
  }, [
    selectedOrganization,
    cost,
    formValidation,
    analyticId,
    organizationAnalyticPostMutation,
    analyticQuery.data?.dc,
  ]);

  if (analyticQuery.isLoading) {
    return <PageLoadingState />;
  }

  if (analyticQuery.isError) {
    return (
      <PageErrorState
        entityName="analytic"
        pluralEntityName="analytics"
        statusCode={analyticQuery.error.response?.status ?? 500}
        tryAgainCallback={() => analyticQuery.refetch()}
        tryAgainLoading={analyticQuery.isFetching}
        navigateBackCallback={() =>
          navigate(
            isViteApp
              ? '/app/admin/platform/analytics' + params
              : '/admin/analytics' + params
          )
        }
      />
    );
  }

  return (
    <div className="px-4 flex w-full h-full flex-col overflow-auto lg:overflow-hidden">
      <div className="w-full flex flex-row font-bold pb-2">
        Review the organizations which have access to this analytic
      </div>

      <div className="flex w-full flex-col flex-grow ">
        <div className="w-full flex flex-col lg:flex-row">
          <div className="flex flex-row w-full gap-1 pb-2">
            <Input.Select.Single
              id="organization-select"
              title="Organization"
              placeholder="Organization"
              value={selectedOrganization}
              setValue={(o) => {
                setSelectedOrganization(o);
              }}
              options={sortedOrganizations ?? []}
              optionBuilder={(o) =>
                o.customerDisplayName ?? o.customerName ?? 'Unknown'
              }
              loading={
                organizationsQuery.isLoading || analyticOrganizations.isLoading
              }
              label={<Input.Label label="Organization" required />}
              disabled={!canEditAnalytics}
              error={
                <Input.Error
                  error={formValidation.errors['organization-select']}
                />
              }
              validation={{
                validationState: formValidation,
                validators: [Input.validators.required('Organization')],
              }}
            />
            <div className="max-w-xs">
              <Input.Number.Integer
                id="new-org-entry-cost"
                value={cost}
                setValue={setCost}
                placeholder="Credit Cost"
                label={<Input.Label label="Credit Cost" required />}
                disabled={!canEditAnalytics}
                onlyPositive
                error={
                  <Input.Error
                    error={formValidation.errors['new-org-entry-cost']}
                  />
                }
                validation={{
                  validationState: formValidation,
                  validators: [Input.validators.required('Credit Cost')],
                }}
              />
            </div>
            <div className="mt-5">
              <Button.Primary
                id="add-organization-button"
                label="Add Analytic To Organization"
                icon={faPlus}
                onClick={addOrganization}
                disabled={
                  !canEditAnalytics ||
                  organizationAnalyticPostMutation.isPending
                }
              />
            </div>
          </div>
        </div>

        <div className="w-full flex flex-grow pb-4">
          <Datatable
            style={{
              ...dataTableAgerStyle,
              tableWrapperStyle: `${dataTableAgerStyle.tableWrapperStyle} border border-gray-500`,
              tableMinWidth: 800,
            }}
            data={analyticOrganizations.data ?? []}
            loading={
              analyticOrganizations.isLoading || organizationsQuery.isLoading
            }
            noResults={{
              title: 'No organizations have access to this analytic',
              message:
                'Add an organization to give them access to this analytic',
            }}
            error={
              analyticOrganizations.isError
                ? {
                    title: 'There was a problem loading organizations',
                    message: 'Try refreshing the page',
                    action: () => analyticOrganizations.refetch(),
                  }
                : undefined
            }
            columns={[
              {
                label: 'Organization',
                value: (orgAnalytic) => {
                  if (orgAnalytic.customerId === undefined) {
                    return null;
                  }

                  if (!organizationsLookupTable) {
                    return <FontAwesomeIcon icon={faCircleNotch} spin />;
                  }

                  const org =
                    organizationsLookupTable?.[orgAnalytic.customerId];

                  return org?.customerDisplayName ?? org?.customerName ?? null;
                },
                flex: 1,
              },
              {
                label: 'Cost',
                flex: 2,
                style: {
                  bodyStyle: 'overflow-visible',
                },
                value: (orgAnalytic, index) => (
                  <OrganizationAnalyticCostMgmt
                    orgAnalytic={orgAnalytic}
                    index={index}
                    canEditAnalytics={canEditAnalytics}
                    removing={organizationAnalyticRemovePutMutation.isPending}
                  />
                ),
              },
              {
                label: 'Availability',
                flex: 1,
                value: (orgAnalytic) => (
                  <OrganizationAnalyticAvailabilityMgmt
                    orgAnalytic={orgAnalytic}
                    canEditAnalytics={canEditAnalytics}
                    removing={organizationAnalyticRemovePutMutation.isPending}
                  />
                ),
              },
              {
                label: '',
                visible: canEditAnalytics,
                value: (orgAnalytic, index) => (
                  <div className="p-1">
                    <Button.Danger
                      id={`remove-organization-button-${index}`}
                      label="Remove"
                      icon={faTrash}
                      onClick={() => {
                        const confirm = window.confirm(
                          'Are you sure you want to remove this organization?'
                        );
                        if (!confirm) {
                          return;
                        }
                        const data: APIModels.CustomerAnalytic = {
                          ...orgAnalytic,
                          archived: true,
                        };
                        delete data.analytic;
                        organizationAnalyticRemovePutMutation.mutate({
                          id: orgAnalytic?.id as number,
                          data: data,
                        });
                      }}
                    />
                  </div>
                ),
                flex: 1,
                style: {
                  columnWrapperStyle: 'flex justify-end',
                },
              },
            ]}
            rowHeight={70}
          />
        </div>
      </div>
    </div>
  );
};

interface OrganizationAnalyticCostMgmtProps {
  orgAnalytic: APIModels.CustomerAnalytic;
  index: number;
  canEditAnalytics: boolean;
  removing: boolean;
}

const OrganizationAnalyticCostMgmt = ({
  orgAnalytic,
  index,
  canEditAnalytics,
  removing,
}: OrganizationAnalyticCostMgmtProps) => {
  const [cost, setCost] = useState<number | undefined>(
    orgAnalytic?.analyticCreditCost ?? undefined
  );

  useEffect(() => {
    setCost(orgAnalytic?.analyticCreditCost);
  }, [orgAnalytic?.analyticCreditCost]);

  const formValidation = useFormValidation();

  const { organizationAnalyticCostPutMutation } = useAdminAnalyticsQueries();

  return (
    <div className="flex flex-row gap-1 p-1">
      <Input.Number.Integer
        id={`organization-analytic-cost-${index}`}
        value={cost}
        setValue={setCost}
        readOnly={!canEditAnalytics}
        onlyPositive
        validation={{
          validationState: formValidation,
          validators: [Input.validators.required('Cost')],
        }}
        error={
          <Input.Error
            error={formValidation.errors[`organization-analytic-cost-${index}`]}
          />
        }
      />
      {canEditAnalytics && (
        <Button.Primary
          id={`update-organization-analytic-cost-${index}`}
          label="Update"
          onClick={async () => {
            if (organizationAnalyticCostPutMutation.isPending || removing) {
              return;
            }
            if (await formValidation.hasErrors()) {
              return;
            }

            const data = {
              ...orgAnalytic,
              analyticCreditCost: cost,
            };
            delete data.analytic;
            organizationAnalyticCostPutMutation.mutate({
              id: orgAnalytic?.id as number,
              data: data,
            });
          }}
          disabled={organizationAnalyticCostPutMutation.isPending || removing}
        />
      )}
    </div>
  );
};

interface OrganizationAnalyticAvailabilityMgmtProps {
  orgAnalytic: APIModels.CustomerAnalytic;
  canEditAnalytics: boolean;
  removing: boolean;
}

const OrganizationAnalyticAvailabilityMgmt = ({
  orgAnalytic,
  canEditAnalytics,
  removing,
}: OrganizationAnalyticAvailabilityMgmtProps) => {
  const { organizationAnalyticAvailabilityPutMutation } =
    useAdminAnalyticsQueries();

  return (
    <div className="p-1">
      <Input.Select.Inline
        id={`availability-select-${orgAnalytic.id}`}
        options={[true, false]}
        value={orgAnalytic?.available}
        setValue={(value) => {
          if (value === orgAnalytic?.available) {
            return;
          }
          if (
            organizationAnalyticAvailabilityPutMutation.isPending ||
            removing
          ) {
            return;
          }

          const data: APIModels.CustomerAnalytic = {
            ...orgAnalytic,
            available: value,
          };
          delete data.analytic;
          organizationAnalyticAvailabilityPutMutation.mutate({
            id: orgAnalytic?.id as number,
            data: data,
          });
        }}
        optionBuilder={(o) => (o ? 'Yes' : 'Coming Soon')}
        readOnly={
          !canEditAnalytics ||
          organizationAnalyticAvailabilityPutMutation.isPending ||
          removing
        }
      />
    </div>
  );
};

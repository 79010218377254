import {
  ColorStyle,
  GroupLayersProps,
  LabelType,
  Layer,
  VectorStyle,
  WmsVectorMapLayer,
} from '@agerpoint/types';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import {
  FileDownloadIcon,
  LinearGradient,
  PointCloudIcon,
  RasterIcon,
} from '@agerpoint/component';
import { useEffect, useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LayerListItem } from '../layer-list-item/layer-list-item';
import { colord } from 'colord';
import { faCircleNotch } from '@fortawesome/pro-regular-svg-icons';

export function IndividualGroupLayers({
  group: { layers, id },
  mode,
  selectedLayerId,
  setVisible,
  setStyle,
  setSelectedLayerId,
  currentExpansionId,
  toggleExpansion,
  setMultiSelectedLayers,
  multiSelectGroupId,
  setMultiSelectGroupId,
  clearMultiSelection,
  multiSelectedItems,
  layerTypeId,
  setMultiSelectLayerTypeId,
}: GroupLayersProps) {
  const [multiSelectItems, setMultiSelectItems] = useState<number[]>([]);
  const [selectedGroupId, setSelectedGroupId] = useState<
    number | null | undefined
  >(multiSelectGroupId);

  function onMultiSelectItems(
    layer: number,
    groupId: number,
    tempLayerTypeId: number
  ) {
    let layerExists = false;
    const controlGroupId = multiSelectGroupId;
    const controlSelectedItems = multiSelectItems;
    for (let i = 0; i < multiSelectItems.length; i++) {
      if (layer === multiSelectItems[i]) {
        layerExists = true;
      }
    }
    setMultiSelectLayerTypeId?.(tempLayerTypeId);
    if (layerExists) {
      if (controlSelectedItems.length > 1) {
        setMultiSelectItems((current) =>
          current.filter((layerObject) => {
            return layerObject !== layer;
          })
        );
      } else {
        setMultiSelectItems([]);
        setSelectedGroupId(null);
      }
    } else {
      if (groupId !== controlGroupId && controlGroupId !== null) {
        setMultiSelectItems(multiSelectItems);
      } else {
        setMultiSelectItems([...multiSelectItems, layer]);
        setSelectedGroupId(groupId);
      }
    }
  }
  function onSingleSelectItems(
    layer: number,
    groupId: number,
    tempLayerTypeId: number
  ) {
    let layerExists = false;
    const controlGroupId = multiSelectGroupId;
    for (let i = 0; i < multiSelectItems.length; i++) {
      if (layer === multiSelectItems[i]) {
        layerExists = true;
      }
    }

    setMultiSelectLayerTypeId?.(tempLayerTypeId);
    if (groupId === controlGroupId || controlGroupId === null) {
      if (layerExists && multiSelectItems.length < 2) {
        setMultiSelectItems([]);
        setSelectedGroupId(null);
        setSelectedLayerId(layer);
      } else {
        setMultiSelectItems([layer]);
        setSelectedGroupId(groupId);
        setSelectedLayerId(layer);
      }
    } else {
      clearMultiSelection?.();
      setMultiSelectItems([layer]);
      setSelectedGroupId(groupId);
      setSelectedLayerId(layer);
    }
  }

  useEffect(() => {
    if (
      multiSelectGroupId !== selectedGroupId &&
      multiSelectGroupId !== null &&
      multiSelectGroupId !== undefined
    ) {
      setMultiSelectItems([]);
    }
  }, [multiSelectGroupId]);

  useEffect(() => {
    if (
      (selectedGroupId !== undefined && multiSelectGroupId === null) ||
      (selectedGroupId !== undefined && multiSelectGroupId === undefined) ||
      (selectedGroupId !== undefined && selectedGroupId === multiSelectGroupId)
    ) {
      setMultiSelectedLayers?.(multiSelectItems);
      setMultiSelectGroupId?.(selectedGroupId);
    }
  }, [multiSelectItems, selectedGroupId, setMultiSelectGroupId]);

  useEffect(() => {
    if (!selectedLayerId) return;
    if (multiSelectItems.length > 1) return;
    setMultiSelectedLayers?.([selectedLayerId]);
    setMultiSelectItems([selectedLayerId]);
  }, [selectedLayerId]);

  return (
    <Droppable droppableId={`groupLayer-${id}`}>
      {({ droppableProps, innerRef, placeholder }) => (
        <div ref={innerRef} {...droppableProps}>
          {layers.length > 0 &&
            layers.map((layer, index) => {
              return (
                <div
                  key={layer.id}
                  onClick={(e) => {
                    if (e.metaKey || e.ctrlKey) {
                      return onMultiSelectItems(layer.id, id, layer.typeId);
                    } else {
                      return onSingleSelectItems(layer.id, id, layer.typeId);
                    }
                  }}
                >
                  <Draggable
                    key={layer.id}
                    draggableId={`layer-${layer.id.toString()}`}
                    index={index}
                    isDragDisabled={layer.type === 'Unsaved'}
                  >
                    {(provided, snapshot) => (
                      <LayerListItem
                        currentExpansionId={currentExpansionId}
                        toggleExpansion={toggleExpansion}
                        draggableProvided={provided}
                        layer={layer}
                        selected={multiSelectItems.includes(layer.id)}
                        setVisible={setVisible}
                        setSelectedLayerId={setSelectedLayerId}
                        onSingleSelectItems={onSingleSelectItems}
                        groupId={id}
                        setStyle={(newLayer) => setStyle(layer.id, newLayer)}
                        onSelect={() => {
                          setSelectedLayerId(layer.id, true);
                        }}
                        disabled={
                          layer.type === 'Unsaved' ||
                          (mode === '2d'
                            ? layer.type === 'PointCloud'
                            : layer.type !== 'PointCloud')
                        }
                        checkboxLabel={
                          layer.type !== 'Unsaved' ? (
                            <span className="flex items-center justify-start text-sm">
                              <span className="flex-shrink-0">
                                <LayerIcon layer={layer} />
                              </span>
                              <span
                                title={layer.name}
                                className=" flex justify-start items-center mx-2
                                truncate max-w-48 line-clamp-1 overflow-hidden"
                              >
                                {layer.name}
                              </span>
                            </span>
                          ) : (
                            <FontAwesomeIcon
                              icon={faCircleNotch}
                              className="fa-spin"
                            />
                          )
                        }
                      />
                    )}
                  </Draggable>
                </div>
              );
            })}
          {layers.length > 0 ? (
            placeholder
          ) : (
            <div className="text-center bg-gray-50 p-2 m-5 text-sm">
              Drop layers from library here
              {placeholder}
            </div>
          )}
        </div>
      )}
    </Droppable>
  );
}

interface LayerIconProps {
  layer: Layer;
}

function LayerIcon({ layer }: LayerIconProps) {
  switch (layer.type) {
    case 'WmsVector':
      return <StyleLegend style={(layer as WmsVectorMapLayer).style} />;
    case 'WmsRaster':
      return <RasterIcon />;
    case 'PointCloud':
      return <PointCloudIcon />;
    case 'File':
      return <FileDownloadIcon />;
    default:
      return (
        <StyleLegend
          style={{
            strokeColor: 'transparent',
            fillColor: 'transparent',
            fillOpacity: 0,
            strokeOpacity: 0,
            strokeWidth: 0,
            strokePattern: 'Solid',
            label: { type: LabelType.None },
          }}
        />
      );
  }
}

interface StyleLegend {
  style: VectorStyle;
}

function StyleLegend({ style }: StyleLegend) {
  const fillColorWithAlpha = colord(getColorFromStyle(style.fillColor))
    .alpha(style.fillOpacity)
    .toRgbString();

  const strokeColorWithAlpha = colord(getColorFromStyle(style.strokeColor))
    .alpha(style.strokeOpacity)
    .toRgbString();

  return typeof style.fillColor === 'string' ||
    style.fillColor.type === 'solid' ? (
    <div
      className="rounded-sm border w-5 h-5 flex-shrink-0"
      style={{
        backgroundColor: fillColorWithAlpha,
        borderColor: strokeColorWithAlpha,
      }}
    />
  ) : style.fillColor.type === 'graduated' ||
    style.fillColor.type === 'categorized' ? (
    <LinearGradient
      colors={style.fillColor.gradient}
      className="rounded-sm border w-5 h-5 flex-shrink-0"
      style={{ borderColor: strokeColorWithAlpha }}
    />
  ) : null;
}

function getColorFromStyle(colorStyle: string | ColorStyle): string {
  if (typeof colorStyle === 'string') {
    return colorStyle;
  } else if (colorStyle.type === 'solid') {
    return colorStyle.color;
  } else {
    return 'rgba(0, 0, 0, 0)';
  }
}

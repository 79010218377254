import * as THREE from 'three';

import {
  CaptureObjectMarkerType,
  EventBusNames,
  IMarkerObj,
  MarkerObjOptions,
} from '@agerpoint/types';
import {
  _create3dCustomMarker,
  _create3dExtractionMarker,
  _createTextLabel,
  dragElement,
  eventBus,
} from '@agerpoint/utilities';

export class IconMarker implements IMarkerObj {
  options: MarkerObjOptions;
  element: HTMLElement;
  labelElement: HTMLElement;
  editable: boolean;
  position = new THREE.Vector3(0, 0, 0);
  isHighlighted: boolean = false;
  id = '';

  constructor(
    eventName: EventBusNames,
    eventId: string,
    options: MarkerObjOptions,
    private perspectiveCamera: THREE.PerspectiveCamera,
    private canvas: HTMLCanvasElement
  ) {
    this.options = options;
    this.editable = options?.editable ?? false;

    if (this.options?.type === CaptureObjectMarkerType.ExtractionJob) {
      this.element = _create3dExtractionMarker(
        this.options?.fill,
        this.options?.clickable
      );
    } else {
      this.element = _create3dCustomMarker(
        this.options?.fill,
        this.options?.clickable
      );
    }
    this.labelElement = _createTextLabel(this.options.name);
    if (!this.options.visible) {
      this.element.style.display = 'none';
    }
    if (!options.visibleLabel) {
      this.labelElement.style.display = 'none';
    }
    if (options.type === CaptureObjectMarkerType.Custom) {
      dragElement(this, this.perspectiveCamera, eventName, eventId);
    }

    if (this.options.clickable && this?.element) {
      this.element.onclick = () => {
        eventBus.dispatch(EventBusNames.CaptureObjectClicked, {
          detail: {
            id: eventId,
            position: this.position,
          },
        });
      };
    }
    const potreeRenderAreaElem = document.querySelector('#potree_render_area');
    if (!potreeRenderAreaElem) return;

    potreeRenderAreaElem?.appendChild(this.element);
    potreeRenderAreaElem?.appendChild(this.labelElement);
  }

  isOutsideFrustum(position: any, frustumPlanes: any) {
    for (let i = 0; i < 6; i++) {
      if (frustumPlanes[i].distanceToPoint(position) < 0) {
        return true; // The position is outside the frustum
      }
    }
    return false; // The position is inside the frustum
  }

  updateDOM(
    element: HTMLElement,
    display: string,
    left?: string,
    top?: string
  ): void {
    if (element.style.display !== display) {
      element.style.display = display;
    }
    if (left && element.style.left !== left) {
      element.style.left = left;
    }
    if (top && element.style.top !== top) {
      element.style.top = top;
    }
  }

  updateEditability(value: boolean) {
    if (this.options.type !== CaptureObjectMarkerType.Custom) {
      return;
    }
    this.editable = value;
    this.element.style.pointerEvents = value ? 'auto' : 'none';
    this.element.style.cursor = value ? 'pointer' : 'default';
  }

  updatePosition() {
    const leftAdjust = this.isHighlighted ? 24 : 12;
    const leftAdjustTest = this.isHighlighted ? 36 : 18;
    const topAdjust = this.isHighlighted ? 64 : 32;
    const camera = this.perspectiveCamera; // Replace this with your camera object

    // Get the combined projection-view matrix
    const projectionMatrix = camera.projectionMatrix;
    const viewMatrix = camera.matrixWorldInverse;
    const viewProjectionMatrix = new THREE.Matrix4().multiplyMatrices(
      projectionMatrix,
      viewMatrix
    );

    // Extract frustum planes from the view projection matrix
    const frustum = new THREE.Frustum() as any;
    frustum.setFromProjectionMatrix(viewProjectionMatrix);
    // Get the six planes of the frustum
    const frustumPlanes = frustum.planes;

    // is the point outside the frustum?
    const isOutside = this.isOutsideFrustum(this.position, frustumPlanes);
    // Perform DOM updates based on the current state
    if (isOutside) {
      this.updateDOM(this.element, 'none');
      this.updateDOM(this.labelElement, 'none');
    } else {
      const coords2d = this.get2dCoords(this.position);
      this.updateDOM(
        this.element,
        this.options.visible ? 'block' : 'none',
        `${coords2d.x - leftAdjust}px`,
        `${coords2d.y - topAdjust}px`
      );
      this.updateDOM(
        this.labelElement,
        this.options.visibleLabel ? 'block' : 'none',
        `${coords2d.x + leftAdjustTest}px`,
        `${coords2d.y - topAdjust}px`
      );
    }
  }

  setPosition(_position: THREE.Vector3) {
    this.position = _position;
  }
  get2dCoords(_position: THREE.Vector3) {
    const temp = new (window as any).THREE.Vector3(
      _position.x,
      _position.y,
      _position.z
    );
    const vector = temp.project(this.perspectiveCamera);
    vector.x = ((temp.x + 1) / 2) * this.canvas.width;
    vector.y = (-(temp.y - 1) / 2) * this.canvas.height;
    return vector;
  }

  highlight() {
    const fill = this.element.querySelector<HTMLElement>('svg');
    if (!fill) return;
    this.isHighlighted = true;
    fill.style.height = '64px';
  }

  unHighlight() {
    const fill = this.element.querySelector<HTMLElement>('svg');
    if (!fill) return;
    this.isHighlighted = false;
    fill.style.height = '32px';
  }

  dispose() {
    this.element.remove();
    this.labelElement.remove();
  }
}

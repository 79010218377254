import { IconButton } from '../icon-button/icon-button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { ReactNode } from 'react';

interface SidebarExpansionHeaderProps {
  onCancel?: () => void;
  children?: ReactNode;
  size?: 'small' | 'large';
}

export function SidebarExpansionHeader({
  onCancel,
  children,
  size = 'small',
}: SidebarExpansionHeaderProps) {
  return (
    <div
      className={`shadow-heading flex justify-between items-center ${
        size === 'large' ? 'px-6 pt-7 pb-6' : 'px-4 pt-3 leading-4 pb-2'
      }`}
    >
      {children}
      {onCancel && (
        <IconButton
          show
          onClick={onCancel}
          className={`w-7 h-7 ${size === 'large' ? '-mt-11 -mr-6' : ''}`}
          icon={
            <FontAwesomeIcon
              icon={faTimes}
              className="text-2xl text-gray-600 hover:text-gray-900"
            />
          }
        />
      )}
    </div>
  );
}

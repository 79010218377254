import {
  ExtrudeGeometry,
  Group,
  Material,
  Mesh,
  MeshBasicMaterial,
  PerspectiveCamera,
  Scene,
  ShapePath,
  Sprite,
  SpriteMaterial,
  TextureLoader,
  Vector3,
} from 'three';
import { SVGLoader } from 'three/examples/jsm/loaders/SVGLoader.js';

import {
  Annotation3dPoints,
  ColorsThreeD,
  HexColor,
  IMultiPoint3d,
  IPoint3d,
} from '@agerpoint/types';
import { AnnotationGroupName, createSphereObject } from '@agerpoint/utilities';

import { GeometryBase } from './geometry.base';
import { Point3d } from './point-3d';

export class MultiPoint3d extends GeometryBase implements IMultiPoint3d {
  points: Point3d[] = [];
  public type: Annotation3dPoints;
  readonly uniqueId: string;
  private visible: boolean = true;

  constructor(
    scene: Scene,
    perspectiveCamera: PerspectiveCamera,
    id: string,
    type: Annotation3dPoints,
    color: ColorsThreeD = ColorsThreeD.Cyan,
    visible: boolean = true
  ) {
    super(scene, perspectiveCamera, false);
    this.uniqueId = id;
    this.type = type;
    this.color = color;
  }

  get isVisible() {
    return this.visible;
  }

  dispose() {
    this.points.forEach((point) => {
      point.dispose();
    });
    super.dispose();
  }

  delete(): void {
    this.annoMgr.deleteCapObj(this.uniqueId);
  }

  updatePositions(newPoints: Vector3[]) {
    if (!newPoints?.length) return;
    const group = this.scene.getObjectByName(AnnotationGroupName) as Group;
    if (this.points?.length) {
      this.points.forEach((point) => {
        group.remove(point.object);
        point.dispose();
      });
    }
    this.points = newPoints.map((position, index) => {
      const point = new Point3d(
        this.scene,
        this.perspectiveCamera,
        `${this.uniqueId}`,
        position,
        this.type,
        false,
        this.color,
        true
      );
      return point;
    });
  }

  updateVisibility(isVisible: boolean) {
    this.visible = isVisible;
    this.points.forEach((point) => {
      point.updateVisibility(isVisible);
    });
  }

  updateType(type: Annotation3dPoints) {
    this.type = type;
    this.points.forEach((point) => {
      point.updateType(type);
    });
  }

  updateColor(color: ColorsThreeD | HexColor) {
    this.color = color;
    this.points.forEach((point) => {
      point.updateColor(color);
    });
    this.notifyListeners();
  }

  zoomTo() {
    // TODO: Implement zoomTo method
  }
  disposeAndDelete() {
    this.dispose();
    this.delete();
  }
}

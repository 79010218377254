import { useMemo } from 'react';
import { shallow } from 'zustand/shallow';

import {
  MultiDNDLibraryExpansion as MultiDNDLibraryExpansionComponent,
  MultiDNDPaginatedLibraryExpansion as MultiDNDPaginatedLibraryExpansionComponent,
} from '@agerpoint/feature';
import { LDFlagSet, LdFlags, PanelsState } from '@agerpoint/types';
import { hasPermission } from '@agerpoint/utilities';

import { usePanels } from '../../state/use-panels';

export function LibraryExpansion({ permissions }: { permissions: LDFlagSet }) {
  const { isDraggingFromLibrary, toggleExpansion } = usePanels(
    getLibraryState,
    shallow
  );

  const hasPaginatedLibraryPermission = useMemo(
    () => hasPermission(LdFlags.PaginatedLibrary, permissions),
    [permissions]
  );

  if (hasPaginatedLibraryPermission) {
    return (
      <MultiDNDPaginatedLibraryExpansionComponent
        onCancel={() => toggleExpansion({ type: 'none' })}
      />
    );
  }

  return (
    <MultiDNDLibraryExpansionComponent
      isDragging={isDraggingFromLibrary}
      onCancel={() => toggleExpansion({ type: 'none' })}
    />
  );
}

function getLibraryState({
  isDraggingFromLibrary,
  toggleExpansion,
}: PanelsState) {
  return {
    isDraggingFromLibrary,
    toggleExpansion,
  };
}

import { faTable } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useCallback, useEffect, useState } from 'react';
import { Vector3 } from 'three';

import { CaptureObject, usePutCaptureObjectById } from '@agerpoint/api';
import { PrimaryButton } from '@agerpoint/component';
import { Viewer } from '@agerpoint/three-d-viewer';
import {
  CaptureObjectMarkerType,
  CaptureObjectMarkers,
  EffectNames,
  EventBusNames,
  IViewer,
  LdFlags,
  MixpanelNames,
} from '@agerpoint/types';
import {
  eventBus,
  getUpdatedCaptureMarkers,
  hasPermission,
  useGlobalStore,
} from '@agerpoint/utilities';

import { EditableAttributeTableObjects } from '../attribute-tables/object-attributes/attribute-table-object-editable';
import { CaptureObjectTreeSection } from './utils';

interface CaptureObjectSidebarProps {
  viewer: React.MutableRefObject<IViewer | undefined> | undefined;
  captureObjectsData: CaptureObject[];
  show: boolean;
  showAllMarkers: boolean;
  eptId: number | undefined;
  captureId: number;
  refetch: () => void;
}
/**
 * @deprecated
 */
export const CaptureObjectSidebar = ({
  viewer,
  captureObjectsData,
  show,
  showAllMarkers,
  eptId,
  captureId,
  refetch,
}: CaptureObjectSidebarProps) => {
  const {
    permissions,
    captureObjectMarkers,
    captureObjectMarkersHistory,
    subscribe,
    actions: {
      setCaptureObjectMarkers,
      setCaptureObjectWindowOpen,
      sendEvent,
      addCaptureObjectMarkersHistory,
      removeCaptureObjectMarkersHistory,
      addUndo,
      removeUndo,
    },
    sidebar: { isOpen },
  } = useGlobalStore();
  const { mutate: updateCaptureObject } = usePutCaptureObjectById({ id: NaN });

  const [showEditModal, setShowEditModal] = useState(false);
  const [viewerReady, setViewerReady] = useState(false);
  const [hasApfGeometryPermission, setHasApfGeometryPermission] =
    useState(false);
  const [
    hasApfGeometryConvexHullPermission,
    setHasApfGeometryConvexHullPermission,
  ] = useState(false);
  const [visibleAttributesLookup, setVisibleAttributesLookup] = useState<{
    [key: string]: boolean;
  }>({});

  useEffect(() => {
    if (viewer?.current?.pointCloudReady) {
      setViewerReady(true);
    }

    return () => {
      viewer?.current?.clearAllConvexHulls();
    };
  }, [viewer, setViewerReady]);

  const temp = useCallback(() => {
    const newCaptureObjects = captureObjectMarkers
      .sort((a, b) => a.id - b.id)
      .map((co) => {
        const coId = co.id.toString();
        viewer?.current?.toggleObjectVisibility(coId, showAllMarkers);
        viewer?.current?.toggleTextLabelById(coId, showAllMarkers);
        viewer?.current?.toggleTrunkLineVisibility(coId, showAllMarkers);
        return {
          ...co,
          showMarker: showAllMarkers,
          showLabel: showAllMarkers,
        };
      });
    setCaptureObjectMarkers(newCaptureObjects);
  }, [showAllMarkers, captureObjectMarkers]);

  useEffect(() => {
    temp();
  }, [showAllMarkers, eptId]);

  useEffect(() => {
    if (captureObjectsData) {
      const captureObjectMarkersFromApi = getUpdatedCaptureMarkers(
        captureObjectsData,
        captureObjectMarkers
      );
      setCaptureObjectMarkers(captureObjectMarkersFromApi);
    }
  }, [captureObjectsData]);

  useEffect(() => {
    if (!captureObjectMarkers.length || !viewerReady) {
      return;
    }
    const eventId = eventBus.on(
      EventBusNames.CaptureObjectPositionChanged,
      handleCaptureObjectPositionChanged,
      true
    );

    captureObjectMarkers?.forEach(addMarkerToPotreeViewer);
    return () => {
      // clearAllUndo();
      eventBus.remove(
        EventBusNames.CaptureObjectPositionChanged,
        handleCaptureObjectPositionChanged,
        eventId
      );
    };
  }, [captureObjectMarkers, viewerReady, hasApfGeometryPermission]);

  useEffect(() => {
    const canSeeApfGeom = hasPermission(
      LdFlags.CaptureObjectApfGeometry,
      permissions
    );
    setHasApfGeometryPermission(canSeeApfGeom);

    const canSeeConvexHull = hasPermission(
      LdFlags.CaptureObjectApfGeometryConvexHull,
      permissions
    );
    setHasApfGeometryConvexHullPermission(canSeeConvexHull);
  }, [permissions]);

  useEffect(() => {
    const eventId = eventBus.on(
      EventBusNames.PointCloudLoaded,
      handlePointCloudLoaded,
      true
    );
    viewer?.current?.clearAllMarkers();
    return () => {
      eventBus.remove(
        EventBusNames.PointCloudLoaded,
        handlePointCloudLoaded,
        eventId
      );
    };
  }, []);

  const handlePointCloudLoaded = () => {
    setViewerReady(true);
  };

  const addGeometry = async (captureObject: CaptureObjectMarkers) => {
    const pos = await viewer?.current?.addNewObjectLocation(
      EventBusNames.CaptureObjectPositionChanged,
      captureObject.id.toString(),
      {
        fill: captureObject.markerFill,
        name: captureObject?.name || '',
        visible: captureObject.showMarker,
        visibleLabel: captureObject.showLabel,
        type: CaptureObjectMarkerType.Custom,
        editable: captureObject.editable,
      }
    );
    updateCaptureObjectPosition(captureObject, pos);
  };

  const handleCaptureObjectPositionChanged = (data: {
    detail: { pos: Vector3; id: number };
  }) => {
    const captureObject = captureObjectMarkers.find(
      (co) => co.id == data?.detail?.id
    );
    if (captureObject) {
      updateCaptureObjectPosition(captureObject, data.detail.pos);
    }
  };

  const savePosition = async (captureObject: CaptureObject, position: any) => {
    const newCaptureObject = {
      ...captureObject,
      x: position.x,
      y: position.y,
      z: position.z,
    };
    if (!newCaptureObject.radius || newCaptureObject.radius === null) {
      newCaptureObject.radius = 1;
    }
    delete newCaptureObject.captureObjectCustomAttributes;
    delete newCaptureObject.geom;

    try {
      await updateCaptureObject(newCaptureObject, {
        pathParams: { id: captureObject.id as number },
      });
    } catch (error) {
      console.error(error);
    }
    return newCaptureObject;
  };

  const resetCaptureObjectPosition = async (
    captureObject: CaptureObjectMarkers,
    position: any
  ) => {
    const newCaptureObject = await savePosition(captureObject, position);
    const newCaptureObjects = captureObjectMarkers.map((co) => {
      if (co.id === captureObject.id) {
        return {
          ...newCaptureObject,
          captureObjectCustomAttributes:
            captureObject.captureObjectCustomAttributes,
          showMarker: true,
          id: newCaptureObject.id || 0,
          markerFill: captureObject.markerFill,
          highlighted: captureObject.highlighted,
          showLabel: captureObject.showLabel,
          editable: captureObject.editable,
          type: captureObject.type,
        };
      }
      return co;
    });
    setCaptureObjectMarkers(newCaptureObjects);
    viewer?.current?.clearMarkerById(captureObject.id.toString());
    addMarkerToPotreeViewer(captureObject);
  };

  const updateCaptureObjectPosition = async (
    captureObject: CaptureObject,
    position: any
  ) => {
    const newCaptureObject = await savePosition(captureObject, position);

    const newCaptureObjects = captureObjectMarkers.map((co) => {
      if (co.id === captureObject.id) {
        return {
          ...newCaptureObject,
          captureObjectCustomAttributes: co.captureObjectCustomAttributes,
          showMarker: true,
          id: newCaptureObject.id || 0,
          markerFill: co.markerFill,
          highlighted: co.highlighted,
          showLabel: co.showLabel,
          editable: co.editable,
          type: co.type,
        };
      }
      return co;
    });
    setCaptureObjectMarkers(newCaptureObjects);
  };

  const locateCaptureObject = (captureObject: CaptureObjectMarkers) => {
    sendEvent(MixpanelNames.CaptureObjectLocateToggleClicked);
    captureObject.highlighted = !captureObject.highlighted;
    viewer?.current?.locateObject(
      captureObject.id.toString(),
      captureObject.highlighted
    );
  };

  const updateObjectEditability = (id: number) => {
    sendEvent(MixpanelNames.CaptureObjectLockToggleClicked);
    const newCaptureObjects = captureObjectMarkers.map((co) => {
      if (co.id === id) {
        handleObjectHistoryAndUndo({ ...co });
        const editable = !co.editable;
        const coId = co.id.toString();

        viewer?.current?.updateObjectEditability(coId, editable);
        return { ...co, editable };
      }
      return co;
    });
    setCaptureObjectMarkers(newCaptureObjects);
  };

  const toggleMarker = (id: number) => {
    const newCaptureObjects = captureObjectMarkers.map((co) => {
      if (co.id === id) {
        const show = !co.showMarker;
        const coId = co.id.toString();
        viewer?.current?.toggleObjectVisibility(coId, show);
        viewer?.current?.toggleTextLabelById(coId, show);
        viewer?.current?.toggleTrunkLineVisibility(coId, show);
        return { ...co, showMarker: show, showLabel: show };
      }
      return co;
    });
    setCaptureObjectMarkers(newCaptureObjects);
  };

  const toggleViewerLabel = (id: number) => {
    sendEvent(MixpanelNames.CaptureObjectLabelToggleClicked);
    const newCaptureObjects = captureObjectMarkers.map((co) => {
      if (co.id === id) {
        const show = !co.showLabel;
        const coId = co.id.toString();
        viewer?.current?.toggleTextLabelById(coId, show);
        return { ...co, showLabel: show };
      }
      return co;
    });
    setCaptureObjectMarkers(newCaptureObjects);
  };

  const closeEditableAttributeTable = () => {
    refetch();
    setShowEditModal(false);
  };

  const handleObjectHistoryAndUndo = (co: CaptureObjectMarkers) => {
    const editable = co.editable ? false : true;
    if (editable) {
      addCaptureObjectMarkersHistory({ ...co }, co.id);
    } else {
      const prevVersion = captureObjectMarkersHistory[co.id];
      const coId = co.id.toString();

      const currentVersion = captureObjectMarkers.find(
        (captureObject) => captureObject.id === co.id
      );

      if (
        prevVersion &&
        currentVersion &&
        prevVersion.x === currentVersion.x &&
        prevVersion.y === currentVersion.y &&
        prevVersion.z === currentVersion.z
      ) {
        return;
      }

      addUndo({
        id: coId,
        title: 'Marker Position Updated',
        timestamp: new Date().getTime(),
        undoFn: () => {
          if (prevVersion) {
            resetCaptureObjectPosition(prevVersion, {
              x: prevVersion.x,
              y: prevVersion.y,
              z: prevVersion.z,
            });
          }
          removeCaptureObjectMarkersHistory(co.id);
          removeUndo(coId);
        },
        closeFn: () => {
          removeCaptureObjectMarkersHistory(co.id);
          removeUndo(coId);
        },
      });
    }
  };

  const addMarkerToPotreeViewer = (captureObject: CaptureObjectMarkers) => {
    if (!captureObject.x || !captureObject.y || !captureObject.z) return;
    const coId = captureObject.id.toString();
    viewer?.current?.addExistingObjectLocation(
      EventBusNames.CaptureObjectPositionChanged,
      coId,
      new Vector3(captureObject.x, captureObject.y, captureObject.z),
      {
        fill: captureObject.markerFill,
        name: captureObject.name || '',
        visible: captureObject.showMarker,
        visibleLabel: captureObject.showLabel,
        type: captureObject.type,
        editable: captureObject.editable,
      }
    );

    if (
      hasApfGeometryPermission &&
      captureObject?.geom2D?.coordinates?.length
    ) {
      const path = captureObject?.geom2D?.coordinates[0] as any;
      const coId = captureObject.id.toString();
      viewer?.current?.drawTrunkLine(path, coId);
    }
    if (
      hasApfGeometryConvexHullPermission &&
      captureObject?.geom2D?.coordinates?.length
    ) {
      const path = captureObject?.geom2D?.coordinates[0] as any;
      const coId = captureObject.id.toString();
      viewer?.current?.drawConvexHull(path, coId);
    }
  };

  return (
    <div className="my-1 pl-5 pr-3">
      {show && (
        <div>
          {captureObjectMarkers?.length ? (
            <CaptureObjectTreeSection
              updateObjectEditability={updateObjectEditability}
              captureObjectMarkers={captureObjectMarkers}
              toggleMarker={toggleMarker}
              toggleViewerLabel={toggleViewerLabel}
              locateCaptureObject={locateCaptureObject}
              addGeometry={addGeometry}
              visibleAttributesLookup={visibleAttributesLookup}
              setVisibleAttributesLookup={setVisibleAttributesLookup}
            />
          ) : null}
          <div className="flex flex-row pt-4 justify-center">
            <PrimaryButton
              onClicked={() => {
                setCaptureObjectWindowOpen(true);
              }}
              label="Attribute Table"
              icon={<FontAwesomeIcon icon={faTable} />}
              size="small"
              className="mr-2 w-3/6 h-6"
            />
          </div>
        </div>
      )}
      {showEditModal && (
        <EditableAttributeTableObjects
          isOpen={showEditModal}
          handleCloseDialog={closeEditableAttributeTable}
          captureId={captureId}
          viewer={viewer}
        />
      )}
    </div>
  );
};

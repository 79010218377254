import {
  faExclamationTriangle,
  faFilm,
  faWaveform,
  faWheatSlash,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useQuery } from '@tanstack/react-query';
import { motion } from 'framer-motion';

import { APIClient } from '@agerpoint/api';
import { APIUtils } from '@agerpoint/utilities';

interface ICaptureThumbnail {
  capture: APIClient.Capture | undefined;
  size: number;
}

export const CaptureThumbnail = ({ capture, size }: ICaptureThumbnail) => {
  const captureThumbnailQuery = useQuery({
    queryKey: [
      APIUtils.QueryKey.thumborImage,
      `${size}x${size}`,
      {
        thumborImageId:
          capture?.firstCaptureImageId ?? capture?.firstCaptureImageTagId,
      },
    ],
    retry: false,
    queryFn: async () => {
      let response: Blob | undefined;
      if (capture?.firstCaptureImageId) {
        response = await APIClient.getCaptureImageThumbnailById2(
          capture?.firstCaptureImageId as number,
          'fit-in',
          `${size}x${size}`,
          ' ',
          ' ',
          ' ',
          ' ',
          {
            responseType: 'blob',
          }
        );
      } else if (capture?.firstCaptureImageTagId) {
        response = await APIClient.getCaptureImageTagThumbnailById2(
          capture?.firstCaptureImageTagId as number,
          'fit-in',
          `${size}x${size}`,
          ' ',
          ' ',
          ' ',
          ' ',
          {
            responseType: 'blob',
          }
        );
      }

      if (response === undefined) {
        return undefined;
      }
      if (response.type === 'text/plain') {
        throw new Error('Failed to fetch image');
      }
      return URL.createObjectURL(response);
    },
    enabled:
      Number.isSafeInteger(capture?.firstCaptureImageId) ||
      Number.isSafeInteger(capture?.firstCaptureImageTagId),
    gcTime: APIUtils.getDuration({
      minutes: 1,
    }),
  });

  if (captureThumbnailQuery.isLoading || capture === undefined) {
    return <div className="size-full shimmer" />;
  }

  if (captureThumbnailQuery.isError) {
    return (
      <motion.div
        className="size-full flex-center"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{
          duration: 0.5,
        }}
      >
        <FontAwesomeIcon
          icon={faExclamationTriangle}
          className="text-status-error text-2xl"
        />
      </motion.div>
    );
  }

  if (
    captureThumbnailQuery.isSuccess &&
    captureThumbnailQuery.data !== undefined
  ) {
    return (
      <motion.img
        src={captureThumbnailQuery.data}
        alt={`${capture?.captureName} thumbnail`}
        className="object-cover select-none w-full h-full"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{
          duration: 0.5,
        }}
      />
    );
  }

  if ((capture?.totalCaptureVideos ?? 0) > 0) {
    return (
      <motion.div
        className="size-full flex-center"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{
          duration: 0.5,
        }}
      >
        <FontAwesomeIcon
          icon={faFilm}
          className="text-gray-iconPrimary text-2xl"
        />
      </motion.div>
    );
  }

  if ((capture?.totalCaptureAudios ?? 0) > 0) {
    return (
      <motion.div
        className="size-full flex-center"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{
          duration: 0.5,
        }}
      >
        <FontAwesomeIcon
          icon={faWaveform}
          className="text-gray-iconPrimary text-2xl"
        />
      </motion.div>
    );
  }

  return (
    <motion.div
      className="size-full flex-center"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{
        duration: 0.5,
      }}
    >
      <FontAwesomeIcon
        icon={faWheatSlash}
        className="text-gray-iconSecondary text-2xl"
      />
    </motion.div>
  );
};

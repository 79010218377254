// import {
//   IconDefinition,
//   faMap,
//   faRoad,
//   faSatellite,
// } from '@fortawesome/pro-regular-svg-icons';
// import { MapBrowserEvent } from 'ol';
// import Feature from 'ol/Feature';
// import OlMap from 'ol/Map';
// import Overlay from 'ol/Overlay';
// import { Extent } from 'ol/extent';
// import Geometry from 'ol/geom/Geometry';
// import TileLayer from 'ol/layer/Tile';
// import BingMaps from 'ol/source/BingMaps';
// import { StyleLike } from 'ol/style/Style';
// import React, { useEffect, useMemo, useState } from 'react';
import OlMap from 'ol/Map';
import { Coordinate } from 'ol/coordinate';
import { Extent } from 'ol/extent';
import TileLayer from 'ol/layer/Tile';
import { transform } from 'ol/proj';
import { register } from 'ol/proj/proj4';
import BingMaps from 'ol/source/BingMaps';
import { StyleLike } from 'ol/style/Style';
import proj4 from 'proj4';
import { createContext, useContext, useEffect, useState } from 'react';

import { ICloudOpenlayersBaseMap } from './openlayers-map.types';

export const cloudOpenlayersLayerCache: {
  [key: string]: TileLayer<BingMaps>;
} = {};

export const cloudOpenlayersStyleCache: {
  [key: string]: StyleLike;
} = {};

interface ICloudOpenlayersMapContext {
  map: React.MutableRefObject<OlMap | undefined>;
  mapInitialized: boolean;
}

export const CloudOpenlayersMapContext =
  createContext<ICloudOpenlayersMapContext>({
    map: { current: undefined },
    mapInitialized: false,
  });

export const useCloudOpenlayersMapContext = () =>
  useContext(CloudOpenlayersMapContext);

// export interface OpenLayerMapProps<T> {
//   id: string;
//   controller?: (controller: OpenLayerMapController) => void;
//   bingKey: string;
//   mapLayers: {
//     used: OpenMapLayer[];
//     initial: OpenMapLayer;
//   };
//   featureLayer?: {
//     data: T[];
//     styles?: { [key: string]: StyleLike };
//     featureGenerator: (data: T) => OpenLayerGeneratedFeature | null;
//     popupGenerator?: (id: number | string) => React.ReactNode;
//   };
//   geoJsonLayer?: {
//     data: Feature<Geometry>[];
//   };
//   callbacks?: {
//     onDragBoxSelect?: (ids: (number | string)[]) => void;
//     onFeatureClick?: (
//       id: number | string,
//       event: MapBrowserEvent<PointerEvent>
//     ) => void;
//   };
//   dependencies?: React.DependencyList;
// }

// interface OpenLayerMapPopupProps {
//   popup?: { overlay?: Overlay | undefined; id?: string | number | undefined };
//   setPopup: (overlay?: Overlay) => void;
//   generator: (id: number | string) => React.ReactNode;
// }

// export const OpenlayerMapPopup = ({
//   popup,
//   setPopup,
//   generator,
// }: OpenLayerMapPopupProps) => {
//   const [popupRef, setPopupRef] = useState<HTMLElement>();

//   useEffect(() => {
//     const overlay = popupRef
//       ? new Overlay({
//           element: popupRef,
//           positioning: 'center-left',
//           offset: [8, 0],
//         })
//       : undefined;

//     setPopup(overlay);
//   }, [popupRef]);

//   const children = useMemo(() => {
//     if (!popup?.id) {
//       return null;
//     }

//     return generator(popup.id);
//   }, [generator, popup?.id]);

//   useEffect(() => {
//     if (popup?.overlay?.getPosition()) {
//       popup.overlay.panIntoView({ animation: { duration: 250 }, margin: 50 });
//     }
//   }, [children]);

//   return (
//     <div ref={(ref) => setPopupRef(ref ?? undefined)}>
//       {children && (
//         <div className="flex flex-row relative">
//           <div className="flex justify-center items-center z-10 relative">
//             <div
//               className="absolute m-y-auto w-4 h-4 rotate-45 rounded-bl-sm bg-gray-400"
//               style={{ left: '-7px' }}
//             ></div>
//           </div>
//           <div className=" bg-white rounded-lg overflow-hidden z-20 border border-gray-400 shadow">
//             {children}
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

export const cloudOpenlayersMapViewExtentStorage: { [id: string]: Extent } = {};

export const useCloudOpenlayersMapExtentRestoration = ({
  id,
  map,
  mapInitialized,
  persistView = false,
}: {
  id: string;
  map: React.MutableRefObject<OlMap | undefined>;
  mapInitialized: boolean;
  persistView?: boolean;
}) => {
  const [extentRestored, setExtentRestored] = useState(false);

  useEffect(() => {
    if (id && mapInitialized && !extentRestored && persistView) {
      const view = map.current?.getView();
      const extent: Extent | undefined =
        cloudOpenlayersMapViewExtentStorage?.[id];
      if (extent !== undefined) {
        view?.fit(extent);
      }
      setExtentRestored(true);
    }
  }, [id, extentRestored, mapInitialized, persistView]);

  useEffect(() => {
    const m = map.current;
    if (!extentRestored || !persistView || !mapInitialized || !m) {
      return;
    }

    const onMoveEnd = () => {
      cloudOpenlayersMapViewExtentStorage[id] = m.getView().calculateExtent();
    };
    m.addEventListener('moveend', onMoveEnd);

    return () => {
      m.removeEventListener('moveend', onMoveEnd);
    };
  }, [id, extentRestored, mapInitialized, persistView]);
};

export const useCloudOpenlayersMapProjectionRegistration = () => {
  useEffect(() => {
    proj4.defs(
      'EPSG:4269',
      '+proj=longlat +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +no_defs +type=crs'
    );
    proj4.defs(
      'EPSG:32615',
      '+proj=utm +zone=15 +datum=WGS84 +units=m +no_defs +type=crs'
    );
    proj4.defs('EPSG:4326', '+proj=longlat +datum=WGS84 +no_defs +type=crs');
    register(proj4);
  }, []);
};

export const getBaseMapIcon = (baseMap: ICloudOpenlayersBaseMap) => {
  if (baseMap === 'Aerial') {
    return 'satellite';
  }
  if (baseMap === 'AerialWithLabelsOnDemand') {
    return 'map';
  }
  if (baseMap === 'CanvasLight') {
    return 'road';
  }
  return 'question-circle';
};

export const defaultTransform = (coords: Coordinate) =>
  transform(coords, 'EPSG:4326', 'EPSG:3857');

export const defaultReverseTransform = (coords: Coordinate) =>
  transform(coords, 'EPSG:3857', 'EPSG:4326');

export const defaultInitialView = {
  center: defaultTransform([-97, 37]),
  zoom: 4,
};

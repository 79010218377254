/**
 * Generated by orval v6.28.2 🍺
 * Do not edit manually.
 * GeminiAPI
 * OpenAPI spec version: v1
 */
import { useMutation, useQuery } from '@tanstack/react-query';
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import type { MosaicEngine } from '.././models';
import { AxiosInstance } from '../../axios-instance';
import type { ErrorType, BodyType } from '../../axios-instance';

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

export const getMosaicEngines = (
  options?: SecondParameter<typeof AxiosInstance>,
  signal?: AbortSignal,
) => {
  return AxiosInstance<MosaicEngine[]>(
    { url: `/api/mosaicengines`, method: 'GET', signal },
    options,
  );
};

export const getGetMosaicEnginesQueryKey = () => {
  return [`/api/mosaicengines`] as const;
};

export const getGetMosaicEnginesQueryOptions = <
  TData = Awaited<ReturnType<typeof getMosaicEngines>>,
  TError = ErrorType<void>,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof getMosaicEngines>>, TError, TData>
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetMosaicEnginesQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getMosaicEngines>>
  > = ({ signal }) => getMosaicEngines(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getMosaicEngines>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetMosaicEnginesQueryResult = NonNullable<
  Awaited<ReturnType<typeof getMosaicEngines>>
>;
export type GetMosaicEnginesQueryError = ErrorType<void>;

export const useGetMosaicEngines = <
  TData = Awaited<ReturnType<typeof getMosaicEngines>>,
  TError = ErrorType<void>,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof getMosaicEngines>>, TError, TData>
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetMosaicEnginesQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const putMosaicEngineById = (
  mosaicEngine: BodyType<MosaicEngine>,
  options?: SecondParameter<typeof AxiosInstance>,
) => {
  return AxiosInstance<MosaicEngine>(
    {
      url: `/api/mosaicengines`,
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      data: mosaicEngine,
    },
    options,
  );
};

export const getPutMosaicEngineByIdMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putMosaicEngineById>>,
    TError,
    { data: BodyType<MosaicEngine> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof putMosaicEngineById>>,
  TError,
  { data: BodyType<MosaicEngine> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof putMosaicEngineById>>,
    { data: BodyType<MosaicEngine> }
  > = (props) => {
    const { data } = props ?? {};

    return putMosaicEngineById(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PutMosaicEngineByIdMutationResult = NonNullable<
  Awaited<ReturnType<typeof putMosaicEngineById>>
>;
export type PutMosaicEngineByIdMutationBody = BodyType<MosaicEngine>;
export type PutMosaicEngineByIdMutationError = ErrorType<void>;

export const usePutMosaicEngineById = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putMosaicEngineById>>,
    TError,
    { data: BodyType<MosaicEngine> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof putMosaicEngineById>>,
  TError,
  { data: BodyType<MosaicEngine> },
  TContext
> => {
  const mutationOptions = getPutMosaicEngineByIdMutationOptions(options);

  return useMutation(mutationOptions);
};
export const postMosaicEngine = (
  mosaicEngine: BodyType<MosaicEngine>,
  options?: SecondParameter<typeof AxiosInstance>,
) => {
  return AxiosInstance<MosaicEngine>(
    {
      url: `/api/mosaicengines`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: mosaicEngine,
    },
    options,
  );
};

export const getPostMosaicEngineMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postMosaicEngine>>,
    TError,
    { data: BodyType<MosaicEngine> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postMosaicEngine>>,
  TError,
  { data: BodyType<MosaicEngine> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postMosaicEngine>>,
    { data: BodyType<MosaicEngine> }
  > = (props) => {
    const { data } = props ?? {};

    return postMosaicEngine(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostMosaicEngineMutationResult = NonNullable<
  Awaited<ReturnType<typeof postMosaicEngine>>
>;
export type PostMosaicEngineMutationBody = BodyType<MosaicEngine>;
export type PostMosaicEngineMutationError = ErrorType<void>;

export const usePostMosaicEngine = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postMosaicEngine>>,
    TError,
    { data: BodyType<MosaicEngine> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof postMosaicEngine>>,
  TError,
  { data: BodyType<MosaicEngine> },
  TContext
> => {
  const mutationOptions = getPostMosaicEngineMutationOptions(options);

  return useMutation(mutationOptions);
};
export const getMosaicEngineById = (
  id: number,
  options?: SecondParameter<typeof AxiosInstance>,
  signal?: AbortSignal,
) => {
  return AxiosInstance<MosaicEngine>(
    {
      url: `/api/mosaicengines/${encodeURIComponent(String(id))}`,
      method: 'GET',
      signal,
    },
    options,
  );
};

export const getGetMosaicEngineByIdQueryKey = (id: number) => {
  return [`/api/mosaicengines/${id}`] as const;
};

export const getGetMosaicEngineByIdQueryOptions = <
  TData = Awaited<ReturnType<typeof getMosaicEngineById>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getMosaicEngineById>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetMosaicEngineByIdQueryKey(id);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getMosaicEngineById>>
  > = ({ signal }) => getMosaicEngineById(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getMosaicEngineById>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetMosaicEngineByIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof getMosaicEngineById>>
>;
export type GetMosaicEngineByIdQueryError = ErrorType<void>;

export const useGetMosaicEngineById = <
  TData = Awaited<ReturnType<typeof getMosaicEngineById>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getMosaicEngineById>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetMosaicEngineByIdQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

import { faPlus, faTimelineArrow } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  DatatableOld,
  dataTableAgerStyle,
} from 'libs/feature/src/datatable/datatable-old';
import 'ol/ol.css';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { useDebouncyEffect } from 'use-debouncy';

import {
  Upload,
  UploadFilter,
  UploadType,
  User,
  formatDate,
  useGetFilteredPagedUploads,
  useGetUploadType,
} from '@agerpoint/api';
import { Input, PrimaryButton } from '@agerpoint/component';
import {
  AppBar,
  RunCOGPipelineModal,
  RunImagePipelineModal,
  RunLAZPipelineModal,
} from '@agerpoint/feature';
import { AppBarProps, UserClaims } from '@agerpoint/types';
import {
  getAppBarProps,
  hasClaims,
  useGlobalStore,
  usePageTitle,
  useQueryState,
} from '@agerpoint/utilities';

export const UploadsPage = () => {
  const { user } = useGlobalStore();
  const hasClaim = hasClaims(
    [UserClaims.Uploads],
    user?.cloudClaims as UserClaims[]
  );
  return hasClaim ? <Uploads /> : <Navigate to="/" replace={true} />;
};

export function Uploads() {
  const navigate = useNavigate();
  const { user } = useGlobalStore();
  const [uploads, setUploads] = useState<Upload[]>([]);
  usePageTitle(() => 'Uploads', []);
  const [nameFilter, setNameFilter] = useQueryState<string>({
    paramName: 'name',
    initialValue: '',
    fromUrlParam: (a) => a.trim(),
    toUrlParam: (a) => a.trim(),
  });

  const { data: uploadTypes, loading: loadingUploadTypes } = useGetUploadType(
    {}
  );

  const [typeFilter, setTypeFilter] = useQueryState<UploadType | undefined>({
    paramName: 'type',
    initialValue: undefined,
    fromUrlParam: (a) => {
      if (
        !hasClaims([UserClaims.AgerAdmin], user?.cloudClaims as UserClaims[])
      ) {
        return undefined;
      }

      try {
        if (!Number.isInteger(+a)) {
          return undefined;
        }
        const type = uploadTypes?.find((t) => t.id === +a);
        if (type) {
          return type;
        }
      } catch (e) {
        return undefined;
      }
      return undefined;
    },
    toUrlParam: (a) => {
      if (
        !hasClaims([UserClaims.AgerAdmin], user?.cloudClaims as UserClaims[])
      ) {
        return '';
      }

      try {
        if (Number.isNaN(a?.id) || !Number.isInteger(a?.id)) {
          return '';
        }
        return a?.id?.toString() ?? '';
      } catch (e) {
        return '';
      }
    },
    retryInitWhen: uploadTypes !== null,
  });

  const [pagingExhausted, setPagingExhausted] = useState(false);

  const uploadsRequestBody = useRef<{ filter: UploadFilter; skip: number }>({
    filter: {
      filterName: nameFilter || undefined,
      uploadTypeId: typeFilter?.id,
      orderAscending: false,
      orderBy: 'createDatetime',
    },
    skip: 0,
  });

  const {
    mutate: getUploads,
    cancel: cancelPreviousGetUploadsCall,
    loading: loadingUploads,
  } = useGetFilteredPagedUploads({ skip: NaN, take: NaN });

  const [openedRunPipelineModalTypeId, setOpenedRunPipelineModalTypeId] =
    useState<number>();
  const [runPipelineModalUpload, setRunPipelineModalUpload] =
    useState<Upload>();

  const getUploadsPage = useCallback(async () => {
    cancelPreviousGetUploadsCall();
    try {
      const response = (await getUploads(uploadsRequestBody.current.filter, {
        pathParams: { skip: uploadsRequestBody.current.skip, take: 30 },
      })) as unknown as Upload[];

      if (!response) {
        return;
      }

      if (uploadsRequestBody.current.skip === 0) {
        setUploads([...response]);
      } else {
        setUploads((prev) => [...prev, ...response]);
      }

      if (response.length > 0) {
        setPagingExhausted(false);
      } else {
        setPagingExhausted(true);
      }
    } catch (e) {
      //
    }
  }, [cancelPreviousGetUploadsCall, getUploads]);

  useDebouncyEffect(
    () => {
      uploadsRequestBody.current = {
        filter: {
          filterName: nameFilter?.trim() || undefined,
          orderAscending: false,
          uploadTypeId: typeFilter?.id,
          orderBy: 'createDatetime',
        },
        skip: 0,
      };

      getUploadsPage();
    },
    500,
    [nameFilter]
  );

  useEffect(() => {
    uploadsRequestBody.current = {
      filter: {
        filterName: nameFilter || undefined,
        uploadTypeId: typeFilter?.id,
        orderAscending: false,
        orderBy: 'createDatetime',
      },
      skip: 0,
    };

    getUploadsPage();
  }, [typeFilter]);

  const appBarProps: AppBarProps = getAppBarProps(user || ({} as User));

  return (
    <>
      <AppBar {...appBarProps} />
      <div className="flex flex-col flex-1 overflow-hidden relative px-4 pt-2 h-full">
        <header>
          <div className="flex max-w-7xl mx-auto items-start justify-between">
            <h1 className="text-3xl font-bold pb-2">Uploads</h1>
            <PrimaryButton
              label="New Upload"
              icon={<FontAwesomeIcon icon={faPlus} />}
              onClicked={() => {
                navigate('/uploads/new');
              }}
            />
          </div>
        </header>
        <div className="flex max-w-7xl mx-auto justify-start flex-wrap w-full z-20">
          <div className="pr-2 flex w-2/3 sm:w-1/3">
            <div className="w-full">
              <Input.Text.Single
                id="upload-name-search"
                value={nameFilter}
                setValue={setNameFilter}
                placeholder="Search by Name"
                placeholderIcon={Input.placeholderIcons.search}
              />
            </div>
          </div>
          {hasClaims(
            [UserClaims.AgerAdmin],
            user?.cloudClaims as UserClaims[]
          ) && (
            <Input.Select.Single
              id="upload-type-filter"
              placeholder="Type"
              options={uploadTypes ?? []}
              optionBuilder={(o) => o?.name ?? ''}
              loading={loadingUploadTypes}
              value={typeFilter}
              setValue={setTypeFilter}
              search={false}
              title="Type"
            />
          )}
        </div>
        <main className="max-h-full min-h-0 max-w-7xl mx-auto flex h-full w-full">
          <div className="py-2 w-full h-full">
            <DatatableOld
              data={uploads}
              rowHeight={40}
              style={{ ...dataTableAgerStyle, tableMinWidth: 900 }}
              pagination={{
                hasNextPage: !pagingExhausted,
                loadPage: () => {
                  if (loadingUploads || pagingExhausted) {
                    return;
                  }
                  uploadsRequestBody.current = {
                    ...uploadsRequestBody.current,
                    skip: uploads.length,
                  };
                  getUploadsPage();
                },
              }}
              initialSortingOptions={{
                key: 'createDatetime',
                order: 'desc',
              }}
              sortingChanged={(options) => {
                uploadsRequestBody.current = {
                  filter: {
                    ...uploadsRequestBody.current.filter,
                    orderBy: options.key,
                    orderAscending: options.order === 'asc',
                  },
                  skip: 0,
                };
                getUploadsPage();
              }}
              columns={[
                {
                  label: 'Name',
                  value: (row) => row.name,
                  flex: 2,
                  sortKey: 'name',
                },
                {
                  label: 'UUID',
                  value: (row) => row.uuid,
                  flex: 2,
                  sortKey: 'uuid',
                },
                {
                  label: 'Uploaded On',
                  value: (row) =>
                    row?.createDatetime ? formatDate(row.createDatetime) : '',
                  flex: 1,
                  sortKey: 'createDatetime',
                },
                {
                  label: 'Description',
                  value: (row) => row.description,
                  flex: 2,
                  sortKey: 'description',
                },
                {
                  label: 'Type',
                  value: (row) => row.uploadType?.name,
                  flex: 2,
                },
                {
                  label: 'Initiated',
                  value: (row) => {
                    return row?.pipelineJobs?.length || '-';
                  },
                  style: {
                    columnWrapperStyle: 'justify-end',
                  },
                },
                {
                  label: 'Completed',
                  style: {
                    columnWrapperStyle: 'justify-end',
                  },
                  value: (row) => {
                    return (
                      row?.pipelineJobs?.filter((job) => {
                        return !!job?.completedDatetime;
                      })?.length || '-'
                    );
                  },
                },
                {
                  label: '',
                  style: {
                    columnWrapperStyle: 'justify-end',
                  },
                  value: (row) => {
                    // GeoJSON type
                    if (row.uploadTypeId === 5) {
                      return null;
                    }

                    // Geometry Collection type
                    if (row.uploadTypeId === 6) {
                      return null;
                    }

                    return (
                      <PrimaryButton
                        label="Run Pipeline"
                        onClicked={() => {
                          setRunPipelineModalUpload(row);
                          setOpenedRunPipelineModalTypeId(row.uploadTypeId);
                        }}
                        icon={<FontAwesomeIcon icon={faTimelineArrow} />}
                        size="small"
                        theme="solidDarkBlue"
                      />
                    );
                  },
                  flex: 1.5,
                  visible: hasClaims(
                    [UserClaims.AgerAdmin],
                    (user?.cloudClaims || []) as UserClaims[]
                  ),
                },
              ]}
            />
          </div>
        </main>
      </div>
      <RunImagePipelineModal
        key={runPipelineModalUpload?.id + 'Image'}
        open={openedRunPipelineModalTypeId === 2}
        upload={runPipelineModalUpload}
        handleCloseDialog={() => {
          setOpenedRunPipelineModalTypeId(undefined);
        }}
      />
      <RunLAZPipelineModal
        key={runPipelineModalUpload?.id + 'LAZ'}
        open={openedRunPipelineModalTypeId === 3}
        upload={runPipelineModalUpload}
        handleCloseDialog={() => {
          setOpenedRunPipelineModalTypeId(undefined);
        }}
      />
      <RunCOGPipelineModal
        key={runPipelineModalUpload?.id + 'COG'}
        open={openedRunPipelineModalTypeId === 4}
        upload={runPipelineModalUpload}
        handleCloseDialog={() => {
          setOpenedRunPipelineModalTypeId(undefined);
        }}
      />
    </>
  );
}

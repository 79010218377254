import { AnimatePresence, motion } from 'framer-motion';
import Feature from 'ol/Feature';
import OlMap from 'ol/Map';
import MapBrowserEvent from 'ol/MapBrowserEvent';
import View, { AnimationOptions, FitOptions } from 'ol/View';
import { shiftKeyOnly } from 'ol/events/condition';
import { Extent, isEmpty } from 'ol/extent';
import { LineString, Polygon } from 'ol/geom';
import Point from 'ol/geom/Point';
import { DragBox, Interaction, defaults } from 'ol/interaction';
import VectorLayer from 'ol/layer/Vector';
import { Pixel } from 'ol/pixel';
import VectorSource from 'ol/source/Vector';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { CloudOpenlayersGeometryStyles } from './geom-styles/geom-styles';
import { useCloudOpenlayersBaseMapLayer } from './layers/base-map-layer';
import { useCloudOpenlayersClusterFeatureLayer } from './layers/cluster-feature-layer';
import { useCloudOpenlayersFeatureLayer } from './layers/feature-layer';
import { useCloudOpenlayerWMSLayer } from './layers/wms-layer';
import { CloudOpenlayersMapOverlays } from './openlayers-map-overlays';
import {
  ICloudOpenlayersMap,
  isClusterFeatureLayer,
  isFeatureLayer,
  isWMSLayer,
} from './openlayers-map.types';
import {
  CloudOpenlayersMapContext,
  defaultInitialView,
  defaultReverseTransform,
  defaultTransform,
  useCloudOpenlayersMapExtentRestoration,
  useCloudOpenlayersMapProjectionRegistration,
} from './openlayers-map.utilities';

function CloudOpenlayersMap({
  id,
  baseMap,
  baseMapOnClick: baseMapOnClickProp,
  disableInteraction,
  initialView = defaultInitialView,
  layers,
  overlays,
  controller: setController,
  persistView = false,
}: ICloudOpenlayersMap) {
  const map = useRef<OlMap>();
  const [mapContainer, setMapContainer] = useState<HTMLDivElement | null>(null);
  const [mapInitialized, setMapInitialized] = useState(false);
  const [layersInitialized, setLayersInitialized] = useState(false);

  const loading = useMemo(
    () => layers?.some((layer) => 'loading' in layer && layer.loading) ?? false,
    [layers]
  );

  // Initialize the map
  useEffect(() => {
    if (!mapContainer) {
      return;
    }

    map.current = new OlMap({
      view: new View(initialView),
      target: mapContainer,
      interactions: [],
      controls: [],
    });
    setMapInitialized(true);

    return () => {
      map.current?.dispose();
      map.current = undefined;
      setMapInitialized(false);
    };
  }, [mapContainer]);

  useCloudOpenlayersBaseMapLayer({ id, baseMap, map, mapInitialized });
  useCloudOpenlayersMapProjectionRegistration();
  useCloudOpenlayersMapExtentRestoration({
    id,
    map,
    mapInitialized,
    persistView,
  });
  const { addFeatureLayer, updateFeatureLayer, removeFeatureLayer } =
    useCloudOpenlayersFeatureLayer({
      map,
      mapInitialized,
      layersInitialized,
    });
  const {
    addClusterFeatureLayer,
    updateClusterFeatureLayer,
    removeClusterFeatureLayer,
  } = useCloudOpenlayersClusterFeatureLayer({
    map,
    mapInitialized,
    layersInitialized,
  });
  const { addWMSLayer, updateWMSLayer, removeWMSLayer } =
    useCloudOpenlayerWMSLayer({
      map,
      mapInitialized,
      layersInitialized,
    });

  useEffect(() => {
    if (layers === undefined || !mapInitialized) {
      return;
    }

    layers.forEach((layer) => {
      if (isFeatureLayer(layer)) {
        addFeatureLayer(layer);
      } else if (isClusterFeatureLayer(layer)) {
        addClusterFeatureLayer(layer);
      } else if (isWMSLayer(layer)) {
        addWMSLayer(layer);
      }
    });
    setLayersInitialized(true);

    return () => {
      setLayersInitialized(false);
      layers.forEach((layer) => {
        if (isFeatureLayer(layer)) {
          removeFeatureLayer(layer);
        } else if (isClusterFeatureLayer(layer)) {
          removeClusterFeatureLayer(layer);
        } else if (isWMSLayer(layer)) {
          removeWMSLayer(layer);
        }
      });
    };
  }, [mapInitialized]);

  useEffect(() => {
    if (!layersInitialized) {
      return;
    }

    layers?.forEach((layer) => {
      if (isFeatureLayer(layer)) {
        updateFeatureLayer(layer);
      } else if (isClusterFeatureLayer(layer)) {
        updateClusterFeatureLayer(layer);
      } else if (isWMSLayer(layer)) {
        updateWMSLayer(layer);
      }
    });
  }, [layersInitialized, layers]);

  // +++++ Event handlers
  const getFeaturesAtPixel = useCallback(
    (pixel: Pixel) => {
      if (!mapInitialized) {
        return [];
      }

      try {
        return map.current?.getFeaturesAtPixel(pixel) ?? [];
      } catch {
        return [];
      }
    },
    [mapInitialized]
  );

  const featureOnClick = useCallback(
    (e: MapBrowserEvent<PointerEvent>) => {
      if (!mapInitialized) {
        return;
      }

      const featuresAtPixel = getFeaturesAtPixel(e.pixel);
      if ((featuresAtPixel?.length ?? 0) === 0) {
        return;
      }

      const topmostFeature = featuresAtPixel[0] as Feature<Point>;
      const featureId = topmostFeature.getId();
      const layerName = topmostFeature.get('layerId');
      if (!featureId) {
        return;
      }

      for (const layer of layers ?? []) {
        if (isFeatureLayer(layer)) {
          if (layer.featureLayerId === layerName) {
            layer.featureOnClick?.(featureId.toString(), e);
          }
        }
      }
    },
    [layers, mapInitialized, getFeaturesAtPixel]
  );

  const clusterOnClick = useCallback(
    (e: MapBrowserEvent<PointerEvent>) => {
      if (!mapInitialized) {
        return;
      }
      const featuresAtPixel = getFeaturesAtPixel(e.pixel);
      if ((featuresAtPixel?.length ?? 0) === 0) {
        return;
      }

      const topmostFeature = featuresAtPixel[0] as Feature<Point>;
      const layerName = topmostFeature.get('layerId');
      const clusterFeatures = topmostFeature.get(
        'features'
      ) as Feature<Point>[];

      if ((clusterFeatures?.length ?? 0) === 0) {
        return;
      }

      const clusterFeatureIds = clusterFeatures.map(
        (f) => f.getId()?.toString() ?? ''
      );

      for (const layer of layers ?? []) {
        if (isClusterFeatureLayer(layer)) {
          if (
            layer.clusterFeatureLayerId === layerName &&
            layer.clusterOnClick !== undefined
          ) {
            layer.clusterOnClick(clusterFeatureIds, e);
          }
        }
      }
    },
    [layers, mapInitialized, getFeaturesAtPixel]
  );

  const baseMapOnClick = useCallback(
    (e: MapBrowserEvent<PointerEvent>) => {
      const m = map.current;
      if (!mapInitialized || !m) {
        return;
      }

      const hasFeature = m.hasFeatureAtPixel(e.pixel);
      if (!hasFeature) {
        baseMapOnClickProp?.(e);
      }
    },
    [mapInitialized, baseMapOnClickProp]
  );

  const pointerCursorOnHover = useCallback(
    (e: MapBrowserEvent<PointerEvent>) => {
      const m = map.current;
      if (!mapInitialized || !m) {
        return;
      }

      const featuresAtPixel = getFeaturesAtPixel(e.pixel);
      if ((featuresAtPixel?.length ?? 0) === 0) {
        m.getTargetElement().style.cursor = '';
        return;
      }

      const topmostFeature = featuresAtPixel[0] as Feature<Point>;
      const layerName = topmostFeature.get('layerId');
      if (!layerName) {
        m.getTargetElement().style.cursor = '';
        return;
      }

      for (const layer of layers ?? []) {
        if (isFeatureLayer(layer)) {
          if (
            layer.featureLayerId === layerName &&
            layer.featureOnClick !== undefined
          ) {
            m.getTargetElement().style.cursor = 'pointer';
          }
        } else if (isClusterFeatureLayer(layer)) {
          if (
            layer.clusterFeatureLayerId === layerName &&
            layer.clusterOnClick !== undefined
          ) {
            m.getTargetElement().style.cursor = 'pointer';
          }
        }
      }
    },
    [mapInitialized, layers, getFeaturesAtPixel]
  );

  useEffect(() => {
    const m = map.current;
    if (!mapInitialized || !m) {
      return;
    }

    m.on('pointermove', pointerCursorOnHover);
    m.on('click', featureOnClick);
    m.on('dblclick', featureOnClick);
    m.on('click', clusterOnClick);
    m.on('click', baseMapOnClick);
    return () => {
      m.un('pointermove', pointerCursorOnHover);
      m.un('click', featureOnClick);
      m.un('click', clusterOnClick);
      m.un('click', baseMapOnClick);
      m.un('dblclick', featureOnClick);
    };
  }, [
    mapInitialized,
    featureOnClick,
    baseMapOnClick,
    pointerCursorOnHover,
    clusterOnClick,
  ]);
  // ----- Event handlers

  // +++++ Controller methods
  const resetView = useCallback(
    (options?: AnimationOptions) => {
      if (!mapInitialized) {
        return;
      }

      map.current?.getView()?.animate({
        duration: 1000,
        ...options,
        ...initialView,
      });
    },
    [mapInitialized, initialView]
  );

  const zoomMapToLonLat = useCallback(
    (options?: AnimationOptions) => {
      if (!mapInitialized) {
        return;
      }

      map.current?.getView()?.animate({
        duration: 1000,
        ...options,
      });
    },
    [mapInitialized]
  );

  const zoomMapToExtent = useCallback(
    (extent: Extent, options?: FitOptions) => {
      if (isEmpty(extent) || !mapInitialized) {
        return;
      }

      map.current?.getView()?.fit(extent, {
        padding: [100, 100, 100, 100],
        duration: 1000,
        ...options,
      });
    },
    [mapInitialized]
  );

  useEffect(() => {
    setController?.({
      info: {
        mapInitialized,
      },
      zoomMapToLonLat,
      zoomMapToExtent,
      resetView,
    });
  }, [
    resetView,
    zoomMapToExtent,
    zoomMapToLonLat,
    setController,
    mapInitialized,
  ]);
  // ----- Controller methods

  // +++++ Interactions and Controls setup
  useEffect(() => {
    const m = map.current;
    if (!m) {
      return;
    }

    let interations: Interaction[] = [];

    if (!disableInteraction) {
      interations = defaults({
        shiftDragZoom: false,
        pinchRotate: false,
        altShiftDragRotate: false,
      }).getArray();
    }

    m.getInteractions().extend(interations);

    return () => {
      m.getInteractions().clear();
    };
  }, [mapInitialized, disableInteraction]);

  useEffect(() => {
    if (!mapInitialized || disableInteraction) {
      return;
    }

    // Check if some layer has a drag box
    const needDragBox = layers?.some((layer) => {
      if (isFeatureLayer(layer)) {
        return layer.featureOnDragBox !== undefined;
      }
      return false;
    });

    if (!needDragBox) {
      return;
    }

    const m = map.current;
    if (!m) {
      return;
    }

    const dragBoxInteration = new DragBox({
      condition: shiftKeyOnly,
      onBoxEnd: (e) => {
        const dragBox = map.current
          ?.getInteractions()
          .getArray()
          .find((i) => i instanceof DragBox) as DragBox;

        const dragBoxExtent = dragBox?.getGeometry().getExtent();

        if (!dragBoxExtent) {
          return;
        }

        for (const layer of layers ?? []) {
          if (isFeatureLayer(layer) && layer.featureOnDragBox !== undefined) {
            const olLayer = map.current
              ?.getLayers()
              ?.getArray()
              .find(
                (l) => l.getProperties().id === layer.featureLayerId
              ) as VectorLayer<VectorSource>;
            const olFeatures = olLayer
              ?.getSource()
              ?.getFeaturesInExtent?.(dragBoxExtent);

            if ((olFeatures?.length ?? 0) === 0) {
              continue;
            }

            const featureIds =
              olFeatures?.map((f) => f.getId()?.toString() ?? '') ?? [];

            layer.featureOnDragBox?.(featureIds, e);
          }
        }
      },
    });

    m.addInteraction(dragBoxInteration);

    return () => {
      m.removeInteraction(dragBoxInteration);
    };
  }, [mapInitialized, disableInteraction, layers]);
  // ----- Interactions and Controls setup

  return (
    <div
      className="size-full relative bg-gray-background"
      id={id}
      ref={setMapContainer}
    >
      <AnimatePresence>
        {loading && (
          <motion.div
            className="absolute w-full top-0 h-1 bg-gray-background z-10"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <div className="size-full shimmer-fast" />
          </motion.div>
        )}
      </AnimatePresence>
      <CloudOpenlayersMapContext.Provider value={{ map, mapInitialized }}>
        {overlays ?? null}
      </CloudOpenlayersMapContext.Provider>
    </div>
  );
}

CloudOpenlayersMap.GeomStyles = CloudOpenlayersGeometryStyles;
CloudOpenlayersMap.Overlays = CloudOpenlayersMapOverlays;
CloudOpenlayersMap.Defaults = {
  defaultTransform,
  defaultReverseTransform,
  defaultInitialView,
};
CloudOpenlayersMap.Geometries = {
  Point: Point,
  Line: LineString,
  Polygon: Polygon,
};

export { CloudOpenlayersMap };

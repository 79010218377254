import {
  faBroccoli,
  faCamera,
  faChartColumn,
  faCloud,
  faCloudArrowUp,
  faCube,
  faGrid2,
  faLink,
  faMap,
  faUsers,
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLocation, useNavigate } from 'react-router-dom';

import {
  ToggleableSidebarGroup,
  ToggleableSidebarGroupItem,
  ToggleableSidebarItem,
} from '@agerpoint/component';
import { LdFlags, UserClaims } from '@agerpoint/types';
import { hasClaims, hasPermission, useGlobalStore } from '@agerpoint/utilities';

export const AppSidebarStandardItems = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { permissions, user } = useGlobalStore();
  const userClaims = user?.cloudClaims as UserClaims[];

  return (
    <>
      {/* captures page */}
      <ToggleableSidebarItem
        name={'Captures'}
        route={'/captures'}
        icon={faCamera}
      />
      {/* organization page */}
      {hasPermission(LdFlags.OrganizationManagement, permissions) && (
        <ToggleableSidebarItem
          name={'Organization'}
          route={'/organization/details'}
          icon={faUsers}
        />
      )}
      {/* projects page */}
      <ToggleableSidebarItem
        name={'Projects'}
        route={'/dashboard'}
        icon={faMap}
      />
      {/* reports page */}
      {hasPermission(LdFlags.ReportingPage, permissions) && (
        <ToggleableSidebarItem
          name={'Reports'}
          route={'/reports'}
          icon={faChartColumn}
        />
      )}
      {/* uploads page */}
      {hasClaims([UserClaims.Uploads], userClaims) && (
        <ToggleableSidebarItem
          name={'Uploads'}
          route={'/uploads'}
          icon={faCloudArrowUp}
        />
      )}
      <div className="w-full h-0.5 bg-gray-300 my-1 rounded" />
      {/* ops page */}
      {hasClaims([UserClaims.QAQC], userClaims) && (
        <ToggleableSidebarGroup
          name={
            <span className="flex items-center">
              Operations&nbsp;
              <FontAwesomeIcon
                className="w-4 h-4 cursor-pointer"
                icon={faGrid2}
                onClick={(e) => {
                  e.stopPropagation();
                  navigate('/ops');
                }}
              />
            </span>
          }
          initiallyExpanded={location.pathname.includes('/ops')}
          route={'/ops'}
        >
          <ToggleableSidebarGroupItem name={'QAQC'} route={'/ops/qaqc'} />
          <ToggleableSidebarGroup
            name={
              <span className="flex items-center">
                Pipelines&nbsp;
                <FontAwesomeIcon
                  className="w-4 h-4 cursor-pointer"
                  icon={faGrid2}
                  onClick={(e) => {
                    e.stopPropagation();
                    navigate('/ops/pipeline');
                  }}
                />
              </span>
            }
            route={'/ops/pipeline'}
            initiallyExpanded={location.pathname.includes('/pipeline')}
            cascadeLevel={1}
          >
            <ToggleableSidebarGroupItem
              name={'Captures'}
              route={'/ops/pipeline/captures'}
              cascadeLevel={2}
            />
            <ToggleableSidebarGroupItem
              name={'Uploads'}
              route={'/ops/pipeline/uploads'}
              cascadeLevel={2}
            />
            {hasClaims([UserClaims.AgerAdmin], userClaims) ? (
              <ToggleableSidebarGroupItem
                name={'All Jobs'}
                route={'/ops/pipeline/pipeline-jobs'}
                cascadeLevel={2}
              />
            ) : (
              <></>
            )}
          </ToggleableSidebarGroup>
          {hasClaims([UserClaims.AgerAdmin], userClaims) ? (
            <ToggleableSidebarGroupItem
              name={'Analytic Requests'}
              route={'/ops/analytics'}
            />
          ) : (
            <></>
          )}
        </ToggleableSidebarGroup>
      )}
      {/* admin page - platform */}
      {hasClaims(
        [UserClaims.AgerAdmin],
        (user?.cloudClaims as UserClaims[]) || ([] as UserClaims[])
      ) && (
        <ToggleableSidebarGroup
          name={
            <span className="flex items-center">
              Platform&nbsp;
              <FontAwesomeIcon
                className="w-4 h-4 cursor-pointer"
                icon={faGrid2}
                onClick={(e) => {
                  e.stopPropagation();
                  navigate('/admin');
                }}
              />
            </span>
          }
          initiallyExpanded={location.pathname.includes('/admin')}
          route="/admin"
        >
          <ToggleableSidebarGroupItem
            name={'Analytics'}
            route={'/admin/analytics'}
          />
          <ToggleableSidebarGroupItem name={'Blocks'} route={'/admin/blocks'} />
          <ToggleableSidebarGroupItem
            name={'Captures'}
            route={'/admin/captures'}
          />
          <ToggleableSidebarGroupItem name={'Crops'} route={'/admin/crops'} />
          <ToggleableSidebarGroupItem name={'Farms'} route={'/admin/farms'} />
          <ToggleableSidebarGroupItem
            name={'Geometries'}
            route={'/admin/geometries'}
          />
          <ToggleableSidebarGroupItem
            name={'Job Types'}
            route={'/admin/job-types'}
          />
          <ToggleableSidebarGroupItem
            name={'ML Models'}
            route={'/admin/ml-models'}
          />
          <ToggleableSidebarGroupItem
            name={'Mosaic Engines'}
            route={'/admin/mosaic-engines'}
          />
          <ToggleableSidebarGroupItem
            name={'Organizations'}
            route={'/admin/organizations'}
          />
          <ToggleableSidebarGroupItem
            name={'Orthomosaics'}
            route={'/admin/orthomosaics'}
          />
          <ToggleableSidebarGroupItem
            name={'Pipelines'}
            route={'/admin/pipelines'}
          />
          <ToggleableSidebarGroupItem
            name={'Pointclouds'}
            route={'/admin/pointclouds'}
          />
          <ToggleableSidebarGroupItem
            name={'Projects'}
            route={'/admin/projects'}
          />
          <ToggleableSidebarGroupItem
            name={'Uploads'}
            route={'/admin/uploads'}
          />
          {/* <ToggleableSidebarGroupItem name={'Users'} route={'/admin/users'} /> */}
        </ToggleableSidebarGroup>
      )}
      {hasClaims(
        [UserClaims.AgerAdmin],
        (user?.cloudClaims as UserClaims[]) || ([] as UserClaims[])
      ) && (
        <ToggleableSidebarItem
          name={'3D Viewer'}
          route={'/viewer'}
          icon={faCube}
        />
      )}
    </>
  );
};

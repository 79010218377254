import { Fill, Stroke, Style } from 'ol/style';
import CircleStyle from 'ol/style/Circle';

import { APIClient, CaptureObject, Customer } from '@agerpoint/api';

import { AppBarProps } from './app-bar';
import { SkipAndSortArgs } from './component';
import { IEnvironment } from './environment';

export interface CapturePagesProps {
  captures: APIClient.Capture[];
  customers: Customer[];
  appBarProps: AppBarProps;
  environment: IEnvironment;
  // projectRefetch: () => void;
}

export interface CapturesUrlParams {
  id?: string | undefined;
  eptId?: string | undefined;
}

export type CapturesDatatableHead =
  | ''
  | 'Name'
  | 'Capture Date'
  | 'Update Date'
  | 'Organization'
  | 'Project(s)'
  | 'Owner'
  | 'Status'
  | 'Size (MB)'
  | 'No. of Images'
  | 'Tags'
  | 'Notes'
  | 'Actions'
  | 'Select'
  | 'Resolution'
  | JSX.Element;

export interface CaptureRow extends APIClient.Capture {
  smallThumbnail?: JSX.Element;
  bigThumbnail?: JSX.Element;
  selected?: boolean;
  customerName?: string | undefined | null;
  selectCapture?: JSX.Element;
  resolution?: string;
}

export interface CaptureRowLookup {
  [key: string]: CaptureRow;
}

export enum CaptureJobTypes {
  'Unknown',
  'Low Resolution',
  'High Resolution',
}

export enum CaptureJobTypeAbbreviations {
  '-',
  'LD',
  'HD',
}

export interface GetPagedCapturesResponse {
  data: APIClient.Capture[] | null;
  loading: boolean;
  error: any;
  refetch: () => void;
}

export interface PostPagedCapturesResponse {
  mutate: (data: any) => APIClient.Capture[] | null;
  loading: boolean;
  error: any;
}

export interface CaptureObjectMarkers extends CaptureObject {
  id: number;
  showMarker: boolean;
  showLabel: boolean;
  markerFill: string;
  highlighted: boolean;
  editable: boolean;
  type: CaptureObjectMarkerType;
}

export interface CaptureImageThumbnailUrlParams {
  size: number;
}

export enum CaptureObjectMarkerType {
  'Custom',
  'ExtractionJob',
}

export enum CaptureJobMosaicEngines {
  'Unknown',
  'P1' = 1,
  'g1' = 'g1',
  'g2' = 'g2',
  'n1' = 'n1',
}

export enum CaptureJobMosaicEnginesIds {
  'g2' = 12,
}

export enum CaptureExpansionPanels {
  Unknown,
  'CaptureJobInfo',
  'CaptureColorSelector',
}
// order matters because it must match the ASPRS LAS 1.4 specification
export enum CaptureMaterialClassifications {
  'NeverClassified',
  'Unclassified',
  'Ground',
  'LowVegetation',
  'MediumVegetation',
  'HighVegetation',
  'Building',
  'LowPoint',
  'ModelKeyPoint',
  'Water',
  'Rail',
  'RoadSurface',
  'Reserved',
  'WireGuard',
  'WireGuide',
  'TransmissionTower',
  'WireStructure',
  'BridgeDeck',
  'HighNoise',
}

// order matters because it must match the ASPRS LAS 1.4 specification
export enum CaptureMaterialClassificationsLabels {
  // 0
  'NeverClassified' = 'Other',
  // 1
  'Unclassified' = 'Unclassified',
  // 2
  'Ground' = 'Ground',
  // 3
  'LowVegetation' = 'Low Vegetation',
  // 4
  'MediumVegetation' = 'Leaves',
  // 5
  'HighVegetation' = 'Trunk',
  // 6
  'Building' = 'Fruit',
  // 7
  'LowPoint' = 'Trellis',
  // 8
  'ModelKeyPoint' = 'Model Key Point',
  // 9
  'Water' = 'Water',
  // 10
  'Rail' = 'Rail',
  // 11
  'RoadSurface' = 'Immature Pod',
  // 12
  'Reserved' = 'Defective Pod',
  // 13
  'WireGuard' = 'Stage 3',
  // 14
  'WireGuide' = 'Stage 4',
  // 15
  'TransmissionTower' = 'Stage 5-6',
  // 16
  'WireStructure' = 'Wire - Structure',
  // 17
  'BridgeDeck' = 'Bridge Deck',
  // 18
  'HighNoise' = 'High Noise',
}

export const CaptureMaterialColorsLookup: {
  [key in CaptureMaterialClassifications]: number[];
} = {
  [CaptureMaterialClassifications.NeverClassified]: [0.58, 0.57, 0.28, 1.0],
  [CaptureMaterialClassifications.Unclassified]: [0.2, 0.2, 0.2, 1.0],
  [CaptureMaterialClassifications.Ground]: [0.5, 0.5, 0.5, 1.0],
  [CaptureMaterialClassifications.LowVegetation]: [0.0, 1.0, 0.0, 1.0],
  [CaptureMaterialClassifications.MediumVegetation]: [0.0, 0.8, 0.0, 1.0],
  [CaptureMaterialClassifications.HighVegetation]: [0.63, 0.32, 0.18, 1.0],
  [CaptureMaterialClassifications.Building]: [1.0, 0.0, 1.0, 1.0],
  [CaptureMaterialClassifications.LowPoint]: [0.2, 0.2, 0.2, 1.0],
  [CaptureMaterialClassifications.ModelKeyPoint]: [0.2, 0.2, 0.2, 1.0],
  [CaptureMaterialClassifications.Water]: [0.0, 0.0, 1.0, 1.0],
  [CaptureMaterialClassifications.Rail]: [0.8, 0.8, 0.8, 1.0],
  // Immature pod
  [CaptureMaterialClassifications.RoadSurface]: [0.96, 0.96, 0.86, 1],
  // Defective pod
  [CaptureMaterialClassifications.Reserved]: [0.59, 0.29, 0.0, 1],
  // Stage 3
  [CaptureMaterialClassifications.WireGuard]: [1.0, 0.84, 0.5, 1],
  // Stage 4
  [CaptureMaterialClassifications.WireGuide]: [1.0, 0.0, 0.0, 1],
  // Stage 5-6
  [CaptureMaterialClassifications.TransmissionTower]: [1.0, 1.0, 0.0, 1],

  [CaptureMaterialClassifications.WireStructure]: [0.0, 0.0, 0.0, 1.0],
  [CaptureMaterialClassifications.BridgeDeck]: [0.0, 0.0, 0.0, 1.0],
  [CaptureMaterialClassifications.HighNoise]: [0.0, 0.0, 0.0, 1.0],
};

export interface CaptureRequestFilterBody {
  captureName: string | null;
  description: string | null;
  customerId: (string | number)[] | null;
  projectUuids: (string | number)[] | null;
  userUuids: (string | number)[] | null;
  startScanDatetime: null;
  endScanDatetime: null;
  orderBy: SkipAndSortArgs['attribute'];
  orderAscending: boolean;
}

export interface CaptureImage {
  id: number;
  imagePath: string;
  isFavorite?: boolean;
  note?: string;
  localIndex?: number;
}

export enum CaptureModes {
  Unknown,
  arkit_LiDAR,
  arkit_LiDAR_highRes,
  arkit,
  arkit_highRes,
  images,
  video,
  ar_video,
}

export type CaptureModesNiceNames = {
  [key in CaptureModes]: string;
};

export const captureModesNiceNames: CaptureModesNiceNames = {
  [CaptureModes.Unknown]: 'Unknown',
  [CaptureModes.arkit_LiDAR]: 'LiDAR 2.7',
  [CaptureModes.arkit_LiDAR_highRes]: 'LiDAR 8.3 (Beta)',
  [CaptureModes.arkit]: 'AR 2.7 (Beta)',
  [CaptureModes.arkit_highRes]: 'AR 8.3 (Beta)',
  [CaptureModes.images]: '8.3 (Beta)',
  [CaptureModes.video]: 'Video',
  [CaptureModes.ar_video]: 'Emergence',
};

export function getCaptureModeNiceName(
  id?: CaptureModes | null | undefined
): string {
  if (!id) return 'Unknown';
  const niceName = captureModesNiceNames[id];
  return niceName || 'Unknown';
}

export enum CaptureModalTabs {
  analytics = 'analytics',
  details = 'details',
  models = 'models',
  projects = 'projects',
}

export interface CaptureModalNavigationProps {
  analytics: string;
  details: string;
  models: string;
  projects: string;
}

export const defaultCaptureMapFeatureStyle = new Style({
  image: new CircleStyle({
    radius: 7,
    fill: new Fill({
      color: '#3399CC',
    }),
    stroke: new Stroke({
      color: '#000',
      width: 1,
    }),
  }),
  zIndex: 200,
});

export const selectedCaptureMapFeatureStyle = new Style({
  image: new CircleStyle({
    radius: 7,
    fill: new Fill({
      color: '#176754',
    }),
    stroke: new Stroke({
      color: '#AAA',
      width: 2,
    }),
  }),
  zIndex: 201,
});

export const isCaptureReadyToViewInLegacyLayout = (
  capture: APIClient.Capture | undefined
): boolean => getFirstActiveEptId(capture) !== undefined;

export const isCaptureReadyToViewInNewLayout = (
  capture: APIClient.Capture | undefined
): boolean =>
  capture?.completedJobs?.find((job) => !job.archived) !== undefined;

export const getFirstActiveEptId = (
  capture: APIClient.Capture | undefined
): number | undefined =>
  capture?.completedJobs?.find((job) => !job.archived)?.eptPointcloudId ??
  undefined;

export enum CaptureState {
  Processing,
  NoJobs,
  Ready,
}

export const getCaptureState = (capture: APIClient.Capture): CaptureState => {
  if (!capture?.scanDatetime) return CaptureState.Processing;
  const scanDate = Date.parse(capture?.scanDatetime);
  const now: number = new Date().getTime();
  const hours = Math.abs(now - scanDate) / 36e5;
  if (hours <= 24) {
    return CaptureState.Processing;
  }

  if (capture?.completedJobs?.find((job) => !job.archived) === undefined) {
    return CaptureState.NoJobs;
  }

  return CaptureState.Ready;
};

import { useEffect } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import { ToastsComponent, UndosComponent } from '@agerpoint/feature';
import { EffectNames, EventBusNames, UserClaims } from '@agerpoint/types';
import {
  environment,
  eventBus,
  hasClaims,
  useGlobalStore,
} from '@agerpoint/utilities';

import { AdminPageRoutes } from '../admin-page';
import { CapturesRoutes } from '../captures-page/captures-page';
import { NewUpload } from '../new-upload-page';
import { Ops } from '../ops-page';
import { Organization } from '../organization-page';
import { ProjectPage } from '../project-page';
import { Projects } from '../projects-page/projects';
import { Reports } from '../reports-page';
import { Settings } from '../settings/settings-page';
import { StandAloneViewerMainPage } from '../stand-alone-viewer-main-page/stand-alone-viewer-main-page';
import { UploadsPage } from '../uploads';

export const AgercloudMainPage = () => {
  const {
    user,
    actions: { dispatchEffect },
  } = useGlobalStore();

  const hasCapturesClaim = hasClaims(
    [UserClaims.Cloud],
    user?.cloudClaims as UserClaims[]
  );

  const handlePointCloudLoaded = () => {
    dispatchEffect(EffectNames.POTREE_POINT_CLOUD_LOADED, {});
  };

  useEffect(() => {
    const eventId = eventBus.on(
      EventBusNames.PointCloudLoaded,
      handlePointCloudLoaded,
      true
    );
    return () => {
      eventBus.remove(
        EventBusNames.PointCloudLoaded,
        handlePointCloudLoaded,
        eventId
      );
    };
  }, []);

  const defaultUserRoute = hasCapturesClaim ? '/captures' : '/projects';
  return (
    <>
      <ToastsComponent />
      <UndosComponent />
      <div className="h-screen w-screen flex flex-col overflow-hidden">
        <Routes>
          <Route path="/captures/*" element={<CapturesRoutes />} />
          <Route path="/project/:id" element={<ProjectPage />} />
          <Route path="/projects" element={<Projects />} />
          <Route path="/uploads" element={<UploadsPage />} />
          <Route
            path="/uploads/new"
            element={<NewUpload bingKey={environment.bing_api_key} />}
          />
          <Route path="/profile" element={<Settings />} />
          <Route path="/admin/*" element={<AdminPageRoutes />} />
          <Route path="/ops/*" element={<Ops />} />
          <Route path="/reports/*" element={<Reports />} />
          <Route path="/organization/*" element={<Organization />} />
          <Route path="/viewer" element={<StandAloneViewerMainPage />} />
          <Route path="/dashboard" element={<Navigate to="/projects" />} />
          <Route path="/" element={<Navigate to={defaultUserRoute} />} />
          {/* <Route path="/*" element={<Navigate to={defaultUserRoute} />} /> */}
          {/* <Route
            path="*"
            element={
              <Intermediate
                message={{
                  title: 'Unknown path',
                  message: `The provided path was not found: ${pathname}`,
                }}
              />
            }
          /> */}
        </Routes>
      </div>
    </>
  );
};

import * as turf from '@turf/turf';
import { useAuth } from 'oidc-react';
import { Feature, ImageTile, MapBrowserEvent, Overlay, getUid } from 'ol';
import { FeatureLike } from 'ol/Feature';
import Geolocation from 'ol/Geolocation';
import OlMap from 'ol/Map';
import TileState from 'ol/TileState';
import { toString } from 'ol/color';
import BaseEvent from 'ol/events/Event';
import { Geometry, Polygon } from 'ol/geom';
import { Layer } from 'ol/layer';
import VectorLayer from 'ol/layer/Vector';
import 'ol/ol.css';
import RenderFeature from 'ol/render/Feature';
import TileWMS from 'ol/source/TileWMS';
import VectorSource from 'ol/source/Vector';
import VectorTileSource from 'ol/source/VectorTile';
import { Stroke, Style } from 'ol/style';
import {
  ComponentType,
  Fragment,
  MutableRefObject,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useCallback } from 'react';

import { Capture, useGetCaptureById } from '@agerpoint/api';
import {
  Group,
  LayerComponentProps,
  WmsRasterMapLayer as WmsRasterMapLayerType,
  WmsVectorMapLayer as WmsVectorMapLayerType,
} from '@agerpoint/types';
import { cvtHexToRgba } from '@agerpoint/utilities';

import { CapturesMapPopup } from '../capture-map-popover';
import { TwoDTools } from '../two-d-tools/two-d-tools';
import {
  BaseMapLayer,
  EnableLocationService,
  useTileLayer,
} from '../utilities';
import { FeatureInfo } from './feature-info/feature-info';
import './map-2d.scss';
import { lineStyleUtil } from './style.utilities';
import { ProjectMapProps } from './types';
import { WmsVectorMapLayer } from './wms-vector-map-layer';

export function Map2d({
  groups,
  initialExtent,
  margins,
  bingKey,
  selectedLayerId,
  setSelectedLayerId,
  selectedFeatureIds,
  setSelectedFeatureIds,
  serverUrl,
  token,
  layerIdUpdateCount,
  selectedOlUid,
  setSelectedOlUid,
}: ProjectMapProps) {
  const mapContainer: MutableRefObject<HTMLDivElement | null> = useRef(null);
  const [map, setMap] = useState<OlMap>();
  const [mapGroups, setMapGroups] = useState<Group[]>([]);
  const [highlightLayer, setHighlightLayer] =
    useState<VectorLayer<VectorSource<Feature<Geometry>>>>();
  const [selectedFeature, setSelectedFeature] = useState<{
    feature: FeatureLike;
    layer: Layer | undefined;
  }>();
  const [mapLayerLookup, setMapLayerLookup] = useState<{
    [key: string]: WmsVectorMapLayerType;
  }>({});
  const [featureInfoOverlay, setFeatureInfoOverlay] = useState<Overlay>();
  const [clearHighlightedFeatures, setClearHighlightedFeatures] =
    useState(true);
  const [popupRef, setPopupRef] = useState<HTMLElement>();
  const [popoverData, setPopoverData] = useState<{
    featureType: string;
    featureOver: FeatureLike;
    wmsLayer: WmsVectorMapLayerType;
    strokeColor: number[];
  }>();
  const [selectedCaptureId, setSelectedCaptureId] = useState<
    number | undefined
  >();

  const { data: captureData, refetch: refetchCapture } = useGetCaptureById({
    id: selectedCaptureId || NaN,
    lazy: true,
  }) as unknown as { data: Capture; refetch: () => void };

  useEffect(() => {
    setCapture(captureData);
  }, [captureData]);

  const [capture, setCapture] = useState<Capture>();

  const getLayer = useCallback(
    (featureOver: FeatureLike) => {
      if (!map) return;

      return map
        .getLayers()
        .getArray()
        .find((layer) => {
          const wmsLayerId = layer.get('wmsLayerId');
          return wmsLayerId && wmsLayerId === featureOver.get('wmsLayerId');
        }) as Layer;
    },
    [map]
  );

  const clickListener = useCallback(
    (event: Event | BaseEvent) => {
      if (!map || !event) return;

      const evt = event as MapBrowserEvent<PointerEvent>;

      const [featureOver, layer]: [FeatureLike, Layer] | [] =
        map.forEachFeatureAtPixel(evt.pixel, (feature, layer) => {
          return [feature, layer];
        }) || [];
      setClearHighlightedFeatures(true);

      if (!featureOver) {
        setSelectedFeatureIds([]);
        setSelectedOlUid('');
        return;
      }

      setClearHighlightedFeatures(false);
      if (featureOver?.get('morphology_id')) {
        setSelectedFeatureIds([featureOver.get('morphology_id')]);
      } else if (featureOver?.get('geometry_id')) {
        setSelectedFeatureIds([featureOver.get('geometry_id')]);
      } else if (featureOver?.get('capture_id')) {
        setSelectedFeatureIds([featureOver.get('capture_id')]);
      }
      const selectedOlUid = getUid(featureOver);
      setSelectedOlUid(selectedOlUid);
      if (layer) {
        setSelectedLayerId(layer.get('wmsLayerId'));
      }

      if (layer?.getClassName() === 'highlight-layer') return;

      if (layer?.getClassName() === 'label-layer') {
        // if the feature is a label, we need to find the layer which it was derived from
        const foundLayer = getLayer(featureOver);
        setSelectedFeature({ feature: featureOver, layer: foundLayer });
        return;
      }

      setSelectedFeature({ feature: featureOver, layer });
    },
    [map]
  );

  useEffect(() => {
    if (clearHighlightedFeatures) {
      const src = highlightLayer?.getSource();
      src?.clear();
      if (featureInfoOverlay) {
        map?.removeOverlay(featureInfoOverlay);
        setSelectedFeature({
          feature: {} as FeatureLike,
          layer: undefined,
        });
      }
    }
  }, [clearHighlightedFeatures]);

  useEffect(() => {
    if (mapContainer.current) {
      const highlightStyle = new Style({
        stroke: new Stroke({
          color: 'rgb(255, 255, 255)',
          width: 5,
        }),
        zIndex: 9999,
      });

      const featureOverlay = new VectorLayer({
        source: new VectorSource({
          features: [],
        }),
        style: highlightStyle,
        className: 'highlight-layer',
        zIndex: 9999,
      });
      setHighlightLayer(featureOverlay);

      const map = new OlMap({
        layers: [featureOverlay],
        target: mapContainer.current,
      });
      setMap(map);
      const userGeolocation = new Geolocation({
        // enableHighAccuracy must be set to true to have the heading value.
        trackingOptions: {
          enableHighAccuracy: true,
        },
        projection: map.getView().getProjection(),
      });
      EnableLocationService(map, userGeolocation);
      return () => {
        map.setTarget(undefined);
        setMap(undefined);
      };
    }

    return;
  }, [mapContainer]);

  const marginsRef = useRef(margins);
  marginsRef.current = margins;

  useEffect(() => {
    if (map && initialExtent) {
      map.getView().fit(initialExtent, {
        maxZoom: 20,
        padding: marginsRef.current.map((margin) => margin + 60),
      });
      map.on(['click'], clickListener);
    }

    return () => {
      if (map) {
        map.un(['click'], clickListener);
      }
    };
  }, [map, initialExtent]);

  const popoverChildren: React.ReactNode = useMemo(() => {
    if (!popoverData) return null;
    const isCapture = popoverData.featureType === 'Captures';
    const captureReady = capture?.id;
    if (!isCapture) {
      return (
        <FeatureInfo
          layerName={popoverData.wmsLayer.name}
          strokeColor={toString(popoverData.strokeColor)}
          feature={popoverData.featureOver}
        />
      );
    }
    return captureReady ? (
      <div className="flex flex-row relative">
        <div className="flex justify-center items-center z-10 relative">
          <div
            className="absolute m-y-auto w-4 h-4 rotate-45 rounded-bl-sm bg-gray-400"
            style={{ left: '-7px' }}
          ></div>
        </div>
        <div className=" bg-white rounded-lg overflow-hidden z-20 border border-gray-400 shadow">
          <CapturesMapPopup capture={capture} />
        </div>
      </div>
    ) : null;
  }, [popoverData, capture]);

  useEffect(() => {
    const captureId = selectedFeature?.feature?.get?.('capture_id');
    if (!captureId) {
      setCapture(undefined);
      setSelectedCaptureId(undefined);
      return;
    }
    setSelectedCaptureId(parseInt(captureId));
  }, [selectedFeature]);

  useEffect(() => {
    if (!selectedCaptureId) return;
    refetchCapture();
  }, [selectedCaptureId]);

  useEffect(() => {
    if (!map) return;

    map.on('pointermove', function (event) {
      if (!map) return;
      if (map.hasFeatureAtPixel(event.pixel)) {
        map.getTargetElement().style.cursor = 'pointer';
      } else {
        map.getTargetElement().style.cursor = ''; // Reset to default when not over a feature
      }
    });
    return () => {
      map?.un('pointermove', () => {
        // eslint-disable-next-line @typescript-eslint/no-empty-function
      });
    };
  }, [map]);

  useEffect(() => {
    setPopoverData(undefined);

    const { feature: featureOver, layer } = selectedFeature || {};
    if (!featureOver || !layer || !map) return;
    const featureId = featureOver.get('morphology_id');
    const geometryId = featureOver.get('geometry_id');

    const layerType = featureOver.get('layer');
    if (layerType === 'Measurements') return;

    const wmsLayer = mapLayerLookup[layer.get('wmsLayerId') as string];
    const style = wmsLayer?.style;
    const tileLyrSrc = layer?.getSource() as VectorTileSource;
    const ext = map.getView().calculateExtent(map.getSize());
    const feat = tileLyrSrc?.getFeaturesInExtent(ext) as RenderFeature[];
    const likeFeatures = feat?.filter((f) => {
      const morphId = f.get('morphology_id');
      const geomId = f.get('geometry_id');

      return (
        (morphId && morphId === featureId) || (geomId && geomId === geometryId)
      );
    });

    const initUnion = turf.polygon([]);

    const u: turf.Feature<turf.Polygon> = likeFeatures.reduce(
      (acc: turf.Feature<turf.Polygon>, cur) => {
        if (cur.getGeometry().getType() !== 'Polygon') return acc;
        const coords = cur.getFlatCoordinates();
        const ends = cur.getEnds().flat();
        if (coords.length < 4) return acc;
        const polygon = new Polygon(coords, 'XY', ends);
        const polygonCoords = polygon?.getCoordinates();
        if (!polygonCoords) return acc;
        const turfPoly = turf.polygon(polygonCoords);
        // truncate to 6 decimal places; fixes calculation errors
        const trunc = turf.truncate(turfPoly, { precision: 6 });
        return turf.union(acc, trunc) as turf.Feature<turf.Polygon>;
      },
      initUnion
    );

    const strokeColor = cvtHexToRgba(
      style?.strokeColor as string,
      style?.strokeOpacity || 1
    );
    const lineStyle = lineStyleUtil({
      strokeColor,
      strokeWidth: (style?.strokeWidth || 1) * 5,
      strokePattern: style?.strokePattern,
      fillColor: cvtHexToRgba('#000000', 0),
    });
    highlightLayer?.setStyle(lineStyle);
    const src = highlightLayer?.getSource();

    if (highlightLayer && src) {
      const newFeature = new Feature({
        geometry: new Polygon(u.geometry.coordinates),
        zIndex: 9999,
      });
      src.clear();
      src.addFeature(newFeature);

      // add feature info overlay
      const featureType = featureOver.get('layer');

      const extent = featureOver?.getGeometry()?.getExtent() || [];
      const overlay = new Overlay({
        element: popupRef,
        autoPan: { animation: { duration: 250 }, margin: 360 },
        position: [extent[2], (extent[1] + extent[3]) / 2],
        positioning: 'center-left',
        offset: [32, 0],
        className: 'feature-info-overlay',
      });

      map.addOverlay(overlay);
      setFeatureInfoOverlay(overlay);
      setPopoverData({
        featureType,
        featureOver,
        wmsLayer,
        strokeColor,
      });
    }
  }, [selectedFeature, map]);

  useEffect(() => {
    const layers = groups.reduce((acc, group) => {
      return {
        ...acc,
        ...group.layers.reduce((lacc, lyr) => {
          return {
            ...lacc,
            [lyr.id]: lyr,
          };
        }, {}),
      };
    }, {});
    setMapGroups(groups);
    setMapLayerLookup(layers);
  }, [groups]);

  // We assume groups and layers are ordered top to bottom, so that the first should
  // render on top of everything else. We decrement zIndex for each drawn layer.
  let zIndex = 0;

  return (
    <>
      <TwoDTools map={map} />

      <div className="absolute inset-0 project-map" ref={mapContainer} />
      <div ref={(ref) => setPopupRef(ref ?? undefined)}>
        {popoverChildren && <div>{popoverChildren}</div>}
      </div>
      {map &&
        mapGroups.map((group) => (
          <Fragment key={group.id}>
            {group.layers
              .filter((layer) => layer.dimensions === 2)
              .map((layer) => {
                const LayerComponent = getLayerComponent(layer.type);
                const isSelectedLayer = layer.id === selectedLayerId;
                return (
                  <LayerComponent
                    key={layer.id}
                    map={map}
                    layer={layer}
                    bingKey={bingKey}
                    zIndex={--zIndex}
                    visible={group.visible && layer.visible}
                    selectedFeatureIds={selectedFeatureIds}
                    setSelectedFeatureIds={setSelectedFeatureIds}
                    setSelectedLayerId={setSelectedLayerId}
                    layerIdUpdateCount={layerIdUpdateCount}
                    isSelectedLayer={isSelectedLayer}
                    showFeatureInfo={true}
                    serverUrl={serverUrl}
                    token={token}
                    selectedOlUid={selectedOlUid}
                    setSelectedOlUid={setSelectedOlUid}
                  />
                );
              })}
          </Fragment>
        ))}
    </>
  );
}

function getLayerComponent(
  layerType: string
): ComponentType<LayerComponentProps> {
  switch (layerType) {
    case 'BaseMap':
      return BaseMapLayer;
    case 'WmsVector':
      return WmsVectorMapLayer;
    case 'WmsRaster':
      return WmsRasterMapLayer;
    default:
      throw new Error(`Unknown layer type "${layerType}".`);
  }
}

function WmsRasterMapLayer({
  map,
  layer,
  zIndex,
  visible,
}: LayerComponentProps) {
  const { userData } = useAuth();
  const wmsLayer = layer as WmsRasterMapLayerType;
  const { wmsUrl, layers, params } = wmsLayer;
  const createSourceFn = useCallback(
    () =>
      new TileWMS({
        url: wmsUrl,
        params: {
          LAYERS: layers,
          viewparams: params,
          FORMAT: 'image/png',
        },
        tileLoadFunction: async function customLoader(tile, src) {
          try {
            const response = await fetch(src, {
              headers: { Authorization: `Bearer ${userData?.access_token}` },
            });
            const imageData = await response.blob();
            const imageElement = (
              tile as ImageTile
            ).getImage() as HTMLImageElement;
            imageElement.src = window.URL.createObjectURL(imageData);
          } catch (e) {
            console.error(e);
            tile.setState(TileState.ERROR);
          }
        },
      }),
    [wmsUrl, layers, params, userData?.access_token]
  );

  useTileLayer(map, zIndex, visible, createSourceFn, wmsLayer.extent);

  return null;
}

/**
 * Generated by orval v6.28.2 🍺
 * Do not edit manually.
 * GeminiAPI
 * OpenAPI spec version: v1
 */
import { useMutation, useQuery } from '@tanstack/react-query';
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import type {
  ApGeometry,
  ApGeometryCollection,
  ApGeometryCollectionDetails,
  ApGeometryCollectionFilter,
  CreateGeojsonToGeometryCollectionBody,
  Download,
  GeometryCollectionCloneRequest,
  Histogram,
  HistogramCategorized,
  HistogramParameters,
  UploadGeojsonToGeometryCollectionBody,
  UploadGeojsonToGeometryCollectionParams,
} from '.././models';
import { AxiosInstance } from '../../axios-instance';
import type { ErrorType, BodyType } from '../../axios-instance';

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

export const getGeometryCollection = (
  id: number,
  options?: SecondParameter<typeof AxiosInstance>,
  signal?: AbortSignal,
) => {
  return AxiosInstance<ApGeometryCollection>(
    {
      url: `/api/GeometryCollections/${encodeURIComponent(String(id))}`,
      method: 'GET',
      signal,
    },
    options,
  );
};

export const getGetGeometryCollectionQueryKey = (id: number) => {
  return [`/api/GeometryCollections/${id}`] as const;
};

export const getGetGeometryCollectionQueryOptions = <
  TData = Awaited<ReturnType<typeof getGeometryCollection>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getGeometryCollection>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetGeometryCollectionQueryKey(id);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getGeometryCollection>>
  > = ({ signal }) => getGeometryCollection(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getGeometryCollection>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetGeometryCollectionQueryResult = NonNullable<
  Awaited<ReturnType<typeof getGeometryCollection>>
>;
export type GetGeometryCollectionQueryError = ErrorType<void>;

export const useGetGeometryCollection = <
  TData = Awaited<ReturnType<typeof getGeometryCollection>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getGeometryCollection>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetGeometryCollectionQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const putGeometryCollection = (
  id: number,
  apGeometryCollection: BodyType<ApGeometryCollection>,
  options?: SecondParameter<typeof AxiosInstance>,
) => {
  return AxiosInstance<ApGeometryCollection>(
    {
      url: `/api/GeometryCollections/${encodeURIComponent(String(id))}`,
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      data: apGeometryCollection,
    },
    options,
  );
};

export const getPutGeometryCollectionMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putGeometryCollection>>,
    TError,
    { id: number; data: BodyType<ApGeometryCollection> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof putGeometryCollection>>,
  TError,
  { id: number; data: BodyType<ApGeometryCollection> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof putGeometryCollection>>,
    { id: number; data: BodyType<ApGeometryCollection> }
  > = (props) => {
    const { id, data } = props ?? {};

    return putGeometryCollection(id, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PutGeometryCollectionMutationResult = NonNullable<
  Awaited<ReturnType<typeof putGeometryCollection>>
>;
export type PutGeometryCollectionMutationBody = BodyType<ApGeometryCollection>;
export type PutGeometryCollectionMutationError = ErrorType<void>;

export const usePutGeometryCollection = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putGeometryCollection>>,
    TError,
    { id: number; data: BodyType<ApGeometryCollection> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof putGeometryCollection>>,
  TError,
  { id: number; data: BodyType<ApGeometryCollection> },
  TContext
> => {
  const mutationOptions = getPutGeometryCollectionMutationOptions(options);

  return useMutation(mutationOptions);
};
export const getDownloadGeometryCollection = (
  id: number,
  options?: SecondParameter<typeof AxiosInstance>,
  signal?: AbortSignal,
) => {
  return AxiosInstance<Download>(
    {
      url: `/api/GeometryCollections/${encodeURIComponent(String(id))}/download`,
      method: 'GET',
      signal,
    },
    options,
  );
};

export const getGetDownloadGeometryCollectionQueryKey = (id: number) => {
  return [`/api/GeometryCollections/${id}/download`] as const;
};

export const getGetDownloadGeometryCollectionQueryOptions = <
  TData = Awaited<ReturnType<typeof getDownloadGeometryCollection>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getDownloadGeometryCollection>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetDownloadGeometryCollectionQueryKey(id);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getDownloadGeometryCollection>>
  > = ({ signal }) => getDownloadGeometryCollection(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getDownloadGeometryCollection>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetDownloadGeometryCollectionQueryResult = NonNullable<
  Awaited<ReturnType<typeof getDownloadGeometryCollection>>
>;
export type GetDownloadGeometryCollectionQueryError = ErrorType<void>;

export const useGetDownloadGeometryCollection = <
  TData = Awaited<ReturnType<typeof getDownloadGeometryCollection>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getDownloadGeometryCollection>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetDownloadGeometryCollectionQueryOptions(
    id,
    options,
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getGeometryCollections = (
  options?: SecondParameter<typeof AxiosInstance>,
  signal?: AbortSignal,
) => {
  return AxiosInstance<ApGeometryCollection[]>(
    { url: `/api/GeometryCollections`, method: 'GET', signal },
    options,
  );
};

export const getGetGeometryCollectionsQueryKey = () => {
  return [`/api/GeometryCollections`] as const;
};

export const getGetGeometryCollectionsQueryOptions = <
  TData = Awaited<ReturnType<typeof getGeometryCollections>>,
  TError = ErrorType<void>,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getGeometryCollections>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetGeometryCollectionsQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getGeometryCollections>>
  > = ({ signal }) => getGeometryCollections(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getGeometryCollections>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetGeometryCollectionsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getGeometryCollections>>
>;
export type GetGeometryCollectionsQueryError = ErrorType<void>;

export const useGetGeometryCollections = <
  TData = Awaited<ReturnType<typeof getGeometryCollections>>,
  TError = ErrorType<void>,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getGeometryCollections>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetGeometryCollectionsQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const postGeometryCollection = (
  apGeometryCollection: BodyType<ApGeometryCollection>,
  options?: SecondParameter<typeof AxiosInstance>,
) => {
  return AxiosInstance<ApGeometryCollection>(
    {
      url: `/api/GeometryCollections`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: apGeometryCollection,
    },
    options,
  );
};

export const getPostGeometryCollectionMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postGeometryCollection>>,
    TError,
    { data: BodyType<ApGeometryCollection> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postGeometryCollection>>,
  TError,
  { data: BodyType<ApGeometryCollection> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postGeometryCollection>>,
    { data: BodyType<ApGeometryCollection> }
  > = (props) => {
    const { data } = props ?? {};

    return postGeometryCollection(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostGeometryCollectionMutationResult = NonNullable<
  Awaited<ReturnType<typeof postGeometryCollection>>
>;
export type PostGeometryCollectionMutationBody = BodyType<ApGeometryCollection>;
export type PostGeometryCollectionMutationError = ErrorType<void>;

export const usePostGeometryCollection = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postGeometryCollection>>,
    TError,
    { data: BodyType<ApGeometryCollection> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof postGeometryCollection>>,
  TError,
  { data: BodyType<ApGeometryCollection> },
  TContext
> => {
  const mutationOptions = getPostGeometryCollectionMutationOptions(options);

  return useMutation(mutationOptions);
};
export const getPagedGeometryCollections = (
  skip: number,
  take: number,
  options?: SecondParameter<typeof AxiosInstance>,
  signal?: AbortSignal,
) => {
  return AxiosInstance<ApGeometryCollection[]>(
    {
      url: `/api/GeometryCollections/skip/${encodeURIComponent(String(skip))}/take/${encodeURIComponent(String(take))}`,
      method: 'GET',
      signal,
    },
    options,
  );
};

export const getGetPagedGeometryCollectionsQueryKey = (
  skip: number,
  take: number,
) => {
  return [`/api/GeometryCollections/skip/${skip}/take/${take}`] as const;
};

export const getGetPagedGeometryCollectionsQueryOptions = <
  TData = Awaited<ReturnType<typeof getPagedGeometryCollections>>,
  TError = ErrorType<void>,
>(
  skip: number,
  take: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getPagedGeometryCollections>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetPagedGeometryCollectionsQueryKey(skip, take);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getPagedGeometryCollections>>
  > = ({ signal }) =>
    getPagedGeometryCollections(skip, take, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!(skip && take),
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getPagedGeometryCollections>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetPagedGeometryCollectionsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getPagedGeometryCollections>>
>;
export type GetPagedGeometryCollectionsQueryError = ErrorType<void>;

export const useGetPagedGeometryCollections = <
  TData = Awaited<ReturnType<typeof getPagedGeometryCollections>>,
  TError = ErrorType<void>,
>(
  skip: number,
  take: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getPagedGeometryCollections>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetPagedGeometryCollectionsQueryOptions(
    skip,
    take,
    options,
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getFilteredPagedGeometryCollections = (
  skip: number,
  take: number,
  apGeometryCollectionFilter: BodyType<ApGeometryCollectionFilter>,
  options?: SecondParameter<typeof AxiosInstance>,
) => {
  return AxiosInstance<ApGeometryCollection[]>(
    {
      url: `/api/GeometryCollections/skip/${encodeURIComponent(String(skip))}/take/${encodeURIComponent(String(take))}`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: apGeometryCollectionFilter,
    },
    options,
  );
};

export const getGetFilteredPagedGeometryCollectionsMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof getFilteredPagedGeometryCollections>>,
    TError,
    { skip: number; take: number; data: BodyType<ApGeometryCollectionFilter> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof getFilteredPagedGeometryCollections>>,
  TError,
  { skip: number; take: number; data: BodyType<ApGeometryCollectionFilter> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof getFilteredPagedGeometryCollections>>,
    { skip: number; take: number; data: BodyType<ApGeometryCollectionFilter> }
  > = (props) => {
    const { skip, take, data } = props ?? {};

    return getFilteredPagedGeometryCollections(
      skip,
      take,
      data,
      requestOptions,
    );
  };

  return { mutationFn, ...mutationOptions };
};

export type GetFilteredPagedGeometryCollectionsMutationResult = NonNullable<
  Awaited<ReturnType<typeof getFilteredPagedGeometryCollections>>
>;
export type GetFilteredPagedGeometryCollectionsMutationBody =
  BodyType<ApGeometryCollectionFilter>;
export type GetFilteredPagedGeometryCollectionsMutationError = ErrorType<void>;

export const useGetFilteredPagedGeometryCollections = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof getFilteredPagedGeometryCollections>>,
    TError,
    { skip: number; take: number; data: BodyType<ApGeometryCollectionFilter> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof getFilteredPagedGeometryCollections>>,
  TError,
  { skip: number; take: number; data: BodyType<ApGeometryCollectionFilter> },
  TContext
> => {
  const mutationOptions =
    getGetFilteredPagedGeometryCollectionsMutationOptions(options);

  return useMutation(mutationOptions);
};
export const getGeometriesPaginatedByGeometryCollectionId = (
  geometryCollectionId: number,
  pageNumber: number,
  pageSize: number,
  options?: SecondParameter<typeof AxiosInstance>,
  signal?: AbortSignal,
) => {
  return AxiosInstance<ApGeometry[]>(
    {
      url: `/api/GeometryCollections/${encodeURIComponent(String(geometryCollectionId))}/Geometries/${encodeURIComponent(String(pageNumber))}/${encodeURIComponent(String(pageSize))}`,
      method: 'GET',
      signal,
    },
    options,
  );
};

export const getGetGeometriesPaginatedByGeometryCollectionIdQueryKey = (
  geometryCollectionId: number,
  pageNumber: number,
  pageSize: number,
) => {
  return [
    `/api/GeometryCollections/${geometryCollectionId}/Geometries/${pageNumber}/${pageSize}`,
  ] as const;
};

export const getGetGeometriesPaginatedByGeometryCollectionIdQueryOptions = <
  TData = Awaited<
    ReturnType<typeof getGeometriesPaginatedByGeometryCollectionId>
  >,
  TError = ErrorType<void>,
>(
  geometryCollectionId: number,
  pageNumber: number,
  pageSize: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof getGeometriesPaginatedByGeometryCollectionId>
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetGeometriesPaginatedByGeometryCollectionIdQueryKey(
      geometryCollectionId,
      pageNumber,
      pageSize,
    );

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getGeometriesPaginatedByGeometryCollectionId>>
  > = ({ signal }) =>
    getGeometriesPaginatedByGeometryCollectionId(
      geometryCollectionId,
      pageNumber,
      pageSize,
      requestOptions,
      signal,
    );

  return {
    queryKey,
    queryFn,
    enabled: !!(geometryCollectionId && pageNumber && pageSize),
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getGeometriesPaginatedByGeometryCollectionId>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetGeometriesPaginatedByGeometryCollectionIdQueryResult =
  NonNullable<
    Awaited<ReturnType<typeof getGeometriesPaginatedByGeometryCollectionId>>
  >;
export type GetGeometriesPaginatedByGeometryCollectionIdQueryError =
  ErrorType<void>;

export const useGetGeometriesPaginatedByGeometryCollectionId = <
  TData = Awaited<
    ReturnType<typeof getGeometriesPaginatedByGeometryCollectionId>
  >,
  TError = ErrorType<void>,
>(
  geometryCollectionId: number,
  pageNumber: number,
  pageSize: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof getGeometriesPaginatedByGeometryCollectionId>
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getGetGeometriesPaginatedByGeometryCollectionIdQueryOptions(
      geometryCollectionId,
      pageNumber,
      pageSize,
      options,
    );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getGeometryCollectionDetails = (
  geometryCollectionId: number,
  options?: SecondParameter<typeof AxiosInstance>,
  signal?: AbortSignal,
) => {
  return AxiosInstance<ApGeometryCollectionDetails>(
    {
      url: `/api/GeometryCollections/${encodeURIComponent(String(geometryCollectionId))}/Details`,
      method: 'GET',
      signal,
    },
    options,
  );
};

export const getGetGeometryCollectionDetailsQueryKey = (
  geometryCollectionId: number,
) => {
  return [`/api/GeometryCollections/${geometryCollectionId}/Details`] as const;
};

export const getGetGeometryCollectionDetailsQueryOptions = <
  TData = Awaited<ReturnType<typeof getGeometryCollectionDetails>>,
  TError = ErrorType<void>,
>(
  geometryCollectionId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getGeometryCollectionDetails>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetGeometryCollectionDetailsQueryKey(geometryCollectionId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getGeometryCollectionDetails>>
  > = ({ signal }) =>
    getGeometryCollectionDetails(geometryCollectionId, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!geometryCollectionId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getGeometryCollectionDetails>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetGeometryCollectionDetailsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getGeometryCollectionDetails>>
>;
export type GetGeometryCollectionDetailsQueryError = ErrorType<void>;

export const useGetGeometryCollectionDetails = <
  TData = Awaited<ReturnType<typeof getGeometryCollectionDetails>>,
  TError = ErrorType<void>,
>(
  geometryCollectionId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getGeometryCollectionDetails>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetGeometryCollectionDetailsQueryOptions(
    geometryCollectionId,
    options,
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getGeometryHistogramByGeometryCollectionId = (
  geometryCollectionId: number,
  attributeName: string,
  binCount: number,
  classificationMethod: string,
  options?: SecondParameter<typeof AxiosInstance>,
  signal?: AbortSignal,
) => {
  return AxiosInstance<Histogram>(
    {
      url: `/api/GeometryCollections/${encodeURIComponent(String(geometryCollectionId))}/Geometries/Histogram/${encodeURIComponent(String(attributeName))}/${encodeURIComponent(String(binCount))}/${encodeURIComponent(String(classificationMethod))}`,
      method: 'GET',
      signal,
    },
    options,
  );
};

export const getGetGeometryHistogramByGeometryCollectionIdQueryKey = (
  geometryCollectionId: number,
  attributeName: string,
  binCount: number,
  classificationMethod: string,
) => {
  return [
    `/api/GeometryCollections/${geometryCollectionId}/Geometries/Histogram/${attributeName}/${binCount}/${classificationMethod}`,
  ] as const;
};

export const getGetGeometryHistogramByGeometryCollectionIdQueryOptions = <
  TData = Awaited<
    ReturnType<typeof getGeometryHistogramByGeometryCollectionId>
  >,
  TError = ErrorType<void>,
>(
  geometryCollectionId: number,
  attributeName: string,
  binCount: number,
  classificationMethod: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getGeometryHistogramByGeometryCollectionId>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetGeometryHistogramByGeometryCollectionIdQueryKey(
      geometryCollectionId,
      attributeName,
      binCount,
      classificationMethod,
    );

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getGeometryHistogramByGeometryCollectionId>>
  > = ({ signal }) =>
    getGeometryHistogramByGeometryCollectionId(
      geometryCollectionId,
      attributeName,
      binCount,
      classificationMethod,
      requestOptions,
      signal,
    );

  return {
    queryKey,
    queryFn,
    enabled: !!(
      geometryCollectionId &&
      attributeName &&
      binCount &&
      classificationMethod
    ),
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getGeometryHistogramByGeometryCollectionId>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetGeometryHistogramByGeometryCollectionIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof getGeometryHistogramByGeometryCollectionId>>
>;
export type GetGeometryHistogramByGeometryCollectionIdQueryError =
  ErrorType<void>;

export const useGetGeometryHistogramByGeometryCollectionId = <
  TData = Awaited<
    ReturnType<typeof getGeometryHistogramByGeometryCollectionId>
  >,
  TError = ErrorType<void>,
>(
  geometryCollectionId: number,
  attributeName: string,
  binCount: number,
  classificationMethod: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getGeometryHistogramByGeometryCollectionId>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getGetGeometryHistogramByGeometryCollectionIdQueryOptions(
      geometryCollectionId,
      attributeName,
      binCount,
      classificationMethod,
      options,
    );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const postGeometryHistogramByGeometryCollectionId = (
  geometryCollectionId: number,
  histogramParameters: BodyType<HistogramParameters>,
  options?: SecondParameter<typeof AxiosInstance>,
) => {
  return AxiosInstance<Histogram>(
    {
      url: `/api/GeometryCollections/${encodeURIComponent(String(geometryCollectionId))}/Geometries/Histogram`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: histogramParameters,
    },
    options,
  );
};

export const getPostGeometryHistogramByGeometryCollectionIdMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postGeometryHistogramByGeometryCollectionId>>,
    TError,
    { geometryCollectionId: number; data: BodyType<HistogramParameters> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postGeometryHistogramByGeometryCollectionId>>,
  TError,
  { geometryCollectionId: number; data: BodyType<HistogramParameters> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postGeometryHistogramByGeometryCollectionId>>,
    { geometryCollectionId: number; data: BodyType<HistogramParameters> }
  > = (props) => {
    const { geometryCollectionId, data } = props ?? {};

    return postGeometryHistogramByGeometryCollectionId(
      geometryCollectionId,
      data,
      requestOptions,
    );
  };

  return { mutationFn, ...mutationOptions };
};

export type PostGeometryHistogramByGeometryCollectionIdMutationResult =
  NonNullable<
    Awaited<ReturnType<typeof postGeometryHistogramByGeometryCollectionId>>
  >;
export type PostGeometryHistogramByGeometryCollectionIdMutationBody =
  BodyType<HistogramParameters>;
export type PostGeometryHistogramByGeometryCollectionIdMutationError =
  ErrorType<void>;

export const usePostGeometryHistogramByGeometryCollectionId = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postGeometryHistogramByGeometryCollectionId>>,
    TError,
    { geometryCollectionId: number; data: BodyType<HistogramParameters> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof postGeometryHistogramByGeometryCollectionId>>,
  TError,
  { geometryCollectionId: number; data: BodyType<HistogramParameters> },
  TContext
> => {
  const mutationOptions =
    getPostGeometryHistogramByGeometryCollectionIdMutationOptions(options);

  return useMutation(mutationOptions);
};
export const getGeometryCategorizedBinsByGeometryCollectionId = (
  geometryCollectionId: number,
  attributeName: string,
  options?: SecondParameter<typeof AxiosInstance>,
  signal?: AbortSignal,
) => {
  return AxiosInstance<HistogramCategorized>(
    {
      url: `/api/GeometryCollections/${encodeURIComponent(String(geometryCollectionId))}/Geometries/CategorizedHistogram/${encodeURIComponent(String(attributeName))}`,
      method: 'GET',
      signal,
    },
    options,
  );
};

export const getGetGeometryCategorizedBinsByGeometryCollectionIdQueryKey = (
  geometryCollectionId: number,
  attributeName: string,
) => {
  return [
    `/api/GeometryCollections/${geometryCollectionId}/Geometries/CategorizedHistogram/${attributeName}`,
  ] as const;
};

export const getGetGeometryCategorizedBinsByGeometryCollectionIdQueryOptions = <
  TData = Awaited<
    ReturnType<typeof getGeometryCategorizedBinsByGeometryCollectionId>
  >,
  TError = ErrorType<void>,
>(
  geometryCollectionId: number,
  attributeName: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof getGeometryCategorizedBinsByGeometryCollectionId>
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetGeometryCategorizedBinsByGeometryCollectionIdQueryKey(
      geometryCollectionId,
      attributeName,
    );

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getGeometryCategorizedBinsByGeometryCollectionId>>
  > = ({ signal }) =>
    getGeometryCategorizedBinsByGeometryCollectionId(
      geometryCollectionId,
      attributeName,
      requestOptions,
      signal,
    );

  return {
    queryKey,
    queryFn,
    enabled: !!(geometryCollectionId && attributeName),
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<
      ReturnType<typeof getGeometryCategorizedBinsByGeometryCollectionId>
    >,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetGeometryCategorizedBinsByGeometryCollectionIdQueryResult =
  NonNullable<
    Awaited<ReturnType<typeof getGeometryCategorizedBinsByGeometryCollectionId>>
  >;
export type GetGeometryCategorizedBinsByGeometryCollectionIdQueryError =
  ErrorType<void>;

export const useGetGeometryCategorizedBinsByGeometryCollectionId = <
  TData = Awaited<
    ReturnType<typeof getGeometryCategorizedBinsByGeometryCollectionId>
  >,
  TError = ErrorType<void>,
>(
  geometryCollectionId: number,
  attributeName: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof getGeometryCategorizedBinsByGeometryCollectionId>
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getGetGeometryCategorizedBinsByGeometryCollectionIdQueryOptions(
      geometryCollectionId,
      attributeName,
      options,
    );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const postGeometryCategorizedBinsByGeometryCollectionId = (
  geometryCollectionId: number,
  postGeometryCategorizedBinsByGeometryCollectionIdBody: BodyType<string>,
  options?: SecondParameter<typeof AxiosInstance>,
) => {
  return AxiosInstance<HistogramCategorized>(
    {
      url: `/api/GeometryCollections/${encodeURIComponent(String(geometryCollectionId))}/Geometries/CategorizedHistogram`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: postGeometryCategorizedBinsByGeometryCollectionIdBody,
    },
    options,
  );
};

export const getPostGeometryCategorizedBinsByGeometryCollectionIdMutationOptions =
  <TError = ErrorType<void>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
      Awaited<
        ReturnType<typeof postGeometryCategorizedBinsByGeometryCollectionId>
      >,
      TError,
      { geometryCollectionId: number; data: BodyType<string> },
      TContext
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  }): UseMutationOptions<
    Awaited<
      ReturnType<typeof postGeometryCategorizedBinsByGeometryCollectionId>
    >,
    TError,
    { geometryCollectionId: number; data: BodyType<string> },
    TContext
  > => {
    const { mutation: mutationOptions, request: requestOptions } =
      options ?? {};

    const mutationFn: MutationFunction<
      Awaited<
        ReturnType<typeof postGeometryCategorizedBinsByGeometryCollectionId>
      >,
      { geometryCollectionId: number; data: BodyType<string> }
    > = (props) => {
      const { geometryCollectionId, data } = props ?? {};

      return postGeometryCategorizedBinsByGeometryCollectionId(
        geometryCollectionId,
        data,
        requestOptions,
      );
    };

    return { mutationFn, ...mutationOptions };
  };

export type PostGeometryCategorizedBinsByGeometryCollectionIdMutationResult =
  NonNullable<
    Awaited<
      ReturnType<typeof postGeometryCategorizedBinsByGeometryCollectionId>
    >
  >;
export type PostGeometryCategorizedBinsByGeometryCollectionIdMutationBody =
  BodyType<string>;
export type PostGeometryCategorizedBinsByGeometryCollectionIdMutationError =
  ErrorType<void>;

export const usePostGeometryCategorizedBinsByGeometryCollectionId = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<typeof postGeometryCategorizedBinsByGeometryCollectionId>
    >,
    TError,
    { geometryCollectionId: number; data: BodyType<string> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof postGeometryCategorizedBinsByGeometryCollectionId>>,
  TError,
  { geometryCollectionId: number; data: BodyType<string> },
  TContext
> => {
  const mutationOptions =
    getPostGeometryCategorizedBinsByGeometryCollectionIdMutationOptions(
      options,
    );

  return useMutation(mutationOptions);
};
export const cloneGeometryCollection = (
  geometryCollectionCloneRequest: BodyType<GeometryCollectionCloneRequest>,
  options?: SecondParameter<typeof AxiosInstance>,
) => {
  return AxiosInstance<void>(
    {
      url: `/api/GeometryCollections/Clone`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: geometryCollectionCloneRequest,
    },
    options,
  );
};

export const getCloneGeometryCollectionMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof cloneGeometryCollection>>,
    TError,
    { data: BodyType<GeometryCollectionCloneRequest> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof cloneGeometryCollection>>,
  TError,
  { data: BodyType<GeometryCollectionCloneRequest> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof cloneGeometryCollection>>,
    { data: BodyType<GeometryCollectionCloneRequest> }
  > = (props) => {
    const { data } = props ?? {};

    return cloneGeometryCollection(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type CloneGeometryCollectionMutationResult = NonNullable<
  Awaited<ReturnType<typeof cloneGeometryCollection>>
>;
export type CloneGeometryCollectionMutationBody =
  BodyType<GeometryCollectionCloneRequest>;
export type CloneGeometryCollectionMutationError = ErrorType<void>;

export const useCloneGeometryCollection = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof cloneGeometryCollection>>,
    TError,
    { data: BodyType<GeometryCollectionCloneRequest> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof cloneGeometryCollection>>,
  TError,
  { data: BodyType<GeometryCollectionCloneRequest> },
  TContext
> => {
  const mutationOptions = getCloneGeometryCollectionMutationOptions(options);

  return useMutation(mutationOptions);
};
export const queueGeometryCollectionsImport = (
  uploadId: number,
  options?: SecondParameter<typeof AxiosInstance>,
) => {
  return AxiosInstance<void>(
    {
      url: `/api/GeometryCollections/UploadId/${encodeURIComponent(String(uploadId))}`,
      method: 'POST',
    },
    options,
  );
};

export const getQueueGeometryCollectionsImportMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof queueGeometryCollectionsImport>>,
    TError,
    { uploadId: number },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof queueGeometryCollectionsImport>>,
  TError,
  { uploadId: number },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof queueGeometryCollectionsImport>>,
    { uploadId: number }
  > = (props) => {
    const { uploadId } = props ?? {};

    return queueGeometryCollectionsImport(uploadId, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type QueueGeometryCollectionsImportMutationResult = NonNullable<
  Awaited<ReturnType<typeof queueGeometryCollectionsImport>>
>;

export type QueueGeometryCollectionsImportMutationError = ErrorType<void>;

export const useQueueGeometryCollectionsImport = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof queueGeometryCollectionsImport>>,
    TError,
    { uploadId: number },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof queueGeometryCollectionsImport>>,
  TError,
  { uploadId: number },
  TContext
> => {
  const mutationOptions =
    getQueueGeometryCollectionsImportMutationOptions(options);

  return useMutation(mutationOptions);
};
export const uploadGeojsonToGeometryCollection = (
  uploadGeojsonToGeometryCollectionBody: BodyType<UploadGeojsonToGeometryCollectionBody>,
  params?: UploadGeojsonToGeometryCollectionParams,
  options?: SecondParameter<typeof AxiosInstance>,
) => {
  const formData = new FormData();
  if (uploadGeojsonToGeometryCollectionBody.File !== undefined) {
    formData.append('File', uploadGeojsonToGeometryCollectionBody.File);
  }

  return AxiosInstance<void>(
    {
      url: `/api/GeometryCollections/Upload`,
      method: 'POST',
      headers: { 'Content-Type': 'multipart/form-data' },
      data: formData,
      params,
    },
    options,
  );
};

export const getUploadGeojsonToGeometryCollectionMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof uploadGeojsonToGeometryCollection>>,
    TError,
    {
      data: BodyType<UploadGeojsonToGeometryCollectionBody>;
      params?: UploadGeojsonToGeometryCollectionParams;
    },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof uploadGeojsonToGeometryCollection>>,
  TError,
  {
    data: BodyType<UploadGeojsonToGeometryCollectionBody>;
    params?: UploadGeojsonToGeometryCollectionParams;
  },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof uploadGeojsonToGeometryCollection>>,
    {
      data: BodyType<UploadGeojsonToGeometryCollectionBody>;
      params?: UploadGeojsonToGeometryCollectionParams;
    }
  > = (props) => {
    const { data, params } = props ?? {};

    return uploadGeojsonToGeometryCollection(data, params, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UploadGeojsonToGeometryCollectionMutationResult = NonNullable<
  Awaited<ReturnType<typeof uploadGeojsonToGeometryCollection>>
>;
export type UploadGeojsonToGeometryCollectionMutationBody =
  BodyType<UploadGeojsonToGeometryCollectionBody>;
export type UploadGeojsonToGeometryCollectionMutationError = ErrorType<void>;

export const useUploadGeojsonToGeometryCollection = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof uploadGeojsonToGeometryCollection>>,
    TError,
    {
      data: BodyType<UploadGeojsonToGeometryCollectionBody>;
      params?: UploadGeojsonToGeometryCollectionParams;
    },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof uploadGeojsonToGeometryCollection>>,
  TError,
  {
    data: BodyType<UploadGeojsonToGeometryCollectionBody>;
    params?: UploadGeojsonToGeometryCollectionParams;
  },
  TContext
> => {
  const mutationOptions =
    getUploadGeojsonToGeometryCollectionMutationOptions(options);

  return useMutation(mutationOptions);
};
export const createGeojsonToGeometryCollection = (
  customerId: number,
  name: string,
  createGeojsonToGeometryCollectionBody: BodyType<CreateGeojsonToGeometryCollectionBody>,
  options?: SecondParameter<typeof AxiosInstance>,
) => {
  const formData = new FormData();
  if (createGeojsonToGeometryCollectionBody.file !== undefined) {
    formData.append('file', createGeojsonToGeometryCollectionBody.file);
  }

  return AxiosInstance<void>(
    {
      url: `/api/GeometryCollections/Upload/Customer/${encodeURIComponent(String(customerId))}/Name/${encodeURIComponent(String(name))}`,
      method: 'POST',
      headers: { 'Content-Type': 'multipart/form-data' },
      data: formData,
    },
    options,
  );
};

export const getCreateGeojsonToGeometryCollectionMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createGeojsonToGeometryCollection>>,
    TError,
    {
      customerId: number;
      name: string;
      data: BodyType<CreateGeojsonToGeometryCollectionBody>;
    },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof createGeojsonToGeometryCollection>>,
  TError,
  {
    customerId: number;
    name: string;
    data: BodyType<CreateGeojsonToGeometryCollectionBody>;
  },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createGeojsonToGeometryCollection>>,
    {
      customerId: number;
      name: string;
      data: BodyType<CreateGeojsonToGeometryCollectionBody>;
    }
  > = (props) => {
    const { customerId, name, data } = props ?? {};

    return createGeojsonToGeometryCollection(
      customerId,
      name,
      data,
      requestOptions,
    );
  };

  return { mutationFn, ...mutationOptions };
};

export type CreateGeojsonToGeometryCollectionMutationResult = NonNullable<
  Awaited<ReturnType<typeof createGeojsonToGeometryCollection>>
>;
export type CreateGeojsonToGeometryCollectionMutationBody =
  BodyType<CreateGeojsonToGeometryCollectionBody>;
export type CreateGeojsonToGeometryCollectionMutationError = ErrorType<void>;

export const useCreateGeojsonToGeometryCollection = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createGeojsonToGeometryCollection>>,
    TError,
    {
      customerId: number;
      name: string;
      data: BodyType<CreateGeojsonToGeometryCollectionBody>;
    },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof createGeojsonToGeometryCollection>>,
  TError,
  {
    customerId: number;
    name: string;
    data: BodyType<CreateGeojsonToGeometryCollectionBody>;
  },
  TContext
> => {
  const mutationOptions =
    getCreateGeojsonToGeometryCollectionMutationOptions(options);

  return useMutation(mutationOptions);
};

import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import compare from 'trivial-compare';

import { APIClient, CaptureCustomAttribute } from '@agerpoint/api';
import { SpecialCaptureObject } from '@agerpoint/types';
import { APIUtils } from '@agerpoint/utilities';

import { useCapturesViewerContext } from '../../../captures-viewer';

export const useThreeDAnnotationsPluginQueries = () => {
  const queryClient = useQueryClient();

  const { captureId } = useParams();

  const { selectedCaptureJob } = useCapturesViewerContext();

  const captureJobId = useMemo(
    () => selectedCaptureJob?.id,
    [selectedCaptureJob]
  );

  const captureObjectsBySelectedCaptureJobQuery =
    APIClient.useGetCaptureObjectsByCaptureId(Number(captureId), {
      query: {
        queryKey: [
          APIUtils.QueryKey.captures,
          { captureId: Number(captureId) },
          APIUtils.QueryKey.captureObjects,
        ],
        enabled: Number.isSafeInteger(Number(captureId)),
        select: (data) =>
          data
            .filter((co) => co.captureJobId === Number(captureJobId))
            .sort((a, b) => compare(a.id, b.id)),
      },
    });

  const createCaptureObject = useMutation({
    mutationFn: async (captureObject: SpecialCaptureObject) => {
      return await APIClient.postCaptureObject(captureObject);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [
          APIUtils.QueryKey.captures,
          { captureId: Number(selectedCaptureJob?.captureId) },
          APIUtils.QueryKey.captureObjects,
        ],
      });
    },
  });

  const deleteCaptureObject = useMutation({
    mutationFn: async (captureObjectId: number) => {
      return await APIClient.deleteCaptureObject(captureObjectId);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [
          APIUtils.QueryKey.captures,
          { captureId: Number(selectedCaptureJob?.captureId) },
          APIUtils.QueryKey.captureObjects,
        ],
      });
    },
  });

  const deleteCustomCaptureObjectAttributes = useMutation({
    mutationFn: async (captureObjectId: number) => {
      return await APIClient.deleteCaptureObjectCustomAttribute(
        captureObjectId
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [
          APIUtils.QueryKey.captures,
          { captureId: Number(selectedCaptureJob?.captureId) },
          APIUtils.QueryKey.captureObjects,
        ],
      });
    },
  });

  const updateCustomCaptureObjectAttributes = useMutation({
    mutationFn: async ({
      updatedCaptureAttribute,
      customAttributeId,
    }: {
      updatedCaptureAttribute: CaptureCustomAttribute;
      customAttributeId: number;
    }) => {
      return await APIClient.putCaptureObjectCustomAttributeById(
        customAttributeId,
        updatedCaptureAttribute
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [
          APIUtils.QueryKey.captures,
          { captureId: Number(selectedCaptureJob?.captureId) },
          APIUtils.QueryKey.captureObjects,
        ],
      });
    },
  });

  return {
    createCaptureObject,
    deleteCaptureObject,
    deleteCustomCaptureObjectAttributes,
    captureObjectsBySelectedCaptureJobQuery,
    updateCustomCaptureObjectAttributes,
  };
};

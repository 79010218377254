import {
  IconName,
  IconPrefix,
  faCircleNotch,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MouseEventHandler, useCallback, useMemo } from 'react';

import { ButtonIconToggle } from './button-icon-toggle';

interface IButtonIcon {
  id: string;
  buttonRef?: React.RefObject<HTMLButtonElement>;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
  loading?: boolean;
  leadingIcon?: IconName;
  leadingIconPrefix?: IconPrefix;
  leadingIconSpin?: boolean;
  leadingIconColor?: string;
  label?: string;
  trailingIcon?: IconName;
  trailingIconPrefix?: IconPrefix;
  trailingIconSpin?: boolean;
  trailingIconColor?: string;
  toggled?: boolean;
  shrink?: boolean;
}

const ButtonIcon = ({
  id,
  buttonRef,
  onClick,
  disabled,
  loading,
  leadingIcon,
  leadingIconPrefix = 'far',
  leadingIconSpin,
  leadingIconColor,
  trailingIcon,
  trailingIconPrefix = 'far',
  trailingIconSpin,
  trailingIconColor,
  label,
  toggled,
  shrink,
}: IButtonIcon) => {
  const className = useMemo(() => {
    let classes = `transition-colors duration-200 h-8 shrink-0
    flex flex-row items-center justify-center relative`;

    if (!shrink) {
      classes += ' px-1 ';
    }

    if (toggled) {
      classes += ' rounded-lg bg-primary bg-opacity-10 text-primary ';
    } else {
      classes += ' rounded-full ';
    }

    if (disabled) {
      classes += ' cursor-not-allowed ';
    } else {
      classes += ' cursor-pointer hover-overlay-5 ';
    }

    return classes.trim();
  }, [disabled, toggled]);

  const buildIcon = useCallback(
    (prefix: IconPrefix, icon?: IconName, spin?: boolean, color?: string) => {
      if (!icon) return null;

      if (loading) {
        return (
          <div className="size-6 flex-center">
            <FontAwesomeIcon
              spin
              icon={faCircleNotch}
              className="text-base text-gray-iconSecondary"
            />
          </div>
        );
      }

      if (disabled) {
        return (
          <div className="size-6 flex-center">
            <FontAwesomeIcon
              icon={[toggled ? 'fas' : prefix, icon]}
              spin={spin}
              className="text-base text-gray-iconSecondary"
            />
          </div>
        );
      }

      return (
        <div className="size-6 flex-center">
          <FontAwesomeIcon
            icon={[toggled ? 'fas' : prefix, icon]}
            spin={spin}
            className={`text-base ${toggled ? color ?? 'text-primary' : color}`}
            style={{
              color: toggled ? color : color,
            }}
          />
        </div>
      );
    },
    [loading, disabled, toggled]
  );

  return (
    <button
      data-test-id={id}
      type="button"
      onClick={onClick}
      className={className}
      disabled={disabled}
      style={{
        height: shrink ? '24px' : '32px',
      }}
      ref={buttonRef}
    >
      {buildIcon(
        leadingIconPrefix,
        leadingIcon,
        leadingIconSpin,
        leadingIconColor
      )}
      {label && <div className="px-1">{label}</div>}
      {buildIcon(
        trailingIconPrefix,
        trailingIcon,
        trailingIconSpin,
        trailingIconColor
      )}
    </button>
  );
};

ButtonIcon.Toggle = ButtonIconToggle;

export { ButtonIcon };

import { Dispatch, SetStateAction } from 'react';
import { Group, PerspectiveCamera, Scene, Vector3 } from 'three';

import {
  Annotation3dLines,
  Annotation3dPoints,
  Annotation3dPolygons,
  ColorsThreeD,
  GeomType,
  HexColor,
  IAnnotations3d,
  IAnnotations3dGeometry,
} from '@agerpoint/types';
import { AnnotationGroupName } from '@agerpoint/utilities';

import { AnnotationBase } from '../annotations.base';
import { AnnotationsManager } from './annotations-manager';
import { Line3d } from './line-3d';
import { MultiPoint3d } from './multi-point';
import { Point3d } from './point-3d';

export class Annotations3d extends AnnotationBase implements IAnnotations3d {
  private annoMgr: AnnotationsManager;
  private annotationGroup: Group;

  constructor(
    scene: Scene,
    perspectiveCamera: PerspectiveCamera,
    isPotree: boolean
  ) {
    super(scene, perspectiveCamera, isPotree);
    this.annoMgr = new AnnotationsManager();
    this.isPotree = isPotree;
    this.annotationGroup = new Group();
    this.annotationGroup.name = AnnotationGroupName;
    this.scene.add(this.annotationGroup);
  }

  public add3dPoint(
    id: string,
    position: Vector3 = new Vector3(0, 0, 0),
    type: Annotation3dPoints,
    color?: ColorsThreeD | HexColor,
    visible?: boolean
  ) {
    const point = this.createPoint3d(id, position, type, false, color, visible);
    if (type === Annotation3dPoints.AnnotationPoint) {
      this.notifyListeners();
    }
    return point;
  }

  public add3dMultiPoint(
    id: string,
    positions: Vector3[],
    type: Annotation3dPoints,
    color?: ColorsThreeD | HexColor
  ) {
    const multiPoint = this.createMultiPoint3d(id, positions, type, color);
    this.notifyListeners();
    return multiPoint;
  }

  public add3dLine(
    id: string,
    points: Vector3[],
    type: Annotation3dLines,
    color?: ColorsThreeD | HexColor
  ) {
    const line = this.createLine3d(id, points, type, color);
    this.notifyListeners();
    return line;
  }

  public add3dSprite(
    id: string,
    position: Vector3 = new Vector3(0, 0, 0),
    type: Annotation3dPoints,
    color?: ColorsThreeD | HexColor
  ) {
    const sprite = this.createPoint3d(id, position, type, true, color);
    this.notifyListeners();
    return sprite;
  }

  public add3dPolygon(
    id: string,
    points: Vector3[],
    type: Annotation3dPolygons,
    color?: ColorsThreeD | HexColor
  ) {
    const polygon = this.createPolygon3d(id, points, type, color);
    this.notifyListeners();
    return polygon;
  }

  public remove3dSpriteById(id: string) {
    this.remove3dPointById(id);
    this.notifyListeners();
  }

  public remove3dPointsByType(type: Annotation3dPoints) {
    Annotations3d.annoStore.removePoint3dByType(type);
    this.notifyListeners();
  }

  public remove3dLineById(id: string) {
    Annotations3d.annoStore.removeLine3dById(id);
    this.notifyListeners();
  }

  public remove3dPolygonById(id: string) {
    Annotations3d.annoStore.removePolygon3dById(id);
    this.notifyListeners();
  }

  public remove3dMultiPointById(id: string) {
    Annotations3d.annoStore.removeMultiPoint3dById(id);
    this.notifyListeners();
  }

  public get3dPointById(id: string) {
    return Annotations3d.annoStore.getPoint3d(id);
  }

  public get3dMultiPointById(id: string) {
    return Annotations3d.annoStore.getMultiPoint3d(id);
  }

  public get3dLinePositionById(id: string) {
    return Annotations3d.annoStore.getLinePositionById(id);
  }

  public highlight3dPointById(id: string, color?: ColorsThreeD | HexColor) {
    Annotations3d.annoStore.highlightPoint3dById(id, color);
    this.setSelectedObject(id, Annotation3dPoints.AnnotationPoint);
  }

  public highlight3dLineById(id: string) {
    Annotations3d.annoStore.highlightLine3dById(id);
    this.setSelectedObject(id, Annotation3dLines.AnnotationLine);
  }

  public highlight3dPolygonById(id: string) {
    Annotations3d.annoStore.highlightPolygon3dById(id);
    this.setSelectedObject(id, Annotation3dPolygons.AnnotationPolygon);
  }

  public highlight3dMultiPointById(id: string) {
    Annotations3d.annoStore.highlightMultiPoint3dById(id);
    this.setSelectedObject(id, Annotation3dPoints.MultiPointMarker);
  }

  public hide3dPointsByType(type: Annotation3dPoints) {
    Annotations3d.annoStore.hidePointsByType(type);
  }

  public show3dPointsByType(type: Annotation3dPoints) {
    Annotations3d.annoStore.showPoints3dByType(type);
  }

  public getNew3dPointId() {
    return Annotations3d.annoStore.getNewPoint3dId();
  }

  public getNew3dMultiPointId() {
    return Annotations3d.annoStore.getNewMultiPoint3dId();
  }

  public getNew3dLineId() {
    return Annotations3d.annoStore.getNewLine3dId();
  }

  public getNew3dPolygonId() {
    return Annotations3d.annoStore.getNewPolygon3dId();
  }

  public updateAll3dPointLookAt() {
    Annotations3d.annoStore.updateAllPoint3dLookAt();
  }

  public update3dMultiPointPosition(id: string, points: Vector3[]) {
    Annotations3d.annoStore.updateMultiPoint3dPosition(id, points);
  }

  public update3dLinePosition(id: string, points: Vector3[]) {
    Annotations3d.annoStore.updateLine3dPosition(id, points);
  }

  public update3dPolygonPosition(id: string, points: Vector3[]) {
    Annotations3d.annoStore.updatePolygon3dPosition(id, points);
  }

  public finishCreating3dMultiPoint(id: string) {
    Annotations3d.annoStore.finishCreating3dMultiPoint(id);
  }

  public finishCreating3dLine(id: string) {
    Annotations3d.annoStore.finishCreating3dLine(id);
  }

  public finishCreating3dPolygon(id: string) {
    Annotations3d.annoStore.finishCreating3dPolygon(id);
  }

  public clear3dPoints() {
    Annotations3d.annoStore.clear3dPoints();
    this.notifyListeners();
  }

  public clear3dMultiPoints() {
    Annotations3d.annoStore.clear3dMultiPoints();
    this.notifyListeners();
  }

  public clear3dLines() {
    Annotations3d.annoStore.clear3dLines();
    this.notifyListeners();
  }

  public clear3dPolygons() {
    Annotations3d.annoStore.clear3dPolygons();
    this.notifyListeners();
  }

  public savePoint(id: string) {
    const obj = Annotations3d.annoStore.getPoint3d(id);
    obj.updateType(Annotation3dPoints.AnnotationPoint);
    this.annoMgr.createCapObj(obj, GeomType.Point);
    this.notifyListeners();
  }

  public saveMultiPoint(id: string) {
    const obj = Annotations3d.annoStore.getMultiPoint3d(id);
    obj.updateType(Annotation3dPoints.MultiPointMarker);
    this.annoMgr.createCapObj(obj, GeomType.MultiPoint);
    this.notifyListeners();
  }

  public saveLine(id: string) {
    const obj = Annotations3d.annoStore.getLine3d(id);
    obj.updateType(Annotation3dLines.AnnotationLine);
    this.annoMgr.createCapObj(obj, GeomType.LineString);
    this.notifyListeners();
  }

  public savePolygon(id: string) {
    const obj = Annotations3d.annoStore.getPolygon3d(id);
    obj.updateType(Annotation3dPolygons.AnnotationPolygon);
    this.annoMgr.createCapObj(obj, GeomType.Polygon);
    this.notifyListeners();
  }

  public destroyGeometry() {
    this.clear3dPoints();
    this.clear3dLines();
    this.clear3dPolygons();
    this.clear3dMultiPoints();
  }

  public getAllGeometry() {
    return {
      points: Annotations3d.annoStore.getAllPoints3d(),
      lines: Annotations3d.annoStore.getAllLines3d(),
      polygons: Annotations3d.annoStore.getAllPolygons3d(),
      selected: this.selectedObject,
    };
  }

  public subscribeToAllGeometryChanges(
    listener: Dispatch<SetStateAction<IAnnotations3dGeometry>>
  ) {
    this.listeners.push(listener);
    return () => {
      this.listeners = this.listeners.filter((l) => l !== listener);
    };
  }

  public remove3dPointById(id: string) {
    const pnt = Annotations3d.annoStore.getPoint3d(id);
    if (pnt) {
      Annotations3d.annoStore.removePoint3dById(id);
      this.notifyListeners();
    }
  }

  private unHighlightAll() {
    Annotations3d.annoStore.unHighlightAll3d();
    this.selectedObject = null;
  }

  private setSelectedObject(
    id: string,
    type: Annotation3dPoints | Annotation3dLines | Annotation3dPolygons
  ) {
    this.selectedObject = { id, type };
    this.notifyListeners();
  }

  private createPoint3d(
    id: string,
    position: Vector3,
    type: Annotation3dPoints,
    isSprite: boolean,
    color?: ColorsThreeD | HexColor,
    visible?: boolean
  ) {
    const existingPnt = Annotations3d.annoStore.getPoint3d(id);
    if (!existingPnt) {
      const pnt = new Point3d(
        this.scene,
        this.perspectiveCamera,
        id,
        position,
        type,
        isSprite,
        color,
        visible,
        this.isPotree
      );
      Annotations3d.annoStore.addPoint3d(pnt);
      return pnt;
    } else {
      return Annotations3d.annoStore.getPoint3d(id);
    }
  }

  private createMultiPoint3d(
    id: string,
    positions: Vector3[],
    type: Annotation3dPoints,
    color: any
  ) {
    const existingMultiPnt = Annotations3d.annoStore.getMultiPoint3d(id);
    if (!existingMultiPnt) {
      const multiPnts = new MultiPoint3d(
        this.scene,
        this.perspectiveCamera,
        id,
        type,
        color,
        true
      );
      Annotations3d.annoStore.addMultiPoint3d(multiPnts, id);
      return multiPnts;
    } else {
      return Annotations3d.annoStore.getMultiPoint3d(id);
    }
  }

  private createLine3d(
    id: string,
    points: Vector3[],
    type: Annotation3dLines,
    color: any
  ) {
    const existingLine = Annotations3d.annoStore.getLine3d(id);
    if (!existingLine) {
      const line = new Line3d(
        this.scene,
        this.perspectiveCamera,
        this.isPotree,
        id,
        points,
        type,
        color
      );
      Annotations3d.annoStore.addLine3d(line);
      return line;
    } else {
      return Annotations3d.annoStore.getLine3d(id);
    }
  }

  private createPolygon3d(
    id: string,
    points: Vector3[],
    type: Annotation3dPolygons,
    color: any
  ) {
    const existingPolygon = Annotations3d.annoStore.getPolygon3d(id);
    if (!existingPolygon) {
      const line = new Line3d(
        this.scene,
        this.perspectiveCamera,
        this.isPotree,
        id,
        points,
        type,
        color
      );
      Annotations3d.annoStore.addPolygon3d(line);
      return line;
    } else {
      return Annotations3d.annoStore.getPolygon3d(id);
    }
  }
}

import { AnimatePresence, motion } from 'framer-motion';
import { useRef } from 'react';
import { createPortal } from 'react-dom';

import { CloudButton } from '../button';

interface IModal {
  open: boolean;
  setOpen: (open: boolean) => void;
  children?: React.ReactNode;
}

const Modal = ({ open, setOpen, children }: IModal) => {
  const contentRef = useRef<HTMLDivElement>(null);

  return createPortal(
    <AnimatePresence>
      {open && (
        <motion.div
          className="absolute inset-0 bg-black bg-opacity-20 flex-center overflow-hidden z-modal"
          onClick={(e) => {
            if (
              contentRef.current?.contains(e.target as Node) ||
              e.defaultPrevented
            ) {
              return;
            }

            setOpen(false);
          }}
          transition={{
            duration: 0.3,
          }}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <motion.div
            className="bg-white rounded-4xl shadow-modal max-h-screen max-w-screen overflow-auto"
            initial={{ scale: 0 }}
            ref={contentRef}
            animate={{
              scale: 1,
              transition: {
                type: 'spring',
                stiffness: 100,
                duration: 0.2,
              },
            }}
            exit={{ scale: 0 }}
            transition={{
              duration: 0.2,
            }}
          >
            {children}
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>,
    document.getElementById('apc-modals') as HTMLElement
  );
};

interface IModalTitle {
  title?: string;
  setOpen?: (open: boolean) => void;
}

const ModalTitle = ({ title, setOpen }: IModalTitle) => {
  return (
    <div className="px-6 pt-6 flex flex-row gap-2 items-center">
      {setOpen && (
        <CloudButton.Icon
          id="close-modal-button"
          leadingIcon={'xmark'}
          onClick={() => {
            setOpen(false);
          }}
        />
      )}
      <div className="text-base font-bold">{title}</div>
    </div>
  );
};

interface IModalContent {
  children?: React.ReactNode;
  className?: string;
}

const ModalContent = ({ children, className = '' }: IModalContent) => {
  return <div className={className}>{children}</div>;
};
Modal.Title = ModalTitle;
Modal.Content = ModalContent;

export { Modal };

import {
  ComponentType,
  KeyboardEvent,
  MutableRefObject,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  DraggableProvidedDragHandleProps,
  DraggableProvidedDraggableProps,
  Droppable,
} from 'react-beautiful-dnd';

import {
  ColorDefaults,
  GroupToggle,
  IconTypes,
  Input,
  SvgElement,
  useIsMounted,
} from '@agerpoint/component';
import {
  Group,
  GroupLayersProps,
  Layer,
  MapMode,
  SetVisibleFn,
  toggleExpansionFn,
} from '@agerpoint/types';

import { BaseMapsGroupLayers, IndividualGroupLayers } from '../group-layers';
import { EllipsisIconButton, SaveCheckmark } from '../icon-buttons/';

const BASE_MAPS_GROUP_ID = -1;

function getGroupLayersComponent({
  id,
}: Group): ComponentType<GroupLayersProps> {
  return id !== BASE_MAPS_GROUP_ID
    ? IndividualGroupLayers
    : BaseMapsGroupLayers;
}

interface GroupListItemProps {
  group: Group;
  mode: MapMode;
  selectedLayerId?: number;

  setLayerVisible: SetVisibleFn;
  setGroupVisible: SetVisibleFn;
  setLayerStyle: (layerId: number, layer: Layer) => void;
  setSelectedLayerId: (layerId?: number) => void;
  bingKey: string;
  // react-beautiful-dnd defines innerRef as any, so we do as well
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  innerRef?: any;
  draggableProps?: DraggableProvidedDraggableProps;
  dragHandleProps?: DraggableProvidedDragHandleProps;
  toggleExpansion?: toggleExpansionFn;
  currentExpansionId?: string;
  saveNewGroup?: (name?: string) => Promise<void>;
  isNewGroup: boolean;
  setMultiSelectedLayers?: (addedSelection: number[]) => void;
  multiSelectGroupId?: number | null;
  setMultiSelectGroupId?: (groupId: number | null) => void;
  clearMultiSelection?: () => void | undefined;
  setMultiSelectLayerTypeId?: (layerTypeId: number | null) => void;
  multiSelectedItems?: number[];
  layerTypeId: number | null;
}

export function GroupListItem({
  group,
  mode,
  group: { name },
  selectedLayerId,
  setLayerVisible,
  setLayerStyle,
  setGroupVisible,
  setSelectedLayerId,
  saveNewGroup = undefined,
  innerRef = undefined,
  draggableProps = undefined,
  dragHandleProps = undefined,
  bingKey,
  toggleExpansion,
  currentExpansionId,
  isNewGroup,
  setMultiSelectedLayers,
  multiSelectGroupId,
  setMultiSelectGroupId,
  clearMultiSelection,
  setMultiSelectLayerTypeId,
  layerTypeId,
  multiSelectedItems,
}: GroupListItemProps) {
  const [isOpen, setOpen] = useState<boolean>(false);
  const [temporaryName, setTemporaryName] = useState<string>('');

  const [mouseOver, setMouseOver] = useState<boolean>(false);
  const GroupLayers = getGroupLayersComponent(group);
  const nameInputRef: MutableRefObject<HTMLInputElement | null> = useRef(null);
  const isCurrentExpansionContent = currentExpansionId === `group-${group.id}`;
  const [isCreatingGroup, setCreatingGroup] = useState<boolean>(false);
  const isMounted = useIsMounted();

  useEffect(() => {
    if (isNewGroup && nameInputRef.current) {
      nameInputRef.current.focus();
    }
  }, [nameInputRef, isNewGroup]);

  return (
    <div
      className={`${isNewGroup ? 'border border-blue' : 'border-gray-300'}
       w-full border-b  bg-white shadow-sm py-1`}
      ref={innerRef}
      {...draggableProps}
    >
      <Droppable
        droppableId={`groupHeader-${group.id}`}
        isDropDisabled={group.id < 0}
      >
        {({ droppableProps, innerRef, placeholder }) => (
          <>
            <div
              {...droppableProps}
              ref={innerRef}
              className={`w-full h-10 flex items-center ${
                isOpen ? 'border-b border-gray-300 shadow-sm' : ''
              }`}
            >
              {!isNewGroup && dragHandleProps ? (
                <div {...dragHandleProps}>
                  <SvgElement type="HandleIcon" className="w-4 h-4 ml-2" />
                </div>
              ) : (
                <div className="w-4 h-4 ml-2" />
              )}
              <span className="mx-1">
                <Input.Checkbox
                  id={`group-${group.id}`}
                  value={group.visible}
                  setValue={(checked) => setGroupVisible(group.id, checked)}
                />
              </span>
              <div
                onMouseEnter={() => setMouseOver(true)}
                onMouseLeave={() => setMouseOver(false)}
                tabIndex={0}
                role="button"
                className="flex-grow flex items-center justify-between "
                onClick={!isNewGroup ? () => setOpen(!isOpen) : undefined}
              >
                <div className="flex justify-between items-center max-h-24">
                  <SvgElement
                    type={
                      group?.icon?.name
                        ? ((group.icon.name + 'Icon') as IconTypes)
                        : 'TreeIcon'
                    }
                    className={'m-auto ml-1 mr-2 w-5 h-5'}
                    style={{
                      stroke: group?.icon
                        ? group?.icon.color
                        : ColorDefaults.blue,
                      color: group?.icon
                        ? group?.icon.color
                        : ColorDefaults.blue,
                    }}
                  />
                  <div
                    className={
                      'm-auto ml-1 mr-2 h-auto max-h-20 overflow-hidden line-clamp-2'
                    }
                  >
                    {name ? (
                      name
                    ) : (
                      <div className="w-44 p-1">
                        <Input.Text.Single
                          id="group-name-input"
                          value={temporaryName}
                          setValue={setTemporaryName}
                          placeholder="Group Name"
                          inputRef={nameInputRef}
                          disabled={isCreatingGroup}
                          onKeyDown={saveNewGroup ? keyDownHandler : undefined}
                        />
                      </div>
                    )}
                  </div>
                </div>
                {!isNewGroup ? (
                  <div className="flex pr-3">
                    {toggleExpansion && (
                      <EllipsisIconButton
                        className="block"
                        show={mouseOver || isCurrentExpansionContent}
                        onClick={ontoggleExpansion}
                      />
                    )}
                    <GroupToggle isOpen={isOpen} />
                  </div>
                ) : (
                  <SaveCheckmark
                    isSaving={isCreatingGroup}
                    onSave={internalSaveNewGroup}
                    title="Save"
                  />
                )}
              </div>
            </div>
            {placeholder}
          </>
        )}
      </Droppable>
      {isOpen && (
        <div className="my-2">
          <GroupLayers
            key={group.name}
            group={group}
            selectedLayerId={selectedLayerId}
            mode={mode}
            setVisible={setLayerVisible}
            setStyle={setLayerStyle}
            setSelectedLayerId={setSelectedLayerId}
            bingKey={bingKey}
            currentExpansionId={currentExpansionId}
            toggleExpansion={toggleExpansion}
            setMultiSelectedLayers={setMultiSelectedLayers}
            multiSelectGroupId={multiSelectGroupId}
            setMultiSelectGroupId={setMultiSelectGroupId}
            clearMultiSelection={clearMultiSelection}
            multiSelectedItems={multiSelectedItems}
            setMultiSelectLayerTypeId={setMultiSelectLayerTypeId}
            layerTypeId={layerTypeId}
          />
        </div>
      )}
    </div>
  );

  function keyDownHandler(e: KeyboardEvent<HTMLInputElement>) {
    if (!saveNewGroup) {
      return;
    }

    if (e.code === 'Escape') {
      saveNewGroup(undefined);
      e.stopPropagation();
    } else if (e.code === 'Enter') {
      internalSaveNewGroup();
      e.stopPropagation();
    }
  }

  async function internalSaveNewGroup() {
    if (!saveNewGroup) {
      return;
    }

    setCreatingGroup(true);
    try {
      await saveNewGroup(temporaryName);
    } catch (e) {
      // TODO: show error to user
      console.error('Failed to create group:', e);
      // Attempt to clean up new group
      saveNewGroup(undefined);
    } finally {
      if (isMounted.current) {
        setCreatingGroup(false);
      }
    }
  }

  function ontoggleExpansion() {
    toggleExpansion &&
      toggleExpansion({
        type: 'group',
        id: group.id,
        key: `group-${group.id}`,
      });
  }
}

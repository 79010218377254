import { CaptureObject } from '@agerpoint/api';
import {
  EffectNames,
  GeomType,
  GlobalStore,
  ILine3d,
  IMultiPoint3d,
  IPoint3d,
} from '@agerpoint/types';
import { useGlobalStore } from '@agerpoint/utilities';

import { Line3d } from './line-3d';
import { MultiPoint3d } from './multi-point';
import { Point3d } from './point-3d';

export class AnnotationsManager {
  private store: GlobalStore;
  constructor() {
    this.store = useGlobalStore.getState();
  }

  createCapObj(geom: IPoint3d | ILine3d | IMultiPoint3d, type: GeomType) {
    if (!geom) return;
    let coordinates = [];
    if (type === GeomType.Point && geom instanceof Point3d) {
      coordinates = [geom.object.position]
        .map((coord: any) => [coord.x, coord.y, coord.z])
        .flat();
    } else if (type === GeomType.LineString && geom instanceof Line3d) {
      coordinates = geom
        .getPosition()
        .map((coord: any) => [coord.x, coord.y, coord.z]);
    } else if (type === GeomType.Polygon && geom instanceof Line3d) {
      coordinates = [
        geom.getPosition().map((coord: any) => [coord.x, coord.y, coord.z]),
      ];
    } else if (type === GeomType.MultiPoint && geom instanceof MultiPoint3d) {
      coordinates = geom.points.map((point) => {
        return [
          point.object.position.x,
          point.object.position.y,
          point.object.position.z,
        ];
      });
    }

    const obj: CaptureObject = {
      name: geom.type,
      description: geom.type,
      captureObjectTypeId: 4,
      geom2D: {
        type,
        coordinates,
      } as any,
      validated: true,
      captureId: 11667,
      captureObjectCustomAttributes: [
        {
          attributeName: 'color',
          attributeDisplayName: 'Color',
          attributeValue: geom.color,
          customAttributeUnitId: 0,
          validated: true,
        },
        {
          // _customType is used to differentiate between 3d annotations
          attributeName: '_customType',
          attributeDisplayName: 'Custom Type',
          attributeValue: geom.type,
          customAttributeUnitId: 0,
          validated: true,
        },
      ],
    };

    this.store.actions.dispatchEffect(
      EffectNames.CAPTURE_OBJECTS_LISTENER_CREATE,
      obj
    );
  }

  updateCapObj(id: string) {}

  updateCapObjColor(id: string, color: string) {
    this.store.actions.dispatchEffect(
      EffectNames.CAPTURE_OBJECTS_LISTENER_UPDATE,
      { id, color }
    );
  }

  deleteCapObj(id: string) {
    this.store.actions.dispatchEffect(
      EffectNames.CAPTURE_OBJECTS_LISTENER_DELETE,
      id
    );
  }
}

/**
 * Generated by orval v6.28.2 🍺
 * Do not edit manually.
 * GeminiAPI
 * OpenAPI spec version: v1
 */
import { useMutation, useQuery } from '@tanstack/react-query';
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import type { Region } from '.././models';
import { AxiosInstance } from '../../axios-instance';
import type { ErrorType, BodyType } from '../../axios-instance';

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

export const getRegions = (
  options?: SecondParameter<typeof AxiosInstance>,
  signal?: AbortSignal,
) => {
  return AxiosInstance<Region[]>(
    { url: `/api/Regions`, method: 'GET', signal },
    options,
  );
};

export const getGetRegionsQueryKey = () => {
  return [`/api/Regions`] as const;
};

export const getGetRegionsQueryOptions = <
  TData = Awaited<ReturnType<typeof getRegions>>,
  TError = ErrorType<void>,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof getRegions>>, TError, TData>
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetRegionsQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getRegions>>> = ({
    signal,
  }) => getRegions(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getRegions>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetRegionsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getRegions>>
>;
export type GetRegionsQueryError = ErrorType<void>;

export const useGetRegions = <
  TData = Awaited<ReturnType<typeof getRegions>>,
  TError = ErrorType<void>,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof getRegions>>, TError, TData>
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetRegionsQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const postRegion = (
  region: BodyType<Region>,
  options?: SecondParameter<typeof AxiosInstance>,
) => {
  return AxiosInstance<Region>(
    {
      url: `/api/Regions`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: region,
    },
    options,
  );
};

export const getPostRegionMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postRegion>>,
    TError,
    { data: BodyType<Region> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postRegion>>,
  TError,
  { data: BodyType<Region> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postRegion>>,
    { data: BodyType<Region> }
  > = (props) => {
    const { data } = props ?? {};

    return postRegion(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostRegionMutationResult = NonNullable<
  Awaited<ReturnType<typeof postRegion>>
>;
export type PostRegionMutationBody = BodyType<Region>;
export type PostRegionMutationError = ErrorType<void>;

export const usePostRegion = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postRegion>>,
    TError,
    { data: BodyType<Region> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof postRegion>>,
  TError,
  { data: BodyType<Region> },
  TContext
> => {
  const mutationOptions = getPostRegionMutationOptions(options);

  return useMutation(mutationOptions);
};
export const getRegion = (
  id: number,
  options?: SecondParameter<typeof AxiosInstance>,
  signal?: AbortSignal,
) => {
  return AxiosInstance<Region>(
    {
      url: `/api/Regions/${encodeURIComponent(String(id))}`,
      method: 'GET',
      signal,
    },
    options,
  );
};

export const getGetRegionQueryKey = (id: number) => {
  return [`/api/Regions/${id}`] as const;
};

export const getGetRegionQueryOptions = <
  TData = Awaited<ReturnType<typeof getRegion>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getRegion>>, TError, TData>
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetRegionQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getRegion>>> = ({
    signal,
  }) => getRegion(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<Awaited<ReturnType<typeof getRegion>>, TError, TData> & {
    queryKey: QueryKey;
  };
};

export type GetRegionQueryResult = NonNullable<
  Awaited<ReturnType<typeof getRegion>>
>;
export type GetRegionQueryError = ErrorType<void>;

export const useGetRegion = <
  TData = Awaited<ReturnType<typeof getRegion>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getRegion>>, TError, TData>
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetRegionQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const putRegion = (
  id: number,
  region: BodyType<Region>,
  options?: SecondParameter<typeof AxiosInstance>,
) => {
  return AxiosInstance<Region>(
    {
      url: `/api/Regions/${encodeURIComponent(String(id))}`,
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      data: region,
    },
    options,
  );
};

export const getPutRegionMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putRegion>>,
    TError,
    { id: number; data: BodyType<Region> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof putRegion>>,
  TError,
  { id: number; data: BodyType<Region> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof putRegion>>,
    { id: number; data: BodyType<Region> }
  > = (props) => {
    const { id, data } = props ?? {};

    return putRegion(id, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PutRegionMutationResult = NonNullable<
  Awaited<ReturnType<typeof putRegion>>
>;
export type PutRegionMutationBody = BodyType<Region>;
export type PutRegionMutationError = ErrorType<void>;

export const usePutRegion = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putRegion>>,
    TError,
    { id: number; data: BodyType<Region> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof putRegion>>,
  TError,
  { id: number; data: BodyType<Region> },
  TContext
> => {
  const mutationOptions = getPutRegionMutationOptions(options);

  return useMutation(mutationOptions);
};
export const deleteRegion = (
  id: number,
  options?: SecondParameter<typeof AxiosInstance>,
) => {
  return AxiosInstance<Region>(
    { url: `/api/Regions/${encodeURIComponent(String(id))}`, method: 'DELETE' },
    options,
  );
};

export const getDeleteRegionMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteRegion>>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteRegion>>,
  TError,
  { id: number },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteRegion>>,
    { id: number }
  > = (props) => {
    const { id } = props ?? {};

    return deleteRegion(id, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteRegionMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteRegion>>
>;

export type DeleteRegionMutationError = ErrorType<void>;

export const useDeleteRegion = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteRegion>>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof deleteRegion>>,
  TError,
  { id: number },
  TContext
> => {
  const mutationOptions = getDeleteRegionMutationOptions(options);

  return useMutation(mutationOptions);
};
export const getRegionsByCountryId = (
  countryId: number,
  options?: SecondParameter<typeof AxiosInstance>,
  signal?: AbortSignal,
) => {
  return AxiosInstance<Region[]>(
    {
      url: `/api/Regions/Country/${encodeURIComponent(String(countryId))}`,
      method: 'GET',
      signal,
    },
    options,
  );
};

export const getGetRegionsByCountryIdQueryKey = (countryId: number) => {
  return [`/api/Regions/Country/${countryId}`] as const;
};

export const getGetRegionsByCountryIdQueryOptions = <
  TData = Awaited<ReturnType<typeof getRegionsByCountryId>>,
  TError = ErrorType<void>,
>(
  countryId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getRegionsByCountryId>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetRegionsByCountryIdQueryKey(countryId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getRegionsByCountryId>>
  > = ({ signal }) => getRegionsByCountryId(countryId, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!countryId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getRegionsByCountryId>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetRegionsByCountryIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof getRegionsByCountryId>>
>;
export type GetRegionsByCountryIdQueryError = ErrorType<void>;

export const useGetRegionsByCountryId = <
  TData = Awaited<ReturnType<typeof getRegionsByCountryId>>,
  TError = ErrorType<void>,
>(
  countryId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getRegionsByCountryId>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetRegionsByCountryIdQueryOptions(countryId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

import { BlobServiceClient } from '@azure/storage-blob';
import {
  useInfiniteQuery,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';
import { geometry } from '@turf/turf';
import { useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { APIClient, APIModels } from '@agerpoint/api';
import {
  APIUtils,
  useIsViteApp,
  useLookupTable,
  useToasts,
} from '@agerpoint/utilities';

export const useAdminGeometriesQueries = (
  filter?: APIModels.ApGeometryCollectionFilter
) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const location = useLocation();
  const params = location.state?.params ?? '';

  const isViteApp = useIsViteApp();

  const toasts = useToasts();

  const { geometryId } = useParams();

  const organizationsQuery = APIClient.useGetCustomer({
    query: {
      queryKey: [APIUtils.QueryKey.organizations],
      select: (data) => APIUtils.Sort.organizations(data),
    },
  });

  const usersQuery = APIClient.useGetUsersAvailibleFromCaptures({
    query: {
      queryKey: [APIUtils.QueryKey.usersCaptures],
      select: (data) => APIUtils.Sort.users(data),
    },
  });

  const geometryCloneMutation = APIClient.useCloneGeometryCollection({
    mutation: {
      onSettled: () => {
        queryClient.invalidateQueries({
          queryKey: [APIUtils.QueryKey.geometries],
        });
      },
      onSuccess: () => {
        toasts.add(toasts.prepare.entityCloned('geometry'));
      },
      onError: (e) => {
        console.error(e);

        toasts.add(toasts.prepare.error('Failed to clone geometry!'));
      },
    },
  });

  const geometryPutMutation = APIClient.usePutGeometryCollection({
    mutation: {
      onSettled: () => {
        queryClient.invalidateQueries({
          queryKey: [APIUtils.QueryKey.geometries],
        });
      },
      onSuccess: (_, variables) => {
        APIUtils.updateQueryCache({
          queryClient,
          queryKey: [
            APIUtils.QueryKey.geometries,
            { geometryId: Number(geometryId) },
          ],
          data: variables.data,
        });

        APIUtils.updateInfiniteQueryCache({
          queryClient,
          queryKey: [APIUtils.QueryKey.geometries],
          id: Number(geometryId),
          accessor: 'id',
          data: variables.data,
        });

        toasts.add(toasts.prepare.entityUpdated('geometry'));
      },
      onError: (e) => {
        console.error(e);
        toasts.add(toasts.prepare.error('Failed to update geometry!'));
      },
    },
  });

  const geometryArchivePutMutation = APIClient.usePutGeometryCollection({
    mutation: {
      onSettled: () => {
        queryClient.invalidateQueries({
          queryKey: [APIUtils.QueryKey.geometries],
        });
      },
      onSuccess: (_, variables) => {
        APIUtils.updateQueryCache({
          queryClient,
          queryKey: [
            APIUtils.QueryKey.geometries,
            { geometryId: Number(geometryId) },
          ],
          data: variables.data,
        });

        APIUtils.updateInfiniteQueryCache({
          queryClient,
          queryKey: [APIUtils.QueryKey.geometries],
          id: Number(geometryId),
          accessor: 'id',
          data: variables.data,
        });

        toasts.add(toasts.prepare.entityArchived('geometry'));
      },
      onError: (e) => {
        console.error(e);

        toasts.add(toasts.prepare.error('Failed to archive geometry!'));
      },
    },
  });

  const geometryUnarchivePutMutation = APIClient.usePutGeometryCollection({
    mutation: {
      onSettled: () => {
        queryClient.invalidateQueries({
          queryKey: [APIUtils.QueryKey.geometries],
        });
      },
      onSuccess: (_, variables) => {
        APIUtils.updateQueryCache({
          queryClient,
          queryKey: [
            APIUtils.QueryKey.geometries,
            { geometryId: Number(geometryId) },
          ],
          data: variables.data,
        });

        APIUtils.updateInfiniteQueryCache({
          queryClient,
          queryKey: [APIUtils.QueryKey.geometries],
          id: Number(geometryId),
          accessor: 'id',
          data: variables.data,
        });

        toasts.add(toasts.prepare.entityRestored('geometry'));
      },
      onError: (e) => {
        console.error(e);
        toasts.add(toasts.prepare.error('Failed to restore geometry!'));
      },
    },
  });

  const geometryQuery = APIClient.useGetGeometryCollection(Number(geometryId), {
    query: {
      queryKey: [
        APIUtils.QueryKey.geometries,
        { geometryId: Number(geometryId) },
      ],
      enabled: Number.isSafeInteger(Number(geometryId)),
      placeholderData: () =>
        APIUtils.searchInfiniteQueriesForInitialValue({
          queryClient,
          queryKey: [APIUtils.QueryKey.geometries],
          id: Number(geometryId),
          accessor: 'id',
        }),
      retry: 0,
      staleTime: APIUtils.getDuration({
        seconds: 20,
      }),
    },
  });

  const geometryDownloadQuery = APIClient.useGetDownloadGeometryCollection(
    Number(geometryId),
    {
      query: {
        queryKey: [
          APIUtils.QueryKey.geometries,
          { geometryId: Number(geometryId) },
          'download',
        ],
        enabled: Number.isSafeInteger(Number(geometryId)),
        retry: 0,
        staleTime: APIUtils.getDuration({
          seconds: 20,
        }),
      },
    }
  );

  const uploadFileQuery = APIClient.useGetUploadFileById(
    Number(geometryQuery.data?.uploadFileId),
    {
      query: {
        queryKey: [
          APIUtils.QueryKey.uploadFiles,
          { uploadFileId: Number(geometryQuery.data?.uploadFileId) },
        ],
        enabled: Number.isSafeInteger(
          Number(geometryQuery.data?.uploadFileId ?? undefined)
        ),
      },
    }
  );

  const uploadQuery = APIClient.useGetUploadById(
    Number(uploadFileQuery.data?.uploadId),
    {
      query: {
        queryKey: [
          APIUtils.QueryKey.uploads,
          { uploadId: Number(uploadFileQuery.data?.uploadId) },
        ],
        enabled: Number.isSafeInteger(
          Number(uploadFileQuery.data?.uploadId ?? undefined)
        ),
      },
    }
  );

  const downloadMutation = useMutation({
    mutationFn: async () => {
      if (!geometryDownloadQuery.data?.downloadUrl || !geometry) {
        throw new Error('Could not download geometry');
      }

      const fileName = `geometry_${geometry?.name}.geojson`;

      const response = await fetch(geometryDownloadQuery.data?.downloadUrl);
      const blob = await response.blob();
      const url = URL.createObjectURL(blob);

      const link = document.createElement('a');
      link.download = fileName;
      link.href = url;
      link.click();
      URL.revokeObjectURL(url);
    },
    onError: (e) => {
      console.error(e);
      toasts.add(toasts.prepare.error('Failed to download geometry!'));
    },
  });

  const organizationsLookupTable = useLookupTable(
    organizationsQuery.data,
    'id'
  );

  const geometriesQuery = useInfiniteQuery({
    queryKey: [
      APIUtils.QueryKey.geometries,
      APIUtils.QueryKey.infinite,
      { filter: filter },
    ],
    queryFn: async ({ pageParam }) =>
      APIClient.getFilteredPagedGeometryCollections(
        pageParam.skip,
        pageParam.take,
        filter as APIModels.ApGeometryCollectionFilter
      ),
    initialPageParam: APIUtils.defaultInitialPageParam,
    getNextPageParam: APIUtils.defaultGetNextPageParam,
    staleTime: APIUtils.getDuration({ seconds: 20 }),
    enabled: filter !== undefined,
  });

  const blobServiceMutation = useMutation({
    mutationFn: async (data: {
      uploadUrl: string;
      name: string;
      file: File;
    }) => {
      const blobService = new BlobServiceClient(data.uploadUrl);
      const containerClient = blobService.getContainerClient('');
      const blockBlobClient = containerClient.getBlockBlobClient(data.name);
      await blockBlobClient.uploadData(data.file as Blob);
    },
  });

  const uploadFilePostMutation = APIClient.usePostUploadFile({});

  const uploadPostMutation = APIClient.usePostUpload({
    mutation: {
      onSettled: () => {
        queryClient.invalidateQueries({
          queryKey: [APIUtils.QueryKey.uploads],
        });
      },
    },
  });

  const uploadPutMutation = APIClient.usePutUploadById({
    mutation: {
      onSettled: () => {
        queryClient.invalidateQueries({
          queryKey: [APIUtils.QueryKey.uploads],
        });
      },
    },
  });

  const uploadFilePutMutation = APIClient.usePutUploadFileById({});

  const geometryPostMutation = APIClient.useQueueGeometryCollectionsImport({
    mutation: {
      onSettled: () => {
        queryClient.invalidateQueries({
          queryKey: [APIUtils.QueryKey.geometries],
        });
      },
    },
  });

  useEffect(() => {
    if (geometryId === undefined) {
      return;
    }

    if (!Number.isSafeInteger(Number(geometryId))) {
      if (isViteApp) {
        navigate('/app/admin/platform/geometries' + params);
      } else {
        navigate('/admin/geometries' + params);
      }
      queryClient.removeQueries({
        queryKey: [
          APIUtils.QueryKey.geometries,
          { geometryId: Number(geometryId) },
        ],
      });
    }
  }, [geometryId]);

  return {
    geometryArchivePutMutation,
    geometryCloneMutation,
    geometryDownloadQuery,
    geometryPutMutation,
    geometryQuery,
    geometryUnarchivePutMutation,
    uploadQuery,
    uploadFileQuery,
    downloadMutation,
    usersQuery,
    organizationsQuery,
    organizationsLookupTable,
    geometriesQuery,
    blobServiceMutation,
    uploadFilePostMutation,
    uploadPostMutation,
    uploadPutMutation,
    uploadFilePutMutation,
    geometryPostMutation,
  };
};

/**
 * Generated by orval v6.28.2 🍺
 * Do not edit manually.
 * GeminiAPI
 * OpenAPI spec version: v1
 */
import { useMutation, useQuery } from '@tanstack/react-query';
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import type { LayerType } from '.././models';
import { AxiosInstance } from '../../axios-instance';
import type { ErrorType, BodyType } from '../../axios-instance';

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

export const getLayerType = (
  options?: SecondParameter<typeof AxiosInstance>,
  signal?: AbortSignal,
) => {
  return AxiosInstance<LayerType[]>(
    { url: `/api/LayerTypes`, method: 'GET', signal },
    options,
  );
};

export const getGetLayerTypeQueryKey = () => {
  return [`/api/LayerTypes`] as const;
};

export const getGetLayerTypeQueryOptions = <
  TData = Awaited<ReturnType<typeof getLayerType>>,
  TError = ErrorType<void>,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof getLayerType>>, TError, TData>
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetLayerTypeQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getLayerType>>> = ({
    signal,
  }) => getLayerType(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getLayerType>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetLayerTypeQueryResult = NonNullable<
  Awaited<ReturnType<typeof getLayerType>>
>;
export type GetLayerTypeQueryError = ErrorType<void>;

export const useGetLayerType = <
  TData = Awaited<ReturnType<typeof getLayerType>>,
  TError = ErrorType<void>,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof getLayerType>>, TError, TData>
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetLayerTypeQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const postLayerType = (
  layerType: BodyType<LayerType>,
  options?: SecondParameter<typeof AxiosInstance>,
) => {
  return AxiosInstance<LayerType>(
    {
      url: `/api/LayerTypes`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: layerType,
    },
    options,
  );
};

export const getPostLayerTypeMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postLayerType>>,
    TError,
    { data: BodyType<LayerType> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postLayerType>>,
  TError,
  { data: BodyType<LayerType> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postLayerType>>,
    { data: BodyType<LayerType> }
  > = (props) => {
    const { data } = props ?? {};

    return postLayerType(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostLayerTypeMutationResult = NonNullable<
  Awaited<ReturnType<typeof postLayerType>>
>;
export type PostLayerTypeMutationBody = BodyType<LayerType>;
export type PostLayerTypeMutationError = ErrorType<void>;

export const usePostLayerType = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postLayerType>>,
    TError,
    { data: BodyType<LayerType> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof postLayerType>>,
  TError,
  { data: BodyType<LayerType> },
  TContext
> => {
  const mutationOptions = getPostLayerTypeMutationOptions(options);

  return useMutation(mutationOptions);
};
export const getLayerTypeById = (
  id: number,
  options?: SecondParameter<typeof AxiosInstance>,
  signal?: AbortSignal,
) => {
  return AxiosInstance<LayerType>(
    {
      url: `/api/LayerTypes/${encodeURIComponent(String(id))}`,
      method: 'GET',
      signal,
    },
    options,
  );
};

export const getGetLayerTypeByIdQueryKey = (id: number) => {
  return [`/api/LayerTypes/${id}`] as const;
};

export const getGetLayerTypeByIdQueryOptions = <
  TData = Awaited<ReturnType<typeof getLayerTypeById>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getLayerTypeById>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetLayerTypeByIdQueryKey(id);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getLayerTypeById>>
  > = ({ signal }) => getLayerTypeById(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getLayerTypeById>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetLayerTypeByIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof getLayerTypeById>>
>;
export type GetLayerTypeByIdQueryError = ErrorType<void>;

export const useGetLayerTypeById = <
  TData = Awaited<ReturnType<typeof getLayerTypeById>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getLayerTypeById>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetLayerTypeByIdQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const putLayerTypeById = (
  id: number,
  layerType: BodyType<LayerType>,
  options?: SecondParameter<typeof AxiosInstance>,
) => {
  return AxiosInstance<LayerType>(
    {
      url: `/api/LayerTypes/${encodeURIComponent(String(id))}`,
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      data: layerType,
    },
    options,
  );
};

export const getPutLayerTypeByIdMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putLayerTypeById>>,
    TError,
    { id: number; data: BodyType<LayerType> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof putLayerTypeById>>,
  TError,
  { id: number; data: BodyType<LayerType> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof putLayerTypeById>>,
    { id: number; data: BodyType<LayerType> }
  > = (props) => {
    const { id, data } = props ?? {};

    return putLayerTypeById(id, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PutLayerTypeByIdMutationResult = NonNullable<
  Awaited<ReturnType<typeof putLayerTypeById>>
>;
export type PutLayerTypeByIdMutationBody = BodyType<LayerType>;
export type PutLayerTypeByIdMutationError = ErrorType<void>;

export const usePutLayerTypeById = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putLayerTypeById>>,
    TError,
    { id: number; data: BodyType<LayerType> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof putLayerTypeById>>,
  TError,
  { id: number; data: BodyType<LayerType> },
  TContext
> => {
  const mutationOptions = getPutLayerTypeByIdMutationOptions(options);

  return useMutation(mutationOptions);
};
export const deleteLayerTypeById = (
  id: number,
  options?: SecondParameter<typeof AxiosInstance>,
) => {
  return AxiosInstance<LayerType>(
    {
      url: `/api/LayerTypes/${encodeURIComponent(String(id))}`,
      method: 'DELETE',
    },
    options,
  );
};

export const getDeleteLayerTypeByIdMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteLayerTypeById>>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteLayerTypeById>>,
  TError,
  { id: number },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteLayerTypeById>>,
    { id: number }
  > = (props) => {
    const { id } = props ?? {};

    return deleteLayerTypeById(id, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteLayerTypeByIdMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteLayerTypeById>>
>;

export type DeleteLayerTypeByIdMutationError = ErrorType<void>;

export const useDeleteLayerTypeById = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteLayerTypeById>>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof deleteLayerTypeById>>,
  TError,
  { id: number },
  TContext
> => {
  const mutationOptions = getDeleteLayerTypeByIdMutationOptions(options);

  return useMutation(mutationOptions);
};

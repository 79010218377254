import { faBarChart, faCog } from '@fortawesome/pro-light-svg-icons';
import { faCube, faFlask } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { UseGetReturn } from 'restful-react';

import {
  Capture,
  useGetCaptureExtractionJobsByCaptureId,
} from '@agerpoint/api';
import { Button, PrimaryButton } from '@agerpoint/component';
import { Viewer } from '@agerpoint/three-d-viewer';
import {
  AppBarProps,
  CaptureExpansionPanels,
  ExtractionJob,
  IViewer,
  LdFlags,
  UserClaims,
} from '@agerpoint/types';
import {
  hasClaims,
  hasPermission,
  useGlobalStore,
  useNewLayout,
  useToasts,
} from '@agerpoint/utilities';

import { AppBar } from '../../app-bar/app-bar';
import { CapturesViewerThreeDLayerControls } from '../captures-viewer-sidebar-ddd-layers/captures-viewer-sidebar-ddd-layers';

interface CapturesViewerSidebarContentBodyProps {
  appBarProps: AppBarProps;
  captureData: Capture;
  viewer: React.MutableRefObject<IViewer | undefined> | undefined;
  eptId: number | undefined;
  show3dLayers: boolean;
  openExpansionPanel: (value: CaptureExpansionPanels) => void;
  showCaptureAtributes: boolean;
}
/**
 * @deprecated
 */
export const CapturesViewerSidebarContentBody = ({
  appBarProps,
  captureData,
  eptId,
  viewer,
  show3dLayers,
  openExpansionPanel,
  showCaptureAtributes,
}: CapturesViewerSidebarContentBodyProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { permissions, user } = useGlobalStore();
  const toasts = useToasts();

  const [qaqcExtId, setQaqcExtId] = useState<number>(NaN);
  const [hasSelfServeAnalytics, setHasSelfServeAnalytics] =
    useState<boolean>(false);
  const [hasQaqcClaim, setHasQaqcClaim] = useState<boolean>(false);

  useEffect(() => {
    const hasPerm = hasPermission(LdFlags.SelfServeAnalytics, permissions);
    setHasSelfServeAnalytics(hasPerm);
  }, [permissions]);

  useEffect(() => {
    const hasClaim = hasClaims(
      [UserClaims.QAQC],
      (user?.cloudClaims || []) as UserClaims[]
    );
    setHasQaqcClaim(hasClaim);
  }, [user]);

  const { data: extJobs, refetch: refetchExtJobs } =
    useGetCaptureExtractionJobsByCaptureId({
      captureId: captureData?.id ?? NaN,
      lazy: true,
    }) as unknown as UseGetReturn<ExtractionJob[], void, void, unknown>;

  useEffect(() => {
    if (user && hasQaqcClaim) {
      refetchExtJobs();
    }
  }, [user, hasQaqcClaim]);

  useEffect(() => {
    if (extJobs && extJobs.length > 0) {
      const id = Math.max(...extJobs.map((j) => j.id));
      setQaqcExtId(id);
    }
  }, [extJobs]);

  const sidebarContent = () => {
    if (show3dLayers) {
      return (
        <CapturesViewerThreeDLayerControls
          openExpansionPanel={openExpansionPanel}
          eptId={eptId}
          captureId={captureData.id || 0}
          viewer={viewer}
        />
      );
    }

    return null;
  };

  return (
    <div className="h-full max-h-full flex w-full flex-col relative z-20 bg-white overflow-hidden">
      <div className="flex-shrink">
        <div>
          <AppBar
            logoPath={'ap-logo-color.png'}
            navigation={appBarProps.navigation}
            compact={true}
            firstLetterFirstName={appBarProps.firstLetterFirstName}
          />
        </div>
        <div className="relative shadow-sm px-4 py-4 border-b border-gray-100">
          <h1 className="flex font-bold justify-between leading-none text-xl truncate">
            <span
              title={captureData?.captureName || ''}
              className="flex-grow truncate cursor-pointer"
              onClick={() => {
                // copy name to clipboard

                navigator.clipboard.writeText(captureData?.captureName || '');
                toasts.add(toasts.prepare.valueCopied('capture name'));
              }}
            >
              {captureData?.captureName}
            </span>
            <FontAwesomeIcon
              icon={faCog}
              className="ml-2 cursor-pointer"
              onClick={() => {
                navigate(`${location.pathname}/details`);
              }}
              data-test-id="capture-details-cog"
            />
          </h1>
        </div>
        {(hasSelfServeAnalytics || (hasQaqcClaim && !!qaqcExtId)) && (
          <>
            <div className="w-full justify-center flex py-1 px-4 gap-2">
              {hasSelfServeAnalytics && (
                <PrimaryButton
                  onClicked={() => {
                    navigate(`${location.pathname}/models`);
                  }}
                  label={'3D Models'}
                  icon={<FontAwesomeIcon icon={faCube} />}
                />
              )}
              {hasSelfServeAnalytics && (
                <PrimaryButton
                  onClicked={() => {
                    navigate(`${location.pathname}/analytics`);
                  }}
                  label={'Analytics'}
                  icon={<FontAwesomeIcon icon={faBarChart} />}
                />
              )}
            </div>
            {hasQaqcClaim && !!qaqcExtId && (
              <div className="w-full justify-center flex py-1 px-4 gap-2">
                <PrimaryButton
                  onClicked={() => {
                    navigate(
                      `/ops/qaqc/${captureData?.id}/${eptId}/${qaqcExtId}`
                    );
                  }}
                  label={'QAQC'}
                  icon={<FontAwesomeIcon icon={faFlask} />}
                />
              </div>
            )}
          </>
        )}
      </div>
      <div className="flex-grow overflow-y-auto w-full h-full">
        {sidebarContent()}
      </div>
      <AddBetaViewerButton />
    </div>
  );
};

const AddBetaViewerButton = () => {
  const { showNewLayoutToggle, toggleNewLayout } = useNewLayout();

  return showNewLayoutToggle ? (
    <div className="w-full z-50 flex items-end shrink-0 grow-0 p-2">
      <Button.Small
        id="captures-layout-toggle"
        label="Switch to Beta Viewer"
        onClick={() => {
          toggleNewLayout();
        }}
      />
    </div>
  ) : null;
};

export enum EffectNames {
  REFRESH_CAPTURE_JOBS = 'REFRESH_CAPTURE_JOBS',
  POINT_CLOUD_MATERIAL_VISIBILITY_TOGGLED = 'POINT_CLOUD_MATERIAL_VISIBILITY_TOGGLED',
  POTREE_POINT_CLOUD_LOADED = 'POTREE_POINT_CLOUD_LOADED',
  GS_3D_POINT_CLOUD_LOADED = 'GS_3D_POINT_CLOUD_LOADED',
  SET_2D_POLYGON = 'SET_2D_POLYGON',
  REFETCH_CURRENT_CAPTURE = 'REFETCH_CURRENT_CAPTURE',
  REFETCH_ANALYTIC_REQUESTS = 'REFETCH_ANALYTIC_REQUESTS',
  CAPTURE_OBJECTS_LISTENER_CREATE = 'CAPTURE_OBJECTS_LISTENER_CREATE',
  CAPTURE_OBJECTS_LISTENER_UPDATE = 'CAPTURE_OBJECTS_LISTENER_UPDATE',
  CAPTURE_OBJECTS_LISTENER_DELETE = 'CAPTURE_OBJECTS_LISTENER_DELETE',
}

import { PerspectiveCamera, Scene, Vector2, Vector3 } from 'three';
import { Line2 } from 'three/examples/jsm/lines/Line2.js';
import { LineGeometry } from 'three/examples/jsm/lines/LineGeometry.js';
import { LineMaterial } from 'three/examples/jsm/lines/LineMaterial.js';

import {
  Annotation3dLines,
  Annotation3dPolygons,
  ColorsThreeD,
  HexColor,
  ICustomLine,
  ILine3d,
} from '@agerpoint/types';
import { AnnotationGroupName, addGeometryToLine } from '@agerpoint/utilities';

import { GeometryBase } from './geometry.base';

export class Line3d extends GeometryBase implements ILine3d {
  protected line: ICustomLine;
  public type: Annotation3dPolygons | Annotation3dLines;
  readonly uniqueId: string;
  protected position: Vector3[] = [];
  private visible: boolean;
  public vertices = [];

  constructor(
    scene: Scene,
    perspectiveCamera: PerspectiveCamera,
    isPotree: boolean,
    id: string,
    points: Vector3[],
    type: Annotation3dLines | Annotation3dPolygons,
    color: ColorsThreeD | HexColor = ColorsThreeD.Cyan, // Default color red
    visible: boolean = true
  ) {
    super(scene, perspectiveCamera, isPotree);
    this.uniqueId = id;
    this.type = type;
    this.line = {} as ICustomLine;
    this.position = points;
    this.visible = false;
    this.color = color;
    this.userData.originalColor = color;
    this.highlightColor = ColorsThreeD.Yellow;

    this.init();
  }

  get isVisible() {
    return this.line.visible;
  }

  dispose() {
    if (this.line.parent) {
      this.line.parent.remove(this.line);
    }
    this.line.geometry.dispose();
  }

  delete() {
    this.annoMgr.deleteCapObj(this.uniqueId);
    // this.notifyListeners();
  }

  disposeAndDelete() {
    this.dispose();
    this.delete();
  }

  hide() {
    if (!this.line) return;
    this.line.visible = false;
  }

  show() {
    if (!this.line) return;
    this.line.visible = true;
  }

  updateVisibility(isVisible: boolean) {
    if (!this.line) return;
    this.line.visible = isVisible;
  }

  updatePosition(points: Vector3[]) {
    if (!this.line) return;

    const group = this.scene.getObjectByName(AnnotationGroupName);
    if (!group) return;
    if (this.line) {
      group.remove(this.line);
      this.line?.geometry?.dispose();
    }
    this.line = this.createLine2(
      this.uniqueId,
      points,
      this.color,
      this.visible
    );
    // this.line.visible = true;
    this.position = points;
    if (group) {
      group.add(this.line);
    }
  }

  updateType(type: Annotation3dPolygons | Annotation3dLines) {
    this.type = type;
  }

  updateColor(color: ColorsThreeD | HexColor) {
    if (!this.line) return;
    if (typeof color === 'string') {
      this.line.material.color.set(color);
    } else if (color in ColorsThreeD) {
      this.line.material.color.set(color);
    } else {
      this.line.material.color.set('#000000');
    }
    this.color = color;
    this.notifyListeners();
  }

  getPosition() {
    return this.position;
  }

  zoomTo() {
    this.doZoom(this.line);
  }

  highlight() {
    this.userData.originalColor = this.color;
    this.userData.originalLineWidth = this.line.material.linewidth;
    this.updateColor(this.highlightColor);
    this.line.material.linewidth = 10;
    this.line.material.needsUpdate = true; // Ensure the material updates
  }

  unHighlight() {
    if (this?.userData?.originalColor) {
      this.updateColor(this.userData.originalColor);
    }
    if (this?.userData?.originalLineWidth) {
      this.line.material.linewidth = this.userData.originalLineWidth;
    }
  }

  calculateArea(): number {
    if (this.position?.length < 3) {
      return 0;
    }

    const refPoint = this.position[0];
    let totalArea = 0.0;

    for (let i = 1; i < this.position.length - 1; i++) {
      const vec1 = this.position[i].clone().sub(refPoint);
      const vec2 = this.position[i + 1].clone().sub(refPoint);

      const crossProd = vec1.cross(vec2);
      const triangleArea = crossProd.length() / 2.0;

      totalArea += triangleArea;
    }

    return totalArea;
  }

  calculateLength(): number {
    const segmentsLength = this.position.slice(1).map((vertex, index) => {
      const start = this.position[index];
      const end = vertex;
      return Math.sqrt(
        Math.pow(end.x - start.x, 2) +
          Math.pow(end.y - start.y, 2) +
          Math.pow(end.z - start.z, 2)
      );
    });

    return segmentsLength.reduce((acc, length) => acc + length, 0);
  }

  private createLine2(
    id: string,
    points: Vector3[],
    color: ColorsThreeD | HexColor,
    visible: boolean
  ): ICustomLine {
    const hasEnoughPoints = points.length > 1;
    const overRideVisibility = hasEnoughPoints ? true : false;
    const initialPositions =
      points.length > 1 ? points : [new Vector3(0, 0, 0), new Vector3(1, 1, 1)];

    const positions: number[] = [];
    initialPositions.forEach((point) => {
      positions.push(point.x, point.y, point.z);
    });

    const lineGeometry = new LineGeometry();
    const lineMaterial = new LineMaterial({
      color,
      dashSize: 5,
      gapSize: 2,
      linewidth: 4,
      resolution: new Vector2(1000, 1000),
    });

    const line: ICustomLine = new CustomLine(lineGeometry, lineMaterial);

    // crazy scale in potree requires a special line
    if (this.isPotree) {
      // if (points.length < 2) return;
      const lineWithGeom = addGeometryToLine(
        line,
        initialPositions
      ) as ICustomLine;
      lineWithGeom.visible = points.length > 0 ? true : false;
      lineWithGeom.uniqueId = id;
      lineWithGeom.customType = Annotation3dLines.LineMarker;
      return lineWithGeom;
    }
    line.geometry.setPositions(positions);
    line.uniqueId = id;
    line.customType = Annotation3dLines.LineMarker;
    line.visible = points.length > 0 && overRideVisibility ? true : false;
    return line;
  }

  private init() {
    this.updatePosition(this.position);
    this.line.customType = this.type as any;
    const group = this.scene.getObjectByName(AnnotationGroupName);
    if (group) {
      group.add(this.line);
    }
  }
}

class CustomLine extends Line2 implements ICustomLine {
  uniqueId = '';
  customType?: Annotation3dLines | Annotation3dPolygons;
  updatePosition: (pos: Vector3[]) => void;
  constructor(geometry: LineGeometry, material: LineMaterial) {
    super(geometry, material);
    this.updatePosition = (pos: Vector3[]) => {
      // Do nothing
    };
  }
}

import MapBrowserEvent from 'ol/MapBrowserEvent';
import { AnimationOptions, FitOptions, ViewOptions } from 'ol/View';
import { Extent } from 'ol/extent';
import { Geometry } from 'ol/geom';
import { Options as TileWMSOptions } from 'ol/source/TileWMS';
import { StyleLike } from 'ol/style/Style';

export interface ICloudOpenlayersMap {
  id: string;
  baseMap: ICloudOpenlayersBaseMap;
  baseMapOnClick?: (event: MapBrowserEvent<PointerEvent>) => void;
  disableInteraction?: boolean;
  initialView?: ViewOptions;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  layers?: ICloudOpenlayersLayer<any>[];
  overlays?: React.ReactNode;
  controller?: (controller: ICloudOpenlayersMapController) => void;
  persistView?: boolean;
}

export type ICloudOpenlayersBaseMap =
  | 'Aerial'
  | 'AerialWithLabelsOnDemand'
  | 'CanvasLight';

export type ICloudOpenlayersLayer<T> =
  | ICloudOpenlayersFeatureLayer<T>
  | ICloudOpenlayersClusterFeatureLayer<T>
  | ICloudOpenlayersWMSLayer;

export interface ICloudOpenlayersFeatureLayer<T> {
  featureLayerId: string;
  data: T[];
  loading?: boolean;
  featureBuilder: (data: T) => ICloudOpenlayersFeature | undefined;
  featureOnClick?: (id: string, event: MapBrowserEvent<PointerEvent>) => void;
  featureOnDragBox?: (
    ids: string[],
    event: MapBrowserEvent<PointerEvent>
  ) => void;
  maxZoom?: number;
  minZoom?: number;
  styles: { [key: string]: StyleLike };
}

export interface ICloudOpenlayersClusterFeatureLayer<T> {
  clusterFeatureLayerId: string;
  data: T[];
  loading?: boolean;
  featureBuilder: (data: T) => ICloudOpenlayersFeature | undefined;
  clusterOnClick?: (
    ids: string[],
    event: MapBrowserEvent<PointerEvent>
  ) => void;
  cluterDistance?: number;
  clusterMinDistance?: number;
  maxZoom?: number;
  minZoom?: number;
  styles: { [key: string]: StyleLike };
}

export interface ICloudOpenlayersWMSLayer extends TileWMSOptions {
  wmsLayerId: string;
  extent: Extent;
}

export interface ICloudOpenlayersFeature {
  geometry: Geometry;
  id: string;
  style: string;
}

export const isFeatureLayer = <T,>(
  layer: ICloudOpenlayersLayer<T>
): layer is ICloudOpenlayersFeatureLayer<T> => 'featureLayerId' in layer;

export const isClusterFeatureLayer = <T,>(
  layer: ICloudOpenlayersLayer<T>
): layer is ICloudOpenlayersClusterFeatureLayer<T> =>
  'clusterFeatureLayerId' in layer;

export const isWMSLayer = (
  layer: ICloudOpenlayersLayer<unknown>
): layer is ICloudOpenlayersWMSLayer => 'wmsLayerId' in layer;

export interface ICloudOpenlayersMapController {
  info: {
    mapInitialized: boolean;
  };
  zoomMapToLonLat?: (options?: AnimationOptions) => void;
  zoomMapToExtent?: (extent: Extent, options?: FitOptions) => void;
  resetView?: (options?: AnimationOptions) => void;
}

import {
  DraggableProvided,
  DraggableProvidedDragHandleProps,
  DraggableProvidedDraggableProps,
  DraggableStateSnapshot,
} from 'react-beautiful-dnd';

import {
  Capture,
  DefaultLayerIcon,
  FileDownloadIcon,
  GeometryCollection,
  HandleIcon,
  PointCloudIcon,
  RasterIcon,
  SvgElement,
  VectorIcon,
} from '@agerpoint/component';
import { LibraryLayer } from '@agerpoint/types';

import { getLayersUniqueId } from '../library-expansion/utils';

interface LibraryItemProps {
  layer: LibraryLayer;
  index: number;
  // react-beautiful-dnd defines innerRef as any, so we do as well
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  innerRef?: any;
  draggableProps?: DraggableProvidedDraggableProps;
  dragHandleProps?: DraggableProvidedDragHandleProps;
  isDragging?: boolean;
  provided?: DraggableProvided;
  snapshot?: DraggableStateSnapshot;
  toggleSelection?: (layerId: string) => void;
  toggleSelectionInGroup?: (layerId: string) => void;
  multiSelectTo?: (layerId: string) => void;
  isSelected?: boolean;
  isGhosting?: boolean;
  isDragged?: boolean;
  hasMultiDNDPermission?: boolean;
  selectedItemsQuantity?: number;
}

export function LibraryItem({
  layer,
  index,
  innerRef,
  draggableProps = undefined,
  dragHandleProps = undefined,
  isDragging,
  provided,
  snapshot,
  toggleSelection,
  toggleSelectionInGroup,
  multiSelectTo,
  isSelected = false,
  isGhosting = false,
  isDragged = false,
  hasMultiDNDPermission = false,
  selectedItemsQuantity,
}: LibraryItemProps) {
  // Determines if the multiSelect key was used
  const wasMultiSelectKeyUsed = (
    event: React.MouseEvent | React.KeyboardEvent
  ) => event.shiftKey;

  // Determines if the platform specific toggle selection in group key was used
  const wasToggleInSelectionGroupKeyUsed = (
    event: React.MouseEvent | React.KeyboardEvent
  ) => {
    // Fix deprecation
    const platform = navigator?.platform || navigator?.userAgent;
    const isUsingWindows = platform.indexOf('Win') >= 0;
    return isUsingWindows ? event.ctrlKey : event.metaKey;
  };

  const performAction = (event: React.MouseEvent | React.KeyboardEvent) => {
    if (!hasMultiDNDPermission) {
      return;
    }
    if (wasToggleInSelectionGroupKeyUsed(event)) {
      toggleSelectionInGroup?.(getLayersUniqueId(layer));
      return;
    }

    if (wasMultiSelectKeyUsed(event)) {
      multiSelectTo?.(getLayersUniqueId(layer));
      return;
    }

    toggleSelection?.(getLayersUniqueId(layer));
  };

  const onKeyDown = (
    event: React.KeyboardEvent,
    snapshot?: DraggableStateSnapshot
  ) => {
    if (event.defaultPrevented) {
      return;
    }

    if (snapshot?.isDragging ?? true) {
      return;
    }

    if (event.key !== 'Enter') {
      return;
    }

    // we are using the event for selection
    event.preventDefault();

    performAction(event);
  };

  // Using onClick as it will be correctly
  // preventing if there was a drag
  const onClick = (event: React.MouseEvent) => {
    if (event.defaultPrevented) {
      return;
    }

    if (event.button !== 0) {
      return;
    }

    // marking the event as used
    event.preventDefault();

    performAction(event);
  };

  const onTouchEnd = (event: React.TouchEvent) => {
    if (event.defaultPrevented) {
      return;
    }

    // marking the event as used
    // we would also need to add some extra logic to prevent the click
    // if this element was an anchor
    event.preventDefault();
    toggleSelectionInGroup?.(getLayersUniqueId(layer));
  };

  return (
    <div
      onClick={onClick}
      onTouchEnd={onTouchEnd}
      onKeyDown={(event: React.KeyboardEvent) => onKeyDown(event, snapshot)}
    >
      <LibraryRow
        innerRef={innerRef}
        dragHandleProps={dragHandleProps}
        draggableProps={draggableProps}
        layerType={layer.layerTypeId}
        layerName={layer.name}
        isGhosting={isGhosting}
        isSelected={isSelected}
        isDragged={isDragged}
        selectedItemsQuantity={selectedItemsQuantity}
      />
      {isDragging && (
        <LibraryRow layerType={layer.layerTypeId} layerName={layer.name} />
      )}
    </div>
  );
}

interface LibraryRowProps {
  // react-beautiful-dnd defines innerRef as any, so we do as well
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  innerRef?: any;
  draggableProps?: DraggableProvidedDraggableProps;
  dragHandleProps?: DraggableProvidedDragHandleProps;
  isDragged?: boolean;
  layerType?: number;
  layerName?: string;
  isSelected?: boolean;
  isGhosting?: boolean;
  selectedItemsQuantity?: number;
}

function LibraryRow({
  innerRef,
  dragHandleProps,
  draggableProps,
  layerType,
  layerName,
  isSelected = false,
  isGhosting = false,
  isDragged = false,
  selectedItemsQuantity,
}: LibraryRowProps) {
  return (
    <div
      title={layerName}
      ref={innerRef}
      className={`
  group
  flex
  flex-row
  text-sm
  px-2
  py-1
  items-center
  transition-colors
  duration-300
  relative
  ${isDragged ? 'bg-white shadow-md border-gray-200 border' : ' '}
  ${!isSelected && !isGhosting ? 'hover:bg-gray-50' : ' '}
  ${isSelected ? 'bg-blue-100 hover:bg-blue-200' : ' '}
  ${isGhosting ? 'opacity-50' : ' '}
  `}
      {...dragHandleProps}
      {...draggableProps}
    >
      <SvgElement
        type="HandleIcon"
        className={`flex-shrink-0 text-gray-400 ${
          innerRef ? 'group-hover:visible invisible' : 'invisible'
        }`}
      />

      <div className="ml-3 flex-shrink-0">
        <LayerTypeIcon layerId={layerType} />
      </div>

      <div className="ml-2 truncate overflow-hidden flex-grow">{layerName}</div>
      {selectedItemsQuantity && selectedItemsQuantity >= 1 && (
        <div
          className={`h-6 w-6 rounded-full -top-3 -right-3 absolute
            flex justify-center items-center bg-blue-200 shadow`}
        >
          <p className="">{`+${selectedItemsQuantity}`}</p>
        </div>
      )}
    </div>
  );
}

interface LayerTypeIconProps {
  layerId: number | undefined;
}

export function LayerTypeIcon({ layerId }: LayerTypeIconProps) {
  switch (layerId) {
    case 9:
      return <Capture />;
    case 8: //GeometryCollection
      return <GeometryCollection />;
    case 1: //Collections
      return <VectorIcon />;
    case 2: //Blocks
      return <VectorIcon />;
    case 3: //Farm
      return <VectorIcon />;
    case 6: //imagemosaics
      return <RasterIcon />;
    case 5: //eptpointclouds
      return <PointCloudIcon />;
    case 7: //file
      return <FileDownloadIcon />;
    default:
      //Includes: string
      return <DefaultLayerIcon />;
  }
}

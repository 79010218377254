import { Dispatch, SetStateAction, createContext, useContext } from 'react';

import { APIModels } from '@agerpoint/api';
import { IAnnotations3dGeometry, IGsCloudToolState } from '@agerpoint/types';

import { IThreeDViewerControllerGroup } from '../three-d-wrapper';

export interface CapturesViewerContextProps {
  viewerController?: IThreeDViewerControllerGroup;
  setViewerController?: (controller: IThreeDViewerControllerGroup) => void;
  selectedCaptureJob?: APIModels.CaptureJob | undefined | null;
  setSelectedCaptureJob?: (
    captureJob: APIModels.CaptureJob | undefined | null
  ) => void;
  selectedCaptureImage?: APIModels.CaptureImageTag;
  setSelectedCaptureImage?: (
    captureImage: APIModels.CaptureImageTag | undefined
  ) => void;
  selectedCaptureVideo?: APIModels.CaptureVideo;
  setSelectedCaptureVideo?: (
    captureVideo: APIModels.CaptureVideo | undefined
  ) => void;
  selectedCaptureAudio?: APIModels.CaptureAudio;
  setSelectedCaptureAudio?: (
    captureAudio: APIModels.CaptureAudio | undefined
  ) => void;
  selectedAnalyticRequest?: APIModels.AnalyticRequest;
  setSelectedAnalyticRequest?: (
    analyticRequest: APIModels.AnalyticRequest | undefined
  ) => void;
  selectedCaptureExtractionJobId?: number;
  setSelectedCaptureExtractionJobId?: (id: number | undefined) => void;
  annotations3dGeometry?: IAnnotations3dGeometry | undefined;
  setAnnotations3dGeometry?: Dispatch<
    SetStateAction<IAnnotations3dGeometry | undefined>
  >;
  annotationCaptureObjects?: APIModels.CaptureObject[];
  setAnnotationCaptureObjects?: Dispatch<
    SetStateAction<APIModels.CaptureObject[]>
  >;
  gsCloudToolState?: IGsCloudToolState;
  setGsCloudToolState?: Dispatch<SetStateAction<IGsCloudToolState | undefined>>;
}

export const CapturesViewerContext = createContext<CapturesViewerContextProps>(
  {}
);

export const useCapturesViewerContext = () => useContext(CapturesViewerContext);

/**
 * Generated by orval v6.28.2 🍺
 * Do not edit manually.
 * GeminiAPI
 * OpenAPI spec version: v1
 */
import { useMutation, useQuery } from '@tanstack/react-query';
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import type { JobTypeCategory } from '.././models';
import { AxiosInstance } from '../../axios-instance';
import type { ErrorType, BodyType } from '../../axios-instance';

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

export const getJobTypeCategory = (
  options?: SecondParameter<typeof AxiosInstance>,
  signal?: AbortSignal,
) => {
  return AxiosInstance<JobTypeCategory[]>(
    { url: `/api/JobTypeCategories`, method: 'GET', signal },
    options,
  );
};

export const getGetJobTypeCategoryQueryKey = () => {
  return [`/api/JobTypeCategories`] as const;
};

export const getGetJobTypeCategoryQueryOptions = <
  TData = Awaited<ReturnType<typeof getJobTypeCategory>>,
  TError = ErrorType<void>,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getJobTypeCategory>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetJobTypeCategoryQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getJobTypeCategory>>
  > = ({ signal }) => getJobTypeCategory(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getJobTypeCategory>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetJobTypeCategoryQueryResult = NonNullable<
  Awaited<ReturnType<typeof getJobTypeCategory>>
>;
export type GetJobTypeCategoryQueryError = ErrorType<void>;

export const useGetJobTypeCategory = <
  TData = Awaited<ReturnType<typeof getJobTypeCategory>>,
  TError = ErrorType<void>,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getJobTypeCategory>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetJobTypeCategoryQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const postJobCategoryType = (
  jobTypeCategory: BodyType<JobTypeCategory>,
  options?: SecondParameter<typeof AxiosInstance>,
) => {
  return AxiosInstance<JobTypeCategory>(
    {
      url: `/api/JobTypeCategories`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: jobTypeCategory,
    },
    options,
  );
};

export const getPostJobCategoryTypeMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postJobCategoryType>>,
    TError,
    { data: BodyType<JobTypeCategory> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postJobCategoryType>>,
  TError,
  { data: BodyType<JobTypeCategory> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postJobCategoryType>>,
    { data: BodyType<JobTypeCategory> }
  > = (props) => {
    const { data } = props ?? {};

    return postJobCategoryType(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostJobCategoryTypeMutationResult = NonNullable<
  Awaited<ReturnType<typeof postJobCategoryType>>
>;
export type PostJobCategoryTypeMutationBody = BodyType<JobTypeCategory>;
export type PostJobCategoryTypeMutationError = ErrorType<void>;

export const usePostJobCategoryType = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postJobCategoryType>>,
    TError,
    { data: BodyType<JobTypeCategory> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof postJobCategoryType>>,
  TError,
  { data: BodyType<JobTypeCategory> },
  TContext
> => {
  const mutationOptions = getPostJobCategoryTypeMutationOptions(options);

  return useMutation(mutationOptions);
};
export const getJobTypeCategoryById = (
  id: number,
  options?: SecondParameter<typeof AxiosInstance>,
  signal?: AbortSignal,
) => {
  return AxiosInstance<JobTypeCategory>(
    {
      url: `/api/JobTypeCategories/${encodeURIComponent(String(id))}`,
      method: 'GET',
      signal,
    },
    options,
  );
};

export const getGetJobTypeCategoryByIdQueryKey = (id: number) => {
  return [`/api/JobTypeCategories/${id}`] as const;
};

export const getGetJobTypeCategoryByIdQueryOptions = <
  TData = Awaited<ReturnType<typeof getJobTypeCategoryById>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getJobTypeCategoryById>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetJobTypeCategoryByIdQueryKey(id);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getJobTypeCategoryById>>
  > = ({ signal }) => getJobTypeCategoryById(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getJobTypeCategoryById>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetJobTypeCategoryByIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof getJobTypeCategoryById>>
>;
export type GetJobTypeCategoryByIdQueryError = ErrorType<void>;

export const useGetJobTypeCategoryById = <
  TData = Awaited<ReturnType<typeof getJobTypeCategoryById>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getJobTypeCategoryById>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetJobTypeCategoryByIdQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const putJobTypeCategoryById = (
  id: number,
  jobTypeCategory: BodyType<JobTypeCategory>,
  options?: SecondParameter<typeof AxiosInstance>,
) => {
  return AxiosInstance<JobTypeCategory>(
    {
      url: `/api/JobTypeCategories/${encodeURIComponent(String(id))}`,
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      data: jobTypeCategory,
    },
    options,
  );
};

export const getPutJobTypeCategoryByIdMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putJobTypeCategoryById>>,
    TError,
    { id: number; data: BodyType<JobTypeCategory> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof putJobTypeCategoryById>>,
  TError,
  { id: number; data: BodyType<JobTypeCategory> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof putJobTypeCategoryById>>,
    { id: number; data: BodyType<JobTypeCategory> }
  > = (props) => {
    const { id, data } = props ?? {};

    return putJobTypeCategoryById(id, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PutJobTypeCategoryByIdMutationResult = NonNullable<
  Awaited<ReturnType<typeof putJobTypeCategoryById>>
>;
export type PutJobTypeCategoryByIdMutationBody = BodyType<JobTypeCategory>;
export type PutJobTypeCategoryByIdMutationError = ErrorType<void>;

export const usePutJobTypeCategoryById = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putJobTypeCategoryById>>,
    TError,
    { id: number; data: BodyType<JobTypeCategory> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof putJobTypeCategoryById>>,
  TError,
  { id: number; data: BodyType<JobTypeCategory> },
  TContext
> => {
  const mutationOptions = getPutJobTypeCategoryByIdMutationOptions(options);

  return useMutation(mutationOptions);
};

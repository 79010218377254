/**
 * Generated by orval v6.28.2 🍺
 * Do not edit manually.
 * GeminiAPI
 * OpenAPI spec version: v1
 */
import { useMutation, useQuery } from '@tanstack/react-query';
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import type { CaptureImage } from '.././models';
import { AxiosInstance } from '../../axios-instance';
import type { ErrorType, BodyType } from '../../axios-instance';

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

export const getCaptureImages = (
  options?: SecondParameter<typeof AxiosInstance>,
  signal?: AbortSignal,
) => {
  return AxiosInstance<CaptureImage[]>(
    { url: `/api/CaptureImages`, method: 'GET', signal },
    options,
  );
};

export const getGetCaptureImagesQueryKey = () => {
  return [`/api/CaptureImages`] as const;
};

export const getGetCaptureImagesQueryOptions = <
  TData = Awaited<ReturnType<typeof getCaptureImages>>,
  TError = ErrorType<void>,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof getCaptureImages>>, TError, TData>
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetCaptureImagesQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getCaptureImages>>
  > = ({ signal }) => getCaptureImages(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getCaptureImages>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetCaptureImagesQueryResult = NonNullable<
  Awaited<ReturnType<typeof getCaptureImages>>
>;
export type GetCaptureImagesQueryError = ErrorType<void>;

export const useGetCaptureImages = <
  TData = Awaited<ReturnType<typeof getCaptureImages>>,
  TError = ErrorType<void>,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof getCaptureImages>>, TError, TData>
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetCaptureImagesQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getCaptureImageById = (
  id: number,
  options?: SecondParameter<typeof AxiosInstance>,
  signal?: AbortSignal,
) => {
  return AxiosInstance<CaptureImage>(
    {
      url: `/api/CaptureImages/${encodeURIComponent(String(id))}`,
      method: 'GET',
      signal,
    },
    options,
  );
};

export const getGetCaptureImageByIdQueryKey = (id: number) => {
  return [`/api/CaptureImages/${id}`] as const;
};

export const getGetCaptureImageByIdQueryOptions = <
  TData = Awaited<ReturnType<typeof getCaptureImageById>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getCaptureImageById>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetCaptureImageByIdQueryKey(id);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getCaptureImageById>>
  > = ({ signal }) => getCaptureImageById(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getCaptureImageById>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetCaptureImageByIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof getCaptureImageById>>
>;
export type GetCaptureImageByIdQueryError = ErrorType<void>;

export const useGetCaptureImageById = <
  TData = Awaited<ReturnType<typeof getCaptureImageById>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getCaptureImageById>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetCaptureImageByIdQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const putCaptureImageById = (
  id: number,
  captureImage: BodyType<CaptureImage>,
  options?: SecondParameter<typeof AxiosInstance>,
) => {
  return AxiosInstance<CaptureImage>(
    {
      url: `/api/CaptureImages/${encodeURIComponent(String(id))}`,
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      data: captureImage,
    },
    options,
  );
};

export const getPutCaptureImageByIdMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putCaptureImageById>>,
    TError,
    { id: number; data: BodyType<CaptureImage> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof putCaptureImageById>>,
  TError,
  { id: number; data: BodyType<CaptureImage> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof putCaptureImageById>>,
    { id: number; data: BodyType<CaptureImage> }
  > = (props) => {
    const { id, data } = props ?? {};

    return putCaptureImageById(id, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PutCaptureImageByIdMutationResult = NonNullable<
  Awaited<ReturnType<typeof putCaptureImageById>>
>;
export type PutCaptureImageByIdMutationBody = BodyType<CaptureImage>;
export type PutCaptureImageByIdMutationError = ErrorType<void>;

export const usePutCaptureImageById = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putCaptureImageById>>,
    TError,
    { id: number; data: BodyType<CaptureImage> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof putCaptureImageById>>,
  TError,
  { id: number; data: BodyType<CaptureImage> },
  TContext
> => {
  const mutationOptions = getPutCaptureImageByIdMutationOptions(options);

  return useMutation(mutationOptions);
};
export const getCaptureImageFileById = (
  id: number,
  options?: SecondParameter<typeof AxiosInstance>,
  signal?: AbortSignal,
) => {
  return AxiosInstance<Blob>(
    {
      url: `/api/CaptureImages/${encodeURIComponent(String(id))}/File`,
      method: 'GET',
      responseType: 'blob',
      signal,
    },
    options,
  );
};

export const getGetCaptureImageFileByIdQueryKey = (id: number) => {
  return [`/api/CaptureImages/${id}/File`] as const;
};

export const getGetCaptureImageFileByIdQueryOptions = <
  TData = Awaited<ReturnType<typeof getCaptureImageFileById>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getCaptureImageFileById>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetCaptureImageFileByIdQueryKey(id);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getCaptureImageFileById>>
  > = ({ signal }) => getCaptureImageFileById(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getCaptureImageFileById>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetCaptureImageFileByIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof getCaptureImageFileById>>
>;
export type GetCaptureImageFileByIdQueryError = ErrorType<void>;

export const useGetCaptureImageFileById = <
  TData = Awaited<ReturnType<typeof getCaptureImageFileById>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getCaptureImageFileById>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetCaptureImageFileByIdQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getCaptureImageVerticalThumbnailById = (
  id: number,
  fit: string,
  size: string,
  horizontalAlign: string,
  verticalAlign: string,
  crop: string,
  filters: string,
  options?: SecondParameter<typeof AxiosInstance>,
  signal?: AbortSignal,
) => {
  return AxiosInstance<Blob>(
    {
      url: `/api/CaptureImages/${encodeURIComponent(String(id))}/VerticalThumbnail/${encodeURIComponent(String(fit))}/${encodeURIComponent(String(size))}/${encodeURIComponent(String(horizontalAlign))}/${encodeURIComponent(String(verticalAlign))}/${encodeURIComponent(String(crop))}/${encodeURIComponent(String(filters))}`,
      method: 'GET',
      responseType: 'blob',
      signal,
    },
    options,
  );
};

export const getGetCaptureImageVerticalThumbnailByIdQueryKey = (
  id: number,
  fit: string,
  size: string,
  horizontalAlign: string,
  verticalAlign: string,
  crop: string,
  filters: string,
) => {
  return [
    `/api/CaptureImages/${id}/VerticalThumbnail/${fit}/${size}/${horizontalAlign}/${verticalAlign}/${crop}/${filters}`,
  ] as const;
};

export const getGetCaptureImageVerticalThumbnailByIdQueryOptions = <
  TData = Awaited<ReturnType<typeof getCaptureImageVerticalThumbnailById>>,
  TError = ErrorType<void>,
>(
  id: number,
  fit: string,
  size: string,
  horizontalAlign: string,
  verticalAlign: string,
  crop: string,
  filters: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getCaptureImageVerticalThumbnailById>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetCaptureImageVerticalThumbnailByIdQueryKey(
      id,
      fit,
      size,
      horizontalAlign,
      verticalAlign,
      crop,
      filters,
    );

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getCaptureImageVerticalThumbnailById>>
  > = ({ signal }) =>
    getCaptureImageVerticalThumbnailById(
      id,
      fit,
      size,
      horizontalAlign,
      verticalAlign,
      crop,
      filters,
      requestOptions,
      signal,
    );

  return {
    queryKey,
    queryFn,
    enabled: !!(
      id &&
      fit &&
      size &&
      horizontalAlign &&
      verticalAlign &&
      crop &&
      filters
    ),
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getCaptureImageVerticalThumbnailById>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetCaptureImageVerticalThumbnailByIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof getCaptureImageVerticalThumbnailById>>
>;
export type GetCaptureImageVerticalThumbnailByIdQueryError = ErrorType<void>;

export const useGetCaptureImageVerticalThumbnailById = <
  TData = Awaited<ReturnType<typeof getCaptureImageVerticalThumbnailById>>,
  TError = ErrorType<void>,
>(
  id: number,
  fit: string,
  size: string,
  horizontalAlign: string,
  verticalAlign: string,
  crop: string,
  filters: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getCaptureImageVerticalThumbnailById>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetCaptureImageVerticalThumbnailByIdQueryOptions(
    id,
    fit,
    size,
    horizontalAlign,
    verticalAlign,
    crop,
    filters,
    options,
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getCaptureImageHorizontalThumbnailById = (
  id: number,
  fit: string,
  size: string,
  horizontalAlign: string,
  verticalAlign: string,
  crop: string,
  filters: string,
  options?: SecondParameter<typeof AxiosInstance>,
  signal?: AbortSignal,
) => {
  return AxiosInstance<Blob>(
    {
      url: `/api/CaptureImages/${encodeURIComponent(String(id))}/HorizontalThumbnail/${encodeURIComponent(String(fit))}/${encodeURIComponent(String(size))}/${encodeURIComponent(String(horizontalAlign))}/${encodeURIComponent(String(verticalAlign))}/${encodeURIComponent(String(crop))}/${encodeURIComponent(String(filters))}`,
      method: 'GET',
      responseType: 'blob',
      signal,
    },
    options,
  );
};

export const getGetCaptureImageHorizontalThumbnailByIdQueryKey = (
  id: number,
  fit: string,
  size: string,
  horizontalAlign: string,
  verticalAlign: string,
  crop: string,
  filters: string,
) => {
  return [
    `/api/CaptureImages/${id}/HorizontalThumbnail/${fit}/${size}/${horizontalAlign}/${verticalAlign}/${crop}/${filters}`,
  ] as const;
};

export const getGetCaptureImageHorizontalThumbnailByIdQueryOptions = <
  TData = Awaited<ReturnType<typeof getCaptureImageHorizontalThumbnailById>>,
  TError = ErrorType<void>,
>(
  id: number,
  fit: string,
  size: string,
  horizontalAlign: string,
  verticalAlign: string,
  crop: string,
  filters: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getCaptureImageHorizontalThumbnailById>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetCaptureImageHorizontalThumbnailByIdQueryKey(
      id,
      fit,
      size,
      horizontalAlign,
      verticalAlign,
      crop,
      filters,
    );

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getCaptureImageHorizontalThumbnailById>>
  > = ({ signal }) =>
    getCaptureImageHorizontalThumbnailById(
      id,
      fit,
      size,
      horizontalAlign,
      verticalAlign,
      crop,
      filters,
      requestOptions,
      signal,
    );

  return {
    queryKey,
    queryFn,
    enabled: !!(
      id &&
      fit &&
      size &&
      horizontalAlign &&
      verticalAlign &&
      crop &&
      filters
    ),
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getCaptureImageHorizontalThumbnailById>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetCaptureImageHorizontalThumbnailByIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof getCaptureImageHorizontalThumbnailById>>
>;
export type GetCaptureImageHorizontalThumbnailByIdQueryError = ErrorType<void>;

export const useGetCaptureImageHorizontalThumbnailById = <
  TData = Awaited<ReturnType<typeof getCaptureImageHorizontalThumbnailById>>,
  TError = ErrorType<void>,
>(
  id: number,
  fit: string,
  size: string,
  horizontalAlign: string,
  verticalAlign: string,
  crop: string,
  filters: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getCaptureImageHorizontalThumbnailById>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetCaptureImageHorizontalThumbnailByIdQueryOptions(
    id,
    fit,
    size,
    horizontalAlign,
    verticalAlign,
    crop,
    filters,
    options,
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getCaptureImageThumbnailById2 = (
  id: number,
  fit: string,
  size: string,
  horizontalAlign: string,
  verticalAlign: string,
  crop: string,
  filters: string,
  options?: SecondParameter<typeof AxiosInstance>,
  signal?: AbortSignal,
) => {
  return AxiosInstance<Blob>(
    {
      url: `/api/CaptureImages/${encodeURIComponent(String(id))}/Thumbnail/${encodeURIComponent(String(fit))}/${encodeURIComponent(String(size))}/${encodeURIComponent(String(horizontalAlign))}/${encodeURIComponent(String(verticalAlign))}/${encodeURIComponent(String(crop))}/${encodeURIComponent(String(filters))}`,
      method: 'GET',
      responseType: 'blob',
      signal,
    },
    options,
  );
};

export const getGetCaptureImageThumbnailById2QueryKey = (
  id: number,
  fit: string,
  size: string,
  horizontalAlign: string,
  verticalAlign: string,
  crop: string,
  filters: string,
) => {
  return [
    `/api/CaptureImages/${id}/Thumbnail/${fit}/${size}/${horizontalAlign}/${verticalAlign}/${crop}/${filters}`,
  ] as const;
};

export const getGetCaptureImageThumbnailById2QueryOptions = <
  TData = Awaited<ReturnType<typeof getCaptureImageThumbnailById2>>,
  TError = ErrorType<void>,
>(
  id: number,
  fit: string,
  size: string,
  horizontalAlign: string,
  verticalAlign: string,
  crop: string,
  filters: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getCaptureImageThumbnailById2>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetCaptureImageThumbnailById2QueryKey(
      id,
      fit,
      size,
      horizontalAlign,
      verticalAlign,
      crop,
      filters,
    );

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getCaptureImageThumbnailById2>>
  > = ({ signal }) =>
    getCaptureImageThumbnailById2(
      id,
      fit,
      size,
      horizontalAlign,
      verticalAlign,
      crop,
      filters,
      requestOptions,
      signal,
    );

  return {
    queryKey,
    queryFn,
    enabled: !!(
      id &&
      fit &&
      size &&
      horizontalAlign &&
      verticalAlign &&
      crop &&
      filters
    ),
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getCaptureImageThumbnailById2>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetCaptureImageThumbnailById2QueryResult = NonNullable<
  Awaited<ReturnType<typeof getCaptureImageThumbnailById2>>
>;
export type GetCaptureImageThumbnailById2QueryError = ErrorType<void>;

export const useGetCaptureImageThumbnailById2 = <
  TData = Awaited<ReturnType<typeof getCaptureImageThumbnailById2>>,
  TError = ErrorType<void>,
>(
  id: number,
  fit: string,
  size: string,
  horizontalAlign: string,
  verticalAlign: string,
  crop: string,
  filters: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getCaptureImageThumbnailById2>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetCaptureImageThumbnailById2QueryOptions(
    id,
    fit,
    size,
    horizontalAlign,
    verticalAlign,
    crop,
    filters,
    options,
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getCaptureImageThumbnailById = (
  id: number,
  getCaptureImageThumbnailByIdBody: BodyType<string[]>,
  options?: SecondParameter<typeof AxiosInstance>,
) => {
  return AxiosInstance<Blob>(
    {
      url: `/api/CaptureImages/${encodeURIComponent(String(id))}/Thumbnail`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: getCaptureImageThumbnailByIdBody,
      responseType: 'blob',
    },
    options,
  );
};

export const getGetCaptureImageThumbnailByIdMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof getCaptureImageThumbnailById>>,
    TError,
    { id: number; data: BodyType<string[]> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof getCaptureImageThumbnailById>>,
  TError,
  { id: number; data: BodyType<string[]> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof getCaptureImageThumbnailById>>,
    { id: number; data: BodyType<string[]> }
  > = (props) => {
    const { id, data } = props ?? {};

    return getCaptureImageThumbnailById(id, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type GetCaptureImageThumbnailByIdMutationResult = NonNullable<
  Awaited<ReturnType<typeof getCaptureImageThumbnailById>>
>;
export type GetCaptureImageThumbnailByIdMutationBody = BodyType<string[]>;
export type GetCaptureImageThumbnailByIdMutationError = ErrorType<void>;

export const useGetCaptureImageThumbnailById = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof getCaptureImageThumbnailById>>,
    TError,
    { id: number; data: BodyType<string[]> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof getCaptureImageThumbnailById>>,
  TError,
  { id: number; data: BodyType<string[]> },
  TContext
> => {
  const mutationOptions =
    getGetCaptureImageThumbnailByIdMutationOptions(options);

  return useMutation(mutationOptions);
};
export const getCaptureImagesByCaptureId = (
  captureId: number,
  options?: SecondParameter<typeof AxiosInstance>,
  signal?: AbortSignal,
) => {
  return AxiosInstance<CaptureImage[]>(
    {
      url: `/api/CaptureImages/CaptureId/${encodeURIComponent(String(captureId))}`,
      method: 'GET',
      signal,
    },
    options,
  );
};

export const getGetCaptureImagesByCaptureIdQueryKey = (captureId: number) => {
  return [`/api/CaptureImages/CaptureId/${captureId}`] as const;
};

export const getGetCaptureImagesByCaptureIdQueryOptions = <
  TData = Awaited<ReturnType<typeof getCaptureImagesByCaptureId>>,
  TError = ErrorType<void>,
>(
  captureId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getCaptureImagesByCaptureId>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetCaptureImagesByCaptureIdQueryKey(captureId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getCaptureImagesByCaptureId>>
  > = ({ signal }) =>
    getCaptureImagesByCaptureId(captureId, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!captureId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getCaptureImagesByCaptureId>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetCaptureImagesByCaptureIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof getCaptureImagesByCaptureId>>
>;
export type GetCaptureImagesByCaptureIdQueryError = ErrorType<void>;

export const useGetCaptureImagesByCaptureId = <
  TData = Awaited<ReturnType<typeof getCaptureImagesByCaptureId>>,
  TError = ErrorType<void>,
>(
  captureId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getCaptureImagesByCaptureId>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetCaptureImagesByCaptureIdQueryOptions(
    captureId,
    options,
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getCaptureImagesByCaptureJobId = (
  captureJobId: number,
  options?: SecondParameter<typeof AxiosInstance>,
  signal?: AbortSignal,
) => {
  return AxiosInstance<CaptureImage[]>(
    {
      url: `/api/CaptureImages/CaptureJobId/${encodeURIComponent(String(captureJobId))}`,
      method: 'GET',
      signal,
    },
    options,
  );
};

export const getGetCaptureImagesByCaptureJobIdQueryKey = (
  captureJobId: number,
) => {
  return [`/api/CaptureImages/CaptureJobId/${captureJobId}`] as const;
};

export const getGetCaptureImagesByCaptureJobIdQueryOptions = <
  TData = Awaited<ReturnType<typeof getCaptureImagesByCaptureJobId>>,
  TError = ErrorType<void>,
>(
  captureJobId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getCaptureImagesByCaptureJobId>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetCaptureImagesByCaptureJobIdQueryKey(captureJobId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getCaptureImagesByCaptureJobId>>
  > = ({ signal }) =>
    getCaptureImagesByCaptureJobId(captureJobId, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!captureJobId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getCaptureImagesByCaptureJobId>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetCaptureImagesByCaptureJobIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof getCaptureImagesByCaptureJobId>>
>;
export type GetCaptureImagesByCaptureJobIdQueryError = ErrorType<void>;

export const useGetCaptureImagesByCaptureJobId = <
  TData = Awaited<ReturnType<typeof getCaptureImagesByCaptureJobId>>,
  TError = ErrorType<void>,
>(
  captureJobId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getCaptureImagesByCaptureJobId>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetCaptureImagesByCaptureJobIdQueryOptions(
    captureJobId,
    options,
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getPagedCaptureImagesByCaptureJobId = (
  captureJobId: number,
  skip: number,
  take: number,
  options?: SecondParameter<typeof AxiosInstance>,
  signal?: AbortSignal,
) => {
  return AxiosInstance<CaptureImage[]>(
    {
      url: `/api/CaptureImages/CaptureJobId/${encodeURIComponent(String(captureJobId))}/${encodeURIComponent(String(skip))}/${encodeURIComponent(String(take))}`,
      method: 'GET',
      signal,
    },
    options,
  );
};

export const getGetPagedCaptureImagesByCaptureJobIdQueryKey = (
  captureJobId: number,
  skip: number,
  take: number,
) => {
  return [
    `/api/CaptureImages/CaptureJobId/${captureJobId}/${skip}/${take}`,
  ] as const;
};

export const getGetPagedCaptureImagesByCaptureJobIdQueryOptions = <
  TData = Awaited<ReturnType<typeof getPagedCaptureImagesByCaptureJobId>>,
  TError = ErrorType<void>,
>(
  captureJobId: number,
  skip: number,
  take: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getPagedCaptureImagesByCaptureJobId>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetPagedCaptureImagesByCaptureJobIdQueryKey(captureJobId, skip, take);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getPagedCaptureImagesByCaptureJobId>>
  > = ({ signal }) =>
    getPagedCaptureImagesByCaptureJobId(
      captureJobId,
      skip,
      take,
      requestOptions,
      signal,
    );

  return {
    queryKey,
    queryFn,
    enabled: !!(captureJobId && skip && take),
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getPagedCaptureImagesByCaptureJobId>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetPagedCaptureImagesByCaptureJobIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof getPagedCaptureImagesByCaptureJobId>>
>;
export type GetPagedCaptureImagesByCaptureJobIdQueryError = ErrorType<void>;

export const useGetPagedCaptureImagesByCaptureJobId = <
  TData = Awaited<ReturnType<typeof getPagedCaptureImagesByCaptureJobId>>,
  TError = ErrorType<void>,
>(
  captureJobId: number,
  skip: number,
  take: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getPagedCaptureImagesByCaptureJobId>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetPagedCaptureImagesByCaptureJobIdQueryOptions(
    captureJobId,
    skip,
    take,
    options,
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getPagedFavoriteCaptureImagesByCaptureJobId = (
  captureJobId: number,
  skip: number,
  take: number,
  options?: SecondParameter<typeof AxiosInstance>,
  signal?: AbortSignal,
) => {
  return AxiosInstance<CaptureImage[]>(
    {
      url: `/api/CaptureImages/CaptureJobId/Favorite/${encodeURIComponent(String(captureJobId))}/${encodeURIComponent(String(skip))}/${encodeURIComponent(String(take))}`,
      method: 'GET',
      signal,
    },
    options,
  );
};

export const getGetPagedFavoriteCaptureImagesByCaptureJobIdQueryKey = (
  captureJobId: number,
  skip: number,
  take: number,
) => {
  return [
    `/api/CaptureImages/CaptureJobId/Favorite/${captureJobId}/${skip}/${take}`,
  ] as const;
};

export const getGetPagedFavoriteCaptureImagesByCaptureJobIdQueryOptions = <
  TData = Awaited<
    ReturnType<typeof getPagedFavoriteCaptureImagesByCaptureJobId>
  >,
  TError = ErrorType<void>,
>(
  captureJobId: number,
  skip: number,
  take: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getPagedFavoriteCaptureImagesByCaptureJobId>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetPagedFavoriteCaptureImagesByCaptureJobIdQueryKey(
      captureJobId,
      skip,
      take,
    );

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getPagedFavoriteCaptureImagesByCaptureJobId>>
  > = ({ signal }) =>
    getPagedFavoriteCaptureImagesByCaptureJobId(
      captureJobId,
      skip,
      take,
      requestOptions,
      signal,
    );

  return {
    queryKey,
    queryFn,
    enabled: !!(captureJobId && skip && take),
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getPagedFavoriteCaptureImagesByCaptureJobId>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetPagedFavoriteCaptureImagesByCaptureJobIdQueryResult =
  NonNullable<
    Awaited<ReturnType<typeof getPagedFavoriteCaptureImagesByCaptureJobId>>
  >;
export type GetPagedFavoriteCaptureImagesByCaptureJobIdQueryError =
  ErrorType<void>;

export const useGetPagedFavoriteCaptureImagesByCaptureJobId = <
  TData = Awaited<
    ReturnType<typeof getPagedFavoriteCaptureImagesByCaptureJobId>
  >,
  TError = ErrorType<void>,
>(
  captureJobId: number,
  skip: number,
  take: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getPagedFavoriteCaptureImagesByCaptureJobId>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getGetPagedFavoriteCaptureImagesByCaptureJobIdQueryOptions(
      captureJobId,
      skip,
      take,
      options,
    );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getPagedCaptureImagesByCaptureId = (
  captureId: number,
  skip: number,
  take: number,
  options?: SecondParameter<typeof AxiosInstance>,
  signal?: AbortSignal,
) => {
  return AxiosInstance<CaptureImage[]>(
    {
      url: `/api/CaptureImages/CaptureId/${encodeURIComponent(String(captureId))}/${encodeURIComponent(String(skip))}/${encodeURIComponent(String(take))}`,
      method: 'GET',
      signal,
    },
    options,
  );
};

export const getGetPagedCaptureImagesByCaptureIdQueryKey = (
  captureId: number,
  skip: number,
  take: number,
) => {
  return [`/api/CaptureImages/CaptureId/${captureId}/${skip}/${take}`] as const;
};

export const getGetPagedCaptureImagesByCaptureIdQueryOptions = <
  TData = Awaited<ReturnType<typeof getPagedCaptureImagesByCaptureId>>,
  TError = ErrorType<void>,
>(
  captureId: number,
  skip: number,
  take: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getPagedCaptureImagesByCaptureId>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetPagedCaptureImagesByCaptureIdQueryKey(captureId, skip, take);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getPagedCaptureImagesByCaptureId>>
  > = ({ signal }) =>
    getPagedCaptureImagesByCaptureId(
      captureId,
      skip,
      take,
      requestOptions,
      signal,
    );

  return {
    queryKey,
    queryFn,
    enabled: !!(captureId && skip && take),
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getPagedCaptureImagesByCaptureId>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetPagedCaptureImagesByCaptureIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof getPagedCaptureImagesByCaptureId>>
>;
export type GetPagedCaptureImagesByCaptureIdQueryError = ErrorType<void>;

export const useGetPagedCaptureImagesByCaptureId = <
  TData = Awaited<ReturnType<typeof getPagedCaptureImagesByCaptureId>>,
  TError = ErrorType<void>,
>(
  captureId: number,
  skip: number,
  take: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getPagedCaptureImagesByCaptureId>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetPagedCaptureImagesByCaptureIdQueryOptions(
    captureId,
    skip,
    take,
    options,
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getPagedFavoriteCaptureImagesByCaptureId = (
  captureId: number,
  skip: number,
  take: number,
  options?: SecondParameter<typeof AxiosInstance>,
  signal?: AbortSignal,
) => {
  return AxiosInstance<CaptureImage[]>(
    {
      url: `/api/CaptureImages/CaptureId/Favorite/${encodeURIComponent(String(captureId))}/${encodeURIComponent(String(skip))}/${encodeURIComponent(String(take))}`,
      method: 'GET',
      signal,
    },
    options,
  );
};

export const getGetPagedFavoriteCaptureImagesByCaptureIdQueryKey = (
  captureId: number,
  skip: number,
  take: number,
) => {
  return [
    `/api/CaptureImages/CaptureId/Favorite/${captureId}/${skip}/${take}`,
  ] as const;
};

export const getGetPagedFavoriteCaptureImagesByCaptureIdQueryOptions = <
  TData = Awaited<ReturnType<typeof getPagedFavoriteCaptureImagesByCaptureId>>,
  TError = ErrorType<void>,
>(
  captureId: number,
  skip: number,
  take: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getPagedFavoriteCaptureImagesByCaptureId>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetPagedFavoriteCaptureImagesByCaptureIdQueryKey(captureId, skip, take);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getPagedFavoriteCaptureImagesByCaptureId>>
  > = ({ signal }) =>
    getPagedFavoriteCaptureImagesByCaptureId(
      captureId,
      skip,
      take,
      requestOptions,
      signal,
    );

  return {
    queryKey,
    queryFn,
    enabled: !!(captureId && skip && take),
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getPagedFavoriteCaptureImagesByCaptureId>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetPagedFavoriteCaptureImagesByCaptureIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof getPagedFavoriteCaptureImagesByCaptureId>>
>;
export type GetPagedFavoriteCaptureImagesByCaptureIdQueryError =
  ErrorType<void>;

export const useGetPagedFavoriteCaptureImagesByCaptureId = <
  TData = Awaited<ReturnType<typeof getPagedFavoriteCaptureImagesByCaptureId>>,
  TError = ErrorType<void>,
>(
  captureId: number,
  skip: number,
  take: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getPagedFavoriteCaptureImagesByCaptureId>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetPagedFavoriteCaptureImagesByCaptureIdQueryOptions(
    captureId,
    skip,
    take,
    options,
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

import { useCallback, useEffect, useState } from 'react';
import { shallow } from 'zustand/shallow';

import { useDeleteLayerGroup, usePutLayerGroup } from '@agerpoint/api';
import { GroupExpansion as GroupExpansionComponent } from '@agerpoint/feature';
import {
  Group,
  GroupExpansion as GroupExpansionType,
  GroupIcon,
  PanelsState,
  ProjectState,
} from '@agerpoint/types';
import { toApiGroup } from '@agerpoint/utilities';

import { usePanels } from '../../state/use-panels';
import { isClientSideGroup, useProject } from '../../state/use-project';

interface GroupExpansionProps {
  expansionInfo: GroupExpansionType;
}

export function GroupExpansion({ expansionInfo }: GroupExpansionProps) {
  const {
    groups,
    updateGroupName,
    updateGroupIcon,
    deleteGroup,
    setDesiredExtent,
  } = useProject(getGroupState, shallow);
  const { toggleExpansion } = usePanels(getPanelsState);
  const [currentGroup, setCurrentGroup] = useState<Group>();

  const { mutate: putLayerGroup } = usePutLayerGroup({ id: NaN });
  const { mutate: deleteLayerGroup } = useDeleteLayerGroup({});

  const internalUpdateGroupName = useCallback(persistGroupName, [
    groups,
    putLayerGroup,
    updateGroupName,
  ]);

  const internalUpdateGroupIcon = useCallback(persistGroupIcon, [
    groups,
    putLayerGroup,
    updateGroupIcon,
  ]);

  const internalDeleteGroup = useCallback(persistDeleteLayerGroup, [
    groups,
    deleteLayerGroup,
    deleteGroup,
  ]);

  useEffect(() => {
    const group = groups.find((group) => group.id === expansionInfo.id);
    if (group) {
      setCurrentGroup(group);
    }
  }, [expansionInfo.id, expansionInfo.type, groups]);

  return currentGroup ? (
    <GroupExpansionComponent
      onCancel={() => toggleExpansion({ type: 'none' })}
      group={currentGroup}
      updateGroupName={internalUpdateGroupName}
      updateGroupIcon={internalUpdateGroupIcon}
      deleteGroup={internalDeleteGroup}
      setDesiredExtent={setDesiredExtent}
    />
  ) : null;

  async function persistGroupName(groupId: number, name: string) {
    const group = groups.find((group) => group.id === groupId);

    if (group) {
      if (!isClientSideGroup(group)) {
        await putLayerGroup(toApiGroup({ ...group, name }), {
          pathParams: { id: group.id },
        });
      }
    }
    updateGroupName(groupId, name);
  }

  async function persistGroupIcon(groupId: number, icon: GroupIcon) {
    const group = groups.find((group) => group.id === groupId);

    if (group) {
      if (!isClientSideGroup(group)) {
        await putLayerGroup(toApiGroup({ ...group, icon }), {
          pathParams: { id: group.id },
        });
      }
    }
    updateGroupIcon(groupId, icon);
  }

  async function persistDeleteLayerGroup(groupId: number) {
    const group = groups.find((group) => group.id === groupId);

    if (group) {
      if (!isClientSideGroup(group)) {
        await deleteLayerGroup(group.id);
      }
    }
    deleteGroup(groupId);
  }
}

function getGroupState({
  groups,
  actions: {
    setDesiredExtent,
    groups: {
      setName: updateGroupName,
      setIcon: updateGroupIcon,
      delete: deleteGroup,
    },
  },
}: ProjectState) {
  return {
    groups,
    updateGroupName,
    updateGroupIcon,
    deleteGroup,
    setDesiredExtent,
  };
}

function getPanelsState({ toggleExpansion }: PanelsState) {
  return { toggleExpansion };
}

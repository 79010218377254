/**
 * Generated by orval v6.28.2 🍺
 * Do not edit manually.
 * GeminiAPI
 * OpenAPI spec version: v1
 */
import { useMutation, useQuery } from '@tanstack/react-query';
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import type { PipelineJob, PipelineJobFilter } from '.././models';
import { AxiosInstance } from '../../axios-instance';
import type { ErrorType, BodyType } from '../../axios-instance';

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

export const getPipelineJob = (
  options?: SecondParameter<typeof AxiosInstance>,
  signal?: AbortSignal,
) => {
  return AxiosInstance<PipelineJob[]>(
    { url: `/api/PipelineJobs`, method: 'GET', signal },
    options,
  );
};

export const getGetPipelineJobQueryKey = () => {
  return [`/api/PipelineJobs`] as const;
};

export const getGetPipelineJobQueryOptions = <
  TData = Awaited<ReturnType<typeof getPipelineJob>>,
  TError = ErrorType<void>,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof getPipelineJob>>, TError, TData>
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetPipelineJobQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getPipelineJob>>> = ({
    signal,
  }) => getPipelineJob(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getPipelineJob>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetPipelineJobQueryResult = NonNullable<
  Awaited<ReturnType<typeof getPipelineJob>>
>;
export type GetPipelineJobQueryError = ErrorType<void>;

export const useGetPipelineJob = <
  TData = Awaited<ReturnType<typeof getPipelineJob>>,
  TError = ErrorType<void>,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof getPipelineJob>>, TError, TData>
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetPipelineJobQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const postPipelineJob = (
  pipelineJob: BodyType<PipelineJob>,
  options?: SecondParameter<typeof AxiosInstance>,
) => {
  return AxiosInstance<PipelineJob>(
    {
      url: `/api/PipelineJobs`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: pipelineJob,
    },
    options,
  );
};

export const getPostPipelineJobMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postPipelineJob>>,
    TError,
    { data: BodyType<PipelineJob> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postPipelineJob>>,
  TError,
  { data: BodyType<PipelineJob> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postPipelineJob>>,
    { data: BodyType<PipelineJob> }
  > = (props) => {
    const { data } = props ?? {};

    return postPipelineJob(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostPipelineJobMutationResult = NonNullable<
  Awaited<ReturnType<typeof postPipelineJob>>
>;
export type PostPipelineJobMutationBody = BodyType<PipelineJob>;
export type PostPipelineJobMutationError = ErrorType<void>;

export const usePostPipelineJob = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postPipelineJob>>,
    TError,
    { data: BodyType<PipelineJob> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof postPipelineJob>>,
  TError,
  { data: BodyType<PipelineJob> },
  TContext
> => {
  const mutationOptions = getPostPipelineJobMutationOptions(options);

  return useMutation(mutationOptions);
};
export const getPipelineJobById = (
  id: number,
  options?: SecondParameter<typeof AxiosInstance>,
  signal?: AbortSignal,
) => {
  return AxiosInstance<PipelineJob>(
    {
      url: `/api/PipelineJobs/${encodeURIComponent(String(id))}`,
      method: 'GET',
      signal,
    },
    options,
  );
};

export const getGetPipelineJobByIdQueryKey = (id: number) => {
  return [`/api/PipelineJobs/${id}`] as const;
};

export const getGetPipelineJobByIdQueryOptions = <
  TData = Awaited<ReturnType<typeof getPipelineJobById>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getPipelineJobById>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetPipelineJobByIdQueryKey(id);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getPipelineJobById>>
  > = ({ signal }) => getPipelineJobById(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getPipelineJobById>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetPipelineJobByIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof getPipelineJobById>>
>;
export type GetPipelineJobByIdQueryError = ErrorType<void>;

export const useGetPipelineJobById = <
  TData = Awaited<ReturnType<typeof getPipelineJobById>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getPipelineJobById>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetPipelineJobByIdQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const putPipelineJobById = (
  id: number,
  pipelineJob: BodyType<PipelineJob>,
  options?: SecondParameter<typeof AxiosInstance>,
) => {
  return AxiosInstance<PipelineJob>(
    {
      url: `/api/PipelineJobs/${encodeURIComponent(String(id))}`,
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      data: pipelineJob,
    },
    options,
  );
};

export const getPutPipelineJobByIdMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putPipelineJobById>>,
    TError,
    { id: number; data: BodyType<PipelineJob> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof putPipelineJobById>>,
  TError,
  { id: number; data: BodyType<PipelineJob> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof putPipelineJobById>>,
    { id: number; data: BodyType<PipelineJob> }
  > = (props) => {
    const { id, data } = props ?? {};

    return putPipelineJobById(id, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PutPipelineJobByIdMutationResult = NonNullable<
  Awaited<ReturnType<typeof putPipelineJobById>>
>;
export type PutPipelineJobByIdMutationBody = BodyType<PipelineJob>;
export type PutPipelineJobByIdMutationError = ErrorType<void>;

export const usePutPipelineJobById = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putPipelineJobById>>,
    TError,
    { id: number; data: BodyType<PipelineJob> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof putPipelineJobById>>,
  TError,
  { id: number; data: BodyType<PipelineJob> },
  TContext
> => {
  const mutationOptions = getPutPipelineJobByIdMutationOptions(options);

  return useMutation(mutationOptions);
};
export const getPagedPipelineJobs = (
  skip: number,
  take: number,
  options?: SecondParameter<typeof AxiosInstance>,
  signal?: AbortSignal,
) => {
  return AxiosInstance<PipelineJob[]>(
    {
      url: `/api/PipelineJobs/skip/${encodeURIComponent(String(skip))}/take/${encodeURIComponent(String(take))}`,
      method: 'GET',
      signal,
    },
    options,
  );
};

export const getGetPagedPipelineJobsQueryKey = (skip: number, take: number) => {
  return [`/api/PipelineJobs/skip/${skip}/take/${take}`] as const;
};

export const getGetPagedPipelineJobsQueryOptions = <
  TData = Awaited<ReturnType<typeof getPagedPipelineJobs>>,
  TError = ErrorType<void>,
>(
  skip: number,
  take: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getPagedPipelineJobs>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetPagedPipelineJobsQueryKey(skip, take);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getPagedPipelineJobs>>
  > = ({ signal }) => getPagedPipelineJobs(skip, take, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!(skip && take),
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getPagedPipelineJobs>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetPagedPipelineJobsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getPagedPipelineJobs>>
>;
export type GetPagedPipelineJobsQueryError = ErrorType<void>;

export const useGetPagedPipelineJobs = <
  TData = Awaited<ReturnType<typeof getPagedPipelineJobs>>,
  TError = ErrorType<void>,
>(
  skip: number,
  take: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getPagedPipelineJobs>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetPagedPipelineJobsQueryOptions(skip, take, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getFilteredPagedPipelineJobs = (
  skip: number,
  take: number,
  pipelineJobFilter: BodyType<PipelineJobFilter>,
  options?: SecondParameter<typeof AxiosInstance>,
) => {
  return AxiosInstance<PipelineJob[]>(
    {
      url: `/api/PipelineJobs/skip/${encodeURIComponent(String(skip))}/take/${encodeURIComponent(String(take))}`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: pipelineJobFilter,
    },
    options,
  );
};

export const getGetFilteredPagedPipelineJobsMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof getFilteredPagedPipelineJobs>>,
    TError,
    { skip: number; take: number; data: BodyType<PipelineJobFilter> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof getFilteredPagedPipelineJobs>>,
  TError,
  { skip: number; take: number; data: BodyType<PipelineJobFilter> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof getFilteredPagedPipelineJobs>>,
    { skip: number; take: number; data: BodyType<PipelineJobFilter> }
  > = (props) => {
    const { skip, take, data } = props ?? {};

    return getFilteredPagedPipelineJobs(skip, take, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type GetFilteredPagedPipelineJobsMutationResult = NonNullable<
  Awaited<ReturnType<typeof getFilteredPagedPipelineJobs>>
>;
export type GetFilteredPagedPipelineJobsMutationBody =
  BodyType<PipelineJobFilter>;
export type GetFilteredPagedPipelineJobsMutationError = ErrorType<void>;

export const useGetFilteredPagedPipelineJobs = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof getFilteredPagedPipelineJobs>>,
    TError,
    { skip: number; take: number; data: BodyType<PipelineJobFilter> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof getFilteredPagedPipelineJobs>>,
  TError,
  { skip: number; take: number; data: BodyType<PipelineJobFilter> },
  TContext
> => {
  const mutationOptions =
    getGetFilteredPagedPipelineJobsMutationOptions(options);

  return useMutation(mutationOptions);
};
export const submitPipelineJob = (
  pipelineJob: BodyType<PipelineJob>,
  options?: SecondParameter<typeof AxiosInstance>,
) => {
  return AxiosInstance<PipelineJob>(
    {
      url: `/api/PipelineJobs/Submit`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: pipelineJob,
    },
    options,
  );
};

export const getSubmitPipelineJobMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof submitPipelineJob>>,
    TError,
    { data: BodyType<PipelineJob> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof submitPipelineJob>>,
  TError,
  { data: BodyType<PipelineJob> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof submitPipelineJob>>,
    { data: BodyType<PipelineJob> }
  > = (props) => {
    const { data } = props ?? {};

    return submitPipelineJob(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type SubmitPipelineJobMutationResult = NonNullable<
  Awaited<ReturnType<typeof submitPipelineJob>>
>;
export type SubmitPipelineJobMutationBody = BodyType<PipelineJob>;
export type SubmitPipelineJobMutationError = ErrorType<void>;

export const useSubmitPipelineJob = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof submitPipelineJob>>,
    TError,
    { data: BodyType<PipelineJob> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof submitPipelineJob>>,
  TError,
  { data: BodyType<PipelineJob> },
  TContext
> => {
  const mutationOptions = getSubmitPipelineJobMutationOptions(options);

  return useMutation(mutationOptions);
};
export const updatePipelineJobStatusByJobUuid = (
  jobUuid: string,
  pipelineJob: BodyType<PipelineJob>,
  options?: SecondParameter<typeof AxiosInstance>,
) => {
  return AxiosInstance<PipelineJob>(
    {
      url: `/api/PipelineJobs/Uuid/${encodeURIComponent(String(jobUuid))}/Status`,
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      data: pipelineJob,
    },
    options,
  );
};

export const getUpdatePipelineJobStatusByJobUuidMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updatePipelineJobStatusByJobUuid>>,
    TError,
    { jobUuid: string; data: BodyType<PipelineJob> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updatePipelineJobStatusByJobUuid>>,
  TError,
  { jobUuid: string; data: BodyType<PipelineJob> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updatePipelineJobStatusByJobUuid>>,
    { jobUuid: string; data: BodyType<PipelineJob> }
  > = (props) => {
    const { jobUuid, data } = props ?? {};

    return updatePipelineJobStatusByJobUuid(jobUuid, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdatePipelineJobStatusByJobUuidMutationResult = NonNullable<
  Awaited<ReturnType<typeof updatePipelineJobStatusByJobUuid>>
>;
export type UpdatePipelineJobStatusByJobUuidMutationBody =
  BodyType<PipelineJob>;
export type UpdatePipelineJobStatusByJobUuidMutationError = ErrorType<void>;

export const useUpdatePipelineJobStatusByJobUuid = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updatePipelineJobStatusByJobUuid>>,
    TError,
    { jobUuid: string; data: BodyType<PipelineJob> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof updatePipelineJobStatusByJobUuid>>,
  TError,
  { jobUuid: string; data: BodyType<PipelineJob> },
  TContext
> => {
  const mutationOptions =
    getUpdatePipelineJobStatusByJobUuidMutationOptions(options);

  return useMutation(mutationOptions);
};

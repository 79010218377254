import Feature from 'ol/Feature';
import { LineString, Polygon } from 'ol/geom';
import Point from 'ol/geom/Point';
import Draw, { DrawEvent } from 'ol/interaction/Draw';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { StyleLike } from 'ol/style/Style';
import { useEffect, useState } from 'react';

import { CloudButton } from '../button';
import { ICloudOpenlayersBaseMap } from './openlayers-map.types';
import {
  getBaseMapIcon,
  useCloudOpenlayersMapContext,
} from './openlayers-map.utilities';

interface ICloudOpenlayersMapBaseMapControls {
  id: string;
  baseMap: ICloudOpenlayersBaseMap;
  setBaseMap: (baseMap: ICloudOpenlayersBaseMap) => void;
  availableBaseMapLayers: ICloudOpenlayersBaseMap[];
}

const CloudOpenlayersMapBaseMapControls = ({
  id,
  availableBaseMapLayers,
  baseMap,
  setBaseMap,
}: ICloudOpenlayersMapBaseMapControls) => {
  const { mapInitialized } = useCloudOpenlayersMapContext();

  if (!mapInitialized) {
    return null;
  }

  if (availableBaseMapLayers.length <= 1) {
    return null;
  }

  return (
    <div
      className={`absolute top-4 left-4 z-1 bg-white rounded-lg
    shadow-lg flex flex-row items-center p-1 gap-1`}
    >
      {availableBaseMapLayers.map((layer, index) => (
        <CloudButton.Icon
          key={index}
          id={`${id}-${layer}-base-map-selector`}
          leadingIcon={getBaseMapIcon(layer)}
          toggled={baseMap === layer}
          onClick={() => {
            setBaseMap(layer);
          }}
        />
      ))}
    </div>
  );
};

interface ICloudOpenlayersMapViewControls {
  id: string;
  zoom?: {
    delta: number;
    onZoom?: (zoom: number) => void;
  };
  center?: {
    onClick?: () => void;
  };
}

const CloudOpenlayesrMapViewControls = ({
  id,
  zoom,
  center,
}: ICloudOpenlayersMapViewControls) => {
  const { map, mapInitialized } = useCloudOpenlayersMapContext();

  if (!mapInitialized) {
    return null;
  }

  return (
    <div className="absolute top-4 right-4 z-1 flex flex-col gap-2">
      {zoom && (
        <div className="bg-white rounded-lg shadow-lg flex flex-col gap-1 p-1">
          <CloudButton.Icon
            id={`${id}-zoom-in-button`}
            leadingIcon="plus"
            onClick={() => {
              const view = map.current?.getView();
              if (!view) {
                return;
              }

              const newZoom = (view.getZoom() ?? 10) + zoom.delta;
              view.cancelAnimations();
              view.animate({
                zoom: newZoom,
                duration: 300,
              });
              zoom.onZoom?.(newZoom);
            }}
          />
          <CloudButton.Icon
            id={`${id}-zoom-out-button`}
            leadingIcon="minus"
            onClick={() => {
              const view = map.current?.getView();
              if (!view) {
                return;
              }

              const newZoom = (view.getZoom() ?? 10) - zoom.delta;
              view.cancelAnimations();
              view.animate({
                zoom: newZoom,
                duration: 300,
              });
              zoom.onZoom?.(newZoom);
            }}
          />
        </div>
      )}
      {center && (
        <div className="bg-white rounded-lg shadow-lg p-1">
          <CloudButton.Icon
            id={`${id}-center-button`}
            leadingIcon="arrows-to-circle"
            onClick={center.onClick}
          />
        </div>
      )}
    </div>
  );
};

interface ICloudOpenlayersAnnotationTools {
  id: string;
  onPointAdded?: (point: Point) => void;
  pointStyle?: StyleLike;
  onLineAdded?: (line: LineString) => void;
  lineStyle?: StyleLike;
  onPolygonAdded?: (polygon: Polygon) => void;
  polygonStyle?: StyleLike;
}

type AnnotationToolTypes = 'point' | 'line' | 'polygon';

const CloudOpenlayersAnnotationTools = ({
  id,
  onPointAdded,
  pointStyle,
  onLineAdded,
  lineStyle,
  onPolygonAdded,
  polygonStyle,
}: ICloudOpenlayersAnnotationTools) => {
  const { map, mapInitialized } = useCloudOpenlayersMapContext();

  const [tool, setTool] = useState<AnnotationToolTypes>();

  useEffect(() => {
    const m = map.current;
    if (tool !== 'point' || !mapInitialized || !m) {
      return;
    }
    // Prepare source, layer and draw objects for point placement
    const olSource = new VectorSource({ wrapX: false });
    const olVectorLayer = new VectorLayer({
      source: olSource,
    });
    const olDraw = new Draw({
      source: olSource,
      type: 'Point',
      style: pointStyle,
    });
    m.addInteraction(olDraw);
    m.addLayer(olVectorLayer);

    olDraw.on('drawend', (e: DrawEvent) => {
      setTool(undefined);
      const olFeature: Feature<Point> = e.feature as Feature<Point>;
      const point = olFeature.getGeometry();
      if (point) {
        onPointAdded?.(point);
      }
    });

    return () => {
      olSource.dispose();
      olDraw.dispose();
      olVectorLayer.dispose();
      m.removeInteraction(olDraw);
      m.removeLayer(olVectorLayer);
    };
  }, [tool, mapInitialized, onPointAdded, pointStyle]);

  useEffect(() => {
    const m = map.current;
    if (tool !== 'line' || !mapInitialized || !m) {
      return;
    }

    const olSource = new VectorSource({ wrapX: false });
    const olVectorLayer = new VectorLayer({
      source: olSource,
      style: lineStyle,
    });
    const olDraw = new Draw({
      source: olSource,
      type: 'LineString',
      style: lineStyle,
    });
    m.addInteraction(olDraw);
    m.addLayer(olVectorLayer);

    olDraw.on('drawend', (e: DrawEvent) => {
      setTool(undefined);
      const olFeature: Feature<LineString> = e.feature as Feature<LineString>;
      const line = olFeature.getGeometry();
      if (line) {
        onLineAdded?.(line);
      }
    });

    return () => {
      olSource.dispose();
      olDraw.dispose();
      olVectorLayer.dispose();
      m.removeInteraction(olDraw);
      m.removeLayer(olVectorLayer);
    };
  }, [tool, mapInitialized, lineStyle, onLineAdded]);

  useEffect(() => {
    const m = map.current;
    if (tool !== 'polygon' || !mapInitialized || !m) {
      return;
    }

    const olSource = new VectorSource({ wrapX: false });
    const olVectorLayer = new VectorLayer({
      source: olSource,
      style: polygonStyle,
    });
    const olDraw = new Draw({
      source: olSource,
      type: 'Polygon',
      style: polygonStyle,
    });
    m.addInteraction(olDraw);
    m.addLayer(olVectorLayer);

    olDraw.on('drawend', (e: DrawEvent) => {
      setTool(undefined);
      const olFeature: Feature<Polygon> = e.feature as Feature<Polygon>;
      const polygon = olFeature.getGeometry();
      if (polygon) {
        onPolygonAdded?.(polygon);
      }
    });

    return () => {
      olSource.dispose();
      olDraw.dispose();
      olVectorLayer.dispose();
      m.removeInteraction(olDraw);
      m.removeLayer(olVectorLayer);
    };
  }, [tool, mapInitialized, polygonStyle, onPolygonAdded]);

  if (!mapInitialized) {
    return null;
  }
  return (
    <div
      className={`absolute bottom-14 left-1/2 -translate-x-1/2
          z-1 bg-white shadow-lg rounded-lg p-1 flex flex-row gap-1`}
    >
      <CloudButton.Icon
        id={`${id}-2d-point-annotation-button`}
        leadingIcon="location-dot"
        onClick={() => {
          if (mapInitialized) {
            if (tool === 'point') {
              setTool(undefined);
            } else {
              setTool('point');
            }
          }
        }}
        toggled={tool === 'point'}
      />

      <CloudButton.Icon
        id={`${id}-2d-line-annotation-button`}
        leadingIcon="ruler"
        onClick={() => {
          if (mapInitialized) {
            if (tool === 'line') {
              setTool(undefined);
            } else {
              setTool('line');
            }
          }
        }}
        toggled={tool === 'line'}
      />

      <CloudButton.Icon
        id={`${id}-2d-polygon-annotation-button`}
        leadingIcon="draw-polygon"
        onClick={() => {
          if (mapInitialized) {
            if (tool === 'polygon') {
              setTool(undefined);
            } else {
              setTool('polygon');
            }
          }
        }}
        toggled={tool === 'polygon'}
      />
    </div>
  );
};

const CloudOpenlayersMapOverlays = {
  BaseMapControls: CloudOpenlayersMapBaseMapControls,
  ViewControls: CloudOpenlayesrMapViewControls,
  AnnotationTools: CloudOpenlayersAnnotationTools,
};

export { CloudOpenlayersMapOverlays };

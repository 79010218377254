import mixpanel from 'mixpanel-browser';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import Stats from 'three/examples/jsm/libs/stats.module.js';

import { APIClient, APIModels } from '@agerpoint/api';
import { GsThreeDViewer, OldGs3dTools } from '@agerpoint/three-d-viewer';
import {
  Annotation3dPoints,
  BackgroundOptionsValues,
  CameraState,
  EffectNames,
  EventBusNames,
  Gs3dViewerProps,
  ICustomMesh,
  IGs3dViewerController,
  LatLngAlt,
  LdFlags,
  MixpanelNames,
} from '@agerpoint/types';
import {
  AnnotationGroupName,
  GaussianSplats3D,
  eventBus,
  hasPermission,
  llaToEnu,
  useGlobalStore,
} from '@agerpoint/utilities';

import { SharedThreeDAnnotationToolbar } from '../annotations/3d/shared-toolbar/shared-toolbar';
import { useCapturesViewerContext } from '../captures-viewer';
import { Gs3DCloudTools } from './gs-three-d-cloud-tools';
import useThreeSceneSetup from './gs-three-d.hooks';
import './gs-three-d.scss';

export const GsThreeDSplatViewerController = ({
  controller: setController,
  showTools = false,
  showCloudTools = false,
  showLoadingIndicator = false,
  plugins,
}: Gs3dViewerProps) => {
  const { setAnnotations3dGeometry } = useCapturesViewerContext();

  const {
    permissions,
    sidebar: { isOpen: sidebarOpen },
    actions: { dispatchEffect },
  } = useGlobalStore();

  const has3dDebugPermission = useMemo(
    () => hasPermission(LdFlags.Debug3dFeatures, permissions),
    [permissions]
  );

  const viewerRef = useRef<{
    splatsViewer?: GaussianSplats3D.Viewer;
    threeViewer?: GsThreeDViewer;
  }>({});
  const [viewerError] = useState<Error | undefined>();
  const [containerRef, setContainerRef] = useState<HTMLDivElement | null>(null);
  const [viewerReady, setViewerReady] = useState(false);
  const [sceneLoaded, setSceneLoaded] = useState(false);
  const [cameraPositionsVisible, setCameraPositionsVisible] = useState(false);
  const [cameraPositionsLoaded, setCameraPositionsLoaded] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const downloadPromiseRef = useRef<any>();
  const [captureJobMetadata, setCaptureJobMetadata] =
    useState<APIModels.CaptureJob>();
  const [captureMetadata, setCaptureMetadata] = useState<APIModels.Capture>();
  const [loading, setLoading] = useState({
    step: '',
    inProgress: false,
    percentage: -1,
    finished: false,
  });
  const { scene, camera, renderer, rootElement } =
    useThreeSceneSetup(containerRef);

  const cameraPositionOnClickRef = useRef<{
    eventId: string;
    callback: (e: CustomEvent) => void;
  }>();
  const statsContainerRef = useRef<HTMLDivElement>(null);
  const [background, setBackground] = useState<string>(
    BackgroundOptionsValues.Gradient
  );
  const mousePosition = useRef<THREE.Vector3 | undefined>();

  const statsObject = useRef<Stats | undefined>(undefined);

  // Cleanup
  useEffect(() => {
    return () => {
      setAnnotations3dGeometry?.(undefined);
      viewerRef.current?.threeViewer?.destroy();
      downloadPromiseRef?.current?.abortHandler?.();
      downloadPromiseRef.current = undefined;
      viewerRef.current?.splatsViewer?.stop();
      viewerRef.current = {};
    };
  }, []);

  useEffect(() => {
    if (!containerRef || !scene || !camera || !renderer || !rootElement) return;

    const viewer = new GaussianSplats3D.Viewer({
      threeScene: scene,
      selfDrivenMode: false,
      renderer: renderer,
      camera: camera,
      useBuiltInControls: false,
      rootElement: rootElement,
      gpuAcceleratedSort: true,
      sharedMemoryForWorkers: true,
    });

    viewerRef.current.splatsViewer = viewer;

    const resize = () => {
      renderer.setSize(containerRef.offsetWidth, containerRef.offsetHeight);
      camera.aspect = containerRef.offsetWidth / containerRef.offsetHeight;
      camera.updateProjectionMatrix();
    };

    window.addEventListener('resize', resize);

    const raycaster = new THREE.Raycaster();
    raycaster.params.Sprite = { threshold: 0.1 }; // Adjust threshold as needed

    const mouse = new THREE.Vector2();
    const onMouseDown = (event: MouseEvent) => {
      event.preventDefault();
      event.stopPropagation();
      // Correct for elements offset
      const rect = rootElement.getBoundingClientRect();
      mouse.x = ((event.clientX - rect.left) / rect.width) * 2 - 1;
      mouse.y = -((event.clientY - rect.top) / rect.height) * 2 + 1;
      raycaster.setFromCamera(mouse, camera);
      const group = scene.getObjectByName(AnnotationGroupName);
      if (!group) return;
      const intersects = raycaster.intersectObjects(group.children, true);
      if (intersects.length > 0) {
        const selectedObject = intersects[0].object as ICustomMesh;
        if (selectedObject?.callback) {
          selectedObject.callback?.(selectedObject.uniqueId);
        }
      }
    };

    rootElement.addEventListener('mousedown', onMouseDown, false);
    // Controls setup
    const controls = new OrbitControls(camera, renderer.domElement);
    controls.target.set(0, 1, 0);
    controls.update();

    viewerRef.current.threeViewer = new GsThreeDViewer(
      camera,
      viewerRef.current.splatsViewer.threeScene,
      controls,
      renderer,
      rootElement
    );

    const mouseMove = (event: MouseEvent) => {
      if (!viewerRef.current) return;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const outHits: any = [];
      const rootElement = viewer.rootElement;
      const rect = rootElement.getBoundingClientRect();

      viewer.raycaster.setFromCameraAndScreenPosition(
        camera,
        // Correct for elements offset
        { x: event.x - rect.left, y: event.y - rect.top },
        { x: containerRef.offsetWidth, y: containerRef.offsetHeight }
      );

      viewer.raycaster.intersectSplatMesh(viewer.splatMesh, outHits);

      viewer.updateMeshCursor();
      if (outHits[0]?.origin === undefined) {
        mousePosition.current = undefined;
        return;
      }
      const intersection = outHits[0]?.origin;
      mousePosition.current = intersection;
    };
    mouseMove.bind(viewer);
    rootElement.addEventListener('mousemove', mouseMove, false);

    setViewerReady(true);

    return () => {
      setViewerReady(false);

      viewerRef.current?.threeViewer?.destroy();
      // destroy the mouse event listener
      rootElement.removeEventListener('mousedown', onMouseDown, false);
      containerRef.removeChild(rootElement);
      while (scene.children.length > 0) {
        scene.remove(scene.children[0]);
      }
      viewerRef.current?.splatsViewer?.stop();
      viewerRef.current = {};
      renderer.dispose();
      setLoading({
        step: '',
        inProgress: false,
        percentage: 0,
        finished: false,
      });
      window.removeEventListener('resize', resize);
    };
  }, [containerRef, scene, camera, renderer, rootElement]);

  useEffect(() => {
    if (!viewerReady) return;

    let semaphore = false;

    const update = () => {
      statsObject?.current?.update();

      if (!viewerRef?.current?.splatsViewer?.isDisposingOrDisposed()) {
        viewerRef?.current?.splatsViewer?.update();
        viewerRef?.current?.splatsViewer?.render();
      }

      if (semaphore === false) {
        requestAnimationFrame(update);
      }
    };

    update();
    return () => {
      semaphore = true;
    };
  }, [viewerReady]);

  useEffect(() => {
    if (!viewerReady) return;

    viewerRef?.current?.threeViewer?.addLight();
  }, [viewerReady]);

  useEffect(() => {
    if (cameraPositionsVisible) {
      controller.info.cameraPositionsVisible = true;
    } else {
      controller.info.cameraPositionsVisible = false;
    }
  }, [cameraPositionsVisible]);

  const removePlyModel = useCallback(() => {
    if (!viewerReady) return;
    //TODO: cleanup the renderer
    alert('This feature is not implemented yet!');
  }, [viewerReady]);

  const loadPlyModel = useCallback(
    async (url: string) => {
      if (!viewerReady) return;
      if (loading.inProgress && downloadPromiseRef.current) {
        downloadPromiseRef.current?.abortHandler?.();
        downloadPromiseRef.current = undefined;

        setLoading({
          step: '',
          inProgress: false,
          percentage: -1,
          finished: false,
        });

        // Workaround for aborting the download, without it the viewer
        // says it's still loading the previous model
        setTimeout(() => {
          loadPlyModel(url);
        }, 500);

        return;
      }

      setLoading({
        step: 'Starting...',
        inProgress: true,
        percentage: -1,
        finished: false,
      });
      downloadPromiseRef.current =
        viewerRef.current?.splatsViewer?.addSplatScene(url, {
          splatAlphaRemovalThreshold: 1,
          format: GaussianSplats3D.SceneFormat.Ply,
          progressiveLoad: true,
          showLoadingUI: false,
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          onProgress: (percent: number, _: any, stage: any) => {
            if (stage === 0) {
              percent = Math.round(percent);
              setLoading({
                step: 'Loading...',
                inProgress: true,
                percentage: percent,
                finished: false,
              });
            } else if (stage === 1) {
              setLoading({
                step: 'Processing...',
                inProgress: true,
                percentage: -1,
                finished: false,
              });
            } else if (stage >= 2) {
              dispatchEffect(EffectNames.GS_3D_POINT_CLOUD_LOADED);
              setLoading({
                step: '',
                inProgress: false,
                percentage: -1,
                finished: true,
              });

              mixpanel.track(MixpanelNames.ThreeJsPlyViewerLoaded, {
                status: 'success',
              });
            }
          },
        });
      setSceneLoaded(true);
    },
    [viewerReady, loading]
  );

  const getCameraSettings = useCallback((): CameraState | undefined => {
    if (!viewerReady) return;

    return viewerRef?.current?.threeViewer?.getCameraSettings();
  }, [viewerReady]);

  const setCameraSettings = useCallback(
    (settings: string) => {
      if (!viewerReady) return;

      return viewerRef?.current?.threeViewer?.setCameraSettings(
        JSON.parse(settings)
      );
    },
    [viewerReady]
  );

  const loadCameraPositions = useCallback(
    (images: APIClient.CaptureImage[]) => {
      if (
        !captureMetadata?.latitude ||
        !captureMetadata?.longitude ||
        !captureMetadata?.altitude
      ) {
        return;
      }

      const origin = {
        lat: captureMetadata.latitude,
        lng: captureMetadata.longitude,
        alt: captureMetadata.altitude,
      };

      // convert to local coordinates
      images.forEach((image: APIClient.CaptureImage) => {
        if (!image.latitude || !image.longitude || !image.altitude) {
          return;
        }

        const lla: LatLngAlt = llaToEnu(
          {
            lat: image.latitude,
            lng: image.longitude,
            alt: image.altitude,
          },
          origin
        );
        const id = image.id as number;
        viewerRef.current.threeViewer?.addImageMarker?.(
          id,
          lla,
          Annotation3dPoints.CaptureImageLocation
        );
      });
      // setCameraPositionsVisible(true);
      setCameraPositionsLoaded(true);
    },
    [
      captureMetadata,
      viewerRef,
      setCameraPositionsLoaded,
      setCameraPositionsVisible,
      cameraPositionsLoaded,
      cameraPositionsVisible,
    ]
  );

  const bindCameraPositionOnClick = useCallback(
    (callback: ((e: CustomEvent) => void) | undefined) => {
      if (!viewerReady) {
        return;
      }

      if (callback === undefined && cameraPositionOnClickRef.current) {
        eventBus.remove(
          EventBusNames.Point3dLocationMarkerClicked,
          cameraPositionOnClickRef.current.callback,
          cameraPositionOnClickRef.current.eventId
        );
        cameraPositionOnClickRef.current = undefined;
        return;
      }

      if (callback !== undefined) {
        cameraPositionOnClickRef.current = {
          eventId: eventBus.on(
            EventBusNames.Point3dLocationMarkerClicked,
            callback,
            true
          ),
          callback: callback,
        };
      }
    },
    [viewerReady]
  );

  const removeCameraPositions = useCallback(() => {
    if (!viewerReady) return;

    viewerRef.current.threeViewer?.removeAllImageMarkers();
    setCameraPositionsVisible(false);
    setCameraPositionsLoaded(false);
  }, [viewerReady]);

  const hideCameraPositions = useCallback(() => {
    if (!viewerReady) return;

    viewerRef.current.threeViewer?.hideImageMarkers();
  }, [viewerReady]);

  const showCameraPositions = useCallback(() => {
    if (!viewerReady) return;

    viewerRef.current.threeViewer?.showImageMarkers();
  }, [viewerReady]);

  const addAxesHelper = useCallback(() => {
    if (!viewerReady) {
      return;
    }
    viewerRef.current.threeViewer?.addAxesHelper();
  }, [viewerReady]);

  const removeAxesHelper = useCallback(() => {
    if (!viewerReady) {
      return;
    }
    viewerRef.current.threeViewer?.removeAxesHelper();
  }, [viewerReady]);

  const controller: IGs3dViewerController = useMemo(
    () => ({
      info: {
        error: viewerError,
        viewerReady,
        captureJobMetadata,
        captureMetadata,
        cameraPositionsVisible,
        cameraPositionsLoaded,
        sceneLoaded,
        element: containerRef,
      },
      loadPlyModel,
      removePlyModel,
      setBackground,
      getCameraSettings,
      setCameraSettings,
      setCaptureJobMetadata,
      loadCameraPositions,
      setCaptureMetadata,
      bindCameraPositionOnClick,
      setCameraPositionsVisible,
      removeCameraPositions,
      hideCameraPositions,
      showCameraPositions,
      addAxesHelper,
      removeAxesHelper,
      mousePosition,
      annotation3d: viewerRef.current?.threeViewer?.annotation3d,
      threeViewer: viewerRef.current?.threeViewer,
    }),
    [
      viewerError,
      loadPlyModel,
      removePlyModel,
      viewerReady,
      setCameraSettings,
      getCameraSettings,
      captureJobMetadata,
      setCaptureJobMetadata,
      loadCameraPositions,
      setCaptureMetadata,
      bindCameraPositionOnClick,
      setCameraPositionsVisible,
      removeCameraPositions,
      hideCameraPositions,
      showCameraPositions,
      addAxesHelper,
      removeAxesHelper,
      mousePosition,
      viewerRef.current?.threeViewer?.annotation3d,
      sceneLoaded,
    ]
  );

  useEffect(() => {
    // hide camera positions
    if (!controller.info.cameraPositionsVisible) {
      hideCameraPositions();
    } else {
      showCameraPositions();
    }
  }, [controller.info.cameraPositionsVisible]);

  useEffect(() => {
    setController?.(controller);
  }, [
    controller,
    setController,
    cameraPositionsVisible,
    cameraPositionsLoaded,
    setCameraPositionsLoaded,
    setCameraPositionsVisible,
  ]);

  useEffect(() => {
    mixpanel.time_event(MixpanelNames.ThreeJsPlyViewerLoaded);
  }, []);

  useEffect(() => {
    if (!viewerReady) return;

    if (has3dDebugPermission) {
      if (statsObject.current === undefined) {
        const stats = new Stats();
        stats.dom.style.zIndex = '40';
        stats.dom.style.position = 'absolute';
        stats.dom.style.top = '4px';
        stats.dom.style.left = 'unset';
        stats.dom.style.right = '45px';

        statsContainerRef?.current?.appendChild(stats.dom);
        statsObject.current = stats;
      }

      controller.addAxesHelper();
    } else {
      const selectedObject =
        viewerRef?.current?.splatsViewer?.threeScene.getObjectByName(
          'axisHelper'
        );
      viewerRef?.current?.splatsViewer?.threeScene.remove(selectedObject);
      if (statsObject.current) {
        statsContainerRef?.current?.removeChild(statsObject.current.dom);
        statsObject.current = undefined;
      }
    }
  }, [has3dDebugPermission, viewerReady]);

  const backgroundStyle = useMemo(() => {
    if (background === BackgroundOptionsValues.Black) {
      return 'black';
    }

    if (background === BackgroundOptionsValues.White) {
      return 'white';
    }

    if (background === BackgroundOptionsValues.Gradient) {
      return 'radial-gradient(#1b292f, #0c1417)';
    }

    return 'radial-gradient(#1b292f, #0c1417)';
  }, [background]);

  return (
    <div className="relative h-full w-full">
      {viewerReady && showCloudTools && (
        <Gs3DCloudTools viewerController={controller} />
      )}
      {showTools && viewerReady && (
        <OldGs3dTools viewerController={controller} />
      )}
      {showCloudTools && viewerReady && (
        <SharedThreeDAnnotationToolbar viewerController={controller} />
      )}
      {plugins?.map((plugin) => plugin)}
      {loading.inProgress && (
        <>
          <div className="absolute w-full h-1 bg-black loading-gradient" />
          {showLoadingIndicator && (
            <div
              className={`absolute flex z-40 pointer-events-none transition-all duration-300 top-2 ${
                sidebarOpen ? 'translate-x-80' : ''
              }`}
              style={{
                left: '2rem',
              }}
            >
              <div className="loaderContainer">
                <div className="loader text-white px-4 py-1 rounded">
                  {`${loading.step} ${
                    loading.percentage >= 0 ? `${loading.percentage}%` : ''
                  }`.trim()}
                </div>
              </div>
            </div>
          )}
        </>
      )}

      <div ref={statsContainerRef} className="top-1" />
      <div
        className={`h-full w-full overflow-hidden`}
        style={{
          background: backgroundStyle,
        }}
        ref={setContainerRef}
      />
    </div>
  );
};

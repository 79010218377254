import { CSSProperties, useContext, useMemo, useState } from 'react';

import { APLoader } from '../../ap-loader';
import { CloudDatatableItemData, StyleContext } from '../datatable.types';

export const Row = <T,>({
  index,
  style,
  data: itemData,
}: {
  index: number;
  style: CSSProperties;
  data: CloudDatatableItemData<T>;
}) => {
  const { data, columns, cellOnClick, loading, extraRow } = itemData;
  const d = data[index];
  const [isHovered, setIsHovered] = useState(false);
  const styleContext = useContext(StyleContext);

  const rowStyle = useMemo(
    () => styleContext?.row?.(d, index),
    [styleContext, d, index]
  );

  if (index === data.length) {
    if (loading) {
      return (
        <div style={{ ...style }} className="flex-center">
          <APLoader.CircleNotch />
        </div>
      );
    }

    return (
      <div style={{ ...style }} className="size-full">
        {extraRow}
      </div>
    );
  }
  return (
    <div
      style={style}
      onPointerEnter={() => {
        setIsHovered(true);
      }}
      onPointerLeave={() => {
        setIsHovered(false);
      }}
      className={`flex flex-row flex-center ${rowStyle}`}
    >
      {columns.map((c, i) => {
        if (c.visible === false) {
          return null;
        }
        const onClick = c.onClickId ? cellOnClick?.(c.onClickId) : undefined;
        return (
          <div
            key={i}
            style={{
              flex: c.fixed ? undefined : c.flex ?? 1,
              width: c.fixed,
              minWidth: c.fixed,
              maxWidth: c.fixed,
              height: '100%',
            }}
            onClick={onClick ? (e) => onClick?.(d, e) : undefined}
            onDoubleClick={onClick ? (e) => onClick?.(d, e) : undefined}
            className={`
              flex flex-col justify-center
              ${onClick ? 'cursor-pointer' : ''}
              ${c.style?.cell ?? ''}
              ${c.style?.overflowStyle ?? ''}
              `}
          >
            <div className={`${c.style?.overflowStyle ?? ''}`}>
              {c.cell(d, index, isHovered)}
            </div>
          </div>
        );
      })}
    </div>
  );
};

import { GetResponse } from '@agerpoint/api';
import {
  Attributes,
  FileMapLayer,
  Layer,
  PointCloudMapLayer,
  WmsVectorMapLayer,
} from '@agerpoint/types';

import { FileLayerStyle } from './file';
import { PointCloudLayerStyle } from './point-cloud';
import { WmsVectorLayerStyle } from './wms-vector';

interface LayerStyle {
  layer: Layer;
  onChange: (layer: Layer) => Promise<void>;
  availableAttributes?: GetResponse<Attributes, void>;
}

export function LayerStyle({
  layer,
  onChange,
  availableAttributes,
  ...props
}: LayerStyle) {
  return layer.type === 'PointCloud' ? (
    <PointCloudLayerStyle
      key={layer.id}
      layer={layer as PointCloudMapLayer}
      onChange={onChange}
      {...props}
    />
  ) : layer.type === 'WmsVector' ? (
    <WmsVectorLayerStyle
      key={layer.id}
      layer={layer as WmsVectorMapLayer}
      availableAttributes={availableAttributes}
      onChange={onChange}
    />
  ) : layer.type === 'File' ? (
    <FileLayerStyle
      key={layer.id}
      layer={layer as FileMapLayer}
      onChange={onChange}
    />
  ) : null;
}

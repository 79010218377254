import { faXmark } from '@fortawesome/pro-light-svg-icons';
import {
  faCircleCheck,
  faCircleNotch,
  faFileCsv,
  faFilterSlash,
} from '@fortawesome/pro-regular-svg-icons';
import { faCircleCheck as faSolidCircleCheck } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDebouncyEffect } from 'use-debouncy';

import { APIModels, formatDate, formatDateAndTime } from '@agerpoint/api';
import { Button, Input } from '@agerpoint/component';
import { Datatable, dataTableAgerStyle } from '@agerpoint/feature';
import {
  promptDownload,
  useItemSelection,
  usePageTitle,
  useQueryState,
  uuidRegex,
} from '@agerpoint/utilities';

import { useAdminUploadsQueries } from './admin-uploads-queries';

export const AdminUploadsList = () => {
  usePageTitle(() => 'Platform - Uploads', []);
  const navigate = useNavigate();

  const [filter, setFilter] = useState<APIModels.UploadFilter>();

  const { uploadTypesQuery, uploadsQuery, organizationsLookupTable } =
    useAdminUploadsQueries(filter);

  const uploads = useMemo(() => {
    return uploadsQuery.data?.pages.flatMap((p) => p) ?? [];
  }, [uploadsQuery.data]);

  const uploadsSelection = useItemSelection<number, APIModels.Upload>({
    dependencies: [uploads.length, { filter }],
    idField: 'id',
    items: uploads,
  });

  const [nameUUIDFilter, setNameUUIDFilter] = useQueryState<string>({
    paramName: 'name',
    initialValue: '',
    fromUrlParam: (a) => a,
    toUrlParam: (a) => a.trim(),
  });

  const [typeFilter, setTypeFilter] = useQueryState<
    APIModels.UploadType | undefined
  >({
    paramName: 'type',
    initialValue: undefined,
    fromUrlParam: (v) => {
      if (!v || !Number.isSafeInteger(Number(v))) {
        return undefined;
      }

      return uploadTypesQuery.data?.find((t) => t.id === Number(v));
    },
    toUrlParam: (v) => v?.id?.toString() ?? '',
    retryInitWhen: uploadTypesQuery.isSuccess,
  });

  useDebouncyEffect(
    () => {
      const isNameAnUUID =
        nameUUIDFilter.trim().length > 0 &&
        uuidRegex.test(nameUUIDFilter.trim());
      setFilter((prev) => ({
        ...prev,
        filterName: isNameAnUUID ? '' : nameUUIDFilter.trim(),
        uploadUuid: isNameAnUUID ? nameUUIDFilter.trim() : undefined,
      }));
    },
    500,
    [nameUUIDFilter]
  );

  useEffect(() => {
    const isNameAnUUID =
      nameUUIDFilter.trim().length > 0 && uuidRegex.test(nameUUIDFilter.trim());
    setFilter((prev) => ({
      ...prev,
      orderAscending: false,
      orderBy: 'createDatetime',
      filterName: isNameAnUUID ? '' : nameUUIDFilter.trim(),
      uploadUuid: isNameAnUUID ? nameUUIDFilter.trim() : undefined,
    }));
  }, []);

  useEffect(() => {
    setFilter((prev) => ({
      ...prev,
      uploadTypeId: typeFilter?.id,
    }));
  }, [typeFilter]);

  const hasFiltersApplied = useMemo(
    () =>
      !!(
        (filter?.filterName?.trim().length ?? 0) > 0 ||
        filter?.uploadTypeId !== undefined ||
        (filter?.uploadUuid?.length ?? 0) > 0
      ),
    [filter]
  );

  const clearFilters = useCallback(() => {
    setTypeFilter(undefined);
    setNameUUIDFilter('');
  }, []);

  const downloadCsv = useCallback(() => {
    const records = uploadsSelection.getSelectionArray().map((r) => ({
      // format scanDatetime to m/d/yyyy HH:MM
      ...r,
      scanDatetime: r.scanDatetime ? formatDateAndTime(r.scanDatetime) : '',
      customerName: r?.customerId
        ? organizationsLookupTable?.[r.customerId].customerDisplayName
        : null,
    }));

    promptDownload({
      records,
      recordFieldNames: {
        name: 'Upload Name',
        uuid: 'Upload UUID',
        blockId: 'Block ID',
        container: 'Container',
        createDatetime: 'Created On',
        createdById: 'Created By',
        customerName: 'Organization Name',
        description: 'Description',
        farmId: 'Farm ID',
        id: 'ID',
        isUploaded: 'Uploaded',
        pipelineJobs: 'Pipeline Jobs',
        scanDatetime: 'Scan Date',
        updateDatetime: 'Updated On',
        updatedById: 'Updated By',
        uploadType: 'Upload Type',
        uploadTypeId: 'Upload Type ID',
        uploadUrl: 'URL',
        uploadUrlPath: 'URL Path',
        uploadUrlQuery: 'URL Query',
      },
      fileNameBase: 'uploads',
    });
  }, [uploadsSelection]);

  return (
    <div className="flex flex-col h-full w-full pt-4">
      <div className="px-4 py-2 flex flex-row gap-1 justify-between">
        <h1 className="text-3xl font-bold">Uploads</h1>
        <div className="flex flex-row gap-2 items-center">
          {uploadsSelection.hasSelectedItems && (
            <Button.Secondary
              id="clear-selection-button"
              onClick={() => {
                uploadsSelection.clearSelection();
              }}
              label={`Clear Selection (${uploadsSelection.selectionSize})`}
              icon={faXmark}
            />
          )}

          {uploadsSelection.hasSelectedItems && (
            <Button.Primary
              id="uploads-list-download-csv"
              label="Download CSV"
              icon={faFileCsv}
              onClick={downloadCsv}
            />
          )}
        </div>
      </div>
      <div className="px-4 flex flex-row gap-2 flex-wrap">
        <div className="max-w-sm w-full">
          <Input.Text.Single
            setValue={setNameUUIDFilter}
            value={nameUUIDFilter}
            placeholder={'Search by Name or UUID'}
            placeholderIcon={Input.placeholderIcons.search}
            id="qaqc-name-search"
          />
        </div>
        <Input.Select.Single
          id="upload-type-filter"
          placeholder="Type"
          options={uploadTypesQuery.data ?? []}
          optionBuilder={(o) => o?.name ?? ''}
          loading={uploadTypesQuery.isLoading}
          value={typeFilter}
          setValue={setTypeFilter}
          search={false}
          title="Type"
        />
        <Button.ClearFilter
          onClick={clearFilters}
          visible={hasFiltersApplied}
        />
      </div>

      <div className="p-4 w-full h-full">
        <Datatable
          id="admin-uploads-datatable"
          data={uploadsQuery.data?.pages.flatMap((p) => p) ?? []}
          rowHeight={50}
          style={{ ...dataTableAgerStyle, tableMinWidth: 900 }}
          loading={
            uploadsQuery.isLoading ||
            uploadsQuery.isFetchingNextPage ||
            filter === undefined
          }
          cellOnClick={() => {
            return (row) => {
              navigate(`${row.id}/details`, {
                state: { params: window.location.search },
              });
            };
          }}
          noResults={
            hasFiltersApplied
              ? {
                  title: 'No matching uploads',
                  message: 'Adjust your filters and try again',
                  action: clearFilters,
                  actionIcon: <FontAwesomeIcon icon={faFilterSlash} />,
                  actionLabel: 'Clear Filters',
                }
              : {
                  title: 'No uploads yet',
                  message: 'Upload files to get started',
                }
          }
          error={
            uploadsQuery.isError
              ? {
                  title: 'There was a problem loading uploads',
                  message: 'Try refreshing the page',
                  action: () => uploadsQuery.refetch(),
                }
              : undefined
          }
          pagination={{
            threshold: 10,
            loadNextPage: () => {
              if (
                uploadsQuery.isLoading ||
                uploadsQuery.isFetchingNextPage ||
                !uploadsQuery.hasNextPage ||
                filter === undefined
              ) {
                return;
              }
              uploadsQuery.fetchNextPage();
            },
          }}
          columns={[
            {
              label: 'Name',
              value: (row) => row.name,
              flex: 2,
              name: 'name',
            },
            {
              label: 'UUID',
              value: (row) => row.uuid,
              flex: 2,
              name: 'uuid',
            },
            {
              label: 'Uploaded On',
              value: (row) =>
                row?.createDatetime ? formatDate(row.createDatetime) : '',
              flex: 1,
              name: 'createDatetime',
            },
            {
              label: 'Scan Date',
              value: (row) =>
                row?.scanDatetime ? formatDate(row.scanDatetime) : '',
              flex: 1,
              name: 'scanDatetime',
            },
            {
              label: 'Organization',
              value: (row) => {
                if (!organizationsLookupTable) {
                  return <FontAwesomeIcon icon={faCircleNotch} spin />;
                }

                if (!row.customerId) {
                  return '';
                }

                const c = organizationsLookupTable[row.customerId];
                return c.customerDisplayName ?? c.customerName ?? null;
              },
              flex: 1,
              name: 'organization',
            },
            {
              label: 'Description',
              value: (row) => row.description,
              flex: 2,
              name: 'description',
            },
            {
              label: 'Type',
              value: (row) => row.uploadType?.name,
              flex: 2,
              name: 'type',
            },
            {
              label: (
                <div className="p-1">
                  {uploadsSelection.isEverythingSelected ? (
                    <Button.Small
                      id="analytic-requests-list-deselect-all"
                      label="Deselect All"
                      onClick={() => {
                        uploadsSelection.toggleSelectionEverything();
                      }}
                      icon={faSolidCircleCheck}
                      iconColor="text-accent"
                    />
                  ) : (
                    <Button.Small
                      id="analytic-requests-list-select-all"
                      label="Select All"
                      onClick={() => {
                        uploadsSelection.toggleSelectionEverything();
                      }}
                      icon={faCircleCheck}
                    />
                  )}
                </div>
              ),
              value: function Select(row, index) {
                const isSelected = row.id
                  ? uploadsSelection.isSelected(row.id)
                  : false;

                return (
                  <div className="p-1">
                    <Button.Icon
                      id={`select-button-${index}`}
                      icon={isSelected ? faSolidCircleCheck : faCircleCheck}
                      onClick={(e) => {
                        if (!row.id) {
                          return;
                        }
                        if (e.shiftKey) {
                          uploadsSelection.addBulkSelectionUntilItem(
                            row.id,
                            row
                          );
                        } else {
                          uploadsSelection.toggleSelection(row.id, row);
                        }
                      }}
                      iconColor={isSelected ? 'text-accent' : undefined}
                    />
                  </div>
                );
              },
              style: { columnWrapperStyle: 'flex justify-center' },
            },
          ]}
        />
      </div>
    </div>
  );
};

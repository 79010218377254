import { TransferProgressEvent } from '@azure/core-http';
import {
  BlobServiceClient,
  BlockBlobParallelUploadOptions,
  ContainerClient,
} from '@azure/storage-blob';
import {
  faCancel,
  faCircleCheck,
  faCircleNotch,
  faEdit,
  faPlus,
  faTimes,
  faTrash,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import crypto from 'crypto';
import exifr from 'exifr';
import { DatatableOld } from 'libs/feature/src/datatable/datatable-old';
import {
  OpenLayerMapController,
  OpenLayerMapProps,
} from 'libs/feature/src/open-layer-map/open-layer-map.utilities';
import mixpanel from 'mixpanel-browser';
import { MapBrowserEvent } from 'ol';
import { createEmpty, extend } from 'ol/extent';
import Point from 'ol/geom/Point';
import {
  MutableRefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { UseMutateReturn } from 'restful-react';
import compare from 'trivial-compare';

import {
  Capture,
  Customer,
  Upload,
  UploadFile,
  usePostPipelineJob,
  usePostUpload,
  usePostUploadFile,
  usePutUploadById,
  usePutUploadFileById,
} from '@agerpoint/api';
import { Input, PrimaryButton } from '@agerpoint/component';
import { useBackgroundTaskManager } from '@agerpoint/feature';
import {
  BackgroundTaskArgs,
  BackgroundTaskResult,
  CogFile,
  ExtractionsFile,
  LazFile,
  selectedUploadFileDotStyle,
  unSelectedUploadFileDotStyle,
} from '@agerpoint/types';
import { MixpanelNames, UploadFileWithExif } from '@agerpoint/types';
import {
  useFormValidation,
  useGlobalStore,
  useItemSelection,
  useToasts,
} from '@agerpoint/utilities';

import { NewUploadType } from '../new-upload-page';
import {
  removeUploadFileHashDuplicates,
  renameUploadFileNameDuplicates,
} from '../new-upload-page.utilities';
import { FileExifOverlay } from './file-exif-overlay';

interface NewUploadTabProps {
  organizations: Customer[] | null;
  goToTaskGroup: (id: string) => void;
  mapController?: OpenLayerMapController;
  setMapProps: React.Dispatch<
    React.SetStateAction<OpenLayerMapProps<UploadFileWithExif>>
  >;
  type: NewUploadType;
  isVisible: boolean;
}

export const NewUploadTab = ({
  organizations,
  goToTaskGroup,
  mapController,
  setMapProps,
  isVisible,
  type,
}: NewUploadTabProps) => {
  const {
    actions: { sendEvent },
  } = useGlobalStore();

  const toasts = useToasts();

  const { createBackgroundTask, addTaskGroup, updateTaskProgress } =
    useBackgroundTaskManager();

  const [startingUpload, setStartingUpload] = useState(false);
  const [autorunPipeline, setAutorunPipeline] = useState(true);
  const [imageSourceCreateTime, setImageSourceCreateTime] = useState<
    string | undefined
  >();

  const [highlightedFile, setHighlightedFile] = useState<UploadFileWithExif>();
  const [showHighlightedFile, setShowHighlightedFile] = useState(false);
  const [showEditableScanDatetime, setShowEditableScanDatetime] =
    useState(false);

  const imageFileInputRef: MutableRefObject<HTMLInputElement | null> =
    useRef<HTMLInputElement>(null);

  const { mutate: postUpload } = usePostUpload({}) as unknown as {
    mutate: (upload: Upload) => Promise<Upload>;
  };

  const { mutate: putUpload } = usePutUploadById({
    id: NaN,
  });

  const { mutate: postPipelineJob } = usePostPipelineJob({});

  const { mutate: postUploadFile } = usePostUploadFile(
    {}
  ) as unknown as UseMutateReturn<UploadFile, void, UploadFile, void, void>;

  const { mutate: putUploadFile } = usePutUploadFileById({ id: NaN });

  const getUploadTypeId = useCallback(() => {
    if (type === NewUploadType.images) {
      return 2;
    }
    if (type === NewUploadType.laz) {
      return 3;
    }

    if (type === NewUploadType.cog) {
      return 4;
    }

    if (type == NewUploadType.extractions) {
      return 5;
    }

    return 2;
  }, [type]);

  const [newUpload, setNewUpload] = useState<Upload>({
    name: '',
    description: '',
    uploadTypeId: getUploadTypeId(),
    scanDatetime: '',
  });

  const [selectedOrganization, setSelectedOrganization] = useState<Customer>();

  const [selectedCaptureToRunPipelineOn, setSelectedCaptureToRunPipelineOn] =
    useState<Capture>();

  useEffect(() => {
    if (organizations?.length === 1) {
      const org = organizations[0];
      setNewUpload((prev) => ({
        ...prev,
        customerId: org.id,
      }));
      setSelectedOrganization(org);
    }
  }, [organizations]);

  const [addedFiles, setAddedFiles] = useState<UploadFileWithExif[]>([]);
  const [lazFile, setLazFile] = useState<LazFile>();
  const [cogFile, setCogFile] = useState<CogFile>();
  const [extractionsFile, setExtractionsFile] = useState<ExtractionsFile>();

  const sortedAddedFiles = useMemo(() => {
    const copy = [...addedFiles];
    copy.sort((a, b) => compare(a.name, b.name));
    return copy;
  }, [addedFiles]);

  const fileSelection = useItemSelection<string, Partial<UploadFileWithExif>>({
    items: sortedAddedFiles,
    idField: 'name',
    dependencies: [sortedAddedFiles.length],
  });

  useEffect(() => {
    setNewUpload((prev) => ({
      ...prev,
      uploadTypeId: getUploadTypeId(),
      customerId:
        type === NewUploadType.laz || type === NewUploadType.extractions
          ? undefined
          : prev.customerId,
    }));

    formValidation.advancedCallbacks.reset();

    if (type === NewUploadType.laz || type === NewUploadType.extractions) {
      setSelectedOrganization(undefined);
    }

    setAddedFiles([]);
    setCogFile(undefined);
    setLazFile(undefined);
    setExtractionsFile(undefined);

    mapController?.refreshView?.();

    setSelectedCaptureToRunPipelineOn(undefined);
  }, [mapController, type]);

  const removeSelectedFiles = useCallback(() => {
    const toDelete = fileSelection.getSelectionArray().map((f) => f.name);
    const newFiles = addedFiles.filter((f) => !toDelete.includes(f.name));
    setAddedFiles(newFiles);
  }, [fileSelection, addedFiles]);

  const onExtractionsFileAdded = useCallback(
    async (file: File | undefined) => {
      if (!file) {
        setExtractionsFile(undefined);
        return;
      }

      try {
        const text = await file.text();
        const json = JSON.parse(text);
        const extFile: ExtractionsFile = {
          data: file,
          geoJson: json,
          reuploadAttempt: 0,
        };

        setExtractionsFile(extFile);
      } catch (e) {
        const extFile: ExtractionsFile = {
          data: file,
          geoJson: JSON.parse('{}'),
          reuploadAttempt: 0,
        };

        setExtractionsFile(extFile);

        return;
      }
    },
    [mapController]
  );

  const onCogFileAdded = useCallback(async (file: File | undefined) => {
    if (!file) {
      setCogFile(undefined);
      return;
    }

    const cog: CogFile = {
      data: file,
      reuploadAttempt: 0,
    };

    setCogFile(cog);
  }, []);

  const onLazFileAdded = useCallback(
    async (file: File | undefined) => {
      if (!file) {
        setLazFile(undefined);
        return;
      }

      const laz: LazFile = {
        data: file,
        reuploadAttempt: 0,
      };

      setLazFile(laz);
    },
    [toasts]
  );

  const onImageFilesAdded = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      if (!event.target.files?.length) {
        return;
      }
      const supportedExtensions = ['.jpeg', '.jpg', '.png'];
      let everyExifCorrect = true;
      const targetFiles: File[] = Array.from(event.target.files).filter((f) =>
        supportedExtensions.some((e) => f.name.toLowerCase().includes(e))
      );

      // Unsupported files selected
      if (event.target.files.length !== targetFiles.length) {
        toasts.add({
          title: 'Unsupported file extensions.',
          message: 'Filtered out files with unsupported extensions.',
          type: 'info',
        });
      }

      if (!targetFiles.length) {
        return;
      }

      let files: UploadFileWithExif[] = [];

      const getExifField = (source: any, key: string, required = false) => {
        if (source === undefined) {
          if (required) {
            throw Error(`Missing required exif field: ${key}`);
          } else {
            return undefined;
          }
        }
        if (key in source) {
          const value = source[key];
          delete source[key];
          return value;
        }
        if (required) {
          throw Error(`Missing required exif field: ${key}`);
        }
        return undefined;
      };

      // Prepare Added files
      for (const file of targetFiles) {
        const exif = await exifr.parse(file);

        try {
          // Cherry pick exif meta data
          const gps = await exifr.gps(file);
          const latitude = getExifField(gps, 'latitude', true);
          const longitude = getExifField(gps, 'longitude', true);
          const altitude = getExifField(exif, 'GPSAltitude');
          const createDate = getExifField(exif, 'CreateDate');
          const modifyDate = getExifField(exif, 'ModifyDate');
          const width = getExifField(exif, 'ExifImageWidth');
          const height = getExifField(exif, 'ExifImageHeight');
          const make = getExifField(exif, 'Make');
          const model = getExifField(exif, 'Model');
          const description = getExifField(exif, 'ImageDescription');

          const fileExif = {
            gps: { latitude, longitude },
            createDate: createDate,
            modifyDate: modifyDate,
            width: width,
            height: height,
            altitude: altitude,
            description: description,
            fileSize: file.size,
            other: exif,
            cameraName: [make ?? '', model ?? ''].join(' ').trim(),
          };

          const sha1 = crypto.createHash('sha1');
          sha1.update(JSON.stringify(fileExif));
          const hash = sha1.digest('hex');
          files.push({
            data: file,
            exif: fileExif,
            name: file.name,
            hash,
            reuploadAttempt: 0,
          });
        } catch (e) {
          everyExifCorrect = false;
          continue;
        }
      }

      if (!everyExifCorrect) {
        toasts.add({
          title: 'File Exif incomplete.',
          message: 'Filtered out files with incomplete required exif data.',
          type: 'info',
        });
      }

      // Merge Added files with the existing ones
      files = [...addedFiles, ...files];

      // Remove file duplicates by exif
      const removalResult = removeUploadFileHashDuplicates(files);
      files = [...removalResult.files];

      if (removalResult.duplicatesDetected) {
        toasts.add({
          title: 'Automatically excluded detected duplicate images.',
          type: 'info',
        });
      }

      // Rename file name duplicates
      files = [...renameUploadFileNameDuplicates(files)];

      // Zoom to files
      const extent = createEmpty();
      for (const file of files) {
        const point = new Point([
          file.exif.gps.longitude,
          file.exif.gps.latitude,
        ]).transform('EPSG:4326', 'EPSG:3857');
        extend(extent, point.getExtent());
      }

      mapController?.zoomMapToExtent?.(extent);

      // Clear file input
      event.target.value = '';

      setAddedFiles(files);
    },
    [addedFiles, mapController]
  );

  const resetNewUploadPage = useCallback(() => {
    let orgId: number | undefined;
    if (organizations?.length === 1) {
      orgId = organizations[0].id;
    }

    setNewUpload({
      name: '',
      description: '',
      uploadTypeId: getUploadTypeId(),
      customerId: orgId,
    });

    setHighlightedFile(undefined);
    setShowHighlightedFile(false);
    fileSelection.clearSelection();
    if (orgId) {
      setSelectedOrganization(organizations?.find((o) => o.id === orgId));
    } else {
      setSelectedOrganization(undefined);
    }
    setAddedFiles([]);
    setStartingUpload(false);
    setSelectedCaptureToRunPipelineOn(undefined);
    setAutorunPipeline(true);
    setLazFile(undefined);
    setCogFile(undefined);
    setExtractionsFile(undefined);
    mapController?.refreshView?.();
  }, [mapController, organizations, type]);

  const createImagesUploadTasks = useCallback(
    (createdUpload: Upload, containerClient: ContainerClient) => {
      const tasks: BackgroundTaskArgs[] = [];

      for (const file of sortedAddedFiles) {
        if (!file.data) {
          continue;
        }

        const task = createBackgroundTask(
          file.name,
          async (resolve, reject, abortSignal, groupId, taskId) => {
            if (!createdUpload) {
              resolve({
                type: BackgroundTaskResult.error,
                payload: new Error(
                  'Failed to pass the created upload to the task'
                ),
              });
              return;
            }
            if (file.uploadFile === undefined) {
              const uploadFile = await postUploadFile(
                {
                  isUploaded: false,
                  latitude: file.exif.gps.latitude,
                  longitude: file.exif.gps.longitude,
                  name: file.name,
                  originalName: file.data.name,
                  uploadId: createdUpload.id,
                },
                { signal: abortSignal }
              );
              file.uploadFile = uploadFile;
            }

            const options: BlockBlobParallelUploadOptions = {
              blobHTTPHeaders: { blobContentType: file.data.type },
              onProgress: (progressEvent: TransferProgressEvent) => {
                const progress = Math.round(
                  (100 * progressEvent.loadedBytes) / file.data.size
                );
                updateTaskProgress(groupId, taskId, progress);
              },
              abortSignal: abortSignal,
              blockSize: file.data.size,
            };

            const blobClient = containerClient.getBlockBlobClient(file.name);
            await blobClient.uploadData(file.data, options);

            if (!file.uploadFile || !file.uploadFile.id) {
              resolve({
                type: BackgroundTaskResult.error,
                payload: new Error(
                  'Failed to finalize the created upload to the task'
                ),
              });
              return;
            }
            file.uploadFile.isUploaded = true;
            await putUploadFile(file.uploadFile, {
              pathParams: { id: file.uploadFile.id },
              signal: abortSignal,
            });

            resolve({ type: BackgroundTaskResult.success });
          },
          file
        );
        tasks.push(task);
      }

      return tasks;
    },
    [sortedAddedFiles]
  );

  const createCogUploadTask = useCallback(
    (createdUpload: Upload, containerClient: ContainerClient) => {
      const tasks: BackgroundTaskArgs[] = [];
      if (!cogFile) {
        throw new Error('No cog file selected');
      }

      const task = createBackgroundTask(
        cogFile.data.name,
        async (resolve, reject, abortSignal, groupId, taskId) => {
          if (!createdUpload) {
            resolve({
              type: BackgroundTaskResult.error,
              payload: new Error(
                'Failed to pass the created upload to the task'
              ),
            });
            return;
          }

          cogFile.autorunPipeline = autorunPipeline;

          if (cogFile.uploadFile === undefined) {
            const uploadFile = await postUploadFile(
              {
                isUploaded: false,
                name: cogFile.data.name,
                originalName: cogFile.data.name,
                uploadId: createdUpload.id,
              },
              { signal: abortSignal }
            );
            cogFile.uploadFile = uploadFile;
          }

          const options: BlockBlobParallelUploadOptions = {
            blobHTTPHeaders: { blobContentType: cogFile.data.type },
            onProgress: (progressEvent: TransferProgressEvent) => {
              const progress = Math.round(
                (100 * progressEvent.loadedBytes) / cogFile.data.size
              );
              updateTaskProgress(groupId, taskId, progress);
            },
            abortSignal: abortSignal,
            blockSize: cogFile.data.size,
          };

          const blobClient = containerClient.getBlockBlobClient(
            cogFile.data.name
          );
          await blobClient.uploadData(cogFile.data, options);

          if (!cogFile.uploadFile || !cogFile.uploadFile.id) {
            resolve({
              type: BackgroundTaskResult.error,
              payload: new Error(
                'Failed to finalize the created upload to the task'
              ),
            });
            return;
          }
          cogFile.uploadFile.isUploaded = true;
          await putUploadFile(cogFile.uploadFile, {
            pathParams: { id: cogFile.uploadFile.id },
            signal: abortSignal,
          });

          resolve({ type: BackgroundTaskResult.success });
        },

        cogFile
      );
      tasks.push(task);

      return tasks;
    },
    [cogFile]
  );

  const createLazUploadTask = useCallback(
    (createdUpload: Upload, containerClient: ContainerClient) => {
      const tasks: BackgroundTaskArgs[] = [];

      if (!lazFile) {
        throw new Error('No laz file selected');
      }

      const task = createBackgroundTask(
        lazFile.data.name,
        async (resolve, reject, abortSignal, groupId, taskId) => {
          if (!createdUpload) {
            resolve({
              type: BackgroundTaskResult.error,
              payload: new Error(
                'Failed to pass the created upload to the task'
              ),
            });
            return;
          }

          lazFile.autorunPipeline = autorunPipeline;

          if (
            lazFile.capture === undefined &&
            selectedCaptureToRunPipelineOn &&
            autorunPipeline
          ) {
            lazFile.capture = { ...selectedCaptureToRunPipelineOn };
          }

          if (lazFile.uploadFile === undefined) {
            const uploadFile = await postUploadFile(
              {
                isUploaded: false,
                name: lazFile.data.name,
                originalName: lazFile.data.name,
                uploadId: createdUpload.id,
              },
              { signal: abortSignal }
            );
            lazFile.uploadFile = uploadFile;
          }

          const options: BlockBlobParallelUploadOptions = {
            blobHTTPHeaders: { blobContentType: lazFile.data.type },
            onProgress: (progressEvent: TransferProgressEvent) => {
              const progress = Math.round(
                (100 * progressEvent.loadedBytes) / lazFile.data.size
              );
              updateTaskProgress(groupId, taskId, progress);
            },
            abortSignal: abortSignal,
            blockSize: lazFile.data.size,
          };

          const blobClient = containerClient.getBlockBlobClient(
            lazFile.data.name
          );
          await blobClient.uploadData(lazFile.data, options);

          if (!lazFile.uploadFile || !lazFile.uploadFile.id) {
            resolve({
              type: BackgroundTaskResult.error,
              payload: new Error(
                'Failed to finalize the created upload to the task'
              ),
            });
            return;
          }
          lazFile.uploadFile.isUploaded = true;
          await putUploadFile(lazFile.uploadFile, {
            pathParams: { id: lazFile.uploadFile.id },
            signal: abortSignal,
          });

          resolve({ type: BackgroundTaskResult.success });
        },
        lazFile
      );
      tasks.push(task);

      return tasks;
    },
    [lazFile, selectedCaptureToRunPipelineOn, autorunPipeline]
  );

  const createExtractionsUploadTask = useCallback(
    (createdUpload: Upload, containerClient: ContainerClient) => {
      const tasks: BackgroundTaskArgs[] = [];

      if (!extractionsFile) {
        throw new Error('No geojson file selected');
      }

      const task = createBackgroundTask(
        extractionsFile.data.name,
        async (resolve, reject, abortSignal, groupId, taskId) => {
          if (!createdUpload) {
            resolve({
              type: BackgroundTaskResult.error,
              payload: new Error(
                'Failed to pass the created upload to the task'
              ),
            });
            return;
          }

          if (
            extractionsFile.capture === undefined &&
            selectedCaptureToRunPipelineOn
          ) {
            extractionsFile.capture = { ...selectedCaptureToRunPipelineOn };
          }

          if (extractionsFile.uploadFile === undefined) {
            const uploadFile = await postUploadFile(
              {
                isUploaded: false,
                name: extractionsFile.data.name,
                originalName: extractionsFile.data.name,
                uploadId: createdUpload.id,
              },
              { signal: abortSignal }
            );
            extractionsFile.uploadFile = uploadFile;
          }

          const options: BlockBlobParallelUploadOptions = {
            blobHTTPHeaders: { blobContentType: extractionsFile.data.type },
            onProgress: (progressEvent: TransferProgressEvent) => {
              const progress = Math.round(
                (100 * progressEvent.loadedBytes) / extractionsFile.data.size
              );
              updateTaskProgress(groupId, taskId, progress);
            },
            abortSignal: abortSignal,
            blockSize: extractionsFile.data.size,
          };

          const blobClient = containerClient.getBlockBlobClient(
            extractionsFile.data.name
          );
          await blobClient.uploadData(extractionsFile.data, options);

          if (!extractionsFile.uploadFile || !extractionsFile.uploadFile.id) {
            resolve({
              type: BackgroundTaskResult.error,
              payload: new Error(
                'Failed to finalize the created upload to the task'
              ),
            });
            return;
          }
          extractionsFile.uploadFile.isUploaded = true;
          await putUploadFile(extractionsFile.uploadFile, {
            pathParams: { id: extractionsFile.uploadFile.id },
            signal: abortSignal,
          });

          resolve({ type: BackgroundTaskResult.success });
        },
        extractionsFile
      );
      tasks.push(task);

      return tasks;
    },
    [extractionsFile, selectedCaptureToRunPipelineOn]
  );

  const formValidation = useFormValidation();

  useEffect(() => {
    if (showEditableScanDatetime) return;
    const fileWithCreateTime = addedFiles?.find(
      (file) => file?.exif?.createDate
    );
    if (fileWithCreateTime) {
      const createTime = fileWithCreateTime.exif.createDate;
      if (createTime) {
        const date = new Date(createTime);
        const formattedDate = date.toISOString();
        setImageSourceCreateTime(formattedDate);
      } else {
        setImageSourceCreateTime(undefined);
      }
    } else {
      setImageSourceCreateTime(undefined);
    }
  }, [addedFiles, showEditableScanDatetime]);

  useEffect(() => {
    if (type === NewUploadType.images) {
      formValidation.advancedCallbacks.subscribe({
        key: 'image-files',
        value: addedFiles,
        validators: [
          Input.validators.required('At least one file is required', true),
        ],
      });
      return () => {
        formValidation.advancedCallbacks.unsubscribe('image-files');
      };
    }

    return () => {};
  }, [type]);

  useEffect(() => {
    formValidation.advancedCallbacks.update('image-files', addedFiles);
  }, [addedFiles]);

  const startUploadProcess = useCallback(async () => {
    if (await formValidation.hasErrors()) {
      return;
    }

    const filesQuantity = type === NewUploadType.images ? addedFiles.length : 1;

    sendEvent(MixpanelNames.MetricUploadStarted, {
      filesQuantity: filesQuantity,
      type: type,
    });

    mixpanel.time_event(MixpanelNames.MetricUploadFinished);

    setStartingUpload(true);

    let createdUpload: Upload | undefined;
    try {
      createdUpload = await postUpload(newUpload);

      if (!createdUpload.uploadUrl) {
        throw new Error('Not enough data to start an upload');
      }
    } catch (e) {
      setStartingUpload(false);

      sendEvent(MixpanelNames.MetricUploadFinished, {
        filesQuantity: filesQuantity,
        type: type,
      });
      console.error(e);

      toasts.add(toasts.prepare.error('Failed to start an upload!'));

      return;
    }
    if (!createdUpload || !createdUpload.uploadUrl) {
      return;
    }

    const blobService = new BlobServiceClient(createdUpload.uploadUrl);

    // Leave the containerName empty!
    const containerClient = blobService.getContainerClient('');

    let tasks: BackgroundTaskArgs[] = [];

    try {
      if (type === NewUploadType.images) {
        tasks = createImagesUploadTasks(createdUpload, containerClient);
      } else if (type === NewUploadType.cog) {
        tasks = createCogUploadTask(createdUpload, containerClient);
      } else if (type === NewUploadType.laz) {
        tasks = createLazUploadTask(createdUpload, containerClient);
      } else if (type === NewUploadType.extractions) {
        tasks = createExtractionsUploadTask(createdUpload, containerClient);
      }
    } catch (e) {
      console.error(e);
      setStartingUpload(false);

      toasts.add(toasts.prepare.error('Failed to start an upload!'));

      sendEvent(MixpanelNames.MetricUploadFinished, {
        filesQuantity: filesQuantity,
        type: type,
      });
      return;
    }

    const id = addTaskGroup({
      groupDesc: `Upload: ${newUpload.name}`,
      groupCustomPayload: newUpload,
      tags: ['upload', type],
      groupTasks: tasks,
      isCancellable: true,
      onSuccess: async () => {
        sendEvent(MixpanelNames.MetricUploadFinished, {
          filesQuantity: filesQuantity,
          type: type,
        });
        if (!createdUpload || !createdUpload.id) {
          toasts.add(toasts.prepare.error('Failed to finalize the upload!'));

          throw new Error('Missing created upload or created upload id');
        } else {
          createdUpload.isUploaded = true;
          try {
            await putUpload(createdUpload, {
              pathParams: { id: createdUpload.id },
            });

            toasts.add({
              title: `${createdUpload?.name} upload finished successfully!`,
              type: 'success',
            });
          } catch (e) {
            console.error(e);

            toasts.add(toasts.prepare.error('Failed to finalize the upload!'));

            throw e;
          }
        }

        // Pipeline autorun
        if (
          autorunPipeline &&
          (type === NewUploadType.laz || type === NewUploadType.cog)
        ) {
          try {
            if (type === NewUploadType.laz) {
              if (!selectedCaptureToRunPipelineOn?.id) {
                toasts.add(toasts.prepare.error('Failed to autorun pipeline!'));

                return;
              }
              await postPipelineJob({
                captureId: selectedCaptureToRunPipelineOn?.id,
                uploadId: createdUpload.id,
                name: createdUpload.name,
                pipelineId: 4,
              });
            }

            if (type === NewUploadType.cog) {
              await postPipelineJob({
                uploadId: createdUpload.id,
                name: createdUpload.name,
                pipelineId: 4,
              });
            }

            toasts.add({
              title: `Pipeline autorun for ${createdUpload?.name} upload triggered successfully!`,
              type: 'success',
            });
          } catch (e) {
            console.error(e);

            toasts.add(toasts.prepare.error('Failed to autorun pipeline!'));

            throw e;
          }
        }
      },
      onError: async (group) => {
        const failedUploadsCount = group.tasks.reduce(
          (curr, prev) =>
            prev.result?.type === BackgroundTaskResult.error ? curr + 1 : curr,
          0
        );
        sendEvent(MixpanelNames.MetricUploadFailed, {
          filesQuantity: filesQuantity,
          failedCount: failedUploadsCount,
          type: type,
        });

        toasts.add(toasts.prepare.error('Failed to upload files!'));
      },
    });

    toasts.add({
      title: `Your upload is being processed.`,
      type: 'info',
    });
    goToTaskGroup(id);
    resetNewUploadPage();
  }, [
    sortedAddedFiles,
    newUpload,
    resetNewUploadPage,
    createImagesUploadTasks,
    createCogUploadTask,
    createExtractionsUploadTask,
    createLazUploadTask,
    type,
    selectedCaptureToRunPipelineOn,
    autorunPipeline,
    formValidation,
  ]);

  const onDragBoxSelect = useCallback(
    (ids: (string | number)[]) => {
      const selection: { key: string; value: Partial<UploadFileWithExif> }[] =
        [];
      for (const file of addedFiles) {
        if (ids.includes(file.name)) {
          selection.push({ key: file.name, value: file });
        }
      }

      fileSelection.addSelectionList(selection);
    },
    [addedFiles, fileSelection.selectionSize]
  );

  const onFeatureClick = useCallback(
    (id: string | number, event: MapBrowserEvent<PointerEvent>) => {
      for (const file of addedFiles) {
        if (file.name === id) {
          fileSelection.toggleSelection(id, file);
          return;
        }
      }
    },
    [addedFiles, fileSelection.selectionSize]
  );

  useEffect(() => {
    if (isVisible && type === NewUploadType.images) {
      setMapProps((prev) => ({
        ...prev,
        featureLayer:
          type === NewUploadType.images
            ? {
                data: sortedAddedFiles,
                featureGenerator: (file) => {
                  return {
                    id: file.name,
                    latitude: file.exif.gps.latitude,
                    longitude: file.exif.gps.longitude,
                    name: file.name,
                    style: fileSelection.isSelected(file.name)
                      ? 'selected'
                      : 'default',
                  };
                },
                styles: {
                  default: unSelectedUploadFileDotStyle,
                  selected: selectedUploadFileDotStyle,
                },
              }
            : undefined,
        callbacks:
          type === NewUploadType.images
            ? {
                onDragBoxSelect,
                onFeatureClick,
              }
            : undefined,
        dependencies: [fileSelection.selectionSize, extractionsFile],
      }));
    }
  }, [
    sortedAddedFiles,
    fileSelection.selectionSize,
    onDragBoxSelect,
    onFeatureClick,
    unSelectedUploadFileDotStyle,
    selectedUploadFileDotStyle,
    isVisible,
    extractionsFile,
    type,
  ]);

  useEffect(() => {
    if (type !== NewUploadType.images) {
      return;
    }

    if (isVisible && mapController) {
      if (addedFiles.length > 0) {
        const extent = createEmpty();
        for (const file of addedFiles) {
          const point = new Point([
            file.exif.gps.longitude,
            file.exif.gps.latitude,
          ]).transform('EPSG:4326', 'EPSG:3857');
          extend(extent, point.getExtent());
        }

        mapController?.zoomMapToExtent?.(extent);
      } else {
        mapController?.refreshView?.();
      }
    }
    if (!isVisible) {
      fileSelection.clearSelection();
    }
  }, [isVisible]);

  useEffect(() => {
    setNewUpload((prev) => ({
      ...prev,
      customerId: selectedOrganization?.id,
    }));
  }, [selectedOrganization]);

  useEffect(() => {
    // format date to 2024-07-22T15:19:40.768Z
    let formattedForDb = '';
    if (imageSourceCreateTime) {
      const date = new Date(imageSourceCreateTime);
      formattedForDb = date.toISOString();
    }

    setNewUpload((prev) => ({
      ...prev,
      scanDatetime: formattedForDb,
    }));
  }, [imageSourceCreateTime]);

  const showEditableDateToggle = useCallback(() => {
    setShowEditableScanDatetime(!showEditableScanDatetime);
  }, [showEditableScanDatetime]);

  return (
    <>
      <div className="pt-2">
        <Input.Text.Single
          id="upload-name-input"
          value={newUpload.name ?? ''}
          setValue={(name) => {
            setNewUpload((prev) => ({ ...prev, name }));
          }}
          label={<Input.Label label="Upload Name" required />}
          error={
            <Input.Error error={formValidation.errors['upload-name-input']} />
          }
          validation={{
            validationState: formValidation,
            validators: [Input.validators.required('Name')],
          }}
        />
      </div>
      {(type === NewUploadType.cog || type === NewUploadType.images) && (
        <div className="pt-1">
          <Input.Select.Single
            id="upload-organization-select"
            options={organizations ?? []}
            optionBuilder={(o) =>
              o.customerDisplayName ?? o.customerName ?? 'Unknown'
            }
            value={selectedOrganization}
            loading={(organizations?.length ?? 0) === 0}
            setValue={setSelectedOrganization}
            label={<Input.Label label="Organization" required />}
            title={'Organization'}
            disabled={(organizations?.length ?? 0) === 1}
            error={
              <Input.Error
                error={formValidation.errors['upload-organization-select']}
              />
            }
            validation={{
              validationState: formValidation,
              validators: [Input.validators.required('Organization')],
            }}
          />
        </div>
      )}
      <div className="pt-1">
        <Input.Text.Area
          id="upload-description"
          rows={2}
          value={newUpload.description ?? ''}
          setValue={(description) => {
            setNewUpload((prev) => ({ ...prev, description }));
          }}
          label={<Input.Label label="Description" />}
        />
      </div>
      {type === NewUploadType.laz && (
        <Input.File.Single
          id="laz-file-select"
          value={lazFile?.data}
          acceptExtensions={['laz']}
          setValue={onLazFileAdded}
          label={<Input.Label label="LAZ File" required />}
          error={
            <Input.Error error={formValidation.errors['laz-file-select']} />
          }
          placeholder="Select .laz file"
          validation={{
            validationState: formValidation,
            validators: [
              Input.validators.required('LAZ File'),
              Input.validators.validExtensions(['laz']),
            ],
          }}
        />
      )}
      {type === NewUploadType.cog && (
        <Input.File.Single
          id="cog-file-select"
          value={cogFile?.data}
          acceptExtensions={['tif', 'tiff']}
          setValue={onCogFileAdded}
          label={<Input.Label label="COG File" required />}
          error={
            <Input.Error error={formValidation.errors['cog-file-select']} />
          }
          placeholder="Select .tif/.tiff file"
          validation={{
            validationState: formValidation,
            validators: [
              Input.validators.required('COG File'),
              Input.validators.validExtensions(['tif', 'tiff']),
            ],
          }}
        />
      )}
      {type === NewUploadType.extractions && (
        <Input.File.Single
          id="ext-file-select"
          value={extractionsFile?.data}
          acceptExtensions={['geojson', 'json']}
          setValue={onExtractionsFileAdded}
          label={<Input.Label label="GeoJSON File" required />}
          error={
            <Input.Error error={formValidation.errors['ext-file-select']} />
          }
          placeholder="Select .geojson/.json file"
          validation={{
            validationState: formValidation,
            validators: [
              Input.validators.required('GeoJSON File'),
              Input.validators.validExtensions(['geojson', 'json']),
            ],
          }}
        />
      )}
      {(type === NewUploadType.cog || type === NewUploadType.laz) && (
        <div className="pt-2">
          <Input.Checkbox
            id="autorun-pipeline-checkbox"
            label={<Input.Label label="Autorun pipeline" />}
            value={autorunPipeline}
            setValue={(autorunPipeline) => {
              setSelectedCaptureToRunPipelineOn(undefined);
              setAutorunPipeline(autorunPipeline);
            }}
          />
        </div>
      )}
      {((type === NewUploadType.laz && autorunPipeline) ||
        type == NewUploadType.extractions) && (
        <>
          <div className="z-30">
            <Input.Capture
              id="new-upload-capture-select"
              value={selectedCaptureToRunPipelineOn}
              setValue={setSelectedCaptureToRunPipelineOn}
              placeholder="Search by Capture Name"
              placeholderIcon={Input.placeholderIcons.search}
              label={<Input.Label label="Capture" required />}
              error={
                <Input.Error
                  error={formValidation.errors['new-upload-capture-select']}
                />
              }
              showUUIDs={true}
              validation={{
                validationState: formValidation,
                validators: [Input.validators.required('Capture')],
              }}
            />
          </div>
        </>
      )}
      {type === NewUploadType.images && (
        <>
          <div className="w-full pt-1">
            <Input.Label label={`Images`} required />
          </div>
          <Input.Error error={formValidation.errors['image-files']} />
          <div className="flex-grow flex flex-col w-full relative overflow-hidden rounded-md">
            <div
              className={`absolute h-full w-full bg-white border border-gray-500
            rounded-md transition-transform transform z-10 ${
              showHighlightedFile ? 'translate-y-0' : 'translate-y-full'
            }`}
            >
              <FileExifOverlay
                closeOverlay={() => setShowHighlightedFile(false)}
                mapController={mapController}
                file={highlightedFile}
              />
            </div>
            <div className="w-full h-full">
              <DatatableOld
                rowHeight={30}
                data={sortedAddedFiles}
                cellOnClick={(name) => {
                  if (name === 'fileName') {
                    return (row) => {
                      setHighlightedFile(row);
                      setShowHighlightedFile(true);
                    };
                  }
                  return;
                }}
                style={{
                  tableWrapperStyle:
                    'bg-white rounded-t-md border border-gray-500',
                  headerWrapperStyle:
                    'px-2 text-xs text-gray-700 font-normal border-b border-gray-500',
                  rowWrapperStyle:
                    'px-2 items-center text-sm hover:bg-gray-100',
                  rowStyle: 'border-b border-gray-200',
                  headerStyle: 'px-1 py-2 h-full flex items-center',
                  cellStyle: 'px-1 flex items-center',
                }}
                columns={[
                  {
                    label: (
                      <div className="w-full h-full flex justify-center items-center">
                        <FontAwesomeIcon
                          icon={faCircleCheck}
                          className={`text-base cursor-pointer ${
                            fileSelection.isEverythingSelected
                              ? 'text-gray-900'
                              : 'text-gray-300 hover:text-gray-500'
                          }`}
                          onClick={() => {
                            fileSelection.toggleSelectionEverything();
                          }}
                        />
                      </div>
                    ),
                    value: (row) => (
                      <div className="w-full h-full flex justify-center items-center">
                        <FontAwesomeIcon
                          icon={faCircleCheck}
                          className={`text-base cursor-pointer ${
                            fileSelection.isSelected(row.name)
                              ? 'text-gray-900'
                              : 'text-gray-300 hover:text-gray-500'
                          }`}
                          onClick={(e) => {
                            if (e.shiftKey) {
                              fileSelection.addBulkSelectionUntilItem(
                                row.name,
                                row
                              );
                            } else {
                              fileSelection.toggleSelection(row.name, row);
                            }
                          }}
                        />
                      </div>
                    ),
                    style: { columnWrapperStyle: 'overflow-visible' },
                  },
                  {
                    label: (
                      <span className="flex flex-row gap-1">
                        File Name
                        <span className="text-gray-400">(.jpeg, .png)</span>
                      </span>
                    ),
                    value: (row) => row.name,
                    flex: 10,
                    name: 'fileName',
                  },
                  {
                    label: (
                      <div className="flex flex-col justify-center items-center">
                        <button
                          data-test-id="add-upload-files"
                          className={`border rounded px-1 py-0.5 whitespace-nowrap flex flex-row gap-1 justify-between items-center bg-green text-white`}
                          type="button"
                          onClick={() => {
                            imageFileInputRef.current?.click();
                          }}
                        >
                          <span>Add</span>
                          <FontAwesomeIcon icon={faPlus} />
                        </button>
                        <input
                          ref={imageFileInputRef}
                          type="file"
                          accept="image/png, image/jpeg"
                          multiple
                          className="hidden"
                          onChange={onImageFilesAdded}
                        ></input>
                      </div>
                    ),
                    style: {
                      headerStyle: 'overflow-visible justify-end',
                      bodyStyle: 'justify-end',
                    },
                    value: (row) => {
                      return null;
                    },
                    flex: 3,
                  },
                ]}
              />
            </div>
            <div
              className={`bg-white rounded-b-md w-full text-sm border-b border-x border-gray-500 p-1 pl-2 flex justify-between flex-wrap`}
            >
              <span className="whitespace-nowrap">
                {fileSelection.hasSelectedItems && (
                  <div className="flex flex-row gap-1 items-center justify-start">
                    <span>{`Selected: ${fileSelection.selectionSize}`}</span>
                    <div
                      className={`p-0.5 flex w-4 h-4 cursor-pointer justify-center items-center hover:bg-gray-300 rounded-full`}
                      onClick={removeSelectedFiles}
                    >
                      <FontAwesomeIcon icon={faTrash} className="text-xs" />
                    </div>
                    <div
                      className={`p-0.5 flex w-4 h-4 cursor-pointer justify-center items-center hover:bg-gray-300 rounded-full`}
                      onClick={() => {
                        fileSelection.clearSelection();
                      }}
                    >
                      <FontAwesomeIcon icon={faTimes} />
                    </div>
                  </div>
                )}
              </span>
              <span className="whitespace-nowrap">{`Files Added: ${addedFiles.length}`}</span>
            </div>
          </div>
        </>
      )}
      {showEditableScanDatetime ? (
        <Input.Date.Single
          id="scan-date-input"
          label={
            <div className="w-full pt-1 text-sm flex justify-between">
              <div>Scan Date</div>
              <div onClick={showEditableDateToggle} className="cursor-pointer">
                <FontAwesomeIcon icon={faCancel} />
              </div>
            </div>
          }
          value={
            imageSourceCreateTime ? new Date(imageSourceCreateTime) : undefined
          }
          setValue={(date) => {
            setImageSourceCreateTime(date?.toISOString().split('T')[0]);
          }}
        />
      ) : (
        <div className="w-full pt-1 text-sm flex flex justify-between">
          <div>{`Scan Date: ${
            imageSourceCreateTime ? imageSourceCreateTime : ''
          }`}</div>
          <div onClick={showEditableDateToggle} className="cursor-pointer">
            <FontAwesomeIcon icon={faEdit} />
          </div>
        </div>
      )}

      <div className="pt-2">
        <PrimaryButton
          className="w-full"
          label={
            startingUpload ? (
              <div className="w-6 h-6">
                <FontAwesomeIcon
                  spin
                  icon={faCircleNotch}
                  className="w-full h-full"
                />
              </div>
            ) : (
              'Upload'
            )
          }
          disabled={startingUpload}
          onClicked={startUploadProcess}
        />
      </div>
    </>
  );
};

export enum MixpanelNames {
  AppInitialized = 'App Initialized',
  CaptureListCsvExport = 'Capture List CSV Export',
  CaptureListLayoutGrid = 'Capture List Layout Grid',
  CaptureListLayoutTable = 'Capture List Layout Table',
  CaptureListOrganizationFilter = 'Capture List Organization Filter',
  CaptureListOrganizationFilterClear = 'Capture List Organization Filter Clear',
  CaptureListProjectFilter = 'Capture List Project Filter',
  CaptureListProjectFilterClear = 'Capture List Project Filter Clear',
  CaptureListSearch = 'Capture List Search',
  CaptureListSearchClear = 'Capture List Search Clear',
  CaptureListUserFilter = 'Capture List User Filter',
  CaptureListUserFilterClear = 'Capture List User Filter Clear',
  CaptureObjectAttributeDeleted = 'Capture Object Attribute Deleted',
  CaptureObjectAttributeDraggableTableHidden = 'Capture Object Attribute Draggable Table Visible',
  CaptureObjectAttributeDraggableTableVisible = 'Capture Object Attribute Draggable Table Visible',
  CaptureObjectAttributesCreated = 'Capture Object Attribute Created',
  CaptureObjectAttributesDeleted = 'Capture Object Attribute Deleted',
  CaptureObjectAttributesUpdated = 'Capture Object Attribute Updated',
  CaptureObjectCreated = 'Capture Object Created',
  CaptureObjectDeleted = 'Capture Object Deleted',
  CaptureObjectLocateToggleClicked = 'Capture Object Locate Toggle Clicked',
  CaptureObjectLockToggleClicked = 'Capture Object Lock Toggle Clicked',
  CaptureObjectModalViewed = 'Capture Object Modal Viewed',
  CaptureObjectUpdated = 'Capture Object Updated',
  CaptureObjectLabelToggleClicked = 'Capture Object Label Toggle Clicked',
  MetricCapturesTableLoaded = 'Metric Viewer Captures Table Loaded',
  MetricUploadFailed = 'New Upload Page Some Files Failed To Upload',
  MetricUploadFinished = 'New Upload Page Files Uploaded',
  MetricUploadStarted = 'New Upload Page File Upload Started',
  MetricViewerCloudLoaded = 'Metric Viewer Point Cloud Loaded',
  MetricViewerInitialized = 'Metric Viewer Initialized',
  MetricViewerToolSelected = 'Metric Viewer Tool Selected',
  PageViewed = 'Page Viewed',
  BackgroundTaskStarted = 'Background Task Started',
  BackgroundTaskPaused = 'Background Task Paused',
  BackgroundTaskCanceled = 'Background Task Canceled',
  BackgroundTaskError = 'Background Task Error',
  BackgroundTaskRetried = 'Background Task Retried',
  ProcessingRequest = 'Processing Request',
  AnalyticsRequest = 'Analytics Requested',
  AnalyticsRequestBulk = 'Analytics Requested Bulk',
  SupplementalImagesSectionOpened = 'Supplemental Images Section Opened',
  SupplementalImagesExpanded = 'Supplemental Images Expanded',
  SupplementalImagesNoteEdited = 'Supplemental Images Note Edited',
  SupplementalImagesArchived = 'Supplemental Images Archived',
  HelpButtonUsed = 'Help Button Used',
  ThreeJsPlyViewerLoaded = 'Three Js Ply Viewer Loaded',
  CapturesViewerPointCloudStyleChanged = 'Captures Viewer Point Cloud Style Changed',
  ThreeDViewerUsageTracking = 'Three D Viewer Usage Tracking',
  CaptureViewerLayoutChanged = 'Capture Viewer Layout Changed',
  QAQCCropBoxUsed = 'QAQC Crop Box Used',
  QAQCKonvaSelectionUsed = 'QAQC Konva Selection Used',
}

import mixpanel from 'mixpanel-browser';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import Stats from 'three/examples/jsm/libs/stats.module.js';

import { APIClient, APIModels } from '@agerpoint/api';
import { GsThreeDViewer } from '@agerpoint/three-d-viewer';
import {
  Annotation3dPoints,
  BackgroundOptionsValues,
  CameraState,
  EffectNames,
  EventBusNames,
  Gs3dViewerProps,
  ICustomMesh,
  IGs3dViewerController,
  LatLngAlt,
  LdFlags,
  MixpanelNames,
} from '@agerpoint/types';
import {
  AnnotationGroupName,
  GaussianSplats3D,
  eventBus,
  hasPermission,
  llaToEnu,
  useGlobalStore,
} from '@agerpoint/utilities';

import { SharedThreeDAnnotationToolbar } from '../annotations/3d/shared-toolbar/shared-toolbar';
import { useCapturesViewerContext } from '../captures-viewer';
import { Gs3DCloudTools } from './gs-three-d-cloud-tools';
import useThreeSceneSetup from './gs-three-d.hooks';
import { PlyParserCodecBase } from './gs-three-d.point-cloud.classes/PlyParserCodecBase';
import './gs-three-d.scss';

export const GsThreeDPointViewerController = ({
  controller: setController,
  showTools = false,
  showCloudTools = false,
  showLoadingIndicator = false,
  plugins,
}: Gs3dViewerProps) => {
  const { setAnnotations3dGeometry } = useCapturesViewerContext();

  const {
    permissions,
    sidebar: { isOpen: sidebarOpen },
    actions: { dispatchEffect },
  } = useGlobalStore();

  const has3dDebugPermission = useMemo(
    () => hasPermission(LdFlags.Debug3dFeatures, permissions),
    [permissions]
  );

  const viewerRef = useRef<{
    splatsViewer?: GaussianSplats3D.Viewer;
    threeViewer?: GsThreeDViewer;
  }>({});
  const [viewerError] = useState<Error | undefined>();
  const [containerRef, setContainerRef] = useState<HTMLDivElement | null>(null);
  const [viewerReady, setViewerReady] = useState(false);
  const [sceneLoaded, setSceneLoaded] = useState(false);
  const [cameraPositionsVisible, setCameraPositionsVisible] = useState(false);
  const [cameraPositionsLoaded, setCameraPositionsLoaded] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const downloadPromiseRef = useRef<any>();
  const [captureJobMetadata, setCaptureJobMetadata] =
    useState<APIModels.CaptureJob>();
  const [captureMetadata, setCaptureMetadata] = useState<APIModels.Capture>();
  const { scene, camera, renderer } = useThreeSceneSetup(containerRef);
  const [loading, setLoading] = useState({
    step: '',
    inProgress: false,
    percentage: -1,
    finished: false,
  });
  const cameraPositionOnClickRef = useRef<{
    eventId: string;
    callback: (e: CustomEvent) => void;
  }>();
  const statsContainerRef = useRef<HTMLDivElement>(null);
  const [background, setBackground] = useState<string>(
    BackgroundOptionsValues.Gradient
  );
  const mousePosition = useRef<THREE.Vector3 | undefined>();

  const statsObject = useRef<Stats | undefined>(undefined);

  // Cleanup
  useEffect(() => {
    return () => {
      setAnnotations3dGeometry?.(undefined);
      viewerRef.current?.threeViewer?.destroy();
      downloadPromiseRef?.current?.abortHandler?.();
      downloadPromiseRef.current = undefined;
      viewerRef.current?.splatsViewer?.stop();
      viewerRef.current = {};
    };
  }, []);

  useEffect(() => {
    if (!containerRef || !scene || !camera || !renderer) return;

    const resize = () => {
      renderer.setSize(containerRef.offsetWidth, containerRef.offsetHeight);
      camera.aspect = containerRef.offsetWidth / containerRef.offsetHeight;
      camera.updateProjectionMatrix();
    };
    window.addEventListener('resize', resize);

    const raycaster = new THREE.Raycaster();
    raycaster.params.Sprite = { threshold: 0.1 }; // Adjust threshold as needed

    const mouse = new THREE.Vector2();
    const onMouseDown = (event: MouseEvent) => {
      event.preventDefault();
      event.stopPropagation();
      // Correct for elements offset
      const rect = containerRef.getBoundingClientRect();
      mouse.x = ((event.clientX - rect.left) / rect.width) * 2 - 1;
      mouse.y = -((event.clientY - rect.top) / rect.height) * 2 + 1;
      raycaster.setFromCamera(mouse, camera);
      const group = scene.getObjectByName(AnnotationGroupName);
      if (!group) return;
      const intersects = raycaster.intersectObjects(group.children, true);
      if (intersects.length > 0) {
        const selectedObject = intersects[0].object as ICustomMesh;
        if (selectedObject?.callback) {
          selectedObject.callback?.(selectedObject.uniqueId);
        }
      }
    };

    containerRef.addEventListener('mousedown', onMouseDown, false);

    const controls = new OrbitControls(camera, renderer.domElement);
    controls.update();

    viewerRef.current.threeViewer = new GsThreeDViewer(
      camera,
      scene,
      controls,
      renderer,
      containerRef
    );

    setViewerReady(true);

    return () => {
      setViewerReady(false);
      viewerRef.current?.threeViewer?.destroy();
      while (scene.children.length > 0) {
        scene.remove(scene.children[0]);
      }
      viewerRef.current = {};
      renderer.dispose();
      setLoading({
        step: '',
        inProgress: false,
        percentage: 0,
        finished: false,
      });
      window.removeEventListener('resize', resize);
    };
  }, [containerRef, scene, camera, renderer]);

  useEffect(() => {
    if (!viewerReady) return;

    let semaphore = false;

    const update = () => {
      statsObject?.current?.update();

      renderer.render(scene, camera);

      if (semaphore === false) {
        requestAnimationFrame(update);
      }
    };

    update();
    return () => {
      semaphore = true;
    };
  }, [viewerReady]);

  useEffect(() => {
    if (!viewerReady) return;

    viewerRef?.current?.threeViewer?.addLight();
  }, [viewerReady]);

  useEffect(() => {
    if (cameraPositionsVisible) {
      controller.info.cameraPositionsVisible = true;
    } else {
      controller.info.cameraPositionsVisible = false;
    }
  }, [cameraPositionsVisible]);

  const loadPlyModel = useCallback(
    async (url: string) => {
      if (!viewerReady) return;

      setLoading({
        step: 'Starting...',
        inProgress: true,
        percentage: -1,
        finished: false,
      });

      // relying on browser cache for now
      const response = await fetch(url);
      const arrayBuffer = await response.arrayBuffer();
      const parser = new PlyParserCodecBase();
      await parser.readPly(arrayBuffer);
      parser.decompress(0, 1, 5.0, 256);

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const data: any = parser.splatData;
      const geometry = new THREE.BufferGeometry();

      // Assuming numSplats tells you the total number of points
      const numPoints = data.numSplats;

      const positions = new Float32Array(numPoints * 3);
      const colors = new Float32Array(numPoints * 3); // Assuming colors are RGB and normalized to [0,1]
      const SH_C0 = 0.28209479177387814;

      for (let i = 0; i < numPoints; i++) {
        // Position
        positions[i * 3 + 0] = data.x[i];
        positions[i * 3 + 1] = data.y[i];
        positions[i * 3 + 2] = data.z[i];

        // Colors (re-normalizing from the transformed values if necessary)
        colors[i * 3 + 0] = data.f_dc_0[i] * SH_C0 + 0.5;
        colors[i * 3 + 1] = data.f_dc_1[i] * SH_C0 + 0.5;
        colors[i * 3 + 2] = data.f_dc_2[i] * SH_C0 + 0.5;
      }

      geometry.setAttribute(
        'position',
        new THREE.BufferAttribute(positions, 3)
      );
      geometry.setAttribute('color', new THREE.BufferAttribute(colors, 3));
      geometry.setAttribute(
        'size',
        new THREE.BufferAttribute(new Float32Array(numPoints).fill(0.04), 1)
      );

      const vertexShader = `
attribute float size;
varying vec3 vColor;

void main() {
    vColor = color;
    vec4 mvPosition = modelViewMatrix * vec4(position, 1.0);
    gl_PointSize = size * (300.0 / -mvPosition.z);
    gl_Position = projectionMatrix * mvPosition;
}
`;

      const fragmentShader = `
uniform sampler2D pointTexture;
varying vec3 vColor;

void main() {
    gl_FragColor = vec4(vColor, 1.0);
    vec2 circCoord = 2.0 * gl_PointCoord - 1.0;
    if (dot(circCoord, circCoord) > 1.0) {
        discard;
    }
}
`;

      const material = new THREE.ShaderMaterial({
        vertexShader: vertexShader,
        fragmentShader: fragmentShader,
        transparent: true,
        vertexColors: true,
      });
      const points = new THREE.Points(geometry, material);
      points.scale.set(1, 1, 1);
      scene.add(points);

      const raycaster = new THREE.Raycaster();
      const mouse = new THREE.Vector2();
      let debounceTimeout: NodeJS.Timeout | null = null;
      const debounceDelay = 0; // Delay in milliseconds
      function onMouseMove(event: MouseEvent) {
        // Update the mouse position
        if (!containerRef) return;
        const rect = containerRef.getBoundingClientRect();
        mouse.x = ((event.clientX - rect.left) / rect.width) * 2 - 1;
        mouse.y = -((event.clientY - rect.top) / rect.height) * 2 + 1;

        // Clear the previous timeout if it exists
        if (debounceTimeout !== null) {
          clearTimeout(debounceTimeout);
        }

        // Set a new timeout to check intersections
        debounceTimeout = setTimeout(() => {
          checkIntersections();
        }, debounceDelay);
      }

      function checkIntersections() {
        // Update the picking ray with the camera and mouse position
        raycaster.setFromCamera(mouse, camera);

        // Calculate objects intersecting the picking ray
        const intersects = raycaster.intersectObject(points);

        if (intersects.length > 0) {
          mousePosition.current = intersects[0].point;
        }
      }
      containerRef?.addEventListener('mousemove', onMouseMove, false);

      setSceneLoaded(true);
      dispatchEffect(EffectNames.GS_3D_POINT_CLOUD_LOADED);
      setLoading({
        step: '',
        inProgress: false,
        percentage: -1,
        finished: true,
      });

      mixpanel.track(MixpanelNames.ThreeJsPlyViewerLoaded, {
        status: 'success',
      });

      return () => {
        containerRef?.removeEventListener('mousemove', onMouseMove);
      };
    },
    [viewerReady, loading, scene, camera, renderer]
  );

  const getCameraSettings = useCallback((): CameraState | undefined => {
    if (!viewerReady) return;

    return viewerRef?.current?.threeViewer?.getCameraSettings();
  }, [viewerReady]);

  const setCameraSettings = useCallback(
    (settings: string) => {
      if (!viewerReady) return;

      return viewerRef?.current?.threeViewer?.setCameraSettings(
        JSON.parse(settings)
      );
    },
    [viewerReady]
  );

  const loadCameraPositions = useCallback(
    (images: APIClient.CaptureImage[]) => {
      if (
        !captureMetadata?.latitude ||
        !captureMetadata?.longitude ||
        !captureMetadata?.altitude
      ) {
        return;
      }

      const origin = {
        lat: captureMetadata.latitude,
        lng: captureMetadata.longitude,
        alt: captureMetadata.altitude,
      };

      // convert to local coordinates
      images.forEach((image: APIClient.CaptureImage) => {
        if (!image.latitude || !image.longitude || !image.altitude) {
          return;
        }

        const lla: LatLngAlt = llaToEnu(
          {
            lat: image.latitude,
            lng: image.longitude,
            alt: image.altitude,
          },
          origin
        );
        const id = image.id as number;
        viewerRef.current.threeViewer?.addImageMarker?.(
          id,
          lla,
          Annotation3dPoints.CaptureImageLocation
        );
      });
      // setCameraPositionsVisible(true);
      setCameraPositionsLoaded(true);
    },
    [
      captureMetadata,
      viewerRef,
      setCameraPositionsLoaded,
      setCameraPositionsVisible,
      cameraPositionsLoaded,
      cameraPositionsVisible,
    ]
  );

  const bindCameraPositionOnClick = useCallback(
    (callback: ((e: CustomEvent) => void) | undefined) => {
      if (!viewerReady) {
        return;
      }

      if (callback === undefined && cameraPositionOnClickRef.current) {
        eventBus.remove(
          EventBusNames.Point3dLocationMarkerClicked,
          cameraPositionOnClickRef.current.callback,
          cameraPositionOnClickRef.current.eventId
        );
        cameraPositionOnClickRef.current = undefined;
        return;
      }

      if (callback !== undefined) {
        cameraPositionOnClickRef.current = {
          eventId: eventBus.on(
            EventBusNames.Point3dLocationMarkerClicked,
            callback,
            true
          ),
          callback: callback,
        };
      }
    },
    [viewerReady]
  );

  const removeCameraPositions = useCallback(() => {
    if (!viewerReady) return;

    viewerRef.current.threeViewer?.removeAllImageMarkers();
    setCameraPositionsVisible(false);
    setCameraPositionsLoaded(false);
  }, [viewerReady]);

  const hideCameraPositions = useCallback(() => {
    if (!viewerReady) return;

    viewerRef.current.threeViewer?.hideImageMarkers();
  }, [viewerReady]);

  const showCameraPositions = useCallback(() => {
    if (!viewerReady) return;

    viewerRef.current.threeViewer?.showImageMarkers();
  }, [viewerReady]);

  const addAxesHelper = useCallback(() => {
    if (!viewerReady) {
      return;
    }
    viewerRef.current.threeViewer?.addAxesHelper();
  }, [viewerReady]);

  const removeAxesHelper = useCallback(() => {
    if (!viewerReady) {
      return;
    }
    viewerRef.current.threeViewer?.removeAxesHelper();
  }, [viewerReady]);

  const controller: IGs3dViewerController = useMemo(
    () => ({
      info: {
        error: viewerError,
        viewerReady,
        captureJobMetadata,
        captureMetadata,
        cameraPositionsVisible,
        cameraPositionsLoaded,
        sceneLoaded,
        element: containerRef,
      },
      loadPlyModel,
      setBackground,
      getCameraSettings,
      setCameraSettings,
      setCaptureJobMetadata,
      loadCameraPositions,
      setCaptureMetadata,
      bindCameraPositionOnClick,
      setCameraPositionsVisible,
      removeCameraPositions,
      hideCameraPositions,
      showCameraPositions,
      addAxesHelper,
      removeAxesHelper,
      mousePosition,
      annotation3d: viewerRef.current?.threeViewer?.annotation3d,
      threeViewer: viewerRef.current?.threeViewer,
    }),
    [
      viewerError,
      loadPlyModel,
      viewerReady,
      setCameraSettings,
      getCameraSettings,
      captureJobMetadata,
      setCaptureJobMetadata,
      loadCameraPositions,
      setCaptureMetadata,
      bindCameraPositionOnClick,
      setCameraPositionsVisible,
      removeCameraPositions,
      hideCameraPositions,
      showCameraPositions,
      addAxesHelper,
      removeAxesHelper,
      mousePosition,
      viewerRef.current?.threeViewer?.annotation3d,
      sceneLoaded,
    ]
  );

  useEffect(() => {
    // hide camera positions
    if (!controller.info.cameraPositionsVisible) {
      hideCameraPositions();
    } else {
      showCameraPositions();
    }
  }, [controller.info.cameraPositionsVisible]);

  useEffect(() => {
    setController?.(controller);
  }, [
    controller,
    setController,
    cameraPositionsVisible,
    cameraPositionsLoaded,
    setCameraPositionsLoaded,
    setCameraPositionsVisible,
  ]);

  useEffect(() => {
    mixpanel.time_event(MixpanelNames.ThreeJsPlyViewerLoaded);
  }, []);

  useEffect(() => {
    if (!viewerReady) return;

    if (has3dDebugPermission) {
      if (statsObject.current === undefined) {
        const stats = new Stats();
        stats.dom.style.zIndex = '40';
        stats.dom.style.position = 'absolute';
        stats.dom.style.top = '4px';
        stats.dom.style.left = 'unset';
        stats.dom.style.right = '45px';

        statsContainerRef?.current?.appendChild(stats.dom);
        statsObject.current = stats;
      }

      controller.addAxesHelper();
    } else {
      const selectedObject =
        viewerRef?.current?.splatsViewer?.threeScene.getObjectByName(
          'axisHelper'
        );
      viewerRef?.current?.splatsViewer?.threeScene.remove(selectedObject);
      if (statsObject.current) {
        statsContainerRef?.current?.removeChild(statsObject.current.dom);
        statsObject.current = undefined;
      }
    }
  }, [has3dDebugPermission, viewerReady]);

  const backgroundStyle = useMemo(() => {
    if (background === BackgroundOptionsValues.Black) {
      return 'black';
    }

    if (background === BackgroundOptionsValues.White) {
      return 'white';
    }

    if (background === BackgroundOptionsValues.Gradient) {
      return 'radial-gradient(#1b292f, #0c1417)';
    }

    return 'radial-gradient(#1b292f, #0c1417)';
  }, [background]);

  return (
    <div className="relative h-full w-full">
      {viewerReady && <Gs3DCloudTools viewerController={controller} />}
      {showCloudTools && viewerReady && (
        <SharedThreeDAnnotationToolbar viewerController={controller} />
      )}
      {plugins?.map((plugin) => plugin)}
      {loading.inProgress && (
        <>
          <div className="absolute w-full h-1 bg-black loading-gradient" />
          {showLoadingIndicator && (
            <div
              className={`absolute flex z-40 pointer-events-none transition-all duration-300 top-2 ${
                sidebarOpen ? 'translate-x-80' : ''
              }`}
              style={{
                left: '2rem',
              }}
            >
              <div className="loaderContainer">
                <div className="loader text-white px-4 py-1 rounded">
                  {`${loading.step} ${
                    loading.percentage >= 0 ? `${loading.percentage}%` : ''
                  }`.trim()}
                </div>
              </div>
            </div>
          )}
        </>
      )}

      <div ref={statsContainerRef} className="top-1" />
      <div
        className={`h-full w-full overflow-hidden`}
        style={{
          background: backgroundStyle,
        }}
        ref={setContainerRef}
      />
    </div>
  );
};

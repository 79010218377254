/**
 * Generated by orval v6.28.2 🍺
 * Do not edit manually.
 * GeminiAPI
 * OpenAPI spec version: v1
 */
import { useMutation, useQuery } from '@tanstack/react-query';
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import type { Analytic } from '.././models';
import { AxiosInstance } from '../../axios-instance';
import type { ErrorType, BodyType } from '../../axios-instance';

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

export const getAnalytic = (
  options?: SecondParameter<typeof AxiosInstance>,
  signal?: AbortSignal,
) => {
  return AxiosInstance<Analytic[]>(
    { url: `/api/Analytics`, method: 'GET', signal },
    options,
  );
};

export const getGetAnalyticQueryKey = () => {
  return [`/api/Analytics`] as const;
};

export const getGetAnalyticQueryOptions = <
  TData = Awaited<ReturnType<typeof getAnalytic>>,
  TError = ErrorType<void>,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof getAnalytic>>, TError, TData>
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetAnalyticQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getAnalytic>>> = ({
    signal,
  }) => getAnalytic(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getAnalytic>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetAnalyticQueryResult = NonNullable<
  Awaited<ReturnType<typeof getAnalytic>>
>;
export type GetAnalyticQueryError = ErrorType<void>;

export const useGetAnalytic = <
  TData = Awaited<ReturnType<typeof getAnalytic>>,
  TError = ErrorType<void>,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof getAnalytic>>, TError, TData>
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetAnalyticQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const postAnalytic = (
  analytic: BodyType<Analytic>,
  options?: SecondParameter<typeof AxiosInstance>,
) => {
  return AxiosInstance<Analytic>(
    {
      url: `/api/Analytics`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: analytic,
    },
    options,
  );
};

export const getPostAnalyticMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postAnalytic>>,
    TError,
    { data: BodyType<Analytic> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postAnalytic>>,
  TError,
  { data: BodyType<Analytic> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postAnalytic>>,
    { data: BodyType<Analytic> }
  > = (props) => {
    const { data } = props ?? {};

    return postAnalytic(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostAnalyticMutationResult = NonNullable<
  Awaited<ReturnType<typeof postAnalytic>>
>;
export type PostAnalyticMutationBody = BodyType<Analytic>;
export type PostAnalyticMutationError = ErrorType<void>;

export const usePostAnalytic = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postAnalytic>>,
    TError,
    { data: BodyType<Analytic> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof postAnalytic>>,
  TError,
  { data: BodyType<Analytic> },
  TContext
> => {
  const mutationOptions = getPostAnalyticMutationOptions(options);

  return useMutation(mutationOptions);
};
export const getAnalyticById = (
  id: number,
  options?: SecondParameter<typeof AxiosInstance>,
  signal?: AbortSignal,
) => {
  return AxiosInstance<Analytic>(
    {
      url: `/api/Analytics/${encodeURIComponent(String(id))}`,
      method: 'GET',
      signal,
    },
    options,
  );
};

export const getGetAnalyticByIdQueryKey = (id: number) => {
  return [`/api/Analytics/${id}`] as const;
};

export const getGetAnalyticByIdQueryOptions = <
  TData = Awaited<ReturnType<typeof getAnalyticById>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getAnalyticById>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetAnalyticByIdQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getAnalyticById>>> = ({
    signal,
  }) => getAnalyticById(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getAnalyticById>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetAnalyticByIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof getAnalyticById>>
>;
export type GetAnalyticByIdQueryError = ErrorType<void>;

export const useGetAnalyticById = <
  TData = Awaited<ReturnType<typeof getAnalyticById>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getAnalyticById>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetAnalyticByIdQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const putAnalyticById = (
  id: number,
  analytic: BodyType<Analytic>,
  options?: SecondParameter<typeof AxiosInstance>,
) => {
  return AxiosInstance<Analytic>(
    {
      url: `/api/Analytics/${encodeURIComponent(String(id))}`,
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      data: analytic,
    },
    options,
  );
};

export const getPutAnalyticByIdMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putAnalyticById>>,
    TError,
    { id: number; data: BodyType<Analytic> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof putAnalyticById>>,
  TError,
  { id: number; data: BodyType<Analytic> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof putAnalyticById>>,
    { id: number; data: BodyType<Analytic> }
  > = (props) => {
    const { id, data } = props ?? {};

    return putAnalyticById(id, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PutAnalyticByIdMutationResult = NonNullable<
  Awaited<ReturnType<typeof putAnalyticById>>
>;
export type PutAnalyticByIdMutationBody = BodyType<Analytic>;
export type PutAnalyticByIdMutationError = ErrorType<void>;

export const usePutAnalyticById = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putAnalyticById>>,
    TError,
    { id: number; data: BodyType<Analytic> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof putAnalyticById>>,
  TError,
  { id: number; data: BodyType<Analytic> },
  TContext
> => {
  const mutationOptions = getPutAnalyticByIdMutationOptions(options);

  return useMutation(mutationOptions);
};

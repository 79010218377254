
export * from "./Actions.js";
// export * from "./Annotation.js";
export * from "./defines.js";
export * from "./Enum.js";
export * from "./EventDispatcher.js";
export * from "./Features.js";
export * from "./KeyCodes.js";
export * from "./LRU.js";
export * from "./PointCloudEptGeometry.js";
export * from "./PointCloudOctree.js";
export * from "./PointCloudOctreeGeometry.js";
export * from "./PointCloudTree.js";
export * from "./Points.js";
export * from "./Potree_update_visibility.js";
export * from "./PotreeRenderer.js";
export * from "./TextSprite.js";
export * from "./utils.js";
export * from "./Version.js";
export * from "./WorkerPool.js";
export * from "./XHRFactory.js";
export * from "./viewer/LoadProject.js";

export * from "./materials/ClassificationScheme.js";
export * from "./materials/EyeDomeLightingMaterial.js";
export * from "./materials/Gradients.js";
export * from "./materials/NormalizationEDLMaterial.js";
export * from "./materials/NormalizationMaterial.js";
export * from "./materials/PointCloudMaterial.js";

export * from "./loader/POCLoader.js";
export * from "./modules/loader/2.0/OctreeLoader.js";
export * from "./loader/EptLoader.js";
export * from "./loader/ept/BinaryLoader.js";
export * from "./loader/ept/LaszipLoader.js";
export * from "./loader/ept/ZstandardLoader.js";
export * from "./loader/PointAttributes.js";

export * from "./viewer/viewer.js";
export * from "./viewer/Scene.js";

export * from "./modules/loader/2.0/OctreeLoader.js";

export { FirstPersonControls } from "./navigation/FirstPersonControls.js";

import "./extensions/OrthographicCamera.js";
import "./extensions/PerspectiveCamera.js";
import "./extensions/Ray.js";

import { LRU } from "./LRU.js";
import { OctreeLoader } from "./modules/loader/2.0/OctreeLoader.js";
import { POCLoader } from "./loader/POCLoader.js";
import { EptLoader } from "./loader/EptLoader.js";
import { PointCloudOctree } from "./PointCloudOctree.js";
import { WorkerPool } from "./WorkerPool.js";

export const workerPool = new WorkerPool();

export const version = {
	major: 1,
	minor: 8,
	suffix: '.0'
};

export let lru = new LRU();

// console.log('Potree ' + version.major + '.' + version.minor + version.suffix);

export let pointBudget = 1 * 1000 * 1000;
export let framenumber = 0;
export let numNodesLoading = 0;
export let maxNodesLoading = 4;
export let _pointcloudTransformVersion = undefined;
export let pointLoadLimit = 0;
export let DEM = undefined;

export const debug = {};

let scriptPath = "";

// if (document.currentScript && document.currentScript.src) {
// 	scriptPath = new URL(document.currentScript.src + '/..').href;
// 	if (scriptPath.slice(-1) === '/') {
// 		scriptPath = scriptPath.slice(0, -1);
// 	}
// } else if (import.meta) {
// 	scriptPath = new URL(import.meta.url + "/..").href;
// 	if (scriptPath.slice(-1) === '/') {
// 		scriptPath = scriptPath.slice(0, -1);
// 	}
// } else {
// 	console.error('Potree was unable to find its script path using document.currentScript. Is Potree included with a script tag? Does your browser support this function?');
// }

let resourcePath = scriptPath + '/resources';

// scriptPath: build/potree
// resourcePath:build/potree/resources

export const setScriptPath = function (path) {
	scriptPath = path;
	resourcePath = path + '/resources';
}

const setNumNodesLoading = function (value) {
	numNodesLoading = value;
}

const setPointcloudTransformVersion = function (value) {
	_pointcloudTransformVersion = value;
}

const setPointBudget = function (value) {
	pointBudget = value;
}

const setPointLoadLimit = function (value) {
	pointLoadLimit = value;
}

const setFrameNumber = function (value) {
	framenumber = value;
}


function loadPointCloud(path, options, name) {
	// let loaded = function (e) {
	// 	console.log(e);
	// 	console.log(callback);
	// 	// e.pointcloud.name = name;
	// 	callback(e);
	// };

	let promise = new Promise((resolve, reject) => {

		if (!path) {
		} else if (path.indexOf('ept.json') > 0) {
			try {
				EptLoader.load(path, options, function (error, geometry) {
					if (error) {
						reject(error)
					}
					if (!geometry) {
						console.error(
							new Error(`failed to load point cloud from URL: ${path}`)
						);
					} else {
						let pointcloud = new PointCloudOctree(geometry);
						// loaded(pointcloud);
						resolve({ type: 'pointcloud_loaded', pointcloud: pointcloud });
					}
				});
			} catch (e) {
				reject(e);
			}
		} else if (path.indexOf('cloud.js') > 0) {
			POCLoader.load(path, function (geometry) {
				if (!geometry) {
					//callback({type: 'loading_failed'});
					console.error(new Error(`failed to load point cloud from URL: ${path}`));
				} else {
					let pointcloud = new PointCloudOctree(geometry);
					// loaded(pointcloud);
					resolve({ type: 'pointcloud_loaded', pointcloud: pointcloud });
				}
			});
		} else if (path.indexOf('metadata.json') > 0) {
			Potree.OctreeLoader.load(path).then(e => {
				let geometry = e.geometry;

				if (!geometry) {
					console.error(new Error(`failed to load point cloud from URL: ${path}`));
				} else {
					let pointcloud = new PointCloudOctree(geometry);

					let aPosition = pointcloud.getAttribute("position");

					let material = pointcloud.material;
					material.elevationRange = [
						aPosition.range[0][2],
						aPosition.range[1][2],
					];

					// loaded(pointcloud);
					resolve({ type: 'pointcloud_loaded', pointcloud: pointcloud });
				}
			});

			OctreeLoader.load(path, function (geometry) {
				if (!geometry) {
					//callback({type: 'loading_failed'});
					console.error(new Error(`failed to load point cloud from URL: ${path}`));
				} else {
					let pointcloud = new PointCloudOctree(geometry);
					// loaded(pointcloud);
					resolve({ type: 'pointcloud_loaded', pointcloud: pointcloud });
				}
			});
		} else if (path.indexOf('.vpc') > 0) {
			PointCloudArena4DGeometry.load(path, function (geometry) {
				if (!geometry) {
					//callback({type: 'loading_failed'});
					console.error(new Error(`failed to load point cloud from URL: ${path}`));
				} else {
					let pointcloud = new PointCloudArena4D(geometry);
					// loaded(pointcloud);
					resolve({ type: 'pointcloud_loaded', pointcloud: pointcloud });
				}
			});
		} else {
			//callback({'type': 'loading_failed'});
			console.error(new Error(`failed to load point cloud from URL: ${path}`));
		}
	});

	// if (callback) {
	// 	promise.then(pointcloud => {
	// 		loaded(pointcloud);
	// 	});
	// } else {
	return promise;
	// }
};

export {
	scriptPath,
	resourcePath,
	setNumNodesLoading,
	setPointcloudTransformVersion,
	setPointBudget,
	loadPointCloud,
	setPointLoadLimit,
	setFrameNumber,
};

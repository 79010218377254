import { IconName } from '@fortawesome/fontawesome-svg-core';
import React, { forwardRef } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.min.css';

import { CloudButton } from '../..';
import { CloudInput } from '../input';
import { InputIdContext } from '../input-id-context';
import {
  InputValidation,
  useInputValidationIntegration,
} from '../input-validation';
import './input-date.scss';

interface IInputDateRange {
  /**
   * The unique identifier for the input component.
   */
  id: string;

  /**
   * The label to be displayed above the input component.
   */
  label?: string;

  /**
   * Determines whether the input component is required.
   */
  required?: boolean;

  /**
   * The error message to be displayed below the input component.
   */
  error?: string;

  /**
   * The placeholder text for the input component.
   */
  placeholder?: string;

  /**
   * The placeholder icon to display before the text.
   */
  leadingIcon?: IconName;

  /**
   * The placeholder icon to display after the text.
   */
  trailingIcon?: IconName;

  /**
   * Determines whether the input component is highlighted.
   */
  highlighted?: boolean;

  /**
   * The value of the date range.
   */
  value: [Date | undefined, Date | undefined];

  /**
   * The function to set the value of the date range.
   */
  setValue: (value: [Date | undefined, Date | undefined]) => void;

  /**
   * The validation configuration for the input component.
   */
  validation?: InputValidation<[Date | undefined, Date | undefined]>;

  /**
   * Footer to be displayed below the input component.
   */
  footer?: React.ReactNode;

  /**
   * Determines whether the input component is disabled.
   */
  disabled?: boolean;
}

const InputDateRange = ({
  id,
  error,
  label,
  required,
  placeholder,
  leadingIcon,
  trailingIcon,
  value,
  setValue,
  validation,
  footer,
  disabled = false,
  highlighted = false,
}: IInputDateRange) => {
  useInputValidationIntegration({
    id,
    value,
    validation,
  });

  interface CustomInputProps {
    value?: string;
    onClick?: () => void;
  }

  const CustomInput = forwardRef(
    ({ value, onClick }: CustomInputProps, ref) => (
      <CloudInput.Text.Single
        id={id + '-input'}
        value={value ?? ''}
        leadingIcon={leadingIcon}
        label={label}
        placeholder={placeholder}
        highlighted={highlighted}
        required={required}
        error={error}
        trailingIcon={trailingIcon}
        disabled={disabled}
        setValue={() => {
          //
        }}
        onClick={onClick}
      />
    )
  );

  CustomInput.displayName = 'CustomInput';

  return (
    <InputIdContext.Provider value={id}>
      <div className="flex flex-col w-full">
        <DatePicker
          data-test-id={id}
          placeholderText={placeholder}
          disabled={disabled}
          customInput={<CustomInput />}
          startDate={value[0]}
          endDate={value[1]}
          onChange={(date) => {
            setValue(date as [Date | undefined, Date | undefined]);
          }}
          selectsRange
          dateFormat={'M/d/yy'}
          showPopperArrow={false}
          popperModifiers={[
            {
              name: 'offset',
              fn: (data: any) => {
                return {
                  ...data,
                  y: data.y - 8,
                };
              },
            },
          ]}
          renderCustomHeader={({ date, decreaseMonth, increaseMonth }) => (
            <div className="flex flex-row justify-between items-center px-1">
              <CloudButton.Icon
                id="previous-month"
                leadingIcon="chevron-left"
                onClick={decreaseMonth}
              />
              <div className="text-base font-bold text-gray-textPrimary">
                {`${date.toLocaleString('default', {
                  month: 'long',
                })} ${date.getFullYear()}`}
              </div>
              <CloudButton.Icon
                id="previous-month"
                leadingIcon="chevron-right"
                onClick={increaseMonth}
              />
            </div>
          )}
          popperClassName="z-dropdown shadow-lg rounded-lg"
        >
          {footer}
        </DatePicker>
      </div>
    </InputIdContext.Provider>
  );
};

export { InputDateRange };

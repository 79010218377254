import { DatatableColumnProps } from 'libs/feature/src/datatable/datatable-types';
import Feature from 'ol/Feature';
import { createEmpty, extend } from 'ol/extent';
import GeoJSON from 'ol/format/GeoJSON';
import Geometry from 'ol/geom/Geometry';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { APIModels } from '@agerpoint/api';
import {
  BreadCrumbs,
  Button,
  Input,
  PrimaryButton,
} from '@agerpoint/component';
import {
  Datatable,
  OpenLayerMap,
  dataTableAgerStyle,
} from '@agerpoint/feature';
import { OpenLayerMapController, OpenMapLayer } from '@agerpoint/types';
import {
  environment,
  useFormValidation,
  useIsViteApp,
  usePageTitle,
  useToasts,
} from '@agerpoint/utilities';

import { useAdminGeometriesQueries } from './admin-geometries-queries';

export const AdminGeometriesNew = () => {
  const toasts = useToasts();

  usePageTitle(() => 'Platform - Geometries', []);

  const navigate = useNavigate();
  const location = useLocation();
  const params = location.state?.params ?? '';

  const isViteApp = useIsViteApp();

  const [upload, setUpload] = useState<APIModels.Upload>({});
  const [loading, setLoading] = useState(false);

  const [selectedOrganization, setSelectedOrganization] =
    useState<APIModels.Customer>();

  useEffect(() => {
    setUpload((prev) => ({
      ...prev,
      customerId: selectedOrganization?.id,
    }));
  }, [selectedOrganization]);

  const [geometryFile, setGeometryFile] = useState<File>();
  const [geometryFileFeatures, setGeometryFileFeatures] = useState<
    Feature<Geometry>[]
  >([]);

  useEffect(() => {
    const doAsync = async () => {
      if (!geometryFile) {
        setGeometryFileFeatures([]);
        return;
      }
      try {
        const text = await geometryFile.text();
        const json = JSON.parse(text);
        const geoJson = new GeoJSON({});
        const proj = geoJson.readProjection(json);
        if (proj === null) {
          mapController?.refreshView?.();
          return;
        }
        const features = geoJson.readFeatures(json, {
          featureProjection: 'EPSG:3857',
        }) as Feature<Geometry>[];
        setGeometryFileFeatures(features);

        const extent = createEmpty();
        for (const feature of features) {
          const featureExtent = feature.getGeometry()?.getExtent();
          if (featureExtent) {
            extend(extent, featureExtent);
          }
        }

        mapController?.zoomMapToExtent?.(extent);
      } catch (e) {
        mapController?.refreshView?.();
      }
    };
    doAsync();
  }, [geometryFile]);

  const featurePropertyColumns = useMemo(() => {
    const columns: DatatableColumnProps<Feature<Geometry>>[] = [];
    const c: string[] = [];
    const skip = ['geometry'];
    for (const f of geometryFileFeatures) {
      for (const p of Object.keys(f.getProperties())) {
        if (skip.includes(p)) {
          continue;
        }
        if (!c.includes(p)) {
          columns.push({
            label: p,
            value: (row) => row.getProperties()[p]?.toString(),
          });
          c.push(p);
        }
      }
    }

    return columns;
  }, [geometryFileFeatures]);

  const {
    uploadPostMutation,
    uploadFilePostMutation,
    blobServiceMutation,
    uploadFilePutMutation,
    geometryPostMutation,
    uploadPutMutation,
    organizationsQuery,
  } = useAdminGeometriesQueries();

  const [mapController, setMapController] = useState<OpenLayerMapController>();
  const formValidation = useFormValidation();

  const createGeometry = useCallback(async () => {
    if (await formValidation.hasErrors()) {
      return;
    }

    if (loading) {
      return;
    }

    setLoading(true);

    try {
      const newUpload = await uploadPostMutation.mutateAsync({
        data: {
          ...upload,
          uploadTypeId: 6,
        },
      });

      if (!newUpload || !newUpload?.uploadUrl || !newUpload?.id) {
        throw new Error('Bad Upload');
      }

      const newUploadFile = await uploadFilePostMutation.mutateAsync({
        data: {
          name: upload?.name,
          originalName: upload?.name,
          uploadId: newUpload.id,
        },
      });

      if (!newUploadFile || !newUploadFile?.name || !newUploadFile?.id) {
        throw new Error('Bad Upload File');
      }

      await blobServiceMutation.mutateAsync({
        uploadUrl: newUpload.uploadUrl,
        name: newUpload.name as string,
        file: geometryFile as File,
      });

      await uploadPutMutation.mutateAsync({
        id: newUpload.id,
        data: {
          ...newUpload,
          isUploaded: true,
        },
      });

      await uploadFilePutMutation.mutateAsync({
        id: newUploadFile.id,
        data: {
          ...newUploadFile,
          isUploaded: true,
        },
      });

      await geometryPostMutation.mutateAsync({
        uploadId: newUpload.id,
      });

      toasts.add(toasts.prepare.entityCreated('geometry'));

      if (isViteApp) {
        navigate('/app/admin/platform/geometries' + params);
      } else {
        navigate('/admin/geometries' + params);
      }
    } catch (e) {
      console.error(e);

      toasts.add(toasts.prepare.error('Failed to create geometry!'));
    } finally {
      setLoading(false);
    }
  }, [
    formValidation,
    upload,
    geometryFile,
    blobServiceMutation,
    uploadPostMutation,
    uploadFilePostMutation,
    geometryPostMutation,
    uploadPutMutation,
    uploadFilePutMutation,
    loading,
  ]);

  return (
    <div className="flex flex-col h-full w-full pt-4 overflow-auto">
      <div className="px-4">
        <BreadCrumbs
          items={[
            {
              label: 'Platform',
              path: isViteApp ? '/app/admin/platform' : '/admin',
            },
            {
              label: 'Geometries',
              path: isViteApp
                ? '/app/admin/platform/geometries'
                : '/admin/geometries',
              params,
            },
          ]}
        />
      </div>
      <div className="flex flex-row gap-2 justify-start items-center px-4 py-2">
        <Button.Back
          id="new-geometry-back-button"
          onClick={() => {
            if (isViteApp) {
              navigate('/app/admin/platform/geometries' + params);
            } else {
              navigate('/admin/geometries' + params);
            }
          }}
        />
        <h1 className="text-3xl font-bold">New Geometry</h1>
      </div>
      <div className="p-4 h-full w-full flex flex-row">
        <div className="w-full flex flex-col max-w-lg gap-2">
          <Input.Text.Single
            id="name-input"
            label={<Input.Label label="Name" required />}
            value={upload?.name || ''}
            setValue={(name) => {
              setUpload({ ...upload, name });
            }}
            error={<Input.Error error={formValidation.errors['name-input']} />}
            validation={{
              validationState: formValidation,
              validators: [Input.validators.required('Name')],
            }}
          />
          <Input.Text.Single
            id="description-input"
            label={<Input.Label label="Description" />}
            value={upload?.description || ''}
            setValue={(description) => {
              setUpload({ ...upload, description });
            }}
          />
          <Input.Select.Single
            id="organization-select"
            label={<Input.Label label="Organization" required />}
            error={
              <Input.Error
                error={formValidation.errors['organization-select']}
              />
            }
            options={organizationsQuery.data ?? []}
            loading={organizationsQuery.isLoading}
            optionBuilder={(o) =>
              o.customerDisplayName ?? o.customerName ?? 'Unknown'
            }
            value={selectedOrganization}
            setValue={(o) => {
              setSelectedOrganization(o);
            }}
            title="Organization"
            validation={{
              validationState: formValidation,
              validators: [Input.validators.required('Organization')],
            }}
          />
          <Input.File.Single
            id="geometry-file-input"
            label={<Input.Label label="GeoJSON File" required />}
            error={
              <Input.Error
                error={formValidation.errors['geometry-file-input']}
              />
            }
            acceptExtensions={['geojson', 'json']}
            value={geometryFile}
            setValue={setGeometryFile}
            validation={{
              validationState: formValidation,
              validators: [Input.validators.validGeoJSONFile],
            }}
            placeholder="Select .geojson/.json file..."
          />

          {geometryFileFeatures.length > 0 && (
            <div className="h-full flex flex-col">
              <div className="flex-grow border border-gray-500 rounded overflow-hidden">
                <Datatable
                  data={geometryFileFeatures}
                  columns={[
                    { label: 'Id', value: (f) => f.getId() },
                    ...featurePropertyColumns,
                  ]}
                  rowHeight={50}
                  style={{
                    ...dataTableAgerStyle,
                    tableMinWidth: 100 * featurePropertyColumns.length,
                  }}
                />
              </div>
              <div className="flex flex-col w-full">
                <span className="text-xs">
                  {`${geometryFileFeatures.length} Features with ${
                    featurePropertyColumns.length
                  } properties will produce ${
                    geometryFileFeatures.length * featurePropertyColumns.length
                  } records`}
                </span>
                <span className="text-xs text-red font-bold">
                  Do not upload excessive data unless its absolutely necessary.
                </span>
              </div>
            </div>
          )}
          <div className="w-full flex flex-row justify-end py-4">
            <Button.Primary
              id="create-geometry-button"
              label={'Create'}
              onClick={createGeometry}
              loading={loading}
            />
          </div>
        </div>
        <div className="w-full pt-1 pl-2 h-full">
          <div className="overflow-hidden rounded-md w-full h-full">
            <OpenLayerMap
              id="new-geometry-map"
              controller={setMapController}
              bingKey={environment.bing_api_key}
              mapLayers={{
                used: [
                  OpenMapLayer.Hybrid,
                  OpenMapLayer.Aerial,
                  OpenMapLayer.RoadMap,
                ],
                initial: OpenMapLayer.Hybrid,
              }}
              geoJsonLayer={{
                data: geometryFileFeatures,
              }}
              dependencies={[geometryFile, geometryFileFeatures]}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

/**
 * Generated by orval v6.28.2 🍺
 * Do not edit manually.
 * GeminiAPI
 * OpenAPI spec version: v1
 */
import { useQuery } from '@tanstack/react-query';
import type {
  QueryFunction,
  QueryKey,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import type { User } from '.././models';
import { AxiosInstance } from '../../axios-instance';
import type { ErrorType } from '../../axios-instance';

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

export const getUser = (
  options?: SecondParameter<typeof AxiosInstance>,
  signal?: AbortSignal,
) => {
  return AxiosInstance<User>(
    { url: `/api/Users`, method: 'GET', signal },
    options,
  );
};

export const getGetUserQueryKey = () => {
  return [`/api/Users`] as const;
};

export const getGetUserQueryOptions = <
  TData = Awaited<ReturnType<typeof getUser>>,
  TError = ErrorType<void>,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof getUser>>, TError, TData>
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetUserQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getUser>>> = ({
    signal,
  }) => getUser(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getUser>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetUserQueryResult = NonNullable<
  Awaited<ReturnType<typeof getUser>>
>;
export type GetUserQueryError = ErrorType<void>;

export const useGetUser = <
  TData = Awaited<ReturnType<typeof getUser>>,
  TError = ErrorType<void>,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof getUser>>, TError, TData>
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetUserQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getUsersAvailibleToAssignByUser = (
  options?: SecondParameter<typeof AxiosInstance>,
  signal?: AbortSignal,
) => {
  return AxiosInstance<User[]>(
    { url: `/api/Users/Customers`, method: 'GET', signal },
    options,
  );
};

export const getGetUsersAvailibleToAssignByUserQueryKey = () => {
  return [`/api/Users/Customers`] as const;
};

export const getGetUsersAvailibleToAssignByUserQueryOptions = <
  TData = Awaited<ReturnType<typeof getUsersAvailibleToAssignByUser>>,
  TError = ErrorType<void>,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getUsersAvailibleToAssignByUser>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetUsersAvailibleToAssignByUserQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getUsersAvailibleToAssignByUser>>
  > = ({ signal }) => getUsersAvailibleToAssignByUser(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getUsersAvailibleToAssignByUser>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetUsersAvailibleToAssignByUserQueryResult = NonNullable<
  Awaited<ReturnType<typeof getUsersAvailibleToAssignByUser>>
>;
export type GetUsersAvailibleToAssignByUserQueryError = ErrorType<void>;

export const useGetUsersAvailibleToAssignByUser = <
  TData = Awaited<ReturnType<typeof getUsersAvailibleToAssignByUser>>,
  TError = ErrorType<void>,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getUsersAvailibleToAssignByUser>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetUsersAvailibleToAssignByUserQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getUsersByCustomer = (
  customerId: number,
  options?: SecondParameter<typeof AxiosInstance>,
  signal?: AbortSignal,
) => {
  return AxiosInstance<User[]>(
    {
      url: `/api/Users/Customers/${encodeURIComponent(String(customerId))}`,
      method: 'GET',
      signal,
    },
    options,
  );
};

export const getGetUsersByCustomerQueryKey = (customerId: number) => {
  return [`/api/Users/Customers/${customerId}`] as const;
};

export const getGetUsersByCustomerQueryOptions = <
  TData = Awaited<ReturnType<typeof getUsersByCustomer>>,
  TError = ErrorType<void>,
>(
  customerId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getUsersByCustomer>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetUsersByCustomerQueryKey(customerId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getUsersByCustomer>>
  > = ({ signal }) => getUsersByCustomer(customerId, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!customerId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getUsersByCustomer>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetUsersByCustomerQueryResult = NonNullable<
  Awaited<ReturnType<typeof getUsersByCustomer>>
>;
export type GetUsersByCustomerQueryError = ErrorType<void>;

export const useGetUsersByCustomer = <
  TData = Awaited<ReturnType<typeof getUsersByCustomer>>,
  TError = ErrorType<void>,
>(
  customerId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getUsersByCustomer>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetUsersByCustomerQueryOptions(customerId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getUsersAvailibleFromCaptures = (
  options?: SecondParameter<typeof AxiosInstance>,
  signal?: AbortSignal,
) => {
  return AxiosInstance<User[]>(
    { url: `/api/Users/Captures`, method: 'GET', signal },
    options,
  );
};

export const getGetUsersAvailibleFromCapturesQueryKey = () => {
  return [`/api/Users/Captures`] as const;
};

export const getGetUsersAvailibleFromCapturesQueryOptions = <
  TData = Awaited<ReturnType<typeof getUsersAvailibleFromCaptures>>,
  TError = ErrorType<void>,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getUsersAvailibleFromCaptures>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetUsersAvailibleFromCapturesQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getUsersAvailibleFromCaptures>>
  > = ({ signal }) => getUsersAvailibleFromCaptures(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getUsersAvailibleFromCaptures>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetUsersAvailibleFromCapturesQueryResult = NonNullable<
  Awaited<ReturnType<typeof getUsersAvailibleFromCaptures>>
>;
export type GetUsersAvailibleFromCapturesQueryError = ErrorType<void>;

export const useGetUsersAvailibleFromCaptures = <
  TData = Awaited<ReturnType<typeof getUsersAvailibleFromCaptures>>,
  TError = ErrorType<void>,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getUsersAvailibleFromCaptures>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetUsersAvailibleFromCapturesQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getQAQCUsers = (
  options?: SecondParameter<typeof AxiosInstance>,
  signal?: AbortSignal,
) => {
  return AxiosInstance<User[]>(
    { url: `/api/Users/QAQC`, method: 'GET', signal },
    options,
  );
};

export const getGetQAQCUsersQueryKey = () => {
  return [`/api/Users/QAQC`] as const;
};

export const getGetQAQCUsersQueryOptions = <
  TData = Awaited<ReturnType<typeof getQAQCUsers>>,
  TError = ErrorType<void>,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof getQAQCUsers>>, TError, TData>
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetQAQCUsersQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getQAQCUsers>>> = ({
    signal,
  }) => getQAQCUsers(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getQAQCUsers>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetQAQCUsersQueryResult = NonNullable<
  Awaited<ReturnType<typeof getQAQCUsers>>
>;
export type GetQAQCUsersQueryError = ErrorType<void>;

export const useGetQAQCUsers = <
  TData = Awaited<ReturnType<typeof getQAQCUsers>>,
  TError = ErrorType<void>,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof getQAQCUsers>>, TError, TData>
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetQAQCUsersQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @author Connor Manning
 */

import * as Potree from '../Potree';

export class EptLoader {
	static async load(file, options = {}, callback) {

		try {
			let response = await fetch(file, options);

			if (!response.ok) {
				throw new Error(`HTTP error! status: ${response.status}`);
			}

			let json = await response.json();
			let url = file.substr(0, file.lastIndexOf('ept.json'));
			let geometry = new Potree.PointCloudEptGeometry(url, options, json);
			let root = new Potree.PointCloudEptGeometryNode(geometry);

			geometry.root = root;
			geometry.root.load();

			callback(null, geometry);  // First argument is error, which is null here
		} catch (error) {
			callback(error, null);  // Pass the error to the callback
		}
	}
};


import { useState } from 'react';

import { APIClient } from '@agerpoint/api';
import { IAnnotations3dGeometry, IGsCloudToolState } from '@agerpoint/types';

import { CapturesViewerContext } from '../captures-viewer';
import { IThreeDViewerControllerGroup } from './three-d-wrapper';

interface ThreeDViewer2ContextWrapperProps {
  children: React.ReactNode;
}

export const ThreeDViewer2ContextWrapper = ({
  children,
}: ThreeDViewer2ContextWrapperProps) => {
  const [selectedCaptureJob, setSelectedCaptureJob] = useState<
    APIClient.CaptureJob | undefined | null
  >();
  const [viewerController, setViewerController] =
    useState<IThreeDViewerControllerGroup>();
  const [selectedCaptureExtractionJobId, setSelectedCaptureExtractionJobId] =
    useState<number>();
  const [selectedAnalyticRequest, setSelectedAnalyticRequest] =
    useState<APIClient.AnalyticRequest>();

  const [annotations3dGeometry, setAnnotations3dGeometry] = useState<
    IAnnotations3dGeometry | undefined
  >();

  const [selectedCaptureImage, setSelectedCaptureImage] =
    useState<APIClient.CaptureImageTag>();
  const [selectedCaptureVideo, setSelectedCaptureVideo] =
    useState<APIClient.CaptureVideo>();
  const [selectedCaptureAudio, setSelectedCaptureAudio] =
    useState<APIClient.CaptureAudio>();

  const [annotationCaptureObjects, setAnnotationCaptureObjects] = useState<
    APIClient.CaptureObject[]
  >([]);

  const [gsCloudToolState, setGsCloudToolState] = useState<
    IGsCloudToolState | undefined
  >({
    showSplats: true,
    showCameraPositions: false,
  });

  return (
    <CapturesViewerContext.Provider
      value={{
        viewerController,
        selectedCaptureJob,
        setSelectedCaptureJob,
        setSelectedAnalyticRequest,
        selectedAnalyticRequest,
        setViewerController,
        selectedCaptureExtractionJobId,
        setSelectedCaptureExtractionJobId,
        annotations3dGeometry,
        setAnnotations3dGeometry,
        annotationCaptureObjects,
        setAnnotationCaptureObjects,
        setSelectedCaptureImage,
        selectedCaptureImage,
        setSelectedCaptureVideo,
        selectedCaptureVideo,
        setSelectedCaptureAudio,
        selectedCaptureAudio,
        gsCloudToolState,
        setGsCloudToolState,
      }}
    >
      {children}
    </CapturesViewerContext.Provider>
  );
};

/**
 * Generated by orval v6.28.2 🍺
 * Do not edit manually.
 * GeminiAPI
 * OpenAPI spec version: v1
 */
import { useMutation, useQuery } from '@tanstack/react-query';
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import type { ApGeometry } from '.././models';
import { AxiosInstance } from '../../axios-instance';
import type { ErrorType, BodyType } from '../../axios-instance';

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

export const getGeometryById = (
  id: number,
  options?: SecondParameter<typeof AxiosInstance>,
  signal?: AbortSignal,
) => {
  return AxiosInstance<ApGeometry>(
    {
      url: `/api/Geometries/${encodeURIComponent(String(id))}`,
      method: 'GET',
      signal,
    },
    options,
  );
};

export const getGetGeometryByIdQueryKey = (id: number) => {
  return [`/api/Geometries/${id}`] as const;
};

export const getGetGeometryByIdQueryOptions = <
  TData = Awaited<ReturnType<typeof getGeometryById>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getGeometryById>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetGeometryByIdQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getGeometryById>>> = ({
    signal,
  }) => getGeometryById(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getGeometryById>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetGeometryByIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof getGeometryById>>
>;
export type GetGeometryByIdQueryError = ErrorType<void>;

export const useGetGeometryById = <
  TData = Awaited<ReturnType<typeof getGeometryById>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getGeometryById>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetGeometryByIdQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const putGeometryById = (
  id: number,
  apGeometry: BodyType<ApGeometry>,
  options?: SecondParameter<typeof AxiosInstance>,
) => {
  return AxiosInstance<ApGeometry>(
    {
      url: `/api/Geometries/${encodeURIComponent(String(id))}`,
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      data: apGeometry,
    },
    options,
  );
};

export const getPutGeometryByIdMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putGeometryById>>,
    TError,
    { id: number; data: BodyType<ApGeometry> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof putGeometryById>>,
  TError,
  { id: number; data: BodyType<ApGeometry> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof putGeometryById>>,
    { id: number; data: BodyType<ApGeometry> }
  > = (props) => {
    const { id, data } = props ?? {};

    return putGeometryById(id, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PutGeometryByIdMutationResult = NonNullable<
  Awaited<ReturnType<typeof putGeometryById>>
>;
export type PutGeometryByIdMutationBody = BodyType<ApGeometry>;
export type PutGeometryByIdMutationError = ErrorType<void>;

export const usePutGeometryById = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putGeometryById>>,
    TError,
    { id: number; data: BodyType<ApGeometry> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof putGeometryById>>,
  TError,
  { id: number; data: BodyType<ApGeometry> },
  TContext
> => {
  const mutationOptions = getPutGeometryByIdMutationOptions(options);

  return useMutation(mutationOptions);
};
export const deleteGeometryById = (
  id: number,
  options?: SecondParameter<typeof AxiosInstance>,
) => {
  return AxiosInstance<ApGeometry>(
    {
      url: `/api/Geometries/${encodeURIComponent(String(id))}`,
      method: 'DELETE',
    },
    options,
  );
};

export const getDeleteGeometryByIdMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteGeometryById>>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteGeometryById>>,
  TError,
  { id: number },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteGeometryById>>,
    { id: number }
  > = (props) => {
    const { id } = props ?? {};

    return deleteGeometryById(id, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteGeometryByIdMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteGeometryById>>
>;

export type DeleteGeometryByIdMutationError = ErrorType<void>;

export const useDeleteGeometryById = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteGeometryById>>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof deleteGeometryById>>,
  TError,
  { id: number },
  TContext
> => {
  const mutationOptions = getDeleteGeometryByIdMutationOptions(options);

  return useMutation(mutationOptions);
};
export const getGeometriesByGeometryCollectionId = (
  geometryCollectionid: number,
  options?: SecondParameter<typeof AxiosInstance>,
  signal?: AbortSignal,
) => {
  return AxiosInstance<ApGeometry[]>(
    {
      url: `/api/Geometries/GeometryCollection/${encodeURIComponent(String(geometryCollectionid))}`,
      method: 'GET',
      signal,
    },
    options,
  );
};

export const getGetGeometriesByGeometryCollectionIdQueryKey = (
  geometryCollectionid: number,
) => {
  return [
    `/api/Geometries/GeometryCollection/${geometryCollectionid}`,
  ] as const;
};

export const getGetGeometriesByGeometryCollectionIdQueryOptions = <
  TData = Awaited<ReturnType<typeof getGeometriesByGeometryCollectionId>>,
  TError = ErrorType<void>,
>(
  geometryCollectionid: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getGeometriesByGeometryCollectionId>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetGeometriesByGeometryCollectionIdQueryKey(geometryCollectionid);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getGeometriesByGeometryCollectionId>>
  > = ({ signal }) =>
    getGeometriesByGeometryCollectionId(
      geometryCollectionid,
      requestOptions,
      signal,
    );

  return {
    queryKey,
    queryFn,
    enabled: !!geometryCollectionid,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getGeometriesByGeometryCollectionId>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetGeometriesByGeometryCollectionIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof getGeometriesByGeometryCollectionId>>
>;
export type GetGeometriesByGeometryCollectionIdQueryError = ErrorType<void>;

export const useGetGeometriesByGeometryCollectionId = <
  TData = Awaited<ReturnType<typeof getGeometriesByGeometryCollectionId>>,
  TError = ErrorType<void>,
>(
  geometryCollectionid: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getGeometriesByGeometryCollectionId>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetGeometriesByGeometryCollectionIdQueryOptions(
    geometryCollectionid,
    options,
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const postGeometry = (
  apGeometry: BodyType<ApGeometry>,
  options?: SecondParameter<typeof AxiosInstance>,
) => {
  return AxiosInstance<ApGeometry>(
    {
      url: `/api/Geometries`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: apGeometry,
    },
    options,
  );
};

export const getPostGeometryMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postGeometry>>,
    TError,
    { data: BodyType<ApGeometry> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postGeometry>>,
  TError,
  { data: BodyType<ApGeometry> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postGeometry>>,
    { data: BodyType<ApGeometry> }
  > = (props) => {
    const { data } = props ?? {};

    return postGeometry(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostGeometryMutationResult = NonNullable<
  Awaited<ReturnType<typeof postGeometry>>
>;
export type PostGeometryMutationBody = BodyType<ApGeometry>;
export type PostGeometryMutationError = ErrorType<void>;

export const usePostGeometry = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postGeometry>>,
    TError,
    { data: BodyType<ApGeometry> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof postGeometry>>,
  TError,
  { data: BodyType<ApGeometry> },
  TContext
> => {
  const mutationOptions = getPostGeometryMutationOptions(options);

  return useMutation(mutationOptions);
};

import { faImage, faSwatchbook } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { APIClient } from '@agerpoint/api';
import { CloudInput } from '@agerpoint/cloud/components';
import {
  EffectNames,
  IPotreeViewerController,
  MaterialType,
} from '@agerpoint/types';
import { APIUtils, useActionListener } from '@agerpoint/utilities';

import { useCapturesViewerContext } from '../../captures-viewer';

interface IPotreeCloudTools {
  viewerController?: IPotreeViewerController;
}

export const PotreeCloudTools = ({ viewerController }: IPotreeCloudTools) => {
  const { annotations3dGeometry } = useCapturesViewerContext();

  const annotations = useMemo(() => {
    return [
      ...(annotations3dGeometry?.points ?? []),
      ...(annotations3dGeometry?.lines ?? []),
      ...(annotations3dGeometry?.polygons ?? []),
      ...(annotations3dGeometry?.multiPoints ?? []),
    ];
  }, [annotations3dGeometry]);

  const hasClassification = useMemo(
    () => (viewerController?.info?.classificationClasses?.length ?? 0) > 1,
    [viewerController?.info?.classificationClasses]
  );

  const isRGBA = useMemo(
    () =>
      viewerController?.info?.pointStyle === MaterialType.RGBA &&
      hasClassification,
    [viewerController?.info?.pointStyle, hasClassification]
  );

  const isClassified = useMemo(
    () =>
      viewerController?.info?.pointStyle === MaterialType.CLASSIFICATION &&
      hasClassification,
    [viewerController?.info?.pointStyle, hasClassification]
  );

  const captureJobImagesQuery = APIClient.useGetCaptureImagesByCaptureJobId(
    Number(viewerController?.info?.captureJobMetadata?.id),
    {
      query: {
        enabled: Number.isSafeInteger(
          Number(viewerController?.info?.captureJobMetadata?.id ?? undefined)
        ),
        queryKey: [
          APIUtils.QueryKey.captureJobs,
          {
            captureJobId: Number(
              viewerController?.info?.captureJobMetadata?.id
            ),
          },
          APIUtils.QueryKey.captureImages,
        ],
        select: (data) =>
          data?.filter((image) => image.x && image.y && image.z && image.id),
      },
    }
  );

  const hasImages = useMemo(
    () => (captureJobImagesQuery.data?.length ?? 0) > 0,
    [captureJobImagesQuery.data]
  );

  const [pointCloudLoaded, setPointCloudLoaded] = useState(false);

  useEffect(() => {
    setPointCloudLoaded(false);
  }, [viewerController?.info?.captureJobMetadata]);

  const pointCloudLoadedFn = useCallback(() => {
    setPointCloudLoaded(true);
  }, []);

  useActionListener(EffectNames.POTREE_POINT_CLOUD_LOADED, pointCloudLoadedFn);

  useEffect(() => {
    if (!pointCloudLoaded || !viewerController?.info?.viewerReady) {
      return;
    }

    viewerController?.removeCameraPositions();
    viewerController?.setCameraPositionsVisible?.(false);
    if (captureJobImagesQuery.data?.length) {
      viewerController?.loadCameraPositions?.(captureJobImagesQuery.data);
    }
  }, [
    pointCloudLoaded,
    viewerController?.info?.viewerReady,
    captureJobImagesQuery.data,
  ]);

  useEffect(() => {
    if (!pointCloudLoaded || !viewerController?.info?.viewerReady) {
      return;
    }

    const captureJob = viewerController?.info?.captureJobMetadata;
    if (!captureJob?.cameraSettings) {
      viewerController?.setCameraFrontView();
    } else {
      viewerController?.setCameraSettings(captureJob?.cameraSettings);
    }
  }, [
    pointCloudLoaded,
    viewerController?.info?.viewerReady,
    viewerController?.info?.captureJobMetadata,
  ]);

  return (
    <div
      className="absolute top-4 left-4 flex flex-row gap-2"
      style={{
        zIndex: 2,
      }}
    >
      <div
        className={`bg-white shadow-lg rounded-lg flex flex-row p-1 items-center`}
      >
        <CloudInput.Toggle
          id="camera-positions-toggle"
          disabled={!hasImages || !pointCloudLoaded}
          value={
            (viewerController?.info.cameraPositionsVisible ?? false) &&
            hasImages
          }
          setValue={() => {
            if (!hasImages && !pointCloudLoaded) {
              return;
            }

            viewerController?.setCameraPositionsVisible?.(
              !viewerController.info.cameraPositionsVisible
            );
          }}
          leadingIcon="camera"
          highlighted={
            (viewerController?.info.cameraPositionsVisible ?? false) &&
            hasImages
          }
        />
      </div>
      {annotations.length > 0 && (
        <div
          className={
            'flex flex-row cursor-pointer bg-white rounded-lg px-1 gap-1 items-center'
          }
        >
          <CloudInput.Toggle
            id="annotations-toggle"
            disabled={true}
            value={true}
            highlighted={true}
            setValue={() => {
              //
            }}
            leadingIcon="location-dot"
          />
        </div>
      )}

      <div className="bg-white shadow-lg rounded-lg flex flex-row p-1 items-center">
        <div
          className={`flex-center flex-row gap-2 relative rounded-lg p-1 transition-opacity ${
            isRGBA ? 'bg-primary bg-opacity-10' : ''
          }
            ${
              hasClassification
                ? 'cursor-pointer'
                : 'opacity-50 cursor-not-allowed'
            }
            `}
          onClick={() => {
            if (hasClassification) {
              viewerController?.setPointStyle(MaterialType.RGBA);
            }
          }}
        >
          <FontAwesomeIcon
            icon={faImage}
            className={isRGBA ? 'text-primary' : 'text-gray-iconPrimary'}
          />
          <div className={isRGBA ? 'text-primary' : 'text-gray-textPrimary'}>
            Photorealistic
          </div>
        </div>
        <div
          className={`flex-center flex-row gap-2 p-1 relative rounded-lg transition-opacity ${
            isClassified ? 'bg-primary bg-opacity-10' : ''
          }
            ${
              hasClassification
                ? 'cursor-pointer'
                : 'opacity-50 cursor-not-allowed'
            }
            `}
          onClick={() => {
            if (hasClassification) {
              viewerController?.setPointStyle(MaterialType.CLASSIFICATION);
            }
          }}
        >
          <FontAwesomeIcon
            icon={faSwatchbook}
            className={isClassified ? 'text-primary' : 'text-gray-iconPrimary'}
          />
          <div
            className={isClassified ? 'text-primary' : 'text-gray-textPrimary'}
          >
            Classified
          </div>
        </div>
      </div>
    </div>
  );
};

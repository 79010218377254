import { useCallback, useEffect } from 'react';

import { EffectNames, SpecialCaptureObject } from '@agerpoint/types';
import { useGlobalStore } from '@agerpoint/utilities';

import { useCapturesViewerContext } from '../../../captures-viewer';
import { useThreeDAnnotationsPluginQueries } from './three-d-annotations-plugin-queries';

export const ThreeDAnnotationsPlugin = () => {
  const { selectedCaptureJob, annotationCaptureObjects } =
    useCapturesViewerContext();

  const {
    createCaptureObject,
    deleteCaptureObject,
    deleteCustomCaptureObjectAttributes,
    updateCustomCaptureObjectAttributes,
  } = useThreeDAnnotationsPluginQueries();
  const { subscribe } = useGlobalStore();

  const createEffectFn = useCallback(
    (captureObject: SpecialCaptureObject) => {
      if (!selectedCaptureJob) return;
      const captureId = selectedCaptureJob.captureId;
      const captureJobId = selectedCaptureJob.id;

      captureObject.captureId = captureId;
      captureObject.captureJobId = captureJobId;
      createCaptureObject.mutate(captureObject);
    },
    [selectedCaptureJob, createCaptureObject]
  );

  const deleteEffectFn = useCallback(
    (captureObjectId: string) => {
      if (!selectedCaptureJob) return;

      const co = annotationCaptureObjects?.find(
        (co) => co.id === Number(captureObjectId)
      );
      if (co?.captureObjectCustomAttributes) {
        const promises = Promise.all(
          co.captureObjectCustomAttributes.map((attr) => {
            if (!attr?.id) return Promise.resolve();
            return deleteCustomCaptureObjectAttributes.mutate(attr.id);
          })
        );
        promises.then(() => {
          deleteCaptureObject.mutate(Number(captureObjectId));
        });
      } else {
        deleteCaptureObject.mutate(Number(captureObjectId));
      }
    },
    [selectedCaptureJob, annotationCaptureObjects]
  );

  const updateColorEffectFn = useCallback(
    ({ id, color }: { id: number; color: string }) => {
      const co = annotationCaptureObjects?.find((co) => co.id === Number(id));
      if (!co?.captureObjectCustomAttributes) return;
      const customAttribute = co.captureObjectCustomAttributes.find(
        (attr) => attr.attributeName === 'color'
      );
      if (!customAttribute?.id) return;
      customAttribute.attributeValue = color;
      updateCustomCaptureObjectAttributes.mutate({
        updatedCaptureAttribute: customAttribute,
        customAttributeId: customAttribute.id,
      });
    },
    [annotationCaptureObjects]
  );

  useEffect(() => {
    const unsubscribe = subscribe(
      EffectNames.CAPTURE_OBJECTS_LISTENER_CREATE,
      createEffectFn
    );

    return unsubscribe;
  }, [selectedCaptureJob]);

  useEffect(() => {
    const unsubscribe = subscribe(
      EffectNames.CAPTURE_OBJECTS_LISTENER_DELETE,
      deleteEffectFn
    );
    return unsubscribe;
  }, [selectedCaptureJob, annotationCaptureObjects]);

  useEffect(() => {
    const unsubscribe = subscribe(
      EffectNames.CAPTURE_OBJECTS_LISTENER_UPDATE,
      updateColorEffectFn
    );
    return unsubscribe;
  }, [selectedCaptureJob, annotationCaptureObjects]);

  return null;
};

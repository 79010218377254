/**
 * Generated by orval v6.28.2 🍺
 * Do not edit manually.
 * GeminiAPI
 * OpenAPI spec version: v1
 */
import { useMutation, useQuery } from '@tanstack/react-query';
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import type { CaptureVideo, VideoMetadata } from '.././models';
import { AxiosInstance } from '../../axios-instance';
import type { ErrorType, BodyType } from '../../axios-instance';

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

export const getCaptureVideos = (
  options?: SecondParameter<typeof AxiosInstance>,
  signal?: AbortSignal,
) => {
  return AxiosInstance<CaptureVideo[]>(
    { url: `/api/CaptureVideos`, method: 'GET', signal },
    options,
  );
};

export const getGetCaptureVideosQueryKey = () => {
  return [`/api/CaptureVideos`] as const;
};

export const getGetCaptureVideosQueryOptions = <
  TData = Awaited<ReturnType<typeof getCaptureVideos>>,
  TError = ErrorType<void>,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof getCaptureVideos>>, TError, TData>
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetCaptureVideosQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getCaptureVideos>>
  > = ({ signal }) => getCaptureVideos(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getCaptureVideos>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetCaptureVideosQueryResult = NonNullable<
  Awaited<ReturnType<typeof getCaptureVideos>>
>;
export type GetCaptureVideosQueryError = ErrorType<void>;

export const useGetCaptureVideos = <
  TData = Awaited<ReturnType<typeof getCaptureVideos>>,
  TError = ErrorType<void>,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof getCaptureVideos>>, TError, TData>
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetCaptureVideosQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getCaptureVideoById = (
  id: number,
  options?: SecondParameter<typeof AxiosInstance>,
  signal?: AbortSignal,
) => {
  return AxiosInstance<CaptureVideo>(
    {
      url: `/api/CaptureVideos/${encodeURIComponent(String(id))}`,
      method: 'GET',
      signal,
    },
    options,
  );
};

export const getGetCaptureVideoByIdQueryKey = (id: number) => {
  return [`/api/CaptureVideos/${id}`] as const;
};

export const getGetCaptureVideoByIdQueryOptions = <
  TData = Awaited<ReturnType<typeof getCaptureVideoById>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getCaptureVideoById>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetCaptureVideoByIdQueryKey(id);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getCaptureVideoById>>
  > = ({ signal }) => getCaptureVideoById(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getCaptureVideoById>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetCaptureVideoByIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof getCaptureVideoById>>
>;
export type GetCaptureVideoByIdQueryError = ErrorType<void>;

export const useGetCaptureVideoById = <
  TData = Awaited<ReturnType<typeof getCaptureVideoById>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getCaptureVideoById>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetCaptureVideoByIdQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const putCaptureVideoById = (
  id: number,
  captureVideo: BodyType<CaptureVideo>,
  options?: SecondParameter<typeof AxiosInstance>,
) => {
  return AxiosInstance<CaptureVideo>(
    {
      url: `/api/CaptureVideos/${encodeURIComponent(String(id))}`,
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      data: captureVideo,
    },
    options,
  );
};

export const getPutCaptureVideoByIdMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putCaptureVideoById>>,
    TError,
    { id: number; data: BodyType<CaptureVideo> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof putCaptureVideoById>>,
  TError,
  { id: number; data: BodyType<CaptureVideo> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof putCaptureVideoById>>,
    { id: number; data: BodyType<CaptureVideo> }
  > = (props) => {
    const { id, data } = props ?? {};

    return putCaptureVideoById(id, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PutCaptureVideoByIdMutationResult = NonNullable<
  Awaited<ReturnType<typeof putCaptureVideoById>>
>;
export type PutCaptureVideoByIdMutationBody = BodyType<CaptureVideo>;
export type PutCaptureVideoByIdMutationError = ErrorType<void>;

export const usePutCaptureVideoById = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putCaptureVideoById>>,
    TError,
    { id: number; data: BodyType<CaptureVideo> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof putCaptureVideoById>>,
  TError,
  { id: number; data: BodyType<CaptureVideo> },
  TContext
> => {
  const mutationOptions = getPutCaptureVideoByIdMutationOptions(options);

  return useMutation(mutationOptions);
};
export const getCaptureVideoMetaById = (
  id: number,
  options?: SecondParameter<typeof AxiosInstance>,
  signal?: AbortSignal,
) => {
  return AxiosInstance<VideoMetadata>(
    {
      url: `/api/CaptureVideos/${encodeURIComponent(String(id))}/Meta`,
      method: 'GET',
      signal,
    },
    options,
  );
};

export const getGetCaptureVideoMetaByIdQueryKey = (id: number) => {
  return [`/api/CaptureVideos/${id}/Meta`] as const;
};

export const getGetCaptureVideoMetaByIdQueryOptions = <
  TData = Awaited<ReturnType<typeof getCaptureVideoMetaById>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getCaptureVideoMetaById>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetCaptureVideoMetaByIdQueryKey(id);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getCaptureVideoMetaById>>
  > = ({ signal }) => getCaptureVideoMetaById(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getCaptureVideoMetaById>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetCaptureVideoMetaByIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof getCaptureVideoMetaById>>
>;
export type GetCaptureVideoMetaByIdQueryError = ErrorType<void>;

export const useGetCaptureVideoMetaById = <
  TData = Awaited<ReturnType<typeof getCaptureVideoMetaById>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getCaptureVideoMetaById>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetCaptureVideoMetaByIdQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getCaptureVideosByCaptureId = (
  captureId: number,
  options?: SecondParameter<typeof AxiosInstance>,
  signal?: AbortSignal,
) => {
  return AxiosInstance<CaptureVideo[]>(
    {
      url: `/api/CaptureVideos/Capture/${encodeURIComponent(String(captureId))}`,
      method: 'GET',
      signal,
    },
    options,
  );
};

export const getGetCaptureVideosByCaptureIdQueryKey = (captureId: number) => {
  return [`/api/CaptureVideos/Capture/${captureId}`] as const;
};

export const getGetCaptureVideosByCaptureIdQueryOptions = <
  TData = Awaited<ReturnType<typeof getCaptureVideosByCaptureId>>,
  TError = ErrorType<void>,
>(
  captureId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getCaptureVideosByCaptureId>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetCaptureVideosByCaptureIdQueryKey(captureId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getCaptureVideosByCaptureId>>
  > = ({ signal }) =>
    getCaptureVideosByCaptureId(captureId, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!captureId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getCaptureVideosByCaptureId>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetCaptureVideosByCaptureIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof getCaptureVideosByCaptureId>>
>;
export type GetCaptureVideosByCaptureIdQueryError = ErrorType<void>;

export const useGetCaptureVideosByCaptureId = <
  TData = Awaited<ReturnType<typeof getCaptureVideosByCaptureId>>,
  TError = ErrorType<void>,
>(
  captureId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getCaptureVideosByCaptureId>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetCaptureVideosByCaptureIdQueryOptions(
    captureId,
    options,
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getCaptureVideosByCaptureExtractionJobId = (
  captureExtractionJobId: number,
  options?: SecondParameter<typeof AxiosInstance>,
  signal?: AbortSignal,
) => {
  return AxiosInstance<CaptureVideo[]>(
    {
      url: `/api/CaptureVideos/CaptureExtractionJob/${encodeURIComponent(String(captureExtractionJobId))}`,
      method: 'GET',
      signal,
    },
    options,
  );
};

export const getGetCaptureVideosByCaptureExtractionJobIdQueryKey = (
  captureExtractionJobId: number,
) => {
  return [
    `/api/CaptureVideos/CaptureExtractionJob/${captureExtractionJobId}`,
  ] as const;
};

export const getGetCaptureVideosByCaptureExtractionJobIdQueryOptions = <
  TData = Awaited<ReturnType<typeof getCaptureVideosByCaptureExtractionJobId>>,
  TError = ErrorType<void>,
>(
  captureExtractionJobId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getCaptureVideosByCaptureExtractionJobId>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetCaptureVideosByCaptureExtractionJobIdQueryKey(captureExtractionJobId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getCaptureVideosByCaptureExtractionJobId>>
  > = ({ signal }) =>
    getCaptureVideosByCaptureExtractionJobId(
      captureExtractionJobId,
      requestOptions,
      signal,
    );

  return {
    queryKey,
    queryFn,
    enabled: !!captureExtractionJobId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getCaptureVideosByCaptureExtractionJobId>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetCaptureVideosByCaptureExtractionJobIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof getCaptureVideosByCaptureExtractionJobId>>
>;
export type GetCaptureVideosByCaptureExtractionJobIdQueryError =
  ErrorType<void>;

export const useGetCaptureVideosByCaptureExtractionJobId = <
  TData = Awaited<ReturnType<typeof getCaptureVideosByCaptureExtractionJobId>>,
  TError = ErrorType<void>,
>(
  captureExtractionJobId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getCaptureVideosByCaptureExtractionJobId>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetCaptureVideosByCaptureExtractionJobIdQueryOptions(
    captureExtractionJobId,
    options,
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getCaptureVideosByAnalyticRequestId = (
  analyticRequestId: number,
  options?: SecondParameter<typeof AxiosInstance>,
  signal?: AbortSignal,
) => {
  return AxiosInstance<CaptureVideo[]>(
    {
      url: `/api/CaptureVideos/AnalyticRequest/${encodeURIComponent(String(analyticRequestId))}`,
      method: 'GET',
      signal,
    },
    options,
  );
};

export const getGetCaptureVideosByAnalyticRequestIdQueryKey = (
  analyticRequestId: number,
) => {
  return [`/api/CaptureVideos/AnalyticRequest/${analyticRequestId}`] as const;
};

export const getGetCaptureVideosByAnalyticRequestIdQueryOptions = <
  TData = Awaited<ReturnType<typeof getCaptureVideosByAnalyticRequestId>>,
  TError = ErrorType<void>,
>(
  analyticRequestId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getCaptureVideosByAnalyticRequestId>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetCaptureVideosByAnalyticRequestIdQueryKey(analyticRequestId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getCaptureVideosByAnalyticRequestId>>
  > = ({ signal }) =>
    getCaptureVideosByAnalyticRequestId(
      analyticRequestId,
      requestOptions,
      signal,
    );

  return {
    queryKey,
    queryFn,
    enabled: !!analyticRequestId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getCaptureVideosByAnalyticRequestId>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetCaptureVideosByAnalyticRequestIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof getCaptureVideosByAnalyticRequestId>>
>;
export type GetCaptureVideosByAnalyticRequestIdQueryError = ErrorType<void>;

export const useGetCaptureVideosByAnalyticRequestId = <
  TData = Awaited<ReturnType<typeof getCaptureVideosByAnalyticRequestId>>,
  TError = ErrorType<void>,
>(
  analyticRequestId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getCaptureVideosByAnalyticRequestId>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetCaptureVideosByAnalyticRequestIdQueryOptions(
    analyticRequestId,
    options,
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getCaptureVideoImageById = (
  id: number,
  size: string,
  filters: string,
  options?: SecondParameter<typeof AxiosInstance>,
  signal?: AbortSignal,
) => {
  return AxiosInstance<Blob>(
    {
      url: `/api/CaptureVideos/${encodeURIComponent(String(id))}/Image/${encodeURIComponent(String(size))}/${encodeURIComponent(String(filters))}`,
      method: 'GET',
      responseType: 'blob',
      signal,
    },
    options,
  );
};

export const getGetCaptureVideoImageByIdQueryKey = (
  id: number,
  size: string,
  filters: string,
) => {
  return [`/api/CaptureVideos/${id}/Image/${size}/${filters}`] as const;
};

export const getGetCaptureVideoImageByIdQueryOptions = <
  TData = Awaited<ReturnType<typeof getCaptureVideoImageById>>,
  TError = ErrorType<void>,
>(
  id: number,
  size: string,
  filters: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getCaptureVideoImageById>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetCaptureVideoImageByIdQueryKey(id, size, filters);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getCaptureVideoImageById>>
  > = ({ signal }) =>
    getCaptureVideoImageById(id, size, filters, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!(id && size && filters),
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getCaptureVideoImageById>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetCaptureVideoImageByIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof getCaptureVideoImageById>>
>;
export type GetCaptureVideoImageByIdQueryError = ErrorType<void>;

export const useGetCaptureVideoImageById = <
  TData = Awaited<ReturnType<typeof getCaptureVideoImageById>>,
  TError = ErrorType<void>,
>(
  id: number,
  size: string,
  filters: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getCaptureVideoImageById>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetCaptureVideoImageByIdQueryOptions(
    id,
    size,
    filters,
    options,
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

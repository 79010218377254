import { Vector3 } from 'three';

import { APIModels } from '@agerpoint/api';
import {
  Annotation3dLines,
  Annotation3dPoints,
  Annotation3dPolygons,
  ColorsThreeD,
  HexColor,
  ILine3d,
  IMultiPoint3d,
  IPoint3d,
  LatLngAlt,
} from '@agerpoint/types';
import { enuToLla } from '@agerpoint/utilities';

export class AnnotationsStore {
  private points3d: { [key: string]: IPoint3d } = {};
  private multiPoints3d: { [key: string]: IMultiPoint3d } = {};
  private lines3d: { [key: string]: ILine3d } = {};
  private polygons3d: { [key: string]: ILine3d } = {};

  hasPoint3d(id: string): boolean {
    return this.points3d.hasOwnProperty(id);
  }
  hasLine3d(id: string): boolean {
    return this.lines3d.hasOwnProperty(id);
  }
  hasPolygon3d(id: string): boolean {
    return this.polygons3d.hasOwnProperty(id);
  }

  getPoint3d(id: string): IPoint3d {
    return this.points3d[id];
  }
  getMultiPoint3d(id: string): IMultiPoint3d {
    return this.multiPoints3d[id];
  }
  getLine3d(id: string): ILine3d {
    return this.lines3d[id];
  }
  getPolygon3d(id: string): ILine3d {
    return this.polygons3d[id];
  }

  getAllPoints3d() {
    return this.points3d;
  }
  getAllLines3d() {
    return this.lines3d;
  }
  getAllPolygons3d() {
    return this.polygons3d;
  }

  getLinePositionById(id: string) {
    if (!this.lines3d[id]) return [];
    return this.lines3d[id].getPosition();
  }

  getPoint3dByType(type: Annotation3dPoints) {
    return Object.values(this.points3d).filter((point) => point.type === type);
  }
  getMultiPoint3dByType(type: Annotation3dPoints) {
    return Object.values(this.multiPoints3d).filter(
      (point) => point.type === type
    );
  }
  getLine3dByType(type: Annotation3dLines) {
    return Object.values(this.lines3d).filter((line) => line.type === type);
  }
  getPolygon3dByType(type: Annotation3dPolygons) {
    return Object.values(this.polygons3d).filter(
      (polygon) => polygon.type === type
    );
  }

  public getAnnotationGeometry() {
    // omits camera spheres, for example
    return {
      points: this.getPoint3dByType(Annotation3dPoints.AnnotationPoint),
      lines: this.getLine3dByType(Annotation3dLines.AnnotationLine),
      polygons: this.getPolygon3dByType(Annotation3dPolygons.AnnotationPolygon),
      multiPoints: this.getMultiPoint3dByType(
        Annotation3dPoints.MultiPointMarker
      ),
    };
  }

  addPoint3d(point: IPoint3d) {
    this.points3d[point.uniqueId] = point;
  }
  addMultiPoint3d(multiPoint: IMultiPoint3d, id: string) {
    this.multiPoints3d[id] = multiPoint;
  }
  addLine3d(line: ILine3d) {
    this.lines3d[line.uniqueId] = line;
  }
  addPolygon3d(polygon: ILine3d) {
    this.polygons3d[polygon.uniqueId] = polygon;
  }

  removePoint3dById(id: string) {
    this.points3d[id].dispose();
    delete this.points3d[id];
  }
  removeLine3dById(id: string) {
    this.lines3d[id].dispose();
    delete this.lines3d[id];
  }
  removePolygon3dById(id: string) {
    this.polygons3d[id].dispose();
    delete this.polygons3d[id];
  }
  removeMultiPoint3dById(id: string) {
    this.multiPoints3d[id].dispose();
    delete this.multiPoints3d[id];
  }

  removePoint3dByType(type: Annotation3dPoints) {
    for (const key in this.points3d) {
      if (this.points3d[key].type === type) {
        this.points3d[key].dispose();
        delete this.points3d[key];
      }
    }
  }

  // Highlighting
  highlightPoint3dById(id: string, color?: ColorsThreeD | HexColor) {
    this.unHighlightAll3d();
    const obj = this.points3d[id];
    if (!obj) return;
    obj.highlight(color);
  }
  highlightLine3dById(id: string) {
    this.unHighlightAll3d();
    const obj = this.lines3d[id];
    if (!obj) return;
    obj.highlight();
  }
  highlightPolygon3dById(id: string) {
    this.unHighlightAll3d();
    if (!this.polygons3d[id]) return;
    this.polygons3d[id].highlight();
  }
  highlightMultiPoint3dById(id: string) {
    this.unHighlightAll3d();
    const obj = this.multiPoints3d[id];
    if (!obj) return;
    obj.points.forEach((point) => point.highlight());
  }

  // unHighlighting
  unHighlightAllPoint3d() {
    for (const key in this.points3d) {
      this.points3d[key].unHighlight();
    }
  }
  unHighlightAllMultiPoint3d() {
    for (const key in this.multiPoints3d) {
      this.multiPoints3d[key].points.forEach((point) => point.unHighlight());
    }
  }
  unHighlightAllLine3d() {
    for (const key in this.lines3d) {
      this.lines3d[key].unHighlight();
    }
  }
  unHighlightAllPolygon3d() {
    for (const key in this.polygons3d) {
      this.polygons3d[key].unHighlight();
    }
  }
  unHighlightAll3d() {
    this.unHighlightAllPoint3d();
    this.unHighlightAllMultiPoint3d();
    this.unHighlightAllLine3d();
    this.unHighlightAllPolygon3d();
  }

  hidePointsByType(type: Annotation3dPoints) {
    for (const key in this.points3d) {
      if (this.points3d[key].type === type) {
        this.points3d[key].hide();
      }
    }
  }

  showPoints3dByType(type: Annotation3dPoints) {
    for (const key in this.points3d) {
      if (this.points3d[key].type === type) {
        this.points3d[key].show();
      }
    }
  }

  getNewPoint3dId() {
    return `new-point-${Object.keys(this.points3d).length}`;
  }

  getNewLine3dId() {
    return `new-line-${Object.keys(this.lines3d).length}`;
  }

  getNewPolygon3dId() {
    return `new-polygon-${Object.keys(this.polygons3d).length}`;
  }

  getNewMultiPoint3dId() {
    return `new-count-${Object.keys(this.multiPoints3d).length}`;
  }

  updateAllPoint3dLookAt() {
    const pointKeys = Object.keys(this.points3d);
    pointKeys.forEach((point) => {
      this.points3d[point].updateLookAt();
    });
  }

  updateMultiPoint3dPosition(id: string, points: Vector3[]) {
    if (this.multiPoints3d[id]) {
      this.multiPoints3d[id].updatePositions(points);
    }
  }

  updateLine3dPosition(id: string, points: Vector3[]) {
    if (this.lines3d[id]) {
      this.lines3d[id].updatePosition(points);
    }
  }

  updatePolygon3dPosition(id: string, points: Vector3[]) {
    if (this.polygons3d[id]) {
      this.polygons3d[id].updatePosition(points);
    }
  }

  finishCreating3dMultiPoint(id: string) {
    if (this.multiPoints3d[id]) {
      this.multiPoints3d[id].updateVisibility(true);
      this.multiPoints3d[id].updateType(Annotation3dPoints.MultiPointMarker);
    }
  }

  finishCreating3dLine(id: string) {
    if (this.lines3d[id]) {
      this.lines3d[id].updateVisibility(true);
      this.lines3d[id].updateType(Annotation3dLines.AnnotationLine);
    }
  }

  finishCreating3dPolygon(id: string) {
    if (this.polygons3d[id]) {
      this.polygons3d[id].updateVisibility(true);
      this.polygons3d[id].updateType(Annotation3dPolygons.AnnotationPolygon);
    }
  }

  clear3dPoints() {
    const pointKeys = Object.keys(this.points3d);
    pointKeys.forEach((point) => {
      if (this.points3d[point].type === Annotation3dPoints.AnnotationPoint) {
        this.points3d[point].dispose();
        delete this.points3d[point];
      }
    });
  }

  clear3dMultiPoints() {
    const multiPointKeys = Object.keys(this.multiPoints3d);
    multiPointKeys.forEach((multiPoint) => {
      this.multiPoints3d[multiPoint].dispose();
    });
    this.multiPoints3d = {};
  }

  clear3dLines() {
    const lineKeys = Object.keys(this.lines3d);
    lineKeys.forEach((line) => {
      this.lines3d[line].dispose();
    });
    this.lines3d = {};
  }

  clear3dPolygons() {
    const polygonKeys = Object.keys(this.polygons3d);
    polygonKeys.forEach((polygon) => {
      this.polygons3d[polygon].dispose();
    });
    this.polygons3d = {};
  }
}

/**
 * Generated by orval v6.28.2 🍺
 * Do not edit manually.
 * GeminiAPI
 * OpenAPI spec version: v1
 */
import { useMutation } from '@tanstack/react-query';
import type {
  MutationFunction,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import type { RequestAnalytics } from '.././models';
import { AxiosInstance } from '../../axios-instance';
import type { ErrorType, BodyType } from '../../axios-instance';

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

export const postRequestAnalytics = (
  requestAnalytics: BodyType<RequestAnalytics>,
  options?: SecondParameter<typeof AxiosInstance>,
) => {
  return AxiosInstance<void>(
    {
      url: `/api/RequestAnalytics`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: requestAnalytics,
    },
    options,
  );
};

export const getPostRequestAnalyticsMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postRequestAnalytics>>,
    TError,
    { data: BodyType<RequestAnalytics> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postRequestAnalytics>>,
  TError,
  { data: BodyType<RequestAnalytics> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postRequestAnalytics>>,
    { data: BodyType<RequestAnalytics> }
  > = (props) => {
    const { data } = props ?? {};

    return postRequestAnalytics(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostRequestAnalyticsMutationResult = NonNullable<
  Awaited<ReturnType<typeof postRequestAnalytics>>
>;
export type PostRequestAnalyticsMutationBody = BodyType<RequestAnalytics>;
export type PostRequestAnalyticsMutationError = ErrorType<void>;

export const usePostRequestAnalytics = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postRequestAnalytics>>,
    TError,
    { data: BodyType<RequestAnalytics> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof postRequestAnalytics>>,
  TError,
  { data: BodyType<RequestAnalytics> },
  TContext
> => {
  const mutationOptions = getPostRequestAnalyticsMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * Generated by orval v6.28.2 🍺
 * Do not edit manually.
 * GeminiAPI
 * OpenAPI spec version: v1
 */
import { useMutation, useQuery } from '@tanstack/react-query';
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import type { Upload, UploadFilter } from '.././models';
import { AxiosInstance } from '../../axios-instance';
import type { ErrorType, BodyType } from '../../axios-instance';

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

export const getUploadById = (
  id: number,
  options?: SecondParameter<typeof AxiosInstance>,
  signal?: AbortSignal,
) => {
  return AxiosInstance<Upload>(
    {
      url: `/api/Upload/${encodeURIComponent(String(id))}`,
      method: 'GET',
      signal,
    },
    options,
  );
};

export const getGetUploadByIdQueryKey = (id: number) => {
  return [`/api/Upload/${id}`] as const;
};

export const getGetUploadByIdQueryOptions = <
  TData = Awaited<ReturnType<typeof getUploadById>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getUploadById>>, TError, TData>
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetUploadByIdQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getUploadById>>> = ({
    signal,
  }) => getUploadById(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getUploadById>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetUploadByIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof getUploadById>>
>;
export type GetUploadByIdQueryError = ErrorType<void>;

export const useGetUploadById = <
  TData = Awaited<ReturnType<typeof getUploadById>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getUploadById>>, TError, TData>
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetUploadByIdQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const putUploadById = (
  id: number,
  upload: BodyType<Upload>,
  options?: SecondParameter<typeof AxiosInstance>,
) => {
  return AxiosInstance<Upload>(
    {
      url: `/api/Upload/${encodeURIComponent(String(id))}`,
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      data: upload,
    },
    options,
  );
};

export const getPutUploadByIdMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putUploadById>>,
    TError,
    { id: number; data: BodyType<Upload> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof putUploadById>>,
  TError,
  { id: number; data: BodyType<Upload> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof putUploadById>>,
    { id: number; data: BodyType<Upload> }
  > = (props) => {
    const { id, data } = props ?? {};

    return putUploadById(id, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PutUploadByIdMutationResult = NonNullable<
  Awaited<ReturnType<typeof putUploadById>>
>;
export type PutUploadByIdMutationBody = BodyType<Upload>;
export type PutUploadByIdMutationError = ErrorType<void>;

export const usePutUploadById = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putUploadById>>,
    TError,
    { id: number; data: BodyType<Upload> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof putUploadById>>,
  TError,
  { id: number; data: BodyType<Upload> },
  TContext
> => {
  const mutationOptions = getPutUploadByIdMutationOptions(options);

  return useMutation(mutationOptions);
};
export const getPagedUploads = (
  skip: number,
  take: number,
  options?: SecondParameter<typeof AxiosInstance>,
  signal?: AbortSignal,
) => {
  return AxiosInstance<Upload[]>(
    {
      url: `/api/Upload/Completed/skip/${encodeURIComponent(String(skip))}/take/${encodeURIComponent(String(take))}`,
      method: 'GET',
      signal,
    },
    options,
  );
};

export const getGetPagedUploadsQueryKey = (skip: number, take: number) => {
  return [`/api/Upload/Completed/skip/${skip}/take/${take}`] as const;
};

export const getGetPagedUploadsQueryOptions = <
  TData = Awaited<ReturnType<typeof getPagedUploads>>,
  TError = ErrorType<void>,
>(
  skip: number,
  take: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getPagedUploads>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetPagedUploadsQueryKey(skip, take);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getPagedUploads>>> = ({
    signal,
  }) => getPagedUploads(skip, take, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!(skip && take),
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getPagedUploads>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetPagedUploadsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getPagedUploads>>
>;
export type GetPagedUploadsQueryError = ErrorType<void>;

export const useGetPagedUploads = <
  TData = Awaited<ReturnType<typeof getPagedUploads>>,
  TError = ErrorType<void>,
>(
  skip: number,
  take: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getPagedUploads>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetPagedUploadsQueryOptions(skip, take, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getFilteredPagedUploads = (
  skip: number,
  take: number,
  uploadFilter: BodyType<UploadFilter>,
  options?: SecondParameter<typeof AxiosInstance>,
) => {
  return AxiosInstance<Upload[]>(
    {
      url: `/api/Upload/Completed/skip/${encodeURIComponent(String(skip))}/take/${encodeURIComponent(String(take))}`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: uploadFilter,
    },
    options,
  );
};

export const getGetFilteredPagedUploadsMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof getFilteredPagedUploads>>,
    TError,
    { skip: number; take: number; data: BodyType<UploadFilter> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof getFilteredPagedUploads>>,
  TError,
  { skip: number; take: number; data: BodyType<UploadFilter> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof getFilteredPagedUploads>>,
    { skip: number; take: number; data: BodyType<UploadFilter> }
  > = (props) => {
    const { skip, take, data } = props ?? {};

    return getFilteredPagedUploads(skip, take, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type GetFilteredPagedUploadsMutationResult = NonNullable<
  Awaited<ReturnType<typeof getFilteredPagedUploads>>
>;
export type GetFilteredPagedUploadsMutationBody = BodyType<UploadFilter>;
export type GetFilteredPagedUploadsMutationError = ErrorType<void>;

export const useGetFilteredPagedUploads = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof getFilteredPagedUploads>>,
    TError,
    { skip: number; take: number; data: BodyType<UploadFilter> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof getFilteredPagedUploads>>,
  TError,
  { skip: number; take: number; data: BodyType<UploadFilter> },
  TContext
> => {
  const mutationOptions = getGetFilteredPagedUploadsMutationOptions(options);

  return useMutation(mutationOptions);
};
export const postUpload = (
  upload: BodyType<Upload>,
  options?: SecondParameter<typeof AxiosInstance>,
) => {
  return AxiosInstance<Upload>(
    {
      url: `/api/Upload`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: upload,
    },
    options,
  );
};

export const getPostUploadMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postUpload>>,
    TError,
    { data: BodyType<Upload> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postUpload>>,
  TError,
  { data: BodyType<Upload> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postUpload>>,
    { data: BodyType<Upload> }
  > = (props) => {
    const { data } = props ?? {};

    return postUpload(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostUploadMutationResult = NonNullable<
  Awaited<ReturnType<typeof postUpload>>
>;
export type PostUploadMutationBody = BodyType<Upload>;
export type PostUploadMutationError = ErrorType<void>;

export const usePostUpload = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postUpload>>,
    TError,
    { data: BodyType<Upload> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof postUpload>>,
  TError,
  { data: BodyType<Upload> },
  TContext
> => {
  const mutationOptions = getPostUploadMutationOptions(options);

  return useMutation(mutationOptions);
};

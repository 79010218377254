import { faDownload, faSpinner } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Extent } from 'ol/extent';

import { GetResponse } from '@agerpoint/api';
import { useDownloadLayerById, useGetFileReportById } from '@agerpoint/api';
import {
  ExpansionContainerInner,
  ExpansionContainerOuter,
  IconButton,
} from '@agerpoint/component';
import { Attributes, Layer } from '@agerpoint/types';

import {
  ExpansionDelete,
  ExpansionToolbar,
  ZoomToExtent,
} from '../expansion-toolbar/expansion-toolbar';
import { LayerStyle } from '../layer-style/layer-style';
import { SidebarExpansionHeaderEdit } from '../sidebar-expansion-header-edit/sidebar-expansion-header-edit';

interface LayerExpansionProps {
  layer: Layer;
  onCancel: () => void;
  updateLayerName?: (id: number, name: string) => Promise<void>;
  updateLayerStyle: (layer: Layer) => Promise<void>;
  deleteLayer: (id: number) => Promise<void>;
  setDesiredExtent: (extent: Extent) => void;
  availableAttributes: GetResponse<Attributes, void> | undefined;
}

export function LayerExpansion({
  layer,
  onCancel,
  updateLayerName = undefined,
  deleteLayer,
  updateLayerStyle,
  setDesiredExtent,
  availableAttributes,
}: LayerExpansionProps) {
  let fileName = layer.name + '.geojson';
  const { error: errorLoadingFileName } = useGetFileReportById({
    id: layer.entityId,
    lazy: layer.type !== 'File',
    resolve: (data) => {
      fileName = data.fileName;
      return data;
    },
  });
  const { loading, refetch } = useDownloadLayerById({
    id: layer.id,
    lazy: true,
    resolve: (response) => {
      if (response?.ok) {
        response.blob().then((blob: Blob) => {
          // Create blob link to download
          const url = window.URL.createObjectURL(new Blob([blob]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', fileName);
          // Append to html link element page
          document.body.appendChild(link);
          // Start download
          link.click();
        });
      }
      return response;
    },
  });
  return (
    <div className="h-full relative">
      <SidebarExpansionHeaderEdit
        onSave={
          updateLayerName
            ? (layerName) => updateLayerName(layer.id, layerName)
            : undefined
        }
        onCancel={onCancel}
        name={layer.name}
      />
      <ExpansionContainerOuter>
        <LayerStyle
          layer={layer}
          onChange={updateLayerStyle}
          availableAttributes={availableAttributes}
        />
        <ExpansionContainerInner>
          <ExpansionToolbar>
            {({ showConfirm, isConfirming }) => (
              <>
                {'extent' in layer && layer.typeId !== 7 && (
                  <ZoomToExtent onZoom={() => setDesiredExtent(layer.extent)} />
                )}
                {(layer.typeId === 1 || layer.typeId === 7) &&
                  !errorLoadingFileName && (
                    <IconButton
                      show
                      className={` ${
                        layer.typeId === 7 ? 'ml-12' : 'mr-auto ml-3'
                      } text-gray-500 hover:text-gray-900`}
                      disabled={loading ? true : false}
                      onClick={() => {
                        refetch();
                      }}
                      icon={
                        <FontAwesomeIcon
                          icon={loading ? faSpinner : faDownload}
                          className={loading ? 'text-xl fa-spin' : 'text-xl'}
                        />
                      }
                    />
                  )}
                <ExpansionDelete
                  isConfirming={isConfirming}
                  showConfirm={showConfirm}
                  onDelete={() => deleteLayer(layer.id).finally(onCancel)}
                  message="Are you sure you want to remove this layer?"
                  deleteLabel="Remove Layer"
                />
              </>
            )}
          </ExpansionToolbar>
        </ExpansionContainerInner>
      </ExpansionContainerOuter>
    </div>
  );
}

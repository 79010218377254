/**
 * Generated by orval v6.28.2 🍺
 * Do not edit manually.
 * GeminiAPI
 * OpenAPI spec version: v1
 */
import { useMutation, useQuery } from '@tanstack/react-query';
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import type { OperationJob } from '.././models';
import { AxiosInstance } from '../../axios-instance';
import type { ErrorType, BodyType } from '../../axios-instance';

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

export const getOperationJob = (
  options?: SecondParameter<typeof AxiosInstance>,
  signal?: AbortSignal,
) => {
  return AxiosInstance<OperationJob[]>(
    { url: `/api/OperationJobs`, method: 'GET', signal },
    options,
  );
};

export const getGetOperationJobQueryKey = () => {
  return [`/api/OperationJobs`] as const;
};

export const getGetOperationJobQueryOptions = <
  TData = Awaited<ReturnType<typeof getOperationJob>>,
  TError = ErrorType<void>,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof getOperationJob>>, TError, TData>
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetOperationJobQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getOperationJob>>> = ({
    signal,
  }) => getOperationJob(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getOperationJob>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetOperationJobQueryResult = NonNullable<
  Awaited<ReturnType<typeof getOperationJob>>
>;
export type GetOperationJobQueryError = ErrorType<void>;

export const useGetOperationJob = <
  TData = Awaited<ReturnType<typeof getOperationJob>>,
  TError = ErrorType<void>,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof getOperationJob>>, TError, TData>
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetOperationJobQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const postOperationJob = (
  operationJob: BodyType<OperationJob>,
  options?: SecondParameter<typeof AxiosInstance>,
) => {
  return AxiosInstance<OperationJob>(
    {
      url: `/api/OperationJobs`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: operationJob,
    },
    options,
  );
};

export const getPostOperationJobMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postOperationJob>>,
    TError,
    { data: BodyType<OperationJob> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postOperationJob>>,
  TError,
  { data: BodyType<OperationJob> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postOperationJob>>,
    { data: BodyType<OperationJob> }
  > = (props) => {
    const { data } = props ?? {};

    return postOperationJob(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostOperationJobMutationResult = NonNullable<
  Awaited<ReturnType<typeof postOperationJob>>
>;
export type PostOperationJobMutationBody = BodyType<OperationJob>;
export type PostOperationJobMutationError = ErrorType<void>;

export const usePostOperationJob = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postOperationJob>>,
    TError,
    { data: BodyType<OperationJob> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof postOperationJob>>,
  TError,
  { data: BodyType<OperationJob> },
  TContext
> => {
  const mutationOptions = getPostOperationJobMutationOptions(options);

  return useMutation(mutationOptions);
};
export const getOperationJobById = (
  id: number,
  options?: SecondParameter<typeof AxiosInstance>,
  signal?: AbortSignal,
) => {
  return AxiosInstance<OperationJob>(
    {
      url: `/api/OperationJobs/${encodeURIComponent(String(id))}`,
      method: 'GET',
      signal,
    },
    options,
  );
};

export const getGetOperationJobByIdQueryKey = (id: number) => {
  return [`/api/OperationJobs/${id}`] as const;
};

export const getGetOperationJobByIdQueryOptions = <
  TData = Awaited<ReturnType<typeof getOperationJobById>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getOperationJobById>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetOperationJobByIdQueryKey(id);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getOperationJobById>>
  > = ({ signal }) => getOperationJobById(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getOperationJobById>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetOperationJobByIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof getOperationJobById>>
>;
export type GetOperationJobByIdQueryError = ErrorType<void>;

export const useGetOperationJobById = <
  TData = Awaited<ReturnType<typeof getOperationJobById>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getOperationJobById>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetOperationJobByIdQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const putOperationJobById = (
  id: number,
  operationJob: BodyType<OperationJob>,
  options?: SecondParameter<typeof AxiosInstance>,
) => {
  return AxiosInstance<OperationJob>(
    {
      url: `/api/OperationJobs/${encodeURIComponent(String(id))}`,
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      data: operationJob,
    },
    options,
  );
};

export const getPutOperationJobByIdMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putOperationJobById>>,
    TError,
    { id: number; data: BodyType<OperationJob> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof putOperationJobById>>,
  TError,
  { id: number; data: BodyType<OperationJob> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof putOperationJobById>>,
    { id: number; data: BodyType<OperationJob> }
  > = (props) => {
    const { id, data } = props ?? {};

    return putOperationJobById(id, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PutOperationJobByIdMutationResult = NonNullable<
  Awaited<ReturnType<typeof putOperationJobById>>
>;
export type PutOperationJobByIdMutationBody = BodyType<OperationJob>;
export type PutOperationJobByIdMutationError = ErrorType<void>;

export const usePutOperationJobById = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putOperationJobById>>,
    TError,
    { id: number; data: BodyType<OperationJob> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof putOperationJobById>>,
  TError,
  { id: number; data: BodyType<OperationJob> },
  TContext
> => {
  const mutationOptions = getPutOperationJobByIdMutationOptions(options);

  return useMutation(mutationOptions);
};
export const deleteOperationJobById = (
  id: number,
  options?: SecondParameter<typeof AxiosInstance>,
) => {
  return AxiosInstance<OperationJob>(
    {
      url: `/api/OperationJobs/${encodeURIComponent(String(id))}`,
      method: 'DELETE',
    },
    options,
  );
};

export const getDeleteOperationJobByIdMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteOperationJobById>>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteOperationJobById>>,
  TError,
  { id: number },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteOperationJobById>>,
    { id: number }
  > = (props) => {
    const { id } = props ?? {};

    return deleteOperationJobById(id, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteOperationJobByIdMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteOperationJobById>>
>;

export type DeleteOperationJobByIdMutationError = ErrorType<void>;

export const useDeleteOperationJobById = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteOperationJobById>>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof deleteOperationJobById>>,
  TError,
  { id: number },
  TContext
> => {
  const mutationOptions = getDeleteOperationJobByIdMutationOptions(options);

  return useMutation(mutationOptions);
};

import { faCircleNotch } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { IVideoViewer, IVideoViewerController } from '@agerpoint/types';
import { useGlobalStore } from '@agerpoint/utilities';

export const VideoViewer = ({ controller: setController }: IVideoViewer) => {
  const [url, setUrl] = useState<string>();

  const [viewerReady, setViewerReady] = useState<boolean>(false);

  const loadVideo = useCallback((url: string) => {
    setUrl(url);
  }, []);

  const {
    sidebar: { isOpen: sidebarOpen },
  } = useGlobalStore();

  const controller: IVideoViewerController = useMemo(
    () => ({
      info: {
        viewerReady,
      },
      loadVideo,
    }),
    [loadVideo, viewerReady]
  );

  useEffect(() => {
    setViewerReady(true);
    return () => {
      setViewerReady(false);
    };
  }, []);

  useEffect(() => {
    setController?.(controller);
  }, [controller, setController]);

  return (
    <div className="w-full h-full">
      <div
        className={`flex justify-center items-center w-full h-full text-white text-lg text-center ${
          sidebarOpen ? 'pl-sidebar' : ''
        }`}
        style={{
          background: 'radial-gradient(#1b292f, #0c1417)',
        }}
      >
        {url === undefined ? (
          <div className="flex flex-row gap-2">
            <span>Loading Video...</span>
            <FontAwesomeIcon icon={faCircleNotch} spin />
          </div>
        ) : (
          <div className="p-10 size-full flex-center rounded-lg overflow-hidden shadow-lg">
            <video
              controls
              crossOrigin="anonymous"
              src={url}
              className="size-full rounded-lg"
            />
          </div>
        )}
      </div>
    </div>
  );
};

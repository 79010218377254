// IAnnotations3d.ts
import { Dispatch, SetStateAction } from 'react';
import {
  Group,
  Mesh,
  Object3D,
  OrthographicCamera,
  PerspectiveCamera,
  Scene,
  Sprite,
  Vector3,
} from 'three';
import { Line2 } from 'three/examples/jsm/lines/Line2';

import { HexColor } from './colors';
import { EventBusNames } from './event-bus';
import { ColorsThreeD } from './potree-types';
import { IMarkerObj, MarkerObjOptions } from './viewer';

export enum Annotation3dPoints {
  CaptureImageLocation = 'CaptureImageLocation',
  PendingLocationMarker = 'PendingLocationMarker',
  LocationMarker = 'LocationMarker',
  AnnotationPoint = 'AnnotationPoint',
  PendingMultiPointMarker = 'PendingMultiPointMarker',
  MultiPointMarker = 'MultiPointMarker',
}

export enum Annotation3dLines {
  CaptureLine = 'CaptureLine',
  PendingLine = 'PendingLine',
  LineMarker = 'LineMarker',
  PolygonMarker = 'PolygonMarker',
  CaptureObjectLine = 'CaptureObjectLine',
  CaptureObjectPolygon = 'CaptureObjectPolygon',
  AnnotationLine = 'AnnotationLine',
}

export enum Annotation3dPolygons {
  PendingPolygon = 'PendingPolygon',
  AnnotationPolygon = 'AnnotationPolygon',
}

export interface ICustomMesh extends Mesh {
  customType?: Annotation3dPoints;
  updatePosition: (pos: Vector3) => void;
  uniqueId: string;
  callback: (id: string) => void;
}

export interface CustomSprite extends Sprite {
  customType?: Annotation3dPoints;
  updatePosition: (pos: Vector3) => void;
  uniqueId: string;
}

export interface CustomGroup extends Group {
  customType?: Annotation3dPoints;
  updatePosition: (pos: Vector3) => void;
  uniqueId: string;
  callback: (id: string) => void;
}

export interface ICustomLine extends Line2 {
  customType?: Annotation3dLines | Annotation3dPolygons;
  updatePosition: (pos: Vector3[]) => void;
  uniqueId: string;
}

export interface IAnnotations3dSelected {
  id: string;
  type: Annotation3dPoints | Annotation3dLines | Annotation3dPolygons;
}

export interface IAnnotations2d {
  add2dPoint(
    eventName: EventBusNames,
    pointId: string,
    position: Vector3,
    options: MarkerObjOptions
  ): void;
  get2dPoints(): { [key: string]: IPoint2d };
  clear2dPoints(): void;
  clear2dPointById(id: string): void;
  render(): void;
  destroy(): void;
}

export interface IAnnotations3d {
  add3dPoint(
    id: string,
    position: Vector3,
    type: Annotation3dPoints,
    color?: ColorsThreeD,
    visible?: boolean
  ): IPoint3d;

  add3dLine(
    id: string,
    points: Vector3[],
    type: Annotation3dLines,
    color?: ColorsThreeD
  ): ILine3d; // Specify the correct return type based on your implementation

  add3dSprite(
    id: string,
    position: Vector3,
    type: Annotation3dPoints,
    color?: ColorsThreeD
  ): IPoint3d; // Specify the correct return type based on your implementation

  remove3dSpriteById(id: string): void;

  add3dPolygon(
    id: string,
    points: Vector3[],
    type: Annotation3dPolygons,
    color?: ColorsThreeD
  ): ILine3d; // Specify the correct return type based on your implementation

  remove3dPointById(id: string): void;

  remove3dPointsByType(type: Annotation3dPoints): void;

  get3dPointById(id: string): IPoint3d; // Specify the correct return type based on your implementation

  get3dLinePositionById(id: string): Vector3[] | undefined;

  highlight3dPointById(id: string): void;

  hide3dPointsByType(type: Annotation3dPoints): void;

  show3dPointsByType(type: Annotation3dPoints): void;

  getNew3dPointId(): string;

  getNew3dLineId(): string;

  getNew3dPolygonId(): string;

  update3dLinePosition(id: string, points: Vector3[]): void;

  update3dPolygonPosition(id: string, points: Vector3[]): void;

  finishCreating3dLine(id: string): void;

  clear3dPoints(): void;

  clear3dLines(): void;

  clear3dPolygons(): void;

  savePoint(id: string): void;

  saveLine(id: string): void;

  savePolygon(id: string): void;

  destroyGeometry(): void;

  getAllGeometry(): {
    points: { [key: string]: IPoint3d };
    lines: { [key: string]: ILine3d };
    polygons: { [key: string]: ILine3d };
    selected: IAnnotations3dSelected | null;
  };

  subscribeToAllGeometryChanges(
    listener: Dispatch<SetStateAction<IAnnotations3dGeometry>>
  ): () => void;
}

export interface IGeometryBase extends IAnnotationBase {
  doZoom(object: Object3D): void;
  dispose(): void;
  delete(): void;
  disposeAndDelete(): void;
  userData: {
    originalColor: ColorsThreeD | HexColor;
    originalScale?: Vector3;
    originalLineWidth?: number;
  };
  highlight(color?: ColorsThreeD | HexColor): void;
  unHighlight(): void;
  highlightColor: ColorsThreeD | HexColor;
}

export interface IPoint3d extends IGeometryBase {
  readonly object: ICustomMesh | CustomSprite | CustomGroup;
  readonly uniqueId: string;
  type: Annotation3dPoints;
  isVisible: boolean;
  updateColor(color: ColorsThreeD | HexColor): void;
  hide(): void;
  show(): void;
  updateVisibility(isVisible: boolean): void;
  updatePosition(pos: Vector3): void;
  updateType(type: Annotation3dPoints): void;
  zoomTo(): void;
  updateLookAt(): void;
}

export interface ILine3d extends IGeometryBase {
  readonly uniqueId: string;
  type: Annotation3dLines | Annotation3dPolygons;
  isVisible: boolean;
  hide(): void;
  show(): void;
  updateVisibility(isVisible: boolean): void;
  updatePosition(points: Vector3[]): void;
  updateType(type: Annotation3dLines | Annotation3dPolygons): void;
  updateColor(color: ColorsThreeD | HexColor): void;
  getPosition(): Vector3[];
  calculateArea(): number;
  calculateLength(): number;
  zoomTo(): void;
  highlight(): void;
  unHighlight(): void;
}

export interface IMultiPoint3d extends IGeometryBase {
  points: IPoint3d[];
  type: Annotation3dPoints;
  readonly uniqueId: string;
  isVisible: boolean;
  updatePositions(points: Vector3[]): void;
  updateVisibility(isVisible: boolean): void;
  updateType(type: Annotation3dPoints): void;
  updateColor(color: ColorsThreeD | HexColor): void;
  zoomTo(): void;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type AnyFunction = (...args: any[]) => any;

export interface IAnnotationBase {
  scene: Scene;
  perspectiveCamera: PerspectiveCamera;
  canvas?: HTMLCanvasElement;
  isPotree: boolean;
  orthographicCamera: OrthographicCamera;
  color: ColorsThreeD | HexColor;

  destroy(): void;
  // notifyListeners(): void;
}

export interface IAnnotations3dGeometry {
  points: IPoint3d[];
  multiPoints: IMultiPoint3d[];
  lines: ILine3d[];
  polygons: ILine3d[];
  selected: {
    id: string;
    type: Annotation3dPoints | Annotation3dLines | Annotation3dPolygons;
  } | null;
}

export interface IPoint2d {
  marker: IMarkerObj;
  dispose(): void;
  render(): void;
}

export interface AnnotationGeometry {
  points: { [key: string]: IPoint3d };
  lines: { [key: string]: ILine3d };
  polygons: { [key: string]: ILine3d };
  multiPoints: { [key: string]: IMultiPoint3d };
}

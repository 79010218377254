/**
 * Generated by orval v6.28.2 🍺
 * Do not edit manually.
 * GeminiAPI
 * OpenAPI spec version: v1
 */
import { useMutation, useQuery } from '@tanstack/react-query';
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import type { CaptureCustomAttribute } from '.././models';
import { AxiosInstance } from '../../axios-instance';
import type { ErrorType, BodyType } from '../../axios-instance';

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

export const getCaptureCustomAttributes = (
  options?: SecondParameter<typeof AxiosInstance>,
  signal?: AbortSignal,
) => {
  return AxiosInstance<CaptureCustomAttribute[]>(
    { url: `/api/CaptureCustomAttribute`, method: 'GET', signal },
    options,
  );
};

export const getGetCaptureCustomAttributesQueryKey = () => {
  return [`/api/CaptureCustomAttribute`] as const;
};

export const getGetCaptureCustomAttributesQueryOptions = <
  TData = Awaited<ReturnType<typeof getCaptureCustomAttributes>>,
  TError = ErrorType<void>,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getCaptureCustomAttributes>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetCaptureCustomAttributesQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getCaptureCustomAttributes>>
  > = ({ signal }) => getCaptureCustomAttributes(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getCaptureCustomAttributes>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetCaptureCustomAttributesQueryResult = NonNullable<
  Awaited<ReturnType<typeof getCaptureCustomAttributes>>
>;
export type GetCaptureCustomAttributesQueryError = ErrorType<void>;

export const useGetCaptureCustomAttributes = <
  TData = Awaited<ReturnType<typeof getCaptureCustomAttributes>>,
  TError = ErrorType<void>,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getCaptureCustomAttributes>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetCaptureCustomAttributesQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const postCaptureCustomAttribute = (
  captureCustomAttribute: BodyType<CaptureCustomAttribute>,
  options?: SecondParameter<typeof AxiosInstance>,
) => {
  return AxiosInstance<CaptureCustomAttribute>(
    {
      url: `/api/CaptureCustomAttribute`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: captureCustomAttribute,
    },
    options,
  );
};

export const getPostCaptureCustomAttributeMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postCaptureCustomAttribute>>,
    TError,
    { data: BodyType<CaptureCustomAttribute> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postCaptureCustomAttribute>>,
  TError,
  { data: BodyType<CaptureCustomAttribute> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postCaptureCustomAttribute>>,
    { data: BodyType<CaptureCustomAttribute> }
  > = (props) => {
    const { data } = props ?? {};

    return postCaptureCustomAttribute(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostCaptureCustomAttributeMutationResult = NonNullable<
  Awaited<ReturnType<typeof postCaptureCustomAttribute>>
>;
export type PostCaptureCustomAttributeMutationBody =
  BodyType<CaptureCustomAttribute>;
export type PostCaptureCustomAttributeMutationError = ErrorType<void>;

export const usePostCaptureCustomAttribute = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postCaptureCustomAttribute>>,
    TError,
    { data: BodyType<CaptureCustomAttribute> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof postCaptureCustomAttribute>>,
  TError,
  { data: BodyType<CaptureCustomAttribute> },
  TContext
> => {
  const mutationOptions = getPostCaptureCustomAttributeMutationOptions(options);

  return useMutation(mutationOptions);
};
export const getCaptureCustomAttributeById = (
  id: number,
  options?: SecondParameter<typeof AxiosInstance>,
  signal?: AbortSignal,
) => {
  return AxiosInstance<CaptureCustomAttribute>(
    {
      url: `/api/CaptureCustomAttribute/${encodeURIComponent(String(id))}`,
      method: 'GET',
      signal,
    },
    options,
  );
};

export const getGetCaptureCustomAttributeByIdQueryKey = (id: number) => {
  return [`/api/CaptureCustomAttribute/${id}`] as const;
};

export const getGetCaptureCustomAttributeByIdQueryOptions = <
  TData = Awaited<ReturnType<typeof getCaptureCustomAttributeById>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getCaptureCustomAttributeById>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetCaptureCustomAttributeByIdQueryKey(id);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getCaptureCustomAttributeById>>
  > = ({ signal }) => getCaptureCustomAttributeById(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getCaptureCustomAttributeById>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetCaptureCustomAttributeByIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof getCaptureCustomAttributeById>>
>;
export type GetCaptureCustomAttributeByIdQueryError = ErrorType<void>;

export const useGetCaptureCustomAttributeById = <
  TData = Awaited<ReturnType<typeof getCaptureCustomAttributeById>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getCaptureCustomAttributeById>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetCaptureCustomAttributeByIdQueryOptions(
    id,
    options,
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const putCaptureCustomAttributeById = (
  id: number,
  captureCustomAttribute: BodyType<CaptureCustomAttribute>,
  options?: SecondParameter<typeof AxiosInstance>,
) => {
  return AxiosInstance<CaptureCustomAttribute>(
    {
      url: `/api/CaptureCustomAttribute/${encodeURIComponent(String(id))}`,
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      data: captureCustomAttribute,
    },
    options,
  );
};

export const getPutCaptureCustomAttributeByIdMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putCaptureCustomAttributeById>>,
    TError,
    { id: number; data: BodyType<CaptureCustomAttribute> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof putCaptureCustomAttributeById>>,
  TError,
  { id: number; data: BodyType<CaptureCustomAttribute> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof putCaptureCustomAttributeById>>,
    { id: number; data: BodyType<CaptureCustomAttribute> }
  > = (props) => {
    const { id, data } = props ?? {};

    return putCaptureCustomAttributeById(id, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PutCaptureCustomAttributeByIdMutationResult = NonNullable<
  Awaited<ReturnType<typeof putCaptureCustomAttributeById>>
>;
export type PutCaptureCustomAttributeByIdMutationBody =
  BodyType<CaptureCustomAttribute>;
export type PutCaptureCustomAttributeByIdMutationError = ErrorType<void>;

export const usePutCaptureCustomAttributeById = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putCaptureCustomAttributeById>>,
    TError,
    { id: number; data: BodyType<CaptureCustomAttribute> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof putCaptureCustomAttributeById>>,
  TError,
  { id: number; data: BodyType<CaptureCustomAttribute> },
  TContext
> => {
  const mutationOptions =
    getPutCaptureCustomAttributeByIdMutationOptions(options);

  return useMutation(mutationOptions);
};
export const deleteCaptureCustomAttribute = (
  id: number,
  options?: SecondParameter<typeof AxiosInstance>,
) => {
  return AxiosInstance<CaptureCustomAttribute>(
    {
      url: `/api/CaptureCustomAttribute/${encodeURIComponent(String(id))}`,
      method: 'DELETE',
    },
    options,
  );
};

export const getDeleteCaptureCustomAttributeMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteCaptureCustomAttribute>>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteCaptureCustomAttribute>>,
  TError,
  { id: number },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteCaptureCustomAttribute>>,
    { id: number }
  > = (props) => {
    const { id } = props ?? {};

    return deleteCaptureCustomAttribute(id, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteCaptureCustomAttributeMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteCaptureCustomAttribute>>
>;

export type DeleteCaptureCustomAttributeMutationError = ErrorType<void>;

export const useDeleteCaptureCustomAttribute = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteCaptureCustomAttribute>>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof deleteCaptureCustomAttribute>>,
  TError,
  { id: number },
  TContext
> => {
  const mutationOptions =
    getDeleteCaptureCustomAttributeMutationOptions(options);

  return useMutation(mutationOptions);
};
export const getCaptureCustomAttributesByCaptureId = (
  id: number,
  options?: SecondParameter<typeof AxiosInstance>,
  signal?: AbortSignal,
) => {
  return AxiosInstance<CaptureCustomAttribute[]>(
    {
      url: `/api/CaptureCustomAttribute/Capture/${encodeURIComponent(String(id))}`,
      method: 'GET',
      signal,
    },
    options,
  );
};

export const getGetCaptureCustomAttributesByCaptureIdQueryKey = (
  id: number,
) => {
  return [`/api/CaptureCustomAttribute/Capture/${id}`] as const;
};

export const getGetCaptureCustomAttributesByCaptureIdQueryOptions = <
  TData = Awaited<ReturnType<typeof getCaptureCustomAttributesByCaptureId>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getCaptureCustomAttributesByCaptureId>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetCaptureCustomAttributesByCaptureIdQueryKey(id);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getCaptureCustomAttributesByCaptureId>>
  > = ({ signal }) =>
    getCaptureCustomAttributesByCaptureId(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getCaptureCustomAttributesByCaptureId>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetCaptureCustomAttributesByCaptureIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof getCaptureCustomAttributesByCaptureId>>
>;
export type GetCaptureCustomAttributesByCaptureIdQueryError = ErrorType<void>;

export const useGetCaptureCustomAttributesByCaptureId = <
  TData = Awaited<ReturnType<typeof getCaptureCustomAttributesByCaptureId>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getCaptureCustomAttributesByCaptureId>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetCaptureCustomAttributesByCaptureIdQueryOptions(
    id,
    options,
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getCaptureCustomAttributesByCaptureExtractionJobId = (
  id: number,
  options?: SecondParameter<typeof AxiosInstance>,
  signal?: AbortSignal,
) => {
  return AxiosInstance<CaptureCustomAttribute[]>(
    {
      url: `/api/CaptureCustomAttribute/CaptureExtractionJob/${encodeURIComponent(String(id))}`,
      method: 'GET',
      signal,
    },
    options,
  );
};

export const getGetCaptureCustomAttributesByCaptureExtractionJobIdQueryKey = (
  id: number,
) => {
  return [`/api/CaptureCustomAttribute/CaptureExtractionJob/${id}`] as const;
};

export const getGetCaptureCustomAttributesByCaptureExtractionJobIdQueryOptions =
  <
    TData = Awaited<
      ReturnType<typeof getCaptureCustomAttributesByCaptureExtractionJobId>
    >,
    TError = ErrorType<void>,
  >(
    id: number,
    options?: {
      query?: Partial<
        UseQueryOptions<
          Awaited<
            ReturnType<
              typeof getCaptureCustomAttributesByCaptureExtractionJobId
            >
          >,
          TError,
          TData
        >
      >;
      request?: SecondParameter<typeof AxiosInstance>;
    },
  ) => {
    const { query: queryOptions, request: requestOptions } = options ?? {};

    const queryKey =
      queryOptions?.queryKey ??
      getGetCaptureCustomAttributesByCaptureExtractionJobIdQueryKey(id);

    const queryFn: QueryFunction<
      Awaited<
        ReturnType<typeof getCaptureCustomAttributesByCaptureExtractionJobId>
      >
    > = ({ signal }) =>
      getCaptureCustomAttributesByCaptureExtractionJobId(
        id,
        requestOptions,
        signal,
      );

    return {
      queryKey,
      queryFn,
      enabled: !!id,
      ...queryOptions,
    } as UseQueryOptions<
      Awaited<
        ReturnType<typeof getCaptureCustomAttributesByCaptureExtractionJobId>
      >,
      TError,
      TData
    > & { queryKey: QueryKey };
  };

export type GetCaptureCustomAttributesByCaptureExtractionJobIdQueryResult =
  NonNullable<
    Awaited<
      ReturnType<typeof getCaptureCustomAttributesByCaptureExtractionJobId>
    >
  >;
export type GetCaptureCustomAttributesByCaptureExtractionJobIdQueryError =
  ErrorType<void>;

export const useGetCaptureCustomAttributesByCaptureExtractionJobId = <
  TData = Awaited<
    ReturnType<typeof getCaptureCustomAttributesByCaptureExtractionJobId>
  >,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof getCaptureCustomAttributesByCaptureExtractionJobId>
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getGetCaptureCustomAttributesByCaptureExtractionJobIdQueryOptions(
      id,
      options,
    );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getCaptureCustomAttributesByAnalyticRequestId = (
  id: number,
  options?: SecondParameter<typeof AxiosInstance>,
  signal?: AbortSignal,
) => {
  return AxiosInstance<CaptureCustomAttribute[]>(
    {
      url: `/api/CaptureCustomAttribute/AnalyticRequest/${encodeURIComponent(String(id))}`,
      method: 'GET',
      signal,
    },
    options,
  );
};

export const getGetCaptureCustomAttributesByAnalyticRequestIdQueryKey = (
  id: number,
) => {
  return [`/api/CaptureCustomAttribute/AnalyticRequest/${id}`] as const;
};

export const getGetCaptureCustomAttributesByAnalyticRequestIdQueryOptions = <
  TData = Awaited<
    ReturnType<typeof getCaptureCustomAttributesByAnalyticRequestId>
  >,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof getCaptureCustomAttributesByAnalyticRequestId>
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetCaptureCustomAttributesByAnalyticRequestIdQueryKey(id);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getCaptureCustomAttributesByAnalyticRequestId>>
  > = ({ signal }) =>
    getCaptureCustomAttributesByAnalyticRequestId(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getCaptureCustomAttributesByAnalyticRequestId>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetCaptureCustomAttributesByAnalyticRequestIdQueryResult =
  NonNullable<
    Awaited<ReturnType<typeof getCaptureCustomAttributesByAnalyticRequestId>>
  >;
export type GetCaptureCustomAttributesByAnalyticRequestIdQueryError =
  ErrorType<void>;

export const useGetCaptureCustomAttributesByAnalyticRequestId = <
  TData = Awaited<
    ReturnType<typeof getCaptureCustomAttributesByAnalyticRequestId>
  >,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof getCaptureCustomAttributesByAnalyticRequestId>
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getGetCaptureCustomAttributesByAnalyticRequestIdQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};
